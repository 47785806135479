import React, { FC } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import { checkIsActivePage } from '../../../helpers/checkIsActivePage';

const LinkWrapper = styled(Link) <{ $isActive?: boolean, $isGreen?: boolean }>`
  text-decoration: none;
  position: relative;
  &:after {
    bottom: -7px;
    position: absolute;
    content: '';
    height: 2px;
    width: 0;
    display: block;
    transition: width .5s;
  }
  ${({ $isActive, $isGreen }) => $isActive && `
    &:after {
      width: 100%;
      background: ${!$isGreen ? theme.colors.primary : theme.colors.lightGreen};
    }
  `}
`;

const HeaderLinkWrapper: FC<{ to: string, isGreen?: boolean, 'data-test-id'?: string }> = ({
  children,
  to,
  isGreen,
  'data-test-id': dataTestId,
}) => {
  const { pathname } = useLocation();
  return (
    <LinkWrapper
      data-test-id={dataTestId}
      to={to}
      $isActive={checkIsActivePage(to, pathname)}
      $isGreen={isGreen}
    >
      {children}
    </LinkWrapper>
  );
};

export default HeaderLinkWrapper;
