import { createAction } from '@reduxjs/toolkit';
import { TransactionHistoryItem } from '@starly/starly-types';

export interface TransactionRequestPayload {
  addr: string
}

export interface TransacationsResponsePayload {
  transactions: TransactionHistoryItem[]
}

export interface SetLoadingPayload {
  isLoading: boolean,
}

export const transactionsRequest = createAction<TransactionRequestPayload>('transactions/request');

export const transactionsResponse = createAction<TransacationsResponsePayload>('transactions/response');

export const transactionsLoading = createAction<SetLoadingPayload>('transactions/set-loading');
