/* eslint-disable react/jsx-no-comment-textnodes */
import React, {
  FC,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadErrorImage } from 'static/ic-upload-error-asset.svg';
import { ReactComponent as UploadImage } from 'static/ic-upload-asset.svg';
import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';
import Typography from 'components/Typography';
import { writeCardImageRequest } from 'store/collection/collectionActions';
import { selectActiveCard } from 'store/app/appSelectors';
import { setActiveCard } from 'store/app/appActions';
import { getDiffByRarity } from 'util/rarity';
import { CardProps } from './types';

import {
  AnimatedDots,
  StyledCardContainer,
  StyledCardInfoContainer,
  StyledQRCode,
  StyledQRCodeContainer,
  StyledTypeContainer,
  StyledImageLoadContainer,
  StyledImage,
  StyledEmptyImage,
  StyledImageText,
  StyledCardBackground,
  StyledVideoLoadContainer,
  StyledVideoWrapper,
  StyledVideoWrapperInner,
  MutedIcon,
  StyledCardFixedWrapper,
  UploadWrapper,
  UploadTitle,
  Processing,
  UploadValue,
  StyledActualImage,
  ChangeBtn,
  StyledHeader,
  StyledContentText,
  StyledContent,
} from './styled';
import { CARD_MEDIA_MAX_SIZE_BYTES, isSupportedMediaFormat } from '../../util/supportedMedia';

const MainMedia: FC<{
  errors: { [key: string]: string },
  total: number,
  isUpload: boolean,
  uploadProcess: number,
  isFullScreen: boolean,
  currMedia: string,
  id: string,
  switchMuted: (e: any) => void,
  isMuted: boolean,
  mediaState: string,
  mediaType: string,
  mutedBtnWidth: number,
}> = ({
  errors,
  total,
  isUpload,
  uploadProcess,
  isFullScreen,
  currMedia,
  id,
  switchMuted,
  isMuted,
  mediaState,
  mediaType,
  mutedBtnWidth,
}) => {
  const { t } = useTranslation();

  if (!isUpload) {
    return (
      <UploadWrapper>
        <UploadTitle>{t('steps.two.card.uploading')}</UploadTitle>
        <UploadValue>{`${uploadProcess}%`}</UploadValue>
      </UploadWrapper>
    );
  }
  if (currMedia) {
    return mediaType === 'image' ? (
      <>
        {!isFullScreen && <ChangeBtn htmlFor={id}>{t('steps.two.card.change')}</ChangeBtn>}
        <StyledActualImage $fullscreen={isFullScreen} url={currMedia} />
      </>
    ) : (
      <StyledVideoWrapper>
        <StyledVideoWrapperInner>
          {!isFullScreen && <ChangeBtn htmlFor={id}>{t('steps.two.card.change')}</ChangeBtn>}
          <MutedIcon
            onClick={switchMuted}
            style={{
              width: mutedBtnWidth,
              height: mutedBtnWidth,
            }}
          >
            {isMuted ? <SoundOn /> : <SoundOff />}
          </MutedIcon>
          {(mediaState === 'new') && (
            <Processing>
              <AnimatedDots>{t('steps.two.card.processing')}</AnimatedDots>
            </Processing>
          )}
          <StyledVideoLoadContainer
            key={currMedia}
            $fullscreen={isFullScreen}
            isCreate
            autoPlay
            muted={isMuted}
            controls={false}
            loop
            playsInline
          >
            <source src={currMedia} />
          </StyledVideoLoadContainer>
        </StyledVideoWrapperInner>
      </StyledVideoWrapper>
    );
  }
  const { [`image${total}`]: error } = errors;
  return (
    <StyledEmptyImage error={!!error} htmlFor={id}>
      {error ? <UploadErrorImage /> : <UploadImage />}
      <StyledImageText>{t('steps.two.card.uploadText')}</StyledImageText>
    </StyledEmptyImage>
  );
};

const CreateCard: FC<{
  card: CardProps;
  total: number
  edition?: any,
  errors: any,
  isVideo: boolean,
  description: string,
  uploadProcess: number,
  isUpload: boolean,
  mediaType: string,
  currMedia: string,
  colId: string
  getValues: (data?: string) => any
  mediaState: string,
  register: ((Ref: any, RegisterOptions?: any) =>
  ({ onChange: any, onBlur: any, name: any, ref: any }))
  onMediaError: () => void,
}> = ({
  errors,
  card: {
    title,
    edition,
    rarity,
    cardRarity,
    author,
    collection,
    totalEditions,
    qrCodeUrl,
  },
  uploadProcess,
  isUpload,
  mediaType,
  colId,
  mediaState,
  total,
  description,
  getValues,
  register,
  onMediaError,
}) => {
  const id = `create-card-${total}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector((state: any) => (
    state.auth?.userId || ''
  ));
  const currMedia = useSelector((state: any) => (
    state.collections.activeCollection.step2[rarity][total - 1].url
  ));
  const cardInfo = useSelector((currState: any) => currState.collections.activeCollection.cardInfo);

  const activeCard = useSelector(selectActiveCard);
  const isActiveCard = activeCard === `${colId}/${id}/${rarity}`;

  const [isFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(!isActiveCard);
  const switchMuted = (e: any) => {
    setIsMuted(!isMuted);
    e.preventDefault();

    if (colId) dispatch(setActiveCard(isMuted ? `${colId}/${id}/${rarity}` : null));
  };
  const loadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.[0] && files[0]) {
      if (!isSupportedMediaFormat(files[0]) || files[0].size >= CARD_MEDIA_MAX_SIZE_BYTES) {
        onMediaError();
        return;
      }
      const cardId = (total ?? 1) + getDiffByRarity(rarity, cardInfo);
      dispatch(writeCardImageRequest({
        colId,
        userId,
        file: files[0],
        cardId,
        values: getValues(),
      }));
      if (register) register(`image${total}`).onChange(e);
    }
  };

  const cardContainer = useRef(null);

  const [adaptiveStyles, setAdaptiveStyles] = useState({
    fonts: {
      header: 8,
      title: 24,
      cardNumber: 24,
      cardCount: 13,
    },
    height: {
      title: 98,
      header: 22,
    },
    minHeight: {
      qrCode: 62,
    },
    width: {
      mutedBtn: 22,
    },
  });

  const onResize = () => {
    if (cardContainer.current) {
      // @ts-ignore
      const { width, height } = cardContainer.current.getBoundingClientRect();

      setAdaptiveStyles({
        fonts: {
          header: width / 64,
          title: width / 21,
          cardNumber: width / 21,
          cardCount: width / 39,
        },
        height: {
          title: height / 10.5,
          header: height / 33,
        },
        minHeight: {
          qrCode: height / 10,
        },
        width: {
          mutedBtn: width / 20,
        },
      });
    }
  };

  useEffect(() => {
    setIsMuted(!isActiveCard);
  }, [activeCard]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      onResize();
    };
  }, []);

  useEffect(() => {
    onResize();
  }, [isFullScreen]);

  return (
    <StyledCardFixedWrapper
      as="div"
      description={description}
      $fullscreen={isFullScreen}
      isCreateCard
      className="starly-card"
    >
      <StyledCardBackground
        $fullscreen={isFullScreen}
        rarity={rarity === 'reward' ? cardRarity : rarity}
        ref={cardContainer}
      >
        <StyledCardContainer>
          <StyledImageLoadContainer
            isUpload={isUpload}
            currMedia={currMedia}
            $fullscreen={isFullScreen}
            onClick={(e) => e.stopPropagation()}
          >
            <MainMedia
              errors={errors}
              total={total}
              isUpload={isUpload}
              uploadProcess={uploadProcess}
              isFullScreen={isFullScreen}
              currMedia={currMedia}
              id={id}
              switchMuted={switchMuted}
              isMuted={isMuted}
              mediaState={mediaState}
              mediaType={mediaType}
              mutedBtnWidth={adaptiveStyles.width.mutedBtn}
            />
            <StyledImage
              {...register(`image${total}`)}
              type="file"
              onChange={loadPhoto}
              id={id}
              name={`image${total}`}
            />
          </StyledImageLoadContainer>
          <StyledCardInfoContainer $fullscreen={isFullScreen}>
            <StyledHeader
              style={{
                height: adaptiveStyles.height.header,
                lineHeight: `${adaptiveStyles.height.header}px`,
              }}
            >
              <Typography
                lSpacing={0.8}
                lineHeight="normal"
                uppercase
                font="spaceMono"
                textColor="white"
                style={{
                  fontSize: `${adaptiveStyles.fonts.header}px`,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  lineHeight: 'normal',
                }}
              >
                {`${author} // `}
                <span style={{ fontWeight: 'bold' }}>
                  {collection}
                </span>
              </Typography>
            </StyledHeader>
            <StyledContent>
              <StyledContentText
                lSpacing={-0.75}
                bold
                uppercase
                style={{
                  maxHeight: `${adaptiveStyles.height.title}px`,
                  fontSize: `${adaptiveStyles.fonts.title}px`,
                }}
              >
                {title || t('steps.two.card.titleNa')}
              </StyledContentText>
              <StyledQRCodeContainer style={{ minHeight: adaptiveStyles.minHeight.qrCode }}>
                <StyledQRCode src={qrCodeUrl} />
              </StyledQRCodeContainer>
            </StyledContent>
            <StyledTypeContainer edition={edition}>
              <Typography
                lSpacing={0.5}
                font="syncopate"
                textColor="white"
                style={{
                  fontSize: `${edition ? adaptiveStyles.fonts.cardNumber : adaptiveStyles.fonts.cardCount}px`,
                  marginRight: `${edition ? '1px' : '0px'}`,
                  color: edition ? 'white' : '#aaaaaa',
                }}
                bold
              >
                {edition ? `#${edition}` : '#'}
              </Typography>
              <Typography
                lSpacing={-0.75}
                font="syncopate"
                style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                bold
              >
                /
              </Typography>
              <Typography
                lSpacing={-0.75}
                font="syncopate"
                style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                bold
              >
                {totalEditions}
              </Typography>
            </StyledTypeContainer>
          </StyledCardInfoContainer>
        </StyledCardContainer>
      </StyledCardBackground>
    </StyledCardFixedWrapper>
  );
};

export default CreateCard;
