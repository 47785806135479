import React, { useEffect, useState } from 'react';
import logo from 'static/starly-new-logo.svg';
import logoBeta from 'static/starly-logo-beta.png';
import logoBeta2x from 'static/starly-logo-beta@2x.png';
import logoBeta3x from 'static/starly-logo-beta@3x.png';
import menu from 'static/starly-menu.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toggleSideMenu } from 'store/sideMenu/sideMenuActions';
import { Link } from 'react-router-dom';
import {
  selectFlowFestModal,
  selectFlowModal,
  selectFlowNFTNYCModal,
  selectLowBalanceModal,
  selectWrongModal,
} from 'store/flow/flowSelectors';
import WalletModal from 'views/Modals/WalletModal';
import FlowFestWalletModal from 'views/Modals/WalletModal/FlowFestWalletModal';
import FlowNFTNYCWalletModal from 'views/Modals/WalletModal/FlowNFTNYCWalletModal';
import WrongModal from 'views/Modals/WalletWrong';
import TopUp from 'views/Modals/TopUp';
import PackBattleModal from 'views/Modals/PackBattleModal';
import { selectIsHeaderVisible } from 'store/app/appSelectors';
import { push } from 'connected-react-router';
import { checkFollowers } from 'views/FaceControl/utils';
import { vestingFetchVestings } from 'store/vesting/vestingActions';
import { HeaderProps } from './types';
import {
  StyledHeader,
  HeaderRow,
  HeaderColumn,
  StyledIcon,
  HeaderLogo,
  StyledList,
  StyledHeaderLink,
  GetInMobile,
  IconsContainer,
  GetInDesktop,
  HeaderWrapper,
  AdminBadge,
} from './styled';
import Dropdown from '../Dropdown';
import NotificationMenu from './components/NotificationMenu/NotificationMenu';
import HeaderIcon from './components/HeaderIcon';
import HeaderAvatar from './components/HeaderAvatar';
import MenuNav from './components/MenuNav';
import DropdownHeaderLink from './components/DropdownHeaderLink';
import { selectAuthAuthorized, selectAuthUserId, selectIsAdmin } from '../../store/auth/authSelectors';
import { selectSideMenuIsOpen, selectSideMenuItems } from '../../store/sideMenu/sideMenuSelectors';
import { selectUnreadNotificationsCount } from '../../store/notifications/notificationsSelectors';
import { selectUserById, selectPackBattleWinner } from '../../store/user/userSelectors';
import HeaderLinkWrapper from './components/HeaderLinkWrapper';
import { RouteTypes } from '../../RouteTypes';
import {
  notificationsSetRealtimeListener,
} from '../../store/notifications/notificationsActions';
import { userPacksCountRequest } from '../../store/pack/packActions';
import {
  selectActiveStep,
  selectIsCreateModalOpen,
  selectIsUnavailableModalOpen,
} from '../../store/collection/collectionSelectors';
import { userRequest } from '../../store/user/userActions';
import { setAuthRedirect, setLoginModal } from '../../store/login/loginActions';
import CreateModal from './components/CreateModal';
import { setCreateModal, setUnavailableModal } from '../../store/collection/collectionActions';
import { flowBalanceRequest } from '../../store/flow/flowActions';
import CreateUnavalible from '../CreateUnavalible';
import { marketplacePages } from '../../views/Marketplace/Marketplace';
import { rankingPages } from '../../views/Ranking/Ranking';
import { useWindowSize } from '../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../breakpoints';

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const path = useLocation();
  const { t } = useTranslation();

  // TODO replace avatarPath with selector from data
  const isAuthorized = useSelector(selectAuthAuthorized);
  const isAdmin = useSelector(selectIsAdmin);
  const isMobile = useWindowSize() < desktopBreakpoint;
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const userId = useSelector(selectAuthUserId);
  const user = useSelector(selectUserById(userId));
  const userPath = RouteTypes.User.replace(':username', user?.username);
  const activeStep = useSelector(selectActiveStep);
  // TODO replace avatarPath with selector from data
  const menuItems = useSelector(selectSideMenuItems);
  const sideMenuIsOpen = useSelector(selectSideMenuIsOpen);
  const unreadNotifications = useSelector(selectUnreadNotificationsCount);
  const isWrongModalOpen = useSelector(selectWrongModal);
  const isWalletModalOpen = useSelector(selectFlowModal);
  const isFlowFestWalletModalOpen = useSelector(selectFlowFestModal);
  const isFlowNFTNYCWalletModalOpen = useSelector(selectFlowNFTNYCModal);
  const isLowBalanceModalOpen = useSelector(selectLowBalanceModal);
  const isHeaderVisible = useSelector(selectIsHeaderVisible);
  const isCreateModalOpen = useSelector(selectIsCreateModalOpen);
  const isUnavailableModalOpen = useSelector(selectIsUnavailableModalOpen);
  const isPackBattleModalOpen = useSelector(selectPackBattleWinner(userId));
  const pushGetIn = () => {
    dispatch(setAuthRedirect({ onAuthRedirect: path.pathname }));
    dispatch(setLoginModal({ isOpen: true }));
  };

  const connections = user?.connections;
  const faceControlState = user?.face_control_state;

  useEffect(() => {
    if (isAuthorized) {
      dispatch(userRequest({ id: userId }));
      dispatch(notificationsSetRealtimeListener());
    }
  }, [isAuthorized]);

  useEffect(() => {
    setIsNotificationsOpen(false);
    setIsNavOpen(false);
    checkFollowers({
      faceControlState, connections, activeStep, id: user?.id, dispatch,
    });
  }, [path, isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(userPacksCountRequest());
      dispatch(flowBalanceRequest({}));
      dispatch(vestingFetchVestings());
    }
  }, [isAuthorized, path.pathname]);

  if (!isHeaderVisible) return null;

  /**
   * Handle scroll to top on drops page
   */
  const onLogoClick = () => {
    if (path.pathname === RouteTypes.Drops) {
      window.scrollTo({ left: 0, top: 0 });
    }
  };

  return (
    <>
      {isAdmin && (
        <AdminBadge>admin</AdminBadge>
      )}
      {isCreateModalOpen && (
        <CreateModal
          onClose={() => dispatch(setCreateModal({ isOpen: false }))}
          onCollectionOpen={() => {
            dispatch(setCreateModal({ isOpen: false }));
            dispatch(push(userPath));
          }}
        />
      )}
      {isUnavailableModalOpen && (
        <CreateUnavalible
          onHide={() => {
            dispatch(setUnavailableModal({ isOpen: false }));
          }}
          onContact={() => {
            dispatch(push(RouteTypes.WriteUs));
            dispatch(setUnavailableModal({ isOpen: false }));
          }}
        />
      )}
      {isWrongModalOpen && <WrongModal />}
      {isWalletModalOpen && <WalletModal />}
      {isFlowFestWalletModalOpen && <FlowFestWalletModal />}
      {isFlowNFTNYCWalletModalOpen && <FlowNFTNYCWalletModal />}
      {isLowBalanceModalOpen && <TopUp />}
      {isPackBattleModalOpen && <PackBattleModal />}
      <HeaderWrapper>
        <StyledHeader>
          <HeaderRow data-test-id="header">
            <HeaderColumn>
              <Link to={RouteTypes.Drops} onClick={onLogoClick}>
                <HeaderLogo src={isMobile ? logo : logoBeta} srcSet={isMobile ? logo : `${logoBeta2x} 2x, ${logoBeta3x} 3x`} alt={t('common.starlyLogo')} data-test-id="starly_logo" />
              </Link>
            </HeaderColumn>
            <HeaderColumn data-test-id="header_all_tabs">
              <StyledList>
                <HeaderLinkWrapper data-test-id="drops_link_wrapper" to={RouteTypes.Drops}>
                  <StyledHeaderLink data-test-id="drops">{t('header.drops')}</StyledHeaderLink>
                </HeaderLinkWrapper>
                <DropdownHeaderLink
                  data-test-id="marketplace"
                  links={marketplacePages}
                  to={RouteTypes.Marketplace}
                  pathKey={path.key}
                >
                  {t('header.marketplace')}
                </DropdownHeaderLink>
                <DropdownHeaderLink
                  data-test-id="ranking"
                  links={rankingPages}
                  to={RouteTypes.Ranking}
                  pathKey={path.key}
                >
                  {t('header.ranking')}
                </DropdownHeaderLink>
                <HeaderLinkWrapper data-test-id="token_link_wrapper" to={RouteTypes.TokenLanding}>
                  <StyledHeaderLink data-test-id="Token">{t('header.token')}</StyledHeaderLink>
                </HeaderLinkWrapper>
                <HeaderLinkWrapper data-test-id="staking_link_wrapper" to={RouteTypes.StakingNft}>
                  <StyledHeaderLink data-test-id="staking">{t('header.staking')}</StyledHeaderLink>
                </HeaderLinkWrapper>
                {!isAuthorized && (
                  <GetInDesktop size="small" onClick={pushGetIn} data-test-id="get_in">
                    <StyledHeaderLink>{t('header.getIn')}</StyledHeaderLink>
                  </GetInDesktop>
                )}
                <IconsContainer>
                  {isAuthorized && (
                    <>
                      <HeaderAvatar avatar={user?.avatar} pathLink={userPath} data-test-id="picture_avatar" />
                      <Dropdown
                        menu={<NotificationMenu />}
                        onOutsideClick={() => setIsNotificationsOpen(false)}
                        isOpen={isNotificationsOpen}
                      >
                        <HeaderIcon
                          type="bell"
                          onClick={() => {
                            setIsNotificationsOpen(!isNotificationsOpen);
                            setIsNavOpen(false);
                          }}
                          isRed={unreadNotifications > 0}
                        />
                      </Dropdown>
                    </>
                  )}
                  <Dropdown
                    menu={<MenuNav menuItems={menuItems} />}
                    onOutsideClick={() => setIsNavOpen(false)}
                    isOpen={isNavOpen}
                    data-test-id="menu1"
                  >
                    <HeaderIcon
                      type="down"
                      onClick={() => {
                        setIsNavOpen(!isNavOpen);
                        setIsNotificationsOpen(false);
                      }}
                    />
                  </Dropdown>
                </IconsContainer>
              </StyledList>
            </HeaderColumn>
            <HeaderColumn>
              {!isAuthorized && !sideMenuIsOpen && (
                <GetInMobile size="small" onClick={pushGetIn} data-test-id="get_in_mobile">
                  {t('header.getIn')}
                </GetInMobile>
              )}
              {!sideMenuIsOpen && (
                <StyledIcon data-test-id="menu_mobile" src={menu} alt="menu" onClick={() => dispatch(toggleSideMenu(true))} />
              )}
            </HeaderColumn>
          </HeaderRow>
        </StyledHeader>
      </HeaderWrapper>
    </>
  );
};

export default Header;
