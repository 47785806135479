import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import {
  InputErrorMessage, InputInput, InputLabel, InputWrapper,
} from './styled';
import { InputProps } from './types';

const Input: FC<InputProps> = ({
  id = shortid.generate(),
  danger = false,
  type,
  label,
  placeholder,
  errors,
  value,
  disabled = false,
  gradient = false,
  isCreate = false,
  block = false,
  autocomplete,
  onChange,
  onBlur,
  register,
  name = '',
  maxlength,
  className,
  control,
  isPaginator,
  isFilter,
  defaultValue,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  let params = {};
  let inputRef = null;

  const onWrapperChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const newEvent = {
        ...e,
        target: control ? { ...e.target } : e.target,
      };
      const currValue = onChange(newEvent);
      if (currValue !== null && currValue !== undefined) {
        newEvent.target.value = currValue;
        if (control && params.controllerOnChange) {
          params.controllerOnChange(newEvent);
        }
      }
    }
  };

  if (control) {
    const {
      field: { ref, onChange: controllerOnChange, ...controlProps },
    } = useController({
      name,
      control,
      defaultValue: value,
    });
    inputRef = ref;
    params = {
      ...controlProps,
      ...(!!register && { ...register(name) }),
      onChange: onWrapperChange,
      controllerOnChange,
    };
  } else {
    params = {
      onChange: onWrapperChange,
      ...(!!register && { ...register(name) }),
      ...(value && { defaultValue: value }),
    };
  }

  return (
    <InputWrapper
      $block={block}
      data-testid="inputWrapper"
      className={className}
      isPaginator={isPaginator}
      isFilter={isFilter}
    >
      {label && <InputLabel htmlFor={id} data-testid={`${id}_label`}>{label}</InputLabel>}
      <InputInput
        {...params}
        inputRef={inputRef}
        id={id}
        type={type || 'text'}
        danger={!!danger || !!errors?.[name]}
        gradient={gradient}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autocomplete}
        $block={block}
        name={name}
        defaultValue={defaultValue}
        onBlur={onBlur}
        data-testid={`input_${id}`}
        maxLength={maxlength}
        isPaginator={isPaginator}
        isFilter={isFilter}
        data-test-id={`input_${dataTestId}`}
      />
      {!isCreate && errors && errors[name] && (
        <InputErrorMessage data-test-id={`error_${dataTestId}`}>{t(errors[name].message)}</InputErrorMessage>
      )}
    </InputWrapper>
  );
};

export default Input;
