import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { selectIsFooterVisible } from 'store/app/appSelectors';
import { RouteTypes } from 'RouteTypes';
import {
  ExactLink,
  FooterContainer,
  FooterIcon,
  FooterIconsContainer,
  FooterLinksContainer,
  socialIcons,
  StarlyText,
  StarlyBuildHash,
  FooterTextSection,
  BuildOnFlow,
  BuildOnFlowText,
  BuildOnFlowImage,
} from './styled';
import {
  starlyDsLink, starlyInstLink, starlyTgLink, starlyTwitLink,
} from '../../global/constants';
import { useWindowSize } from '../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../breakpoints';

import flowLogo from '../../static/ic-flow.svg';

const Footer: FC = () => {
  const { t } = useTranslation();
  const isBlog = useRouteMatch(RouteTypes.Blog);
  const isFooterVisible = useSelector(selectIsFooterVisible);

  const width = useWindowSize();
  const isMobileWidth = width < desktopBreakpoint;
  if (!isFooterVisible || isBlog) return null;

  return (
    <FooterContainer data-test-id="footer_container">
      <FooterTextSection>
        <StarlyText data-test-id="copyright">
          © 2022 Starly, LLC.
          {process.env.REACT_APP_NODE_ENV === 'development' && (
            <StarlyBuildHash>
              {process.env.REACT_APP_COMMIT_HASH}
            </StarlyBuildHash>
          )}
        </StarlyText>
        <FooterLinksContainer>
          {
            !isMobileWidth && (
              <BuildOnFlow data-test-id="build_on_flow_logo">
                <BuildOnFlowImage data-test-id="build_on_flow_img" src={flowLogo} />
                <BuildOnFlowText>{t('footer.built')}</BuildOnFlowText>
              </BuildOnFlow>
            )
          }
        </FooterLinksContainer>
      </FooterTextSection>
      <FooterIconsContainer data-test-id="footer_icons_container">
        <ExactLink data-test-id="instagram_link" href={starlyInstLink} target="_blank">
          <FooterIcon src={socialIcons.instagram} />
        </ExactLink>
        <ExactLink data-test-id="twitter_link" href={starlyTwitLink} target="_blank">
          <FooterIcon src={socialIcons.twitter} />
        </ExactLink>
        <ExactLink data-test-id="discord_link" href={starlyDsLink} target="_blank">
          <FooterIcon src={socialIcons.discord} />
        </ExactLink>
        <ExactLink data-test-id="telegram_link" href={starlyTgLink} target="_blank">
          <FooterIcon src={socialIcons.telegram} />
        </ExactLink>
      </FooterIconsContainer>
    </FooterContainer>
  );
};

export default Footer;
