import styled, { css } from 'styled-components';
import theme from 'components/theme';
import { mediaDesktop } from 'breakpoints';
import { ReactSelectContainer } from '../SelectContainer/ReactSelectContainer';

export const StyledFilterSection = styled(ReactSelectContainer)<{ isWhite?: boolean }>`
  margin-bottom: 20px;
  ${mediaDesktop} {
    margin-bottom: 0;
  }
  z-index: 101;
  ${({ isWhite }) => (isWhite ? css`
    .react-select__control {
      transition: all .2s;
      background: #fff !important;
      & * {
        color: #000 !important;
      }
    } 
  ` : '')}
`;

export const StyledShowSection = styled(ReactSelectContainer)`
`;

export const StyledSortBySection = styled(ReactSelectContainer)`
  margin-top: 20px;

  ${mediaDesktop} {
    margin-top: 0;
  }
`;

const containerWithFilterCss = css`
  ${mediaDesktop} {
    display: grid;
    grid-gap: 32px;
    padding: 25px;
    grid-template-columns:  154px minmax(250px, 1fr) minmax(200px, 336px);
    
    & ${ReactSelectContainer} {
      width: 100%;

      .react-select__control {
        margin-top: 0;
      }
    }
  }
`;

export const StyledWrapper = styled.div<{ withFilter?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${theme.colors.dark};

  ${mediaDesktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 28px;
    margin-bottom: 60px;
  }
  ${({ withFilter }) => (withFilter ? containerWithFilterCss : '')}
`;
