// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import Beam from 0x86b4a0010a71cfc3
import BlockleteGames_NFT from 0x329feb3ab062d289
import CaaPass from 0x98c9c2e548b84d31
import ChainmonstersRewards from 0x93615d25d14fa337
import Collectible from 0xf5b0eb433389ac3f
import Crave from 0x6d008a788fc27265
import CricketMoments from 0xed398881d9bf40fb
import Everbloom from 0xe703f7fee6400754
import FantastecNFT from 0x2e1ee1e7a96826ce
import FungibleToken from 0xf233dcee88fe0abe
import FUSD from 0x3c5959b568896393
import Gaia from 0x8b148183c28ff88f
import KlktnNFT from 0xabd6e80be7e9682c
import KOTD from 0x23dddd854fcc8c6f
import MatrixWorldFlowFestNFT from 0x2d2750f240198f91
import MusicBlock from 0x5634aefcb76e7d8c
import Mynft from 0xf6fcbef550d97aa5
import NonFungibleToken from 0x1d7e57aa55817448
import NyatheesOVO from 0x75e0b6de94eb05d0
import RaceDay_NFT from 0x329feb3ab062d289
import RareRooms_NFT from 0x329feb3ab062d289
import RCRDSHPNFT from 0x6c3ff40b90b928ab
import Shard from 0x82b54037a8f180cf
import SportsIconCollectible from 0x8de96244f54db422
import StarlyCard from 0x5b82f21c0edf76e3
import TheFabricantMysteryBox_FF1 from 0xa0cbe021821c0965
import TopShot from 0x0b2a3299cc857e29
import TuneGO from 0x0d9bc5af3fc0c2e3
import Vouchers from 0x444f5ea22c6ea12c

pub fun main(address: Address): {String: [UInt64]} {
    let account = getAccount(address)
    let ret: {String: [UInt64]} = {}

    if (account.getCapability<&{Beam.BeamCollectionPublic}>(Beam.CollectionPublicPath).check()) {
        let beamCollectionRef = account.getCapability(Beam.CollectionPublicPath)!.borrow<&{Beam.BeamCollectionPublic}>()
            ?? panic("Could not borrow Beam receiver reference")
        ret["beam_fright"] = beamCollectionRef.getIDs();
    }

    if (account.getCapability<&BlockleteGames_NFT.Collection{NonFungibleToken.CollectionPublic,BlockleteGames_NFT.BlockleteGames_NFTCollectionPublic}>(BlockleteGames_NFT.CollectionPublicPath).check()) {
        let blockleteCollectionRef = account.getCapability(BlockleteGames_NFT.CollectionPublicPath)!.borrow<&BlockleteGames_NFT.Collection{NonFungibleToken.CollectionPublic,BlockleteGames_NFT.BlockleteGames_NFTCollectionPublic}>()
            ?? panic("Could not borrow BlockleteGames_NFT receiver reference")
        ret["blocklete_golf"] = blockleteCollectionRef.getIDs();
    }

    if (account.getCapability<&{ChainmonstersRewards.ChainmonstersRewardCollectionPublic}>(/public/ChainmonstersRewardCollection).check()) {
        let chainmonstersCollectionRef = account.getCapability(/public/ChainmonstersRewardCollection)!.borrow<&{ChainmonstersRewards.ChainmonstersRewardCollectionPublic}>()
            ?? panic("Could not borrow ChainmonstersRewards receiver reference")
        ret["chainmonsters"] = chainmonstersCollectionRef.getIDs();
    }

    if (account.getCapability<&{Crave.CraveCollectionPublic}>(Crave.CollectionPublicPath).check()) {
        let craveCollectionRef = account.getCapability(Crave.CollectionPublicPath)!.borrow<&{Crave.CraveCollectionPublic}>()
            ?? panic("Could not borrow Crave receiver reference")
        ret["crave"] = craveCollectionRef.getIDs();
    }

    if (account.getCapability<&CricketMoments.Collection{NonFungibleToken.CollectionPublic, CricketMoments.CricketMomentsCollectionPublic}>(CricketMoments.CollectionPublicPath).check()) {
        let cricketMomentsCollectionRef = account.getCapability(CricketMoments.CollectionPublicPath)!.borrow<&CricketMoments.Collection{NonFungibleToken.CollectionPublic, CricketMoments.CricketMomentsCollectionPublic}>()
            ?? panic("Could not borrow Crave receiver reference")
        ret["faze"] = cricketMomentsCollectionRef.getIDs();
    }

    if (account.getCapability<&{Shard.ShardCollectionPublic}>(/public/EternalShardCollection).check()) {
        let shardCollectionRef = account.getCapability(/public/EternalShardCollection)!.borrow<&{Shard.ShardCollectionPublic}>()
            ?? panic("Could not borrow Shard receiver reference")
        ret["eternal"] = shardCollectionRef.getIDs();
    }

    if (account.getCapability<&{Everbloom.PrintCollectionPublic}>(Everbloom.CollectionPublicPath).check()) {
        let everbloomCollectionRef = account.getCapability(Everbloom.CollectionPublicPath)!.borrow<&{Everbloom.PrintCollectionPublic}>()
            ?? panic("Could not borrow Everbloom receiver reference")
        ret["everbloom"] = everbloomCollectionRef.getIDs();
    }

    if (account.getCapability<&FantastecNFT.Collection{NonFungibleToken.CollectionPublic, FantastecNFT.FantastecNFTCollectionPublic}>(FantastecNFT.CollectionPublicPath).check()) {
        let fantastecCollectionRef = account.getCapability(FantastecNFT.CollectionPublicPath)!.borrow<&FantastecNFT.Collection{NonFungibleToken.CollectionPublic, FantastecNFT.FantastecNFTCollectionPublic}>()
            ?? panic("Could not borrow FantastecNFT receiver reference")
        ret["fantastec_swap"] = fantastecCollectionRef.getIDs();
    }

    if (account.getCapability<&{Vouchers.CollectionPublic}>(Vouchers.CollectionPublicPath).check()) {
        let vouchersCollectionRef = account.getCapability(Vouchers.CollectionPublicPath)!.borrow<&{Vouchers.CollectionPublic}>()
            ?? panic("Could not borrow Vouchers receiver reference")
        ret["jambb"] = vouchersCollectionRef.getIDs();
    }

    if (account.getCapability<&{KOTD.NiftoryCollectibleCollectionPublic}>(KOTD.CollectionPublicPath).check()) {
        let kotdCollectionRef = account.getCapability(KOTD.CollectionPublicPath)!.borrow<&{KOTD.NiftoryCollectibleCollectionPublic}>()
            ?? panic("Could not borrow KOTD receiver reference")
        ret["king_of_the_dot"] = kotdCollectionRef.getIDs();
    }

    if (account.getCapability<&KlktnNFT.Collection{NonFungibleToken.CollectionPublic, KlktnNFT.KlktnNFTCollectionPublic}>(KlktnNFT.CollectionPublicPath).check()) {
        let klktnCollectionRef = account.getCapability(KlktnNFT.CollectionPublicPath)!.borrow<&KlktnNFT.Collection{NonFungibleToken.CollectionPublic, KlktnNFT.KlktnNFTCollectionPublic}>()
            ?? panic("Could not borrow KlktnNFT receiver reference")
        ret["klktn"] = klktnCollectionRef.getIDs();
    }

    if (account.getCapability<&MusicBlock.Collection{NonFungibleToken.CollectionPublic, MusicBlock.MusicBlockCollectionPublic}>(MusicBlock.CollectionPublicPath).check()) {
        let musicBlockCollectionRef = account.getCapability(MusicBlock.CollectionPublicPath)!.borrow<&MusicBlock.Collection{NonFungibleToken.CollectionPublic, MusicBlock.MusicBlockCollectionPublic}>()
            ?? panic("Could not borrow MusicBlock receiver reference")
        ret["melos_studio"] = musicBlockCollectionRef.getIDs();
    }

    if (account.getCapability<&Mynft.Collection{Mynft.MynftCollectionPublic}>(Mynft.CollectionPublicPath).check()) {
        let mynftCollectionRef = account.getCapability(Mynft.CollectionPublicPath)!.borrow<&Mynft.Collection{NonFungibleToken.CollectionPublic, Mynft.MynftCollectionPublic}>()
            ?? panic("Could not borrow Mynft receiver reference")
        ret["mynft"] = mynftCollectionRef.getIDs();
    }

    if (account.getCapability<&{Gaia.CollectionPublic}>(Gaia.CollectionPublicPath).check()) {
        let gaiaCollectionRef = account.getCapability(Gaia.CollectionPublicPath)!.borrow<&{Gaia.CollectionPublic}>()
            ?? panic("Could not borrow Gaia receiver reference")
        ret["nft_genius"] = gaiaCollectionRef.getIDs();
    }

    if (account.getCapability<&NyatheesOVO.Collection{NonFungibleToken.CollectionPublic, NyatheesOVO.NFTCollectionPublic}>(NyatheesOVO.CollectionPublicPath).check()) {
        let ovoCollectionRef = account.getCapability(NyatheesOVO.CollectionPublicPath)!.borrow<&NyatheesOVO.Collection{NonFungibleToken.CollectionPublic, NyatheesOVO.NFTCollectionPublic}>()
            ?? panic("Could not borrow NyatheesOVO receiver reference")
        ret["ovo"] = ovoCollectionRef.getIDs();
    }

    if (account.getCapability<&RaceDay_NFT.Collection{NonFungibleToken.CollectionPublic, RaceDay_NFT.RaceDay_NFTCollectionPublic}>(RaceDay_NFT.CollectionPublicPath).check()) {
        let raceDayCollectionRef = account.getCapability(RaceDay_NFT.CollectionPublicPath)!.borrow<&RaceDay_NFT.Collection{NonFungibleToken.CollectionPublic, RaceDay_NFT.RaceDay_NFTCollectionPublic}>()
            ?? panic("Could not borrow RaceDay_NFT receiver reference")
        ret["raceday_nft"] = raceDayCollectionRef.getIDs();
    }

    if (account.getCapability<&RareRooms_NFT.Collection{NonFungibleToken.CollectionPublic, RareRooms_NFT.RareRooms_NFTCollectionPublic}>(RareRooms_NFT.CollectionPublicPath).check()) {
        let rareRoomsCollectionRef = account.getCapability(RareRooms_NFT.CollectionPublicPath)!.borrow<&RareRooms_NFT.Collection{NonFungibleToken.CollectionPublic, RareRooms_NFT.RareRooms_NFTCollectionPublic}>()
            ?? panic("Could not borrow RareRooms_NFT receiver reference")
        ret["rarerooms"] = rareRoomsCollectionRef.getIDs();
    }

    if (account.getCapability<&{NonFungibleToken.CollectionPublic}>(RCRDSHPNFT.collectionPublicPath).check()) {
        let rcrdshpnftCollectionRef = account.getCapability(RCRDSHPNFT.collectionPublicPath)!.borrow<&{NonFungibleToken.CollectionPublic}>()
            ?? panic("Could not borrow RCRDSHPNFT receiver reference")
        ret["rcrdshp"] = rcrdshpnftCollectionRef.getIDs();
    }

    if (account.getCapability<&SportsIconCollectible.Collection{NonFungibleToken.CollectionPublic, SportsIconCollectible.CollectibleCollectionPublic}>(SportsIconCollectible.CollectionPublicPath).check()) {
        let sportsIconCollectionRef = account.getCapability(SportsIconCollectible.CollectionPublicPath)!.borrow<&SportsIconCollectible.Collection{NonFungibleToken.CollectionPublic, SportsIconCollectible.CollectibleCollectionPublic}>()
            ?? panic("Could not borrow SportsIconCollectible receiver reference")
        ret["sportsicon"] = sportsIconCollectionRef.getIDs();
    }

    if (account.getCapability<&StarlyCard.Collection{NonFungibleToken.CollectionPublic, StarlyCard.StarlyCardCollectionPublic}>(StarlyCard.CollectionPublicPath).check()) {
        let starlyCardCollectionRef = account.getCapability(StarlyCard.CollectionPublicPath)!.borrow<&{NonFungibleToken.CollectionPublic}>()
            ?? panic("Could not borrow StarlyCard receiver reference")
        ret["starly"] = starlyCardCollectionRef.getIDs();
    }

    if (account.getCapability<&{TheFabricantMysteryBox_FF1.FabricantCollectionPublic}>(TheFabricantMysteryBox_FF1.CollectionPublicPath).check()) {
        let theFabricanCollectionRef = account.getCapability(TheFabricantMysteryBox_FF1.CollectionPublicPath)!.borrow<&{TheFabricantMysteryBox_FF1.FabricantCollectionPublic}>()
            ?? panic("Could not borrow TheFabricantMysteryBox_FF1 receiver reference")
        ret["the_fabricant"] = theFabricanCollectionRef.getIDs();
    }

    if (account.getCapability<&{NonFungibleToken.CollectionPublic, CaaPass.CollectionPublic}>(CaaPass.CollectionPublicPath).check()) {
        let caaPassCollectionRef = account.getCapability(CaaPass.CollectionPublicPath)!.borrow<&{NonFungibleToken.CollectionPublic, CaaPass.CollectionPublic}>()
            ?? panic("Could not borrow CaaPass receiver reference")
        ret["thing_fund"] = caaPassCollectionRef.getIDs();
    }

    if (account.getCapability<&{TopShot.MomentCollectionPublic}>(/public/MomentCollection).check()) {
        let topShotCollectionRef = account.getCapability(/public/MomentCollection)!.borrow<&{TopShot.MomentCollectionPublic}>()
            ?? panic("Could not borrow TopShot receiver reference")
        ret["topshot"] = topShotCollectionRef.getIDs();
    }

    if (account.getCapability<&{TuneGO.TuneGOCollectionPublic}>(TuneGO.CollectionPublicPath).check()) {
        let tuneGoCollectionRef = account.getCapability(TuneGO.CollectionPublicPath)!.borrow<&{TuneGO.TuneGOCollectionPublic}>()
            ?? panic("Could not borrow TuneGO receiver reference")
        ret["tunego"] = tuneGoCollectionRef.getIDs();
    }

    if (account.getCapability<&MatrixWorldFlowFestNFT.Collection{NonFungibleToken.CollectionPublic, MatrixWorldFlowFestNFT.MatrixWorldFlowFestNFTCollectionPublic}>(MatrixWorldFlowFestNFT.CollectionPublicPath).check()) {
        let matrixWorldCollectionRef = account.getCapability(MatrixWorldFlowFestNFT.CollectionPublicPath)!.borrow<&MatrixWorldFlowFestNFT.Collection{NonFungibleToken.CollectionPublic, MatrixWorldFlowFestNFT.MatrixWorldFlowFestNFTCollectionPublic}>()
            ?? panic("Could not borrow MatrixWorldFlowFestNFT receiver reference")
        ret["whitematrix"] = matrixWorldCollectionRef.getIDs();
    }

    if (account.getCapability<&{Collectible.CollectionPublic}>(Collectible.CollectionPublicPath).check()) {
        let xtinglesCollectionRef = account.getCapability(Collectible.CollectionPublicPath)!.borrow<&{Collectible.CollectionPublic}>()
            ?? panic("Could not borrow Collectible receiver reference")
        ret["xtingles"] = xtinglesCollectionRef.getIDs();
    }

    return ret;
}`;

export function flowFetchNFTIDsMainnetScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
    ]),
  ]).then(fcl.decode);
}
