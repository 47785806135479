import { Dispatch } from '@reduxjs/toolkit';
import { User } from '@starly/starly-types';
import { RouteTypes } from 'RouteTypes';
import { userRequest } from 'store/user/userActions';

interface CheckFollowersProps {
  faceControlState: 'fail' | 'pass',
  connections: User['connections'],
  activeStep: number,
  id: string,
  dispatch: Dispatch<any>,
  callback?: (value: string) => void,
}

export const checkFollowers = ({
  faceControlState, connections, activeStep, id, dispatch, callback,
}: CheckFollowersProps) => {
  const minFollowersCount = parseInt(process.env.REACT_APP_FACE_CONTROL_FOLLOWERS || '', 10);
  if (faceControlState === 'pass') {
    return callback ? callback(`${RouteTypes.StepDefault}${activeStep}`) : `${RouteTypes.StepDefault}${activeStep}`;
  }
  if ((connections?.twitter && connections.twitter.followers_count >= minFollowersCount)
    || (connections?.youtube && +connections.youtube.subscribers_count >= minFollowersCount)
    || (connections?.instagram && connections.instagram?.followers_count
      && connections.instagram.followers_count >= minFollowersCount)) {
    // dispatch(userUpdateRequest({ id, face_control_state: 'pass' }));
    dispatch(userRequest({ id }));

    sessionStorage.removeItem('youtubePending');
    sessionStorage.removeItem('twitterPending');
    sessionStorage.removeItem('instagramPending');

    return callback ? callback(`${RouteTypes.StepDefault}${activeStep}`) : `${RouteTypes.StepDefault}${activeStep}`;
  }
  return callback ? callback(RouteTypes.FaceControl) : RouteTypes.FaceControl;
};
