import { createAction } from '@reduxjs/toolkit';
import {
  Card, CardEdition, CardEditionState, Collection, Listing, SalesHistoryItem,
} from '@starly/starly-types';

export interface CardState {
  card: Card,
  editions: { [editionId: string]: CardEdition },
  collection: Collection,
  listings?: number,
  inCirculation?: number,
  lowestAsk?: number,
  topSale?: number
}

type CardInfoType = 'listing' | 'sales';

interface CardInfoRequestPayload {
  collectionId: string,
  cardId: string,
  sort: number,
  page: number
}

interface CardListingResponsePayload {
  listing: Listing[]
}

interface CardSalesResponsePayload {
  sales: SalesHistoryItem[]
}

interface LoadingPayload {
  infoType: CardInfoType
  isLoading: boolean
}

interface PagePayload {
  infoType: CardInfoType
  page: number
}

interface IsMoreToLoadPayload {
  infoType: CardInfoType
  isMoreToLoad: boolean
}

interface EditionStatusPayload {
  cardId: string,
  edition: string,
  status: CardEditionState,
}
interface CardRequestPayload {
  background?: boolean,
  purchase?: boolean,
  collectionId: string,
  edition: string,
  cardId: string,
}

interface CardAndCollectionPayload {
  cardId: string,
  collectionId: string,
}

export const cardRequest = createAction<CardRequestPayload>('card/request');

export const cardResponse = createAction<{ cardState: CardState }>('card/response');

export const cardSetLoading = createAction<{ isLoading: boolean }>('card/set-loading');

export const cardListingRequest = createAction< CardInfoRequestPayload>('card/card-listing-request');

export const cardListingResponse = createAction<CardListingResponsePayload>('card/card-listing-response');

export const cardSalesRequest = createAction<CardInfoRequestPayload>('card/card-sales-request');

export const cardSalesResponse = createAction<CardSalesResponsePayload>('card/card-sales-response');

export const cardInfoSetPage = createAction<PagePayload>('card/card-info-page');

export const cardInfoSetLoading = createAction<LoadingPayload>('card/card-info-loading');

export const cardInfoSetMoreToLoad = createAction<IsMoreToLoadPayload>('card/card-info-more-to-load');

export const cardInfoReset = createAction<{ infoType: CardInfoType }>('card/card-info-reset');

export const setCardPopUp = createAction<{ isOpen: boolean, type: CardInfoType }>('card/CARD_SET_POPUP');

// Counters

// InCirculation

export const getInCirculationCountRequest = createAction<{ collectionId: string, cardId: string }>('card/CIRCULATION_REQUEST');
export const getInCirculationCountResponse = createAction<{ cardId: string, inCirculation: number }>('card/CIRCULATION_RESPONSE');
export const getCardCountersRequest = createAction<CardAndCollectionPayload>('card/getCardCountersRequest');
export const addCardListenerToMap = createAction<CardAndCollectionPayload>('card/ADD_CARD_LISTENER_TO_MAP');
export const removeCardListenerFromMap = createAction<CardAndCollectionPayload>('card/REMOVE_CARD_LISTENER_FROM_MAP');
export const getCardCountersResponse = createAction<{
  cardId: string,
  listings: number,
  inCirculation: number,
  lowestAsk?: number,
  topSale?: number
}>('collection/getCardCountersResponse');

// Local Updates
export const updateCardEditionStatus = createAction<EditionStatusPayload>('card/UPDATE_CARD_EDITION_STATUS');

export const updateCardEditionStakedStatus = createAction<any>('card/UPDATE_CARD_EDITION_STAKED_STATUS');
