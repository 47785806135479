import { createReducer } from '@reduxjs/toolkit';
import { dropsLoading, dropsResponse } from './dropsAction';

export interface DropsSlice {
  drops: any,
  isLoading: boolean,
}

const initialState: DropsSlice = {
  drops: [],
  isLoading: false,
};

export const dropsReducer = createReducer<DropsSlice>(initialState, (builder) => {
  builder.addCase(dropsResponse, (state, { payload: { drops } }) => {
    state.drops = drops;
  });
  builder.addCase(dropsLoading, (state, { payload: { isLoading } }) => {
    state.isLoading = isLoading;
  });
});
