// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';
// @ts-ignore
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyTokenVesting from 0xSTARLY_TOKEN_VESTING_ADDRESS

transaction(vestingID: UInt64) {
    let vestingCollectionRef: &StarlyTokenVesting.Collection

    prepare(acct: AuthAccount) {
        self.vestingCollectionRef = acct.borrow<&StarlyTokenVesting.Collection>(from: StarlyTokenVesting.CollectionStoragePath)
            ?? panic("Could not borrow reference to the owner's StarlyTokenVesting collection!")
    }

    execute {
        self.vestingCollectionRef.release(id: vestingID)
    }
}`;

export async function flowReleaseStarlyTokenVestingTransaction(
  vestingID: number,
  opts = { onError },
) {
  invariant(vestingID != null, 'vestingID must be supplied');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false)]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(Number(vestingID), t.UInt64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
