import Button from 'components/Button';
import React, {
  FC, useEffect,
} from 'react';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  CheckBoxContainer,
  FilterButtonsContainer,
  FilterContainer, RarityContainer,
} from './styled';
import { FilterProps } from './types';
import CheckBox from '../CheckBox';
import { FilterOption, FormFilters, FormFiltersUpdate } from '../../store/marketplace/marketplaceTypes';

const Filter: FC<FilterProps> = ({
  filters,
  onApply,
  onClose,
  closeBtn,
  'data-test-id': dataTestId,
}) => {
  const { t } = useTranslation();
  const filterKeys = Object.keys(filters);
  const {
    register, getValues, watch, setValue,
  } = useForm({
    defaultValues: {
      ...Object.fromEntries(
        filterKeys
          .map((key) => [
            key,
            {
              ...Object
                .fromEntries(
                  filters[key as keyof FormFilters]?.map(
                    ({ active, value }: FilterOption) => [value, active],
                  ) || [],
                ),
            },
          ]),
      ),
    },
  });

  useEffect(() => {
    watch((data, { name }) => {
      if (data) {
        const val = name && name.split('.').reduce<any>((p, c) => (p?.[c]), data);
        if (val && name) {
          const path = name.split('.');
          const option = filters[
            path[0] as keyof FormFiltersUpdate
          ]?.find(({ value }) => value === path[1]);
          if (option?.deactivate) {
            setValue(option.deactivate, false);
          }
        }
      }
    });
  }, []);

  const onApplyClick = () => {
    const values = getValues() as { [key: string]: {
      [key: string]: boolean
    } };
    const newValues: FormFiltersUpdate = {};
    Object.keys(filters).forEach((key) => {
      const filterKey = key as keyof FormFiltersUpdate;
      newValues[filterKey] = filters[filterKey]?.map(({ value }, i) => ({
        ...filters[filterKey]?.[i],
        active: values[key][value],
      })) || [];
    });
    onApply(newValues);
    onClose();
  };

  return (
    <FilterContainer>
      {
        filterKeys.map((filterKey) => {
          const currentFilters = filters[filterKey as keyof FormFilters];
          return (
            <RarityContainer>
              <Typography data-test-id={`rarity_label_${dataTestId}`} bold uppercase>{filterKey}</Typography>
              <CheckBoxContainer>
                {
                  currentFilters?.map(({ value, label }) => (
                    <CheckBox
                      key={value}
                      register={register}
                      name={`${filterKey}.${value}`}
                      label={label || t(`card.rarity.${value}`)}
                      $filter
                      data-test-id={dataTestId}
                    />
                  ))
                }
              </CheckBoxContainer>
            </RarityContainer>
          );
        })
      }
      <FilterButtonsContainer>
        <Button
          data-test-id={`apply_button_${dataTestId}`}
          onClick={onApplyClick}
          size="small"
          background="green"
          textColor="black"
          fluid
        >
          {t('common.apply')}
        </Button>
        {
          closeBtn && (
            <Button
              data-test-id={`close_button_${dataTestId}`}
              onClick={onClose}
              background="transparent"
              border="white"
            >
              {t('common.close')}
            </Button>
          )
        }
      </FilterButtonsContainer>
    </FilterContainer>
  );
};

export default Filter;
