import { createReducer } from '@reduxjs/toolkit';
import { TopCollectorTokenRewardPayout } from '@starly/starly-types';
import { RankingCollectorsRowProps, RankingRowProps } from '../../views/Ranking/types';
import {
  rankingCollectionsResponse,
  rankingCollectorsResponse, resetRankingCollectorsState,
  resetRankingState, resetRewardClaimState, resetUserRankingState, rewardConfigResponse,
  rewardSnapshotsResponse, rewardToClaimResponse, setClaimingStatus, setClaimRewardClaiming,
  setRankingCollectorsLoadState,
  setRankingCollectorsPage,
  setRankingFilters,
  setRankingLoadState,
  setRankingPage, setUserRankingLoadState, userRankingScoreResponse,
} from './rankingActions';
import { DocumentSnapshot } from '../../global/firebase';

interface RankingSlice {
  rankingCollections: {
    isLoading: boolean,
    isLoad: boolean,
    page: number,
    timeFilter: number,
    isMoreToLoad: boolean,
    rankings: RankingRowProps[]
  }
  rankingCollectors: {
    isLoading: boolean,
    isLoad: boolean,
    isMoreToLoad: boolean,
    page: {
      pageNumber: number,
      pageLastEl: null | DocumentSnapshot
    },
    rankings: RankingCollectorsRowProps[]
  }
  userRanking: {
    userRankingsMap: {
      [id: string]: RankingCollectorsRowProps
    }
    isLoading: boolean
  }
  rewards: {
    rewardsData: {
      rewardPool: number,
      collectorsCount: number,
      rewards: [Date, TopCollectorTokenRewardPayout][]
    },
    isLoading: boolean
  },
  rewardToClaim: {
    isClaiming: boolean,
    reward: number | null,
    status: 'initial' | 'error' | 'success'
  }
}

const initialState: RankingSlice = {
  rankingCollections: {
    isLoad: false,
    isLoading: false,
    isMoreToLoad: true,
    timeFilter: -1,
    page: 0,
    rankings: [],
  },
  rankingCollectors: {
    isLoading: false,
    isLoad: false,
    isMoreToLoad: false,
    page: {
      pageNumber: 0,
      pageLastEl: null,
    },
    rankings: [],
  },
  userRanking: {
    userRankingsMap: {},
    isLoading: false,
  },
  rewards: {
    rewardsData: {
      rewardPool: 0,
      collectorsCount: 0,
      rewards: [],
    },
    isLoading: false,
  },
  rewardToClaim: {
    reward: null,
    isClaiming: false,
    status: 'initial',
  },
};

export const rankingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(rankingCollectionsResponse, (state, { payload: { rankings } }) => {
      state.rankingCollections.rankings.push(...rankings);
    })
    .addCase(setRankingPage, (state, { payload: { page } }) => {
      state.rankingCollections.page = page;
    })
    .addCase(setRankingFilters, (state, { payload: { days } }) => {
      if (state.rankingCollections.timeFilter !== days) {
        state.rankingCollections.timeFilter = days;
        state.rankingCollections.rankings = [];
        state.rankingCollections.page = 0;
        state.rankingCollections.isLoad = false;
        state.rankingCollections.isMoreToLoad = true;
      }
    })
    .addCase(setRankingLoadState, (state, { payload: { isLoad, isLoading, isMoreToLoad } }) => {
      if (typeof isLoad === 'boolean') state.rankingCollections.isLoad = isLoad;
      if (typeof isLoading === 'boolean') state.rankingCollections.isLoading = isLoading;
      if (typeof isMoreToLoad === 'boolean') state.rankingCollections.isMoreToLoad = isMoreToLoad;
    })
    .addCase(resetRankingState, (state) => {
      state.rankingCollections = initialState.rankingCollections;
    })
    .addCase(rankingCollectorsResponse, (state, { payload: { rankings } }) => {
      state.rankingCollectors.rankings.push(...rankings);
    })
    .addCase(setRankingCollectorsPage, (state, { payload }) => {
      state.rankingCollectors.page = payload;
    })
    .addCase(setRankingCollectorsLoadState,
      (state, { payload: { isLoad, isLoading, isMoreToLoad } }) => {
        if (typeof isLoad === 'boolean') state.rankingCollectors.isLoad = isLoad;
        if (typeof isLoading === 'boolean') state.rankingCollectors.isLoading = isLoading;
        if (typeof isMoreToLoad === 'boolean') state.rankingCollectors.isMoreToLoad = isMoreToLoad;
      })
    .addCase(resetRankingCollectorsState,
      (state) => {
        state.rankingCollectors = initialState.rankingCollectors;
      })
    .addCase(setUserRankingLoadState, (state, { payload: { isLoading } }) => {
      state.userRanking.isLoading = isLoading;
    })
    .addCase(userRankingScoreResponse, (state, { payload: { ranking, id } }) => {
      const { isOwnProfile } = ranking;

      const rankingMutated = { ...ranking };
      const { collector_score: score } = ranking.collectorsScore;

      rankingMutated.collectorsScore.collector_score = score || 0;

      if (isOwnProfile && !ranking.collectorsScore.rank && state.rankingCollectors.rankings) {
        const userRank = state.rankingCollectors.rankings.slice(0, 300).find(
          ({ collectorsScore }) => collectorsScore.user_id === ranking.collectorsScore.user_id,
        );

        if (userRank) {
          rankingMutated.place = userRank.place;
        }
      }

      state.userRanking.userRankingsMap[id] = rankingMutated;
    })
    .addCase(rewardConfigResponse, (state, { payload: { rewardPool, collectorsCount } }) => {
      state.rewards.rewardsData.rewardPool = rewardPool;
      state.rewards.rewardsData.collectorsCount = collectorsCount;
    })
    .addCase(resetUserRankingState, (state) => {
      state.userRanking = initialState.userRanking;
    })
    .addCase(rewardSnapshotsResponse, (state, { payload: { rewards } }) => {
      state.rewards.rewardsData.rewards = rewards;
    })
    .addCase(rewardToClaimResponse, (state, { payload: { reward } }) => {
      state.rewardToClaim.reward = reward;
    })
    .addCase(setClaimRewardClaiming, (state, { payload: { isClaiming } }) => {
      state.rewardToClaim.isClaiming = isClaiming;
    })
    .addCase(setClaimingStatus, (state, { payload: { status } }) => {
      state.rewardToClaim.status = status;
    })
    .addCase(resetRewardClaimState, (state) => {
      state.rewardToClaim = initialState.rewardToClaim;
    });
});
