/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { EventName } from '@starly/starly-types';
/* eslint-enable import/no-extraneous-dependencies */

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

/**
 * Initialize Firebase
 */
const app = firebase.initializeApp(firebaseConfig);

/**
 * Default analytics and custom event tracking
 */
firebase.analytics();
const analytics = getAnalytics();
export const trackEvent = (name: EventName, data?: any) => {
  /* eslint-disable-next-line no-console */
  console.debug.apply(this, ['%c Starly Event ', 'background: #3bd86c; color: #ffffff'].concat(name, data || ''));

  logEvent(analytics, name, data);
};

export const trackException = (description: string, fatal: boolean = false) => {
  /* eslint-disable-next-line no-console */
  console.warn.apply(this, ['%c Starly Event ', 'background: #3bd86c; color: #ffffff'].concat(description));

  logEvent(analytics, 'exception', {
    description,
    fatal,
  });
};

/**
 * Remote configuration controlled by Firebase
 */
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // TODO configurable
remoteConfig.defaultConfig = {
  googleLogin: true,
  facebookLogin: true,
  twitterLogin: true,
  weChatLogin: true,
  emailLogin: false,
  customTokenLogin: false,
  flowFestEnabled: true,
  flowFestComingSoonEnabled: false,
  topUpMode: 'moonpay',
};
remoteConfig.fetchAndActivate().then(() => {
  // eslint-disable-next-line no-console
  // console.info('Config activated, result: ', result);
});

initializeAppCheck(
  app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_ID as string),
    isTokenAutoRefreshEnabled: true,
  },
);

/**
 * Firebase auth API and types
 */
export const auth = firebase.auth();
export const { GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } = firebase.auth;
export type UserCredential = firebase.auth.UserCredential;
export type IdTokenResult = firebase.auth.IdTokenResult;

export const database = firebase.database();

export const firestore = firebase.firestore();
export type Query = firebase.firestore.Query;
export type OrderByDirection = firebase.firestore.OrderByDirection;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export const TimestampNow = firebase.firestore.Timestamp.now; // TODO
export const TimestampFromDate = firebase.firestore.Timestamp.fromDate;

export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export const firestorage = firebase.storage();
export const functions = firebase.functions();

// TODO for local testing
// const db = firebase.database();
// export const functions = firebase.functions();
// auth.useEmulator('http://localhost:9099');
// database.useEmulator('localhost', 9000);
// firestorage.useEmulator('localhost', 9199);
// firestore.useEmulator('localhost', 8181);
// functions.useEmulator('localhost', 5001);
