import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
  take,
  delay,
  race,
} from 'redux-saga/effects';
// @ts-ignore
import * as fcl from '@onflow/fcl';
import {
  firestore, functions, QuerySnapshot, trackEvent, trackException,
} from 'global/firebase';
import { setMarketplaceCardPurchaseState, setMarketplacePurchasingState } from 'store/marketplace/marketplaceAction';
import { END, eventChannel, EventChannel } from 'redux-saga';
import { cardSaleSetProgress } from 'store/saleCard/saleCardActions';
import {
  flowAcceptSaleOfferRequest,
  flowAcceptSaleOfferResponse,
  flowBalanceRequest,
  flowBalanceResponse,
  flowBurnAllCardsRequest,
  flowBurnAllCardsResponse,
  flowBurnStarlyCardsRequest,
  flowBurnStarlyCardsResponse,
  flowBuyPackRequest,
  flowBuyPackResponse,
  flowCancelSaleOfferRequest,
  flowCancelSaleOfferResponse,
  flowCreateSaleOfferRequest,
  flowCreateSaleOfferResponse,
  flowFetchSaleOfferIDsRequest,
  flowFetchSaleOfferIDsResponse,
  flowFetchStarlyIDRequest,
  flowFetchStarlyIDResponse,
  flowFetchStarlyCardItemIDsRequest,
  flowFetchStarlyCardItemIDsResponse,
  flowBloctoLoginRequest,
  flowLoginRequest,
  flowLoginResponse,
  flowLogoutRequest,
  flowLogoutResponse,
  flowWrongModal,
  flowToggleModal,
  flowToggleLoading,
  flowFestLoginRequest,
  flowFestLoginResponse,
  flowFestToggleModal,
  flowFestToggleLoading,
  flowNFTNYCLoginRequest,
  flowNFTNYCLoginResponse,
  flowNFTNYCToggleModal,
  flowNFTNYCToggleLoading,
} from './flowActions';
import { flowFestCheckWalletResponse } from '../flowFest/flowFestActions';
import { flowNFTNYCCheckWalletResponse } from '../flowNFTNYC/flowNFTNYCActions';

import {
  selectFlowWalletAddr,
} from './flowSelectors';

import { FlowInit, FlowTransactionResultType, FlowWallet } from '../../types';

import { updateWalletRequest, userRequest, userResponse } from '../user/userActions';

/**
 * Flow scripts
 */
import { blowBuyPackTransaction } from '../../flow/packPurchase/buyPack.tx';
import { blowBuyPackUsingFLOWTransaction } from '../../flow/packPurchase/buyPackUsingFLOW.tx';
import { blowBuyPackUsingSTARLYTransaction } from '../../flow/packPurchase/buyPackUsingSTARLY.tx';
import { blowBuyPackWithConversionTransaction } from '../../flow/packPurchase/buyPackWithConversion.tx';
import { blowBuyFlowPackWithFUSD } from '../../flow/packPurchase/buyFlowPackWithFUSD.tx';
import { buyPackResponse, setPackPurchaseStatus } from '../pack/packActions';

import { flowIsAccountInitializedScript } from '../../flow/isAccountInitialized.script';
import { flowInitializeAccountTransaction } from '../../flow/initializeAccount.tx';
import { flowAcceptSaleOrderTransaction } from '../../flow/acceptSaleOffer.tx';
import { flowAcceptSaleOrderWithConversionTransaction } from '../../flow/acceptSaleOfferWithConversion.tx';
import { flowBurnAllCardsTransaction } from '../../flow/burnAllCards.tx';
import { flowBurnStarlyCardsTransaction } from '../../flow/burnStarlyCards.tx';
import { flowCancelSaleOfferTransaction } from '../../flow/cancelSaleOffer.tx';
import { flowCreateSaleOfferTransaction } from '../../flow/createSaleOffer.tx';
import { flowFetchStarlyIDScript } from '../../flow/fetchStarlyID.script';
import { flowFetchFlowBalanceScript } from '../../flow/fetchFlowBalance.script';
import { flowFetchFUSDBalanceScript } from '../../flow/fetchFUSDBalance.script';
import { flowFetchStarlyTokenBalanceScript } from '../../flow/fetchStarlyTokenBalance.script';
import { flowFetchStarlyCardItemIDsScript } from '../../flow/fetchStarlyCardItemIDs.script';
import { flowFetchSaleOfferIDsScript } from '../../flow/fetchSaleOfferIDs.script';

import { flowIsFlowFestAccountInitializedTestnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.testnet.script';
import { flowInitializeFlowFestAccountTestnetTransaction } from '../../flow/flowfest/initializeFlowFestAccount.testnet.tx';
import { flowIsFlowFestAccountInitializedMainnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.mainnet.script';
import { flowInitializeFlowFestAccountMainnetTransaction } from '../../flow/flowfest/initializeFlowFestAccount.mainnet.tx';
import {
  flowIsNFTNYCAccountInitializedScript,
} from '../../flow/nftnyc/isNFTNYCAccountInitialized.script';
import { flowInitializeNFTNYCAccountTransaction } from '../../flow/nftnyc/initializeNFTNYCAccount.tx';

const SESSION_ERROR_CODES_REGEX = /(1006|1008|Session expired)/;

function* waitForConfirmation(transactionId: string) {
  const promise = new Promise((resolve) => {
    firestore.collection('flowTransactions').doc(transactionId).onSnapshot((snapshot) => {
      const snapData = snapshot.data();
      if (snapData && snapData.state === 'processed') {
        resolve(snapData);
      }
    });
  });

  yield race({
    result: promise,
    delay: delay(15000),
  });
}

function* watchFlowAcceptSaleOfferRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowAcceptSaleOfferRequest, function* takeEveryFlowAcceptSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
        marketCollectionAddress,
        isMarketplace,
        paymentCurrency,
      },
    } = action;
    try {
      let flowAcceptSaleOfferFunction;

      if (paymentCurrency === 'FLOW') {
        flowAcceptSaleOfferFunction = flowAcceptSaleOrderWithConversionTransaction;
      } else {
        flowAcceptSaleOfferFunction = flowAcceptSaleOrderTransaction;
      }
      const result: FlowTransactionResultType = yield call(
        flowAcceptSaleOfferFunction, nftItemId, marketCollectionAddress,
      );
      const errMessage = result ? result?.errorMessage as string : '';
      const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
      if (result && !errMessage && transactionId) {
        yield call(waitForConfirmation, transactionId);
        const { data }: any = result.events.find((el) => el.data.price);
        trackEvent('card_purchase', {
          total: data?.price,
          quantity: 1,
          beneficiaryTotal: data?.beneficiarySaleCut?.amount,
          creatorTotal: data?.creatorSaleCut.amount,
          sellerTotal: data?.sellerSaleCut.amount,
          additionalSaleCuts: data?.additionalSaleCuts?.map((cut: any) => cut?.amount),
          currency: paymentCurrency,
        });
        yield put(flowAcceptSaleOfferResponse({ result, nftItemId, isMarketplace }));
      } else {
        yield put(cardSaleSetProgress({ inProgress: false }));
        if (isMarketplace) {
          yield put(setMarketplacePurchasingState({ isPurchasing: false }));
          yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false }));
        }

        if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (e) {
      if (isMarketplace) {
        yield put(setMarketplacePurchasingState({ isPurchasing: false }));
        yield put(setMarketplaceCardPurchaseState({ nftItemId, inProgress: false }));
      }
    }

    yield put(flowBalanceRequest({}));
  });
}

function* watchFlowBalanceRequest() {
  yield takeEvery(flowBalanceRequest, function* takeEveryFlowBalanceRequest() {
    try {
      const addr: string = yield select(selectFlowWalletAddr);
      const flowBalance: number = yield call(flowFetchFlowBalanceScript, addr);
      const fusdBalance: string = yield call(flowFetchFUSDBalanceScript, addr);
      const starlyBalance: string = yield call(flowFetchStarlyTokenBalanceScript, addr);

      yield put(flowBalanceResponse({
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchFlowBurnAllCardsRequest() {
  yield takeEvery(flowBurnAllCardsRequest, function* takeEveryFlowBurnAllCardsRequest() {
    yield call(flowBurnAllCardsTransaction);
    yield put(flowBurnAllCardsResponse({}));
  });
}

function* watchFlowBurnStarlyCardsRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowBurnStarlyCardsRequest, function* takeEveryFlowBurnStarlyCardsRequest(action) {
    yield call(flowBurnStarlyCardsTransaction, action.payload.nftItemIds);
    yield put(flowBurnStarlyCardsResponse({}));
  });
}

export async function getStarlyRate() {
  const rateSnaps: QuerySnapshot = await firestore.collection('cache').doc('starlyRate').collection('history')
    .where('timestamp', '<=', Math.round(Date.now() / 1000))
    .orderBy('timestamp', 'desc')
    .limit(1)
    .get();
  return rateSnaps.docs[0].data();
}

function* watchFlowBuyPackRequest() {
  yield takeEvery(flowBuyPackRequest, function* takeEveryFlowBuyPackRequest(action) {
    const {
      payload: {
        collectionId,
        packIds,
        currency,
        paymentCurrency,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      },
    } = action;
    let { price } = action.payload;
    let flowBuyPackFunction;
    if (currency === 'FLOW') {
      if (paymentCurrency === 'FLOW') {
        flowBuyPackFunction = blowBuyPackUsingFLOWTransaction;
      } else if (paymentCurrency === 'FUSD') {
        flowBuyPackFunction = blowBuyFlowPackWithFUSD;
      } else if (paymentCurrency === 'STARLY') {
        // yield put(buyPackResponse({ status: 'error' }));
        // yield put(flowToggleModal(true));
        // return;

        // const invertedPriceInFusd = yield call(() => fetchFusdToFlowAmount(1 / price));
        // const priceInFUSD = 1 / invertedPriceInFusd;
        // const rate: any = yield call(getStarlyRate);
        // price = priceInFUSD / rate.rate;
        // flowBuyPackFunction = blowBuyPackUsingSTARLYTransaction;
      }
    } else if (currency === 'FUSD') {
      if (paymentCurrency === 'FLOW') {
        flowBuyPackFunction = blowBuyPackWithConversionTransaction;
      } else if (paymentCurrency === 'STARLY') {
        const rate: any = yield call(getStarlyRate);
        price /= rate.rate;
        flowBuyPackFunction = blowBuyPackUsingSTARLYTransaction;
      } else {
        flowBuyPackFunction = blowBuyPackTransaction;
      }
    }
    let res: FlowTransactionResultType;

    try {
      res = yield call(
        flowBuyPackFunction,
        collectionId,
        packIds,
        price,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      );
    } catch {
      yield put(buyPackResponse({ status: 'error' }));
      yield put(flowToggleModal(true));
      return;
    }

    // @ts-ignore
    const errMessage = res ? res?.errorMessage as string : '';

    const transactionId = res?.events?.find((event) => event.transactionId)?.transactionId;
    if (res && !errMessage && transactionId) {
      yield call(waitForConfirmation, transactionId);
      yield put(flowBuyPackResponse({}));
      yield put(buyPackResponse({ status: 'allowed' }));

      trackEvent('pack_purchase', {
        quantity: packIds.length,
        total: price.toFixed(8).toString(),
        beneficiaryTotal: (beneficiaryCutPercent * price).toFixed(8).toString(),
        creatorTotal: (creatorCutPercent * price).toFixed(8).toString(),
        currency: paymentCurrency,
      });
    } else {
      const unreservePacks: any = functions.httpsCallable('unreservePacks');
      yield call(unreservePacks, { collectionId, packIds });
      yield put(buyPackResponse({ status: 'error' }));

      if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
        yield put(flowLogoutRequest({}));
      }
    }

    yield put(setPackPurchaseStatus({ status: 'initial' }));
  });
}

function* watchFlowCancelSaleOfferRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowCancelSaleOfferRequest, function* takeEveryFlowCancelSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
      },
    } = action;
    let result: FlowTransactionResultType;
    try {
      result = yield call(flowCancelSaleOfferTransaction, nftItemId);
    } catch (error) {
      yield put(flowCancelSaleOfferResponse({
        result: {
          errorMessage: error,
        },
      }));
      return;
    }
    const errMessage = result ? result?.errorMessage as string : '';
    const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
    if (result && !errMessage && transactionId) {
      yield call(waitForConfirmation, transactionId);
    } else if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
      yield put(flowLogoutRequest({}));
    }
    yield put(flowCancelSaleOfferResponse({ result }));
  });
}

function* watchFlowCreateSaleOfferRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowCreateSaleOfferRequest, function* takeEveryFlowCreateSaleOfferRequest(action) {
    const {
      payload: {
        nftItemId,
        price,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        minterAddress,
        mintingCutPercent,
      },
    } = action;
    const result: FlowTransactionResultType = yield call(
      flowCreateSaleOfferTransaction,
      nftItemId,
      price,
      beneficiaryAddress,
      beneficiaryCutPercent,
      creatorAddress,
      creatorCutPercent,
      minterAddress,
      mintingCutPercent,
    );

    const errMessage = result ? result?.errorMessage as string : '';
    const transactionId = result?.events?.find((event) => event.transactionId)?.transactionId;
    if (result && !errMessage && transactionId) {
      // @ts-ignore
      // eslint-disable-next-line new-cap
      const channel:EventChannel<boolean> = new eventChannel<boolean>((emmiter) => {
        const unsub = firestore.collection('flowTransactions').doc(transactionId).onSnapshot((snapshot) => {
          const snapData = snapshot.data();
          if (snapData && snapData.state === 'processed') {
            emmiter(true);
            emmiter(END);
          }
        });
        return () => unsub();
      });

      try {
        while (true) {
          yield take(channel);
        }
      } finally {
        yield put(flowCreateSaleOfferResponse({ result }));
      }
    } else {
      yield put(cardSaleSetProgress({ inProgress: false }));
      if (errMessage?.match(SESSION_ERROR_CODES_REGEX)) {
        yield put(flowLogoutRequest({}));
      }
      yield put(flowCreateSaleOfferResponse({ result }));
    }
  });
}

function* watchFlowFetchSaleOfferIDsRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowFetchSaleOfferIDsRequest, function* takeEveryFlowFetchSaleOfferIDsRequest(action) {
    const {
      payload: {
        flowAccount,
      },
    } = action;
    const saleOfferIDs: object = yield call(flowFetchSaleOfferIDsScript, flowAccount);
    yield put(flowFetchSaleOfferIDsResponse({ saleOfferIDs }));
  });
}

function* watchFlowFetchStarlyIDRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowFetchStarlyIDRequest, function* takeEveryFlowFetchStarlyIDRequest(action) {
    const {
      payload: {
        flowAccount,
        nftItemId,
      },
    } = action;
    const starlyID: object = yield call(flowFetchStarlyIDScript, flowAccount, nftItemId);
    yield put(flowFetchStarlyIDResponse({ starlyCardID: starlyID }));
  });
}

function* watchFlowFetchStarlyCardItemIDsRequest() {
  // eslint-disable-next-line max-len
  yield takeEvery(flowFetchStarlyCardItemIDsRequest, function* takeEveryFlowFetchStarlyCardItemIDsRequest(action) {
    const {
      payload: {
        flowAccount,
      },
    } = action;
    const starlyCardIDs: object = yield call(flowFetchStarlyCardItemIDsScript, flowAccount);
    yield put(flowFetchStarlyCardItemIDsResponse({ starlyCardIDs }));
  });
}

function* watchFlowLoginRequest() {
  yield takeEvery(flowLoginRequest, function* takeEveryFlowLoginRequest() {
    try {
      const wallet: FlowWallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowToggleLoading(false));
        yield put(flowToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const init: FlowInit = yield call(flowIsAccountInitializedScript, wallet.addr);

      if (!init.FUSD || !init.StarlyCard || !init.StarlyCardMarket || !init.StarlyToken) {
        yield call(flowInitializeAccountTransaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck:FlowInit = yield call(flowIsAccountInitializedScript, wallet.addr);

        if (!recheck.FUSD || !recheck.StarlyCard
          || !recheck.StarlyCardMarket || !init.StarlyToken
        ) {
          yield put(flowToggleModal(false));
          yield put(flowToggleLoading(false));

          return;
        }
      }

      const flowBalance: number = yield call(flowFetchFlowBalanceScript, wallet.addr);
      const fusdBalance: string = yield call(flowFetchFUSDBalanceScript, wallet.addr);
      const starlyBalance: string = yield call(flowFetchStarlyTokenBalanceScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));

      yield put(flowToggleModal(false));

      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowToggleLoading(false));
    }
  });
}

function* watchBloctoLoginRequest() {
  yield takeEvery(flowBloctoLoginRequest, function* takeEveryBloctoLoginRequest() {
    try {
      const wallet: FlowWallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowToggleLoading(false));
        yield put(flowToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      const init: FlowInit = yield call(flowIsAccountInitializedScript, wallet.addr);

      if (!init.FUSD || !init.StarlyCard || !init.StarlyCardMarket) {
        yield call(flowInitializeAccountTransaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck:FlowInit = yield call(flowIsAccountInitializedScript, wallet.addr);

        if (!recheck.FUSD || !recheck.StarlyCard || !recheck.StarlyCardMarket) {
          yield put(flowToggleModal(false));
          yield put(flowToggleLoading(false));

          return;
        }
      }

      const flowBalance: object = yield call(flowFetchFlowBalanceScript, wallet.addr);
      const fusdBalance: object = yield call(flowFetchFUSDBalanceScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
      }));

      yield put(flowToggleModal(false));

      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowToggleLoading(false));
    }
  });
}

function* watchFlowFestLoginRequest() {
  yield takeEvery(flowFestLoginRequest, function* takeEveryFlowFestLoginRequest() {
    try {
      const wallet: FlowWallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowFestToggleLoading(false));
        yield put(flowFestToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowFestToggleModal(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const script = (process.env.REACT_APP_CHAIN_ENV === 'testnet')
        ? flowIsFlowFestAccountInitializedTestnetScript
        : flowIsFlowFestAccountInitializedMainnetScript;

      const transaction = (process.env.REACT_APP_CHAIN_ENV === 'testnet')
        ? flowInitializeFlowFestAccountTestnetTransaction
        : flowInitializeFlowFestAccountMainnetTransaction;

      const init: FlowInit = yield call(script, wallet.addr);

      if (Object.values(init).findIndex((v) => !v) > -1) {
        yield call(transaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck:FlowInit = yield call(script, wallet.addr);

        if (Object.values(recheck).findIndex((v) => !v) > -1) {
          yield put(flowFestToggleModal(false));
          yield put(flowFestToggleLoading(false));

          return;
        }
      }
      yield put(flowFestCheckWalletResponse(true));

      const flowBalance: number = yield call(flowFetchFlowBalanceScript, wallet.addr);
      const fusdBalance: string = yield call(flowFetchFUSDBalanceScript, wallet.addr);
      const starlyBalance: string = yield call(flowFetchStarlyTokenBalanceScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowFestLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));

      yield put(flowFestToggleModal(false));
      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowFestToggleLoading(false));
    }
  });
}

function* watchFlowNFTNYCLoginRequest() {
  yield takeEvery(flowNFTNYCLoginRequest, function* takeEveryFlowNFTNYCLoginRequest() {
    try {
      const wallet: FlowWallet = yield call(fcl.logIn);

      /**
       * User closed Flow modal without login to wallet
       */
      if (!wallet.addr) {
        yield put(flowNFTNYCToggleLoading(false));
        yield put(flowNFTNYCToggleModal(false));

        return;
      }

      const userId: string = yield select((state) => state.auth.userId);

      yield put(userRequest({ id: userId }));
      const {
        payload: {
          user,
        },
      } = yield take(userResponse);

      // if user has flow_account same as wallet address then skip updateFlowAccount and checks
      if (user.flow_account !== wallet.addr) {
        const updateFlowAccount = functions.httpsCallable('updateFlowAccount');
        /**
         * User login to own wallet first time
         */
        try {
          yield call(() => updateFlowAccount({ flow_account: wallet.addr }));
        } catch (error) {
          /**
           * Wallet was already connected or belongs to another user
           */
          if (wallet.addr) {
            yield call(fcl.unauthenticate);
            yield put(flowToggleLoading(false));
            yield put(flowNFTNYCToggleModal(false));
            yield put(flowWrongModal(
              user.flow_account
                ? { code: 1, message: user.flow_account }
                : { code: 2, message: wallet.addr },
            ));
            return;
          }
        }
      }

      const init: FlowInit = yield call(flowIsNFTNYCAccountInitializedScript, wallet.addr);

      if (Object.values(init).findIndex((v) => !v) > -1) {
        yield call(flowInitializeNFTNYCAccountTransaction, wallet.addr);

        /**
         * Check is user approved init or not
         */
        const recheck:FlowInit = yield call(flowIsNFTNYCAccountInitializedScript, wallet.addr);

        if (Object.values(recheck).findIndex((v) => !v) > -1) {
          yield put(flowNFTNYCToggleModal(false));
          yield put(flowNFTNYCToggleLoading(false));

          return;
        }
      }
      yield put(flowNFTNYCCheckWalletResponse(true));

      const flowBalance: number = yield call(flowFetchFlowBalanceScript, wallet.addr);
      const fusdBalance: string = yield call(flowFetchFUSDBalanceScript, wallet.addr);
      const starlyBalance: string = yield call(flowFetchStarlyTokenBalanceScript, wallet.addr);

      yield put(updateWalletRequest({
        id: userId,
        address: wallet.addr,
      }));

      yield put(flowNFTNYCLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));

      yield put(flowLoginResponse({
        wallet,
        flowBalance,
        fusdBalance,
        starlyBalance,
      }));

      yield put(flowNFTNYCToggleModal(false));
      trackEvent('wallet_connect');
    } catch (error: any) {
      trackException(error.message);
      yield put(flowNFTNYCToggleLoading(false));
    }
  });
}

function* watchFlowLogoutRequest() {
  yield takeEvery(flowLogoutRequest, function* takeEveryLogoutRequest(action) {
    yield call(fcl.unauthenticate);
    yield put(flowLogoutResponse({ action }));

    trackEvent('wallet_disconnect');
  });
}

export default function* flowSaga() {
  yield all([
    fork(watchFlowAcceptSaleOfferRequest),
    fork(watchFlowBalanceRequest),
    fork(watchFlowBurnAllCardsRequest),
    fork(watchFlowBurnStarlyCardsRequest),
    fork(watchFlowBuyPackRequest),
    fork(watchFlowCancelSaleOfferRequest),
    fork(watchFlowCreateSaleOfferRequest),
    fork(watchFlowFetchSaleOfferIDsRequest),
    fork(watchFlowFetchStarlyIDRequest),
    fork(watchFlowFetchStarlyCardItemIDsRequest),
    fork(watchFlowLoginRequest),
    fork(watchBloctoLoginRequest),
    fork(watchFlowLogoutRequest),
    fork(watchFlowFestLoginRequest),
    fork(watchFlowNFTNYCLoginRequest),
  ]);
}
