import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import PopUp from 'components/PopUp';
import { selectFlowWalletAddr, selectLowBalanceFlowModal } from 'store/flow/flowSelectors';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { flowLowBalanceToggleModal } from 'store/flow/flowActions';
import { auth, functions, remoteConfig } from 'global/firebase';
import { useWindowSize } from 'hooks/useWindowSize';
import { desktopBreakpoint } from 'breakpoints';
import { ButtonsContainer, TittleText } from './styled';

export const RAMP_API_KEY = process.env.REACT_APP_RAMP_API_KEY || '';
export const RAMP_WIDGET_URL = process.env.REACT_APP_RAMP_WIDGET_URL || 'https://buy.ramp.network/';

export const MOONPAY_API_KEY = process.env.REACT_APP_MOONPAY_API_KEY || '';
export const MOONPAY_URL = process.env.REACT_APP_MOONPAY_URL || 'https://buy.moonpay.com/';

const TopUpModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const closeTopUpModal = () => dispatch(flowLowBalanceToggleModal({ isOpen: false }));
  const addr = useSelector(selectFlowWalletAddr);
  const isFlow = useSelector(selectLowBalanceFlowModal);
  const [isDisabled, setIsDisabled] = useState(false);
  const isMobile = useWindowSize() < desktopBreakpoint;

  const onTopUp = (e: React.FormEvent) => {
    e.preventDefault();
    const topUpMode = remoteConfig.getValue('topUpMode').asString();
    if (topUpMode === 'moonpay' || isFlow) {
      setIsDisabled(true);
      const topUpEmail = auth.currentUser && auth.currentUser.email;
      const moonPayUrl = `${MOONPAY_URL}?apiKey=${MOONPAY_API_KEY}&currencyCode=${isFlow ? 'flow' : 'fusd'}&walletAddress=${addr}${topUpEmail ? `&email=${encodeURIComponent(topUpEmail)}` : ''}`;
      functions.httpsCallable('signMoonpayUrl')({ url: moonPayUrl })
        .then((res) => {
          closeTopUpModal();
          setIsDisabled(false);
          window.open(res.data.url, '_blank'); // TODO configuration
        })
        .catch(() => {
          setIsDisabled(false);
        });
    } else {
      new RampInstantSDK({
        userAddress: addr,
        hostAppName: 'Starly',
        hostLogoUrl: 'https://storage.googleapis.com/starly-prod.appspot.com/assets/icons/starly-logo-810x240-on-white.png',
        fiatValue: '50',
        swapAsset: isFlow ? 'FLOW_FLOW' : 'FLOW_FUSD',
        defaultAsset: isFlow ? 'FLOW_FLOW' : 'FLOW_FUSD',
        hostApiKey: RAMP_API_KEY,
        url: RAMP_WIDGET_URL,
        variant: isMobile ? 'mobile' : 'desktop',
      }).show();
      closeTopUpModal();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);
  return (
    <PopUp onHide={closeTopUpModal}>
      <TittleText size="popUpTitle" textColor="black" font="secondary">
        {t('wallet.balance.notEnough', { currency: isFlow ? 'flow' : 'funds' })}
      </TittleText>
      <ButtonsContainer>
        <Button onClick={(e) => onTopUp(e)} disabled={isDisabled}>{t('wallet.balance.topUp')}</Button>
        <Button background="black" onClick={closeTopUpModal}>{t('wallet.notNow')}</Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default TopUpModal;
