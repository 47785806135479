import { createAction } from '@reduxjs/toolkit';
import {
  FlowFestProject,
  FlowFestCard,
  FlowFestProjectId,
} from '@starly/starly-types';
import { FilterOption } from '../marketplace/marketplaceTypes';

export type ExtendedFFCard = FlowFestCard & {
  price: number,
  storefrontAddress: string,
  ftType: 'FLOW' | 'FUSD',
  projectName: string,
  listingResourceID: number,
  isBuying?: boolean,
  isForSale?: boolean,
};

type FFBuyCardRequestPayload = {
  project: string,
  listingResourceID: number,
  storefrontAddress: string,
  buyPrice: number
};

type RemoveFromSaleFFCardRequest = {
  id: number,
  userWallet: string,
};

export const flowNFTNYCProjectRequest = createAction<{ id: string }>('nftnyc/flowProjectRequest');
export const flowNFTNYCProjectResponse = createAction<{ project: FlowFestProject }>('nftnyc/flowProjectResponse');

export const loadNFTNYCProjectsRequest = createAction('nftnyc/loadFFProjectsRequest');
export const loadNFTNYCProjectsResponse = createAction<{ projects: FlowFestProject[] }>('nftnyc/loadFFProjectsResponse');
export const loadNFTNYCProjectsFailure = createAction<{ error: string }>('nftnyc/loadFFProjectsFailure');

export const loadFFCardsRequest = createAction<{
  filter?: string,
  sort?: string,
  rarity?: FilterOption[]
  isResetCursor: boolean,
}>('nftnyc/loadFFCardsRequest');
export const loadFFCardsResponse = createAction<{ cards: ExtendedFFCard[], isLastCards: boolean }>('nftnyc/loadFFCardsResponse');
export const loadFFCardsFailure = createAction<{ error: string }>('nftnyc/loadFFCardsFailure');

export const buyFFCardRequest = createAction<FFBuyCardRequestPayload>('nftnyc/buyFFCardRequest');
export const buyFFCardResponse = createAction<{ listingResourceID: number }>('nftnyc/buyFFCardResponse');
export const buyFFCardFailure = createAction<{ error: string, listingResourceID: number }>('nftnyc/buyFFCardFailure');

export const ffReservePackRequest = createAction('nftnyc/ffReservePackRequest');
export const ffReservePackResponse = createAction('nftnyc/ffReservePackResponse');
export const ffReservePackFailure = createAction('nftnyc/ffReservePackFailure');

export const ffOpenPackRequest = createAction('nftnyc/ffOpenPackRequest');
export const ffOpenPackResponse = createAction('nftnyc/ffOpenPackResponse');
export const ffOpenPackFailure = createAction<{ error: string }>('nftnyc/ffOpenPackFailure');

export const ffShowPackRequest = createAction('nftnyc/ffShowPackRequest');
export const ffShowPackResponse = createAction('nftnyc/ffShowPackResponse');
export const ffShowPackFailure = createAction('nftnyc/ffShowPackFailure');

export const getFlowFestPackStateRequest = createAction('nftnyc/getFlowFestPackStateRequest');
export const getFlowFestPackStateResponse = createAction('nftnyc/getFlowFestPackStateResponse');
export const getFlowFestPackStateFailure = createAction('nftnyc/getFlowFestPackStateFailure');

export const ffValidateCaptchaRequest = createAction('nftnyc/ffValidateCaptchaRequest');
export const ffValidateCaptchaResponse = createAction('nftnyc/ffValidateCaptchaResponse');
export const ffValidateCaptchaFailure = createAction('nftnyc/ffValidateCaptchaFailure');

export const redeemCodeRequest = createAction('nftnyc/redeemCodeRequest');
export const redeemCodeResponse = createAction('nftnyc/redeemCodeResponse');
export const redeemCodeFailure = createAction('nftnyc/redeemCodeFailure');
export const redeemCodeReset = createAction('nftnyc/redeemCodeReset');

export const ffViewCardRequest = createAction('nftnyc/ffViewCardRequest');
export const ffViewCardResponse = createAction('nftnyc/ffViewCardResponse');
export const ffViewCardFailure = createAction('nftnyc/ffViewCardFailure');
export const ffViewPackRequest = createAction('nftnyc/ffViewPackRequest');
export const ffViewPackResponse = createAction('nftnyc/ffViewPackResponse');
export const ffViewPackFailure = createAction('nftnyc/ffViewPackFailure');

export const ffClearReservePackRequest = createAction('nftnyc/ffClearReservePackRequest');
export const ffClearReservePackResponse = createAction('nftnyc/ffClearReservePackResponse');

export const flowNFTNYCCheckWalletRequest = createAction('nftnyc/checkWallet/request');
export const flowNFTNYCCheckWalletResponse = createAction('nftnyc/checkWallet/response');
export const flowNFTNYCCheckWalletFailure = createAction('nftnyc/checkWallet/failure');

export const resetFlowFestState = createAction('nftnyc/resetFlowFestState');
export const resetIsFFCardPurchased = createAction('nftnyc/resetIsFFCardPurchased');
export const resetFFCards = createAction('nftnyc/resetFFCards');

export const setIsFFCardsLoading = createAction<boolean>('nftnyc/setIsFFCardsLoading');
export const setIsMoreFFCardsLoading = createAction<boolean>('nftnyc/setIsMoreFFCardsLoading');

export const saleFFCardRequest = createAction<{
  projectId: FlowFestProjectId,
  itemId: number,
  price: number,
}>('nftnycCard/SALE_FFCARD_REQUEST');
export const saleFFCardResponse = createAction('nftnycCard/SALE_FFCARD_RESPONSE');
export const saleFFCardFailure = createAction('nftnycCard/SALE_FFCARD_FAILURE');

export const removeFromSaleFFCardRequest = createAction<RemoveFromSaleFFCardRequest>('nftnycCard/REMOVE_FROM_SALE_FF_CARD_REQUEST');
export const removeFromSaleFFCardResponse = createAction('nftnycCard/REMOVE_FROM_SALE_FF_CARD_RESPONSE');
export const removeFromSaleFFCardFailure = createAction('nftnycCard/REMOVE_FROM_SALE_FF_CARD_FAILURE');

export const loadMyFFCardsRequest = createAction<string>('nftnycCards/LOAD_MY_FF_CARDS_REQUEST');
export const loadMyFFCardsResponse = createAction<any>('nftnycCards/LOAD_MY_FF_CARDS_RESPONSE');
export const loadMyFFCardsFailure = createAction<any>('nftnycCards/LOAD_MY_FF_CARDS_FAILURE');

export const getFFCardListingRequest = createAction<{
  storeFrontAddress: string,
  parentProjectId: FlowFestProjectId,
  nftItemId: number,
}>('nftnycCards/GET_FF_CARD_LISTING_REQUEST');
export const getFFCardListingResponse = createAction<any>('nftnycCards/GET_FF_CARD_LISTING_RESPONSE');
export const getFFCardListingFailure = createAction<any>('nftnycCards/GET_FF_CARD_LISTING_FAILURE');
