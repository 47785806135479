export const AVATAR_MEDIA_MAX_SIZE_BYTES = 26214400;
export const COVER_MEDIA_MAX_SIZE_BYTES = 26214400;
export const CARD_MEDIA_MAX_SIZE_BYTES = 52428800;

const SUPPORTED_IMAGE_FORMATS = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/webp',
];

const SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/mov',
  'video/quicktime',
  'video/webm',
  'video/avi',
  'video/mpeg',
  'video/x-msvideo', // AVI
  'video/x-matroska', // MKV
];

const WEB_SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/webm',
];

export function getFileExtension(filename: string): string {
  let extension = '';
  if (filename.includes('.')) {
    extension = `.${filename.split('.').pop()}`;
  }
  return extension;
}

export function hasMkvExtension(filename: string): boolean {
  const extension = getFileExtension(filename);
  return extension.toLowerCase().endsWith('.mkv'); // MKV files have no type for some reason
}

export function isVideoFile(file: any): boolean {
  return hasMkvExtension(file.name)
        || SUPPORTED_VIDEO_FORMATS.includes(file.type);
}
export function getFileType(file: any) {
  return isVideoFile(file) ? 'video' : 'image';
}

export function isSupportedMediaFormat(file: any): boolean {
  return hasMkvExtension(file.name)
        || SUPPORTED_IMAGE_FORMATS.includes(file.type)
        || SUPPORTED_VIDEO_FORMATS.includes(file.type);
}

export function isSupportedWebMediaFormat(file: any): boolean {
  return WEB_SUPPORTED_VIDEO_FORMATS.includes(file.type);
}
