// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import { Address } from '@onflow/types';

const CODE = fcl.cdc`
import BlindBoxRedeemVoucher from 0xBLIND_BOX_REDEEM_VOUCHER_ADDRESS
import FiatToken  from 0xFIAT_TOKEN_ADDRESS
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import FUSD from 0xFUSD_ADDRESS
import MetadataViews from 0xMETADATA_VIEWS_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS
import StarlyToken from 0xSTARLY_TOKEN_ADDRESS
import StarlyTokenStaking from 0xSTARLY_TOKEN_STAKING_ADDRESS
import StakedStarlyCard from 0xSTAKED_STARLY_CARD_ADDRESS

pub fun hasBlindBoxRedeemVoucher(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&{BlindBoxRedeemVoucher.CollectionPublic}>(BlindBoxRedeemVoucher.CollectionPublicPath)
        .check()
}

pub fun hasFiatToken(_ address: Address): Bool {
    let receiver: Bool = getAccount(address)
        .getCapability<&FiatToken.Vault{FungibleToken.Receiver}>(FiatToken.VaultReceiverPubPath)
        .check()
    let balance: Bool = getAccount(address)
        .getCapability<&FiatToken.Vault{FungibleToken.Balance}>(FiatToken.VaultBalancePubPath)
        .check()
    return receiver && balance
}

pub fun hasFUSD(_ address: Address): Bool {
    let receiver: Bool = getAccount(address)
        .getCapability<&FUSD.Vault{FungibleToken.Receiver}>(/public/fusdReceiver)
        .check()
    let balance: Bool = getAccount(address)
        .getCapability<&FUSD.Vault{FungibleToken.Balance}>(/public/fusdBalance)
        .check()
    return receiver && balance
}

pub fun hasStakedStarlyCard(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&StakedStarlyCard.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, StakedStarlyCard.CollectionPublic}>(StakedStarlyCard.CollectionPublicPath)
        .check()
}

pub fun hasStarlyCard(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&StarlyCard.Collection{NonFungibleToken.CollectionPublic, StarlyCard.StarlyCardCollectionPublic}>(StarlyCard.CollectionPublicPath)
        .check()
}

pub fun hasStarlyCardMarket(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&StarlyCardMarket.Collection{StarlyCardMarket.CollectionPublic}>(StarlyCardMarket.CollectionPublicPath)
        .check()
}

pub fun hasStarlyToken(_ address: Address): Bool {
    let receiver: Bool = getAccount(address)
        .getCapability<&StarlyToken.Vault{FungibleToken.Receiver}>(StarlyToken.TokenPublicReceiverPath)
        .check()
    let balance: Bool = getAccount(address)
        .getCapability<&StarlyToken.Vault{FungibleToken.Balance}>(StarlyToken.TokenPublicBalancePath)
        .check()
    return receiver && balance
}

pub fun hasStarlyTokenStaking(_ address: Address): Bool {
    return getAccount(address)
        .getCapability<&StarlyTokenStaking.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, StarlyTokenStaking.CollectionPublic}>(StarlyTokenStaking.CollectionPublicPath)
        .check()
}

pub fun main(address: Address): {String: Bool} {
    let ret: {String: Bool} = {}
    ret["BlindBoxRedeemVoucher"] = hasBlindBoxRedeemVoucher(address)
    ret["USDC"] = hasFiatToken(address)
    ret["FUSD"] = hasFUSD(address)
    ret["StakedStarlyCard"] = hasStakedStarlyCard(address)
    ret["StarlyCard"] = hasStarlyCard(address)
    ret["StarlyCardMarket"] = hasStarlyCardMarket(address)
    ret["StarlyToken"] = hasStarlyToken(address)
    ret["StarlyTokenStaking"] = hasStarlyTokenStaking(address)
    return ret
}`;

export function flowIsNFTNYCAccountInitializedScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, Address),
    ]),
  ]).then(fcl.decode);
}
