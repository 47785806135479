import styled from 'styled-components';
import { mediaDesktop } from 'breakpoints';
import theme from 'components/theme';
import { ButtonProps } from './types';

const Button = styled.button<ButtonProps>`
  text-transform: uppercase;
  vertical-align: middle;
  user-select: none;
  border: none;
  outline: none;
  ${({ hide }) => hide && { visibility: 'hidden' }};
  ${({ margin }) => margin && { margin }};
  ${({ width }) => width && { width }};
  ${({ relativePos }) => (relativePos ? { position: 'relative' } : '')};
  ${({ border }) => (border ? (`border: 2px solid ${theme.colors[border]};`) : '')}
  background: ${({ background }) => theme.colors[background || 'primary']};
  color: ${({ textColor }) => theme.colors[textColor || 'white']};
  font-family: ${theme.fonts.primary}, sans-serif;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 13px;
  cursor: ${({ disabled, nonePointer }) => (disabled || nonePointer ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  -webkit-tap-highlight-color: transparent;
  transition: color .2s, background .2s, border .2s;

  @media (hover: hover) and (pointer: fine) {
    &:hover:enabled {
      ${({ background = 'primary' }) => (`
        background: ${theme.hover[background]};
        ${background === 'lightTransparent' ? 'color: #000 !important;' : ''}
      `)}
      ${({ border, background = 'primary' }) => (border ? (`
        border-color: ${theme.hover[border]};
        color: ${theme.hover[border]};
      `) : (`
        border-color: ${theme.hover[background]};
      `))}
      ${({ background, noneHover }) => (noneHover ? (`
      ${background ? `background: ${theme.colors[background]} !important;` : ''}
      ${background ? `border-color: ${theme.colors[background]} !important;` : ''}
      `) : '')}
    }
    transition: color .2s, background .2s, border .2s;
  }

  ${mediaDesktop} {
    font-size: 19px;
    letter-spacing: 0.09375rem;
  }

  ${({ size }: any) => {
    switch (size) {
      case 'small':
        return `
          padding: 0px 7px 0px 7px;
          min-width: 65px;
          ${mediaDesktop} {
            padding: 0px 7px 0px 7px;
            min-width: 110px;
            height: 40px;
          }
        `;
      case 'regular':
        return `
          padding: 0px 8px 0px 8px;
          height: 40px;
          ${mediaDesktop} {
            padding: 0px 12px 0px 12px;
            height: 40px;
          }
        `;
      default:
      case 'big':
        return `
          padding: 0px 12px 0px 12px;
          height: 40px;
          ${mediaDesktop} {
            padding: 0px 18px 0px 18px;
            height: 60px;
          }
        `;
    }
  }}

  ${({ fluid }: ButtonProps) => (fluid ? 'width: 100% !important;' : '')}
`;
export default Button;
