import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { ReactSelectContainer } from 'components/SelectContainer/ReactSelectContainer';
import Select from 'react-select';
import moment from 'moment';
import ContentPopUp from '../ContentPopUp';
import { RewardPoolPopUpProps } from './types';
import {
  HeadContainer,
  PurchaseLabel,
  TableContainer,
} from '../PurchaseCardPopUp/styled';
import { numberWithCommas } from '../../helpers/TextHelpers';
import {
  StyledInfoPanel, RewardValue,
  RewardHeaderRow, RewardTable, RewardSelectContainer, RewardPopUpTitle,
} from './styled';
import { Column } from '../Table';
import RewardTableRow from './RewardTableRow';
import { desktopBreakpoint } from '../../breakpoints';
import { useWindowSize } from '../../hooks/useWindowSize';

const RewardPoolPopUp: FC<RewardPoolPopUpProps> = ({
  onClose,
  rewards,
}) => {
  const snapshotDates = rewards.map(([date]) => date);
  const sortOptions = useMemo(() => snapshotDates.map((d) => ({
    label: moment(d).format('MMMM D, YYYY'),
    value: d.toString(),
  })), [rewards]);
  const [currentDate, setCurrentDate] = useState(sortOptions[0]);
  const currentDateRewards = rewards.find(
    ([date]) => date.toString() === currentDate.value.toString(),
  );
  const currentRewards = currentDateRewards && currentDateRewards[1];
  const isMobile = desktopBreakpoint > useWindowSize();
  useEffect(() => {
    if (!isMobile) {
      window.document.body.style.overflowY = 'hidden';
    }
    return () => {
      window.document.body.style.overflowY = 'initial';
    };
  }, [isMobile]);
  return (
    <ContentPopUp
      data-test-id="rewarded_collectors_popup"
      onClose={onClose}
    >
      <HeadContainer>
        <RewardPopUpTitle data-test-id="rewarded_collectors_popup_headline" font="secondary" bold uppercase>REWARDED COLLECTORS</RewardPopUpTitle>
        <StyledInfoPanel>
          <PurchaseLabel data-test-id="rewarded_collectors_popup_label_1">
            REWARD POOL
            <RewardValue data-test-id="reward_value_1" data-value-attribute={currentRewards?.total_amount || 0}>
              {numberWithCommas(currentRewards?.total_amount || 0, 0)}
              {' '}
              $STARLY
            </RewardValue>
          </PurchaseLabel>
          <PurchaseLabel data-test-id="rewarded_collectors_popup_label_2">
            QUALIFIED FOR A REWARD
            <RewardValue data-test-id="reward_value_2" data-value-attribute={currentRewards?.collector_count}>
              TOP
              {' '}
              {currentRewards?.collector_count}
              {' '}
              COLLECTORS
            </RewardValue>
          </PurchaseLabel>
          <RewardSelectContainer>
            <PurchaseLabel data-test-id="rewarded_collectors_popup_label_3">
              Snapshot date
            </PurchaseLabel>
            <ReactSelectContainer
              data-test-id="rewarded_collectors_popup_dd_menu"
              fluid
            >
              <Select
                classNamePrefix="react-select"
                isSearchable={false}
                defaultValue={sortOptions[0]}
                value={currentDate}
                options={sortOptions}
                onChange={(v) => {
                  if (v) setCurrentDate(v);
                }}
              />
            </ReactSelectContainer>
          </RewardSelectContainer>
        </StyledInfoPanel>
      </HeadContainer>
      <TableContainer data-test-id="reward_popup_table_container">
        <RewardTable data-test-id="reward_popup_table">
          <RewardHeaderRow data-test-id="reward_header_row" header>
            <Column textAlign="center">#</Column>
            <Column>Collector</Column>
            <Column textAlign="center">Score</Column>
            <Column textAlign="right">Reward</Column>
          </RewardHeaderRow>
          {
            !!currentRewards && currentRewards.rewards.map((reward, i) => (
              <RewardTableRow
                reward={reward}
                place={i + 1}
              />
            ))
          }
        </RewardTable>
      </TableContainer>
    </ContentPopUp>
  );
};

export default RewardPoolPopUp;
