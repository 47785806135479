import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import PopUp from '../PopUp';
import { ButtonsContainer, PopUpText } from './styled';

const CreateUnavalible: FC<{
  onHide: () => void;
  onContact: () => void;
}> = ({ onHide, onContact }) => {
  const { t } = useTranslation();
  return (
    <PopUp data-test-id="popup_unavailable" onHide={onHide}>
      <PopUpText data-test-id="unavailable_headline">{t('collection.facecontrol.unavailable.title')}</PopUpText>
      <PopUpText data-test-id="unavailable_subline">{t('collection.facecontrol.unavailable.subtitle')}</PopUpText>
      <ButtonsContainer>
        <Button data-test-id="contact_us_button" fluid onClick={onContact}>
          {t('collection.facecontrol.unavailable.contactUs')}
        </Button>
        <Button data-test-id="contact_us_cancel_button" background="black" onClick={onHide}>
          {t('common.cancel')}
        </Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default CreateUnavalible;
