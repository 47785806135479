import styled from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../theme';
import Button from '../Button';

export const InputContainer = styled.div`
  display: flex;
  & > *:first-of-type {
    margin-right: 16px;
  }
`;

export const CheckBoxContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  & > *{
    margin-bottom: 16px;
  }
  & > *:last-of-type {
    margin-bottom: 0;
  }
`;

export const PriceContainer = styled.div`
`;

export const RarityContainer = styled.form`
`;

export const FilterButtonsContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  
  & ${Button}:first-of-type {
    min-height: 40px;
  }
  & ${Button} {
    ${mediaDesktop} {
      font-size: 16px;
    }
  }
`;

export const FilterContainer = styled.div`
  color: #fff;
  background: ${theme.colors.dark};
  display: grid;
  width: 100%;
  border: 1.5px solid #fff;
  padding: 25px 16px 16px;
  grid-gap: 25px;
  ${mediaDesktop} {
    width: 324px;
  }
`;
