import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

/**
 * Application reducers
 */
import appReducer from './app/appReducer';
import authReducer from './auth/authReducer';
import collectionReducer from './collection/collectionReducer';
import ethereumReducer from './ethereum/ethereumReducer';
import vestingReducer from './vesting/vestingReducer';
import flowReducer from './flow/flowReducer';
import sideMenuReducer from './sideMenu/sideMenuReducer';
import userReducer from './user/userReducer';
import packReducer from './pack/packReducer';
import flowFestReducer from './flowFest/flowFestReducer';
import firebaseConfigReducer from './firebaseConfig/firebaseConfigReducer';

/**
 * Application sagas
 */
import authSaga from './auth/authSaga';
import collectionSaga from './collection/collectionSaga';
import userSaga from './user/userSaga';
import cardReducer from './card/cardReducer';
import cardSaga from './card/cardSaga';
import saleCardReducer from './saleCard/saleCardReducer';
import saleCardSaga from './saleCard/saleCardSaga';
import packSaga from './pack/packSaga';
import loginReducer from './login/loginReducer';
import { notificationsReducer } from './notifications/notificationsReducer';
import { transactionsReducer } from './transactions/transactionsReducer';
import { notificationsSaga } from './notifications/notificationsSaga';
import { transactionsSaga } from './transactions/transactionsSaga';
import { dropsReducer } from './drops/dropsReducer';
import { dropsSaga } from './drops/dropsSaga';
import ethereumSaga from './ethereum/ethereumSaga';
import vestingSaga from './vesting/vestingSaga';
import flowSaga from './flow/flowSaga';
import flowFestSaga from './flowFest/flowFestSaga';
import flowNFTNYCSaga from './flowNFTNYC/flowNFTNYCSaga';
import flowNFTNYCReducer from './flowNFTNYC/flowNFTNYCReducer';
import appSaga from './app/appSaga';
import { marketplaceReducer } from './marketplace/marketplaceReducer';
import { marketplaceSaga } from './marketplace/marketplaceSaga';
import { rankingReducer } from './ranking/rankingReducer';
import { rankingSaga } from './ranking/rankingSaga';

/**
 * Configure persistant storage
 */
const persistConfig = {
  key: process.env.REACT_APP_COMMIT_HASH || 'local',
  whitelist: [
    'auth',
  ],
  storage,
};

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer({
    key: 'auth',
    storage,
  }, authReducer),
  collections: persistReducer({
    key: 'collections',
    blacklist: [
      'activeCollection',
      'itemsCachedState',
    ],
    storage,
  }, collectionReducer),
  ethereum: ethereumReducer,
  vesting: persistReducer({
    key: 'vesting',
    whitelist: ['wallet'],
    storage,
  }, vestingReducer),
  flow: persistReducer({
    key: 'flow',
    blacklist: [
      'isWrongModalOpen',
      'isWalletModalOpen',
      'isLowBalanceModalOpen',
      'isLoading',
    ],
    storage,
  }, flowReducer),
  sideMenu: sideMenuReducer,
  user: persistReducer({
    key: 'user',
    blacklist: [
      'isLoading',
    ],
    storage,
  }, userReducer),
  login: loginReducer,
  card: cardReducer,
  cardForSale: saleCardReducer,
  pack: persistReducer({
    key: 'pack',
    blacklist: [
      'isLoading',
      'isPackOpening',
      'buying',
    ],
    storage,
  }, packReducer),
  marketplace: marketplaceReducer,
  ranking: rankingReducer,
  notifications: notificationsReducer,
  transactions: transactionsReducer,
  drops: dropsReducer,
  flowFest: flowFestReducer,
  nftnyc: flowNFTNYCReducer,
  firebaseConfig: firebaseConfigReducer,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    routerMiddleware(history),
    sagaMiddleware,
  ],
});

/**
 * Run all Sagas
 */
sagaMiddleware.run(authSaga);
sagaMiddleware.run(collectionSaga);
sagaMiddleware.run(ethereumSaga);
sagaMiddleware.run(vestingSaga);
sagaMiddleware.run(flowSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(cardSaga);
sagaMiddleware.run(saleCardSaga);
sagaMiddleware.run(packSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(transactionsSaga);
sagaMiddleware.run(dropsSaga);
sagaMiddleware.run(flowFestSaga);
sagaMiddleware.run(flowNFTNYCSaga);
sagaMiddleware.run(marketplaceSaga);
sagaMiddleware.run(rankingSaga);
sagaMiddleware.run(appSaga);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
