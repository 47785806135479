import { createReducer } from '@reduxjs/toolkit';
import { User, Collection as CollectionSchema, CollectedCollection } from '@starly/starly-types';
import { sortCollectedCollections, sortCreatedCollections } from 'util/collections';
import {
  userResponse,
  userUpdateResponse,
  updateCardsStakedStatus,
  writeAvatarImageRequest,
  writeAvatarImageResponse,
  resetUserState,
  userToggleLoading,
  updateUsernameRequest,
  updateUsernameResponse,
  updateUsernameFailure,
  updateWalletResponse,
  updateEthWallet,
  disconnectUserSocialRequest,
  disconnectUserSocialResponse,
  disconnectUserSocialFailure,
  userUpdateRequest,
} from './userActions';

export interface IExtendedUser extends User {
  collectedCollections: CollectedCollection[];
  createdCollections: CollectionSchema[];
  face_control_state: 'pass' | 'fail';
}

interface UserSlice {
  items: { [key: string]: IExtendedUser },
  isLoading: boolean,
}

const initialState: UserSlice = {
  items: {},
  isLoading: false,
  error: '',
};

const userReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(userResponse, (state, { payload: { user } }) => {
      if (user.id) {
        if (user.connections) {
          Object.keys(user.connections).forEach((type) => {
            // eslint-disable-next-line no-param-reassign
            user.connections[type].isLoading = false;
          });
        }

        // TODO do we need conditions in reducers?
        state.items[user.id] = {
          ...state.items[user.id],
          id: user.id,
          avatar: user.avatar,
          username: user.username,
          name: user.name,
          flow_account: user.flow_account,
          // ethereum_account: user.ethereum_account,
          bio: user.bio,
          url: user.url,
          email: user.email,
          collected_collections: user.collected_collections,
          created_collections: user.created_collections,
          collectedCollections: sortCollectedCollections(user.collected_collections),
          createdCollections: sortCreatedCollections(user.created_collections),
          connections: user.connections || {},
          notifications: user.notifications,
          face_control_state: user.face_control_state,
          allow_create_collection: user.allow_create_collection,
          pack_battle_winner: user.pack_battle_winner,
        };
        state.error = '';
      }
    })
    .addCase(userUpdateRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(userUpdateResponse, (state, {
      payload: { user },
    }) => {
      state.isLoading = false;
      state.items[user.id] = {
        ...state.items[user.id],
        ...user,
      };
    })
    .addCase(updateCardsStakedStatus, (state, { payload: { userId, cards } }) => {
      if (userId) {
        cards.forEach((card) => {
          state.items[userId].collected_collections[card.collection_id]
            .collected_cards[card.id].staked = card.staked;
          state.items[userId].collected_collections[card.collection_id]
            .collected_cards[card.id].stake_id = card.stake_id;
        });

        state.items[userId].collectedCollections = sortCollectedCollections(
          state.items[userId].collected_collections,
        );
      }
    })
    .addCase(resetUserState, (state) => {
      state.items = [];
    })
    .addCase(updateUsernameRequest, (state, { payload: { id, username } }) => {
      state.isLoading = true;
      state.items[id].username = username;
    })
    .addCase(updateUsernameResponse, (state, { payload: { id, username } }) => {
      state.isLoading = false;
      state.items[id].username = username;
      state.error = '';
    })
    .addCase(updateUsernameFailure, (state, { payload: { id, error } }) => {
      state.isLoading = false;
      state.items[id].username = '';
      state.error = error;
    })
    .addCase(updateWalletResponse, (state, { payload: { id, address } }) => {
      state.items[id].flow_account = address;
    })
    .addCase(updateEthWallet, (state, { payload: { id, address } }) => {
      state.items[id].ethereum_account = address;
    })
    .addCase(writeAvatarImageResponse, (state, { payload: { id, avatar } }) => {
      state.isLoading = false;
      state.items[id].avatar = avatar;
    })
    .addCase(writeAvatarImageRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(disconnectUserSocialRequest, (state, { payload: { social, id } }) => {
      state.items[id].connections[social].isLoading = true;
    })
    .addCase(disconnectUserSocialResponse, (state, { payload: { social, id } }) => {
      delete state.items[id].connections[social];
    })
    .addCase(disconnectUserSocialFailure, (state, { payload: { social, id } }) => {
      state.items[id].connections[social].isLoading = false;
    })
    .addCase(userToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
  ,
);

export default userReducer;
