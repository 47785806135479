import styled from 'styled-components';
import { VideoWrapperProps } from './types';

export const VideoWrapper = styled.div<VideoWrapperProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

export const VideoVideo = styled.video<VideoWrapperProps>`
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: inherit;
`;
