// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';
// @ts-ignore
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from './util/tx';

const CODE = fcl.cdc`
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS

transaction(itemID: UInt64) {
    prepare(account: AuthAccount) {
        let offer <- account
          .borrow<&StarlyCardMarket.Collection>(from: StarlyCardMarket.CollectionStoragePath)!
          .remove(itemID: itemID)
        destroy offer
    }
}`;

export async function flowCancelSaleOfferTransaction(
  itemID: number,
  opts = { onError },
) {
  invariant(itemID != null, 'itemID must be supplied');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false)]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([
        fcl.arg(Number(itemID), t.UInt64),
      ]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
