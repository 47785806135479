import styled from 'styled-components';
import Button from '../Button';
import Typography from '../Typography';
import { mediaDesktop } from '../../breakpoints';

export const JoinDiscordLabelContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(to bottom, #8b36ff, #5566fb);
  padding: 12px 16px 16px;
  ${mediaDesktop} {
    padding: 16px 0;
    background-image: linear-gradient(to right, #8b36ff, #5566fb);
    min-height: 72px;
  }
`;

export const JoinDiscordLabelContent = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  ${mediaDesktop} {
    flex-direction: row;
    text-align: left;
    max-width: ${({ theme }) => theme.width.mainContainer};
    margin: auto;
    justify-content: space-between;
  }
`;

export const JoinDiscordButton = styled(Button)`
  width: 156px;
  height: 28px;
  padding: 0;
  
  ${mediaDesktop} {
    width: 225px;
    height: 40px;
  }
`;

export const JoinDiscordButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5566fb;
  font-size: 13px;
  letter-spacing: 1px;
  ${mediaDesktop} {
    letter-spacing: 1.5px;
    font-size: 19px;
  }
`;

export const JoinDiscordButtonIcon = styled.img`
  height: 22px;
  width: 25px;
  margin-right: 13px;
  ${mediaDesktop} {
    margin-right: 5px;
    width: 50px;
    height: 30px;
  }
`;

export const JoinDiscordText = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  letter-spacing: .5px;
  margin-bottom: 12px;
  ${mediaDesktop} {
    letter-spacing: 1px;
    margin-bottom: 0;
    font-size: 21px;
  }
`;

export const JoinDiscordLabelLink = styled.a`
  text-decoration: none;
`;
