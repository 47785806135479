// @ts-ignore
import { config } from '@onflow/fcl';
import { send as grpcSend } from '@onflow/transport-grpc';

config()
  .put('sdk.transport', grpcSend)
  .put('fcl.appDomainTag', 'starly.io')
  .put('env', process.env.REACT_APP_CHAIN_ENV)
  .put('accessNode.api', process.env.REACT_APP_ACCESS_NODE)
  .put('challenge.handshake', process.env.REACT_APP_WALLET_DISCOVERY)
  .put('0xBEAM_ADDRESS', process.env.REACT_APP_CONTRACT_BEAM)
  .put('0xBLIND_BOX_REDEEM_VOUCHER_ADDRESS', process.env.REACT_APP_CONTRACT_BLIND_BOX_REDEEM_VOUCHER)
  .put('0xBLOCKLETE_GAMES_ADDRESS', process.env.REACT_APP_CONTRACT_BLOCKLETE_GAMES)
  .put('0xCAA_PASS_ADDRESS', process.env.REACT_APP_CONTRACT_CAA_PASS)
  .put('0xCHAINMONSTERS_REWARDS_ADDRESS', process.env.REACT_APP_CONTRACT_CHAINMONSTERS_REWARDS)
  .put('0xCOLLECTIBLE_ADDRESS', process.env.REACT_APP_CONTRACT_COLLECTIBLE)
  .put('0xCOMPOUND_INTEREST_ADDRESS', process.env.REACT_APP_CONTRACT_COMPOUND_INTEREST)
  .put('0xCRAVE_ADDRESS', process.env.REACT_APP_CONTRACT_CRAVE)
  .put('0xCRICKET_MOMENTS_ADDRESS', process.env.REACT_APP_CONTRACT_CRICKET_MOMENTS)
  .put('0xEVERBLOOM_ADDRESS', process.env.REACT_APP_CONTRACT_EVERBLOOM)
  .put('0xFANTASTEC_ADDRESS', process.env.REACT_APP_CONTRACT_FANTASTEC)
  .put('0xFIAT_TOKEN_ADDRESS', process.env.REACT_APP_CONTRACT_FIAT_TOKEN)
  .put('0xFLOW_TOKEN_ADDRESS', process.env.REACT_APP_CONTRACT_FLOW_TOKEN)
  .put('0xFLOW_STORAGE_FEES_ADDRESS', process.env.REACT_APP_CONTRACT_FLOW_STORAGE_FEES)
  .put('0xFUNGIBLE_TOKEN_ADDRESS', process.env.REACT_APP_CONTRACT_FUNGIBLE_TOKEN)
  .put('0xFUSD_ADDRESS', process.env.REACT_APP_CONTRACT_FUSD)
  .put('0xFUSD_USDT_SWAP', process.env.REACT_APP_CONTRACT_FUSD_USDT_SWAP)
  .put('0xFLOW_SWAP', process.env.REACT_APP_CONTRACT_FLOW_SWAP)
  .put('0xGAIA_ADDRESS', process.env.REACT_APP_CONTRACT_GAIA)
  .put('0xKLKTN_ADDRESS', process.env.REACT_APP_CONTRACT_KLKTN)
  .put('0xKOTD_ADDRESS', process.env.REACT_APP_CONTRACT_KOTD)
  .put('0xMATRIX_WORLD_FLOWFEST_NFT_ADDRESS', process.env.REACT_APP_CONTRACT_MATRIX_WORLD_FLOWFEST_NFT)
  .put('0xMETADATA_VIEWS_ADDRESS', process.env.REACT_APP_CONTRACT_METADATA_VIEWS)
  .put('0xMUSIC_BLOCK_ADDRESS', process.env.REACT_APP_CONTRACT_MUSIC_BLOCK)
  .put('0xMYNFT_ADDRESS', process.env.REACT_APP_CONTRACT_MYNFT)
  .put('0xNON_FUNGIBLE_TOKEN_ADDRESS', process.env.REACT_APP_CONTRACT_NON_FUNGIBLE_TOKEN)
  .put('0xNFT_STOREFRONT_ADDRESS', process.env.REACT_APP_CONTRACT_NFT_STOREFRONT)
  .put('0xNYATHEES_OVO_ADDRESS', process.env.REACT_APP_CONTRACT_NYATHEES_OVO)
  .put('0xRACEDAY_NFT_ADDRESS', process.env.REACT_APP_CONTRACT_RACEDAY_NFT)
  .put('0xRAREROOMS_NFT_ADDRESS', process.env.REACT_APP_CONTRACT_RAREROOMS_NFT)
  .put('0xRCRDSHP_ADDRESS', process.env.REACT_APP_CONTRACT_RCRDSHP)
  .put('0xSHARD_ADDRESS', process.env.REACT_APP_CONTRACT_ETERNAL_SHARD)
  .put('0xSPORTS_ICON_COLLECTIBLE_ADDRESS', process.env.REACT_APP_CONTRACT_SPORTS_ICON_COLLECTIBLE)
  .put('0xSTARLY_CARD_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_CARD)
  .put('0xSTARLY_CARD_MARKET_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_CARD_MARKET)
  .put('0xSTARLY_CARD_STAKING_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_CARD_STAKING)
  .put('0xSTARLY_CARD_STAKING_CLAIMS_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_CARD_STAKING_CLAIMS)
  .put('0xSTARLY_PACK_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_PACK)
  .put('0xSTARLY_TOKEN_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_TOKEN)
  .put('0xSTARLY_TOKEN_STAKING_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_TOKEN_STAKING)
  .put('0xSTARLY_TOKEN_VESTING_ADDRESS', process.env.REACT_APP_CONTRACT_STARLY_TOKEN_VESTING)
  .put('0xSTAKED_STARLY_CARD_ADDRESS', process.env.REACT_APP_CONTRACT_STAKED_STARLY_CARD)
  .put('0xTHEFABRICANT_MYSTERYBOX_FF1_ADDRESS', process.env.REACT_APP_CONTRACT_THEFABRICANT_MYSTERYBOX_FF1)
  .put('0xTOP_SHOT_ADDRESS', process.env.REACT_APP_CONTRACT_TopShot)
  .put('0xTUNE_GO_ADDRESS', process.env.REACT_APP_CONTRACT_TUNE_GO)
  .put('0xVOUCHERS_ADDRESS', process.env.REACT_APP_CONTRACT_VOUCHERS)
  .put('0xFLOW_USDT_SWAP_PAIR_ADDRESS', process.env.REACT_APP_CONTRACT_FLOW_USDT_SWAP_PAIR)
  .put('0xFUSD_USDT_SWAP_PAIR_ADDRESS', process.env.REACT_APP_CONTRACT_FUSD_USDT_SWAP_PAIR);

if (process.env.REACT_APP_ACCESS_NODE_KEY) {
  config()
    .put('grpc.metadata', { api_key: process.env.REACT_APP_ACCESS_NODE_KEY });
}

// TODO move to env.local
// config()
//   .put('env', 'local')
//   .put('accessNode.api', 'http://localhost:8080')
//   .put('discovery.wallet', 'http://localhost:9999/fcl/authn')
//   .put('0xFungibleToken', '0xee82856bf20e2aa6')
//   .put('0xNonFungibleToken', '0xf8d6e0586b0a20c7')
//   .put('0xFUSD', '0xf8d6e0586b0a20c7')
//   .put('0xStarlyCard', '0xf8d6e0586b0a20c7')
//   .put('0xStarlyCardMarket', '0xf8d6e0586b0a20c7')
//   .put('0xStarlyPack', '0xf8d6e0586b0a20c7');
