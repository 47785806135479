import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toggleSideMenu } from '../../store/sideMenu/sideMenuActions';
import { selectSideMenuIsOpen, selectSideMenuItems } from '../../store/sideMenu/sideMenuSelectors';
import logo from '../../static/starly-transparent-logo.svg';
import MenuItem from './MenuItem/MenuItem';
import closeIcon from '../../static/ic-close.svg';
import { selectAuthAuthorized } from '../../store/auth/authSelectors';

const Portal: FC = ({ children }) => {
  const domElement = document.querySelector('#side-root') as Element;
  return ReactDOM.createPortal(
    children,
    domElement,
  );
};

const wrapperAnimation = keyframes`
  from {
    background: transparent;
  }
  99% {
    background: transparent;
  }
  to {
    background: black;
  }
`;

const SideMenuWrapper = styled.div`
  z-index: 10000;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  height: 120vh;
  background: black;
  animation: ${wrapperAnimation} .5s;
`;

const appearAnimation = keyframes`
  from {
    height: 0;
    min-height: 0;
  }
  to {
    height: 100%;
  } 
`;

const SideMenuContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: 100vh;
  animation: ${appearAnimation} .5s;
  background-color: ${({ theme }) => theme.colors.black};
`;

const MenuLogo = styled.img`
  width: 89px;
  height: 21px;
`;

const MenuList = styled.ul`
  animation: ${appearAnimation} .5s;
  list-style: none;
  text-align: center;
  padding-bottom: 80px;
`;

const MenuCloseIcon = styled.img`
  cursor: pointer;
`;

const MenuHeaderContainer = styled.div`
  left: 0;
  right: 0;
  background: #000;
  display: flex;
  padding: 15.5px 20px 13px 16px;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const SideMenu: FC = () => {
  const dispatch = useDispatch();
  const { pathname, key } = useLocation();
  const { t } = useTranslation();
  const sideMenuIsOpen = useSelector(selectSideMenuIsOpen);
  const menuData = useSelector(selectSideMenuItems);
  const isAuthorized = useSelector(selectAuthAuthorized);
  useEffect(() => {
    document.body.style.overflow = sideMenuIsOpen ? 'hidden' : 'initial';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [sideMenuIsOpen]);

  const onSideMenuToggle = (open: boolean) => {
    dispatch(toggleSideMenu(open));
  };

  useEffect(() => {
    onSideMenuToggle(false);
  }, [pathname, key, isAuthorized]);

  return sideMenuIsOpen && (
    <Portal>
      <SideMenuWrapper>
        <SideMenuContainer>
          <MenuHeaderContainer>
            <MenuLogo src={logo} alt={t('common.starlyLogo')} />
            <MenuCloseIcon src={closeIcon} onClick={() => onSideMenuToggle(false)} />
          </MenuHeaderContainer>
          <MenuList>
            {menuData.map((el) => (
              el.text === t('header.create') ? (
                <MenuItem
                  key={t('header.createCollection')}
                  {...el}
                  text={t('header.createCollection')}
                />
              ) : (
                <MenuItem
                  key={el.text}
                  {...el}
                />
              )
            ))}
          </MenuList>
        </SideMenuContainer>
      </SideMenuWrapper>
    </Portal>
  );
};

export default SideMenu;
