// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS

// This script returns the metadata for an NFT in an account's collection.

pub fun main(address: Address, itemID: UInt64): String {

    // get the public account object for the token owner
    let owner = getAccount(address)

    let collectionBorrow = owner.getCapability(StarlyCard.CollectionPublicPath)!
        .borrow<&{StarlyCard.StarlyCardCollectionPublic}>()
        ?? panic("Could not borrow StarlyCardCollectionPublic")

    // borrow a reference to a specific NFT in the collection
    let starlyCard = collectionBorrow.borrowStarlyCard(id: itemID)
        ?? panic("No such itemID in that collection")

    return starlyCard.starlyID
}`;

export function flowFetchStarlyIDScript(address: string, nftItemId: number) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
      fcl.arg(nftItemId, t.UInt64),
    ]),
  ]).then(fcl.decode);
}
