// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import StarlyCardMarket from 0xSTARLY_CARD_MARKET_ADDRESS

pub fun main(address: Address): [UInt64] {
    let marketCollectionRef = getAccount(address)
        .getCapability<&StarlyCardMarket.Collection{StarlyCardMarket.CollectionPublic}>(
            StarlyCardMarket.CollectionPublicPath
        )
        .borrow()
        ?? panic("Could not borrow market collection from market address")
    
    return marketCollectionRef.getSaleOfferIDs()
}`;

export function flowFetchSaleOfferIDsScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
    ]),
  ]).then(fcl.decode);
}
