import React from 'react';
import * as S from './styled';

import kucoinIcon from '../../static/ic-kucoin.svg';

const kucoinLink = 'https://www.kucoin.com/ucenter/signup?rcode=r31ZMHY';

const KucoinBuyLabel = () => (
  <S.KucoinBuyLabelContainer href={kucoinLink} target="_blank">
    <S.KucoinLabelWrapper>
      <S.KucoinText>
        20% discount transactions with $STARLY on KuCoin exchange
      </S.KucoinText>
      <S.KucoinButton background="white" noneHover>
        <S.KucoinIcon src={kucoinIcon} />
        <S.KucoinBtnText>
          GO TO KUCOIN
        </S.KucoinBtnText>
      </S.KucoinButton>
    </S.KucoinLabelWrapper>
  </S.KucoinBuyLabelContainer>
);

export default KucoinBuyLabel;
