import {
  all, call, fork, put, takeEvery, take, select,
} from 'redux-saga/effects';
import {
  Card, CardEdition, Collection, Listing, SalesHistoryItem,
} from '@starly/starly-types';
import { eventChannel } from 'redux-saga';
import firebase from 'firebase/compat/app';
import {
  cardInfoSetLoading,
  cardInfoSetMoreToLoad,
  cardRequest,
  cardResponse,
  cardSalesResponse,
  cardSalesRequest,
  getCardCountersRequest,
  getCardCountersResponse,
  cardListingRequest,
  cardListingResponse, addCardListenerToMap, cardSetLoading,
} from './cardActions';
import {
  database,
  firestore,
  DocumentSnapshot,
  QuerySnapshot,
  trackException, Query, OrderByDirection,
} from '../../global/firebase';
import { userRequest } from '../user/userActions';
import { purchasedCardLoad } from '../saleCard/saleCardActions';
import { userRankingScoreRequest } from '../ranking/rankingActions';
import { chunkArray } from '../../util/chunkArray';

const LISTING_LIMIT = 25;

function* watchCardRequest() {
  yield takeEvery(cardRequest, function* cardRequestWorker(action) {
    try {
      const {
        payload: {
          collectionId, cardId, edition, background, purchase,
        },
      } = action;
      const collection: DocumentSnapshot = yield call(() => firestore.collection('collections').doc(collectionId).get());
      const creatorId = (collection.data() as Collection).creator.id;
      yield put(userRequest({ id: creatorId }));
      yield put(userRankingScoreRequest({ id: creatorId }));
      const cardSnapshot: DocumentSnapshot = yield call(() => collection.ref.collection('cards').doc(cardId).get());
      const cardEditionSnapshot: DocumentSnapshot = yield call(() => cardSnapshot.ref.collection('editions').doc(edition).get());
      const card = cardSnapshot.data() as Card;
      const cardEdition = cardEditionSnapshot.data() as CardEdition;
      if (cardEdition?.owner) {
        yield put(userRequest({ id: cardEdition.owner.id }));
      }
      const editions: { [id: string]: CardEdition } = {
        [cardEditionSnapshot.id]: cardEdition,
      };
      if (!edition) {
        const firstLastSnap: DocumentSnapshot[] = yield call(() => cardSnapshot.ref.collection('editions').where(
          firebase.firestore.FieldPath.documentId(), 'in', ['1', (card.editions - 1).toString()],
        ).get());
        firstLastSnap.forEach((doc: DocumentSnapshot) => {
          editions[doc.id] = doc.data() as CardEdition;
        });
      }

      yield put(cardResponse({
        cardState: {
          card,
          collection: collection.data() as Collection,
          editions,
        },
      }));
      if (background && purchase) yield put(purchasedCardLoad());
      yield put(getCardCountersRequest({ cardId, collectionId }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

const listingSorts: string[] = [
  'create_time desc',
  'score_price_ratio desc',
  'price asc edition_id asc',
  'price desc edition_id asc',
  'edition_id asc price asc',
  'edition_id desc price asc',
  'collector_score desc price asc',
];

function* watchCardListingRequest() {
  yield takeEvery(cardListingRequest, function* cardListingWorker({
    payload: {
      collectionId,
      cardId,
      sort,
      page,
    },
  }) {
    yield put(cardInfoSetLoading({ isLoading: true, infoType: 'listing' }));
    try {
      let listingQuery: Query = firestore
        .collection(`collections/${collectionId}/cards/${cardId}/listings`);

      const sorts = chunkArray(listingSorts[sort].split(' '), 2);
      sorts.forEach((chunk) => {
        listingQuery = listingQuery.orderBy(...(chunk as [string, OrderByDirection]));
      });

      if (!listingSorts[sort].startsWith('create_time')) {
        listingQuery = listingQuery.orderBy('create_time', 'desc');
      }

      const listing: QuerySnapshot = yield call(() => listingQuery.get());
      const pageIndex = page * LISTING_LIMIT;
      const startIndex = pageIndex > listing.docs.length - 1 ? listing.docs.length - 1 : pageIndex;
      const data: QuerySnapshot = yield call(() => listing
        .query
        .startAt(listing.docs[startIndex])
        .limit(LISTING_LIMIT)
        .get());
      const filteredData = data.docs.map((doc) => doc.data() as Listing);
      if (filteredData.length < LISTING_LIMIT) {
        yield put(cardInfoSetMoreToLoad({ isMoreToLoad: false, infoType: 'listing' }));
      }
      yield put(cardListingResponse({ listing: filteredData }));
    } catch (error: any) {
      trackException(error.message);
    }
    yield put(cardInfoSetLoading({ isLoading: false, infoType: 'listing' }));
  });
}

function* watchCardSalesRequest() {
  yield takeEvery(cardSalesRequest, function* cardSalesWorker({
    payload: {
      collectionId,
      cardId,
      sort,
      page,
    },
  }) {
    yield put(cardInfoSetLoading({ isLoading: true, infoType: 'sales' }));
    try {
      let salesQuery: Query = yield call(() => firestore
        .collection(`collections/${collectionId}/cards/${cardId}/salesHistory`));

      const sorts = chunkArray(listingSorts[sort].split(' '), 2);
      sorts.forEach((chunk) => {
        salesQuery = salesQuery.orderBy(...(chunk as [string, OrderByDirection]));
      });

      if (!listingSorts[sort].startsWith('create_time')) {
        salesQuery = salesQuery.orderBy('create_time', 'desc');
      }

      const sales: QuerySnapshot = yield call(() => salesQuery.get());

      const pageIndex = page * LISTING_LIMIT;
      const startIndex = pageIndex > sales.docs.length - 1 ? sales.docs.length - 1 : pageIndex;
      const data: QuerySnapshot = yield call(() => sales
        .query
        .startAt(sales.docs[startIndex])
        .limit(LISTING_LIMIT)
        .get());
      const filteredData = data.docs.map((doc) => doc.data() as SalesHistoryItem);
      if (filteredData.length < LISTING_LIMIT) {
        yield put(cardInfoSetMoreToLoad({ isMoreToLoad: false, infoType: 'sales' }));
      }
      yield put(cardSalesResponse({ sales: filteredData }));
    } catch (error: any) {
      trackException(error.message);
    }
    yield put(cardInfoSetLoading({ isLoading: false, infoType: 'sales' }));
  });
}

function* watchGetCardCountersRequest() {
  yield takeEvery(getCardCountersRequest, function* takeEveryGetCardCountersRequest(action) {
    const { payload: { cardId, collectionId } } = action;

    const listenersMap: {
      [key: string] : { [key: string]: boolean }
    } = yield select((state) => state.card.listenersMap);

    if (listenersMap[collectionId] && listenersMap[collectionId][cardId]) {
      yield put(cardSetLoading({ isLoading: false }));
      return;
    }

    yield put(addCardListenerToMap({ collectionId, cardId }));

    // @ts-ignore
    // eslint-disable-next-line
    const channel = new eventChannel((emitter) => {
      // get initial data
      database.ref(`counters/${collectionId}/${cardId}`).get().then(
        (snapshot) => emitter({ data: snapshot.val() || {} }),
      );

      const listener = database.ref(`counters/${collectionId}/${cardId}`).on('value', (snapshot) => {
        emitter({ data: snapshot.val() || {} });
      });

      return () => {
        // @ts-ignore
        listener.off();
      };
    });

    while (true) {
      const {
        data: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          listings, in_circulation, lowest_price, top_sale,
        },
      } = yield take(channel);
      yield put(getCardCountersResponse({
        cardId,
        listings: listings || 0,
        inCirculation: in_circulation || 0,
        lowestAsk: lowest_price || 0,
        topSale: top_sale || 0,
      }));
    }
  });
}

export default function* cardSaga() {
  yield all([
    fork(watchCardRequest),
    fork(watchCardListingRequest),
    fork(watchCardSalesRequest),
    fork(watchGetCardCountersRequest),
  ]);
}
