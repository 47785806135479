// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import { invariant } from '@onflow/util-invariant';
import { onError } from 'helpers/onError';
import { tx } from '../util/tx';

const CODE = fcl.cdc`
import MetadataViews from 0xMETADATA_VIEWS_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyTokenVesting from 0xSTARLY_TOKEN_VESTING_ADDRESS

transaction {
    prepare(acct: AuthAccount) {
        if acct.borrow<&StarlyTokenVesting.Collection>(from: StarlyTokenVesting.CollectionStoragePath) == nil {
            acct.save(<-StarlyTokenVesting.createEmptyCollectionAndNotify(beneficiary: acct.address), to: StarlyTokenVesting.CollectionStoragePath)
            acct.link<&StarlyTokenVesting.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, StarlyTokenVesting.CollectionPublic}>(
                StarlyTokenVesting.CollectionPublicPath,
                target: StarlyTokenVesting.CollectionStoragePath)
        }
    }
}
`;

export async function flowInitializeVestingTransaction(
  address: string,
  opts = { onError },
) {
  invariant(address != null, 'Tried to initialize an account but no wallet address was supplied');

  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false)]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(999),
      fcl.ref(block.id),
    ],
    opts,
  );
}
