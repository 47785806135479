import { createAction } from '@reduxjs/toolkit';
import {
  FlowFestProject,
  FlowFestCard,
  FlowFestProjectId,
} from '@starly/starly-types';
import { FilterOption } from '../marketplace/marketplaceTypes';

export type ExtendedFFCard = FlowFestCard & {
  price: number,
  storefrontAddress: string,
  ftType: 'FLOW' | 'FUSD',
  projectName: string,
  listingResourceID: number,
  isBuying?: boolean,
  isForSale?: boolean,
};

type FFBuyCardRequestPayload = {
  project: string,
  listingResourceID: number,
  storefrontAddress: string,
  buyPrice: number
};

type RemoveFromSaleFFCardRequest = {
  id: number,
  userWallet: string,
};

export const flowFestProjectRequest = createAction<{ id: string }>('flowFest/flowProjectRequest');
export const flowFestProjectResponse = createAction<{ project: FlowFestProject }>('flowFest/flowProjectResponse');

export const loadFFProjectsRequest = createAction('flowFest/loadFFProjectsRequest');
export const loadFFProjectsResponse = createAction<{ projects: FlowFestProject[] }>('flowFest/loadFFProjectsResponse');
export const loadFFProjectsFailure = createAction<{ error: string }>('flowFest/loadFFProjectsFailure');

export const loadFFCardsRequest = createAction<{
  filter?: string,
  sort?: string,
  rarity?: FilterOption[]
  isResetCursor: boolean,
}>('flowFest/loadFFCardsRequest');
export const loadFFCardsResponse = createAction<{ cards: ExtendedFFCard[], isLastCards: boolean }>('flowFest/loadFFCardsResponse');
export const loadFFCardsFailure = createAction<{ error: string }>('flowFest/loadFFCardsFailure');

export const buyFFCardRequest = createAction<FFBuyCardRequestPayload>('flowFest/buyFFCardRequest');
export const buyFFCardResponse = createAction<{ listingResourceID: number }>('flowFest/buyFFCardResponse');
export const buyFFCardFailure = createAction<{ error: string, listingResourceID: number }>('flowFest/buyFFCardFailure');

export const ffReservePackRequest = createAction('flowFest/ffReservePackRequest');
export const ffReservePackResponse = createAction('flowFest/ffReservePackResponse');
export const ffReservePackFailure = createAction('flowFest/ffReservePackFailure');

export const ffOpenPackRequest = createAction('flowFest/ffOpenPackRequest');
export const ffOpenPackResponse = createAction('flowFest/ffOpenPackResponse');
export const ffOpenPackFailure = createAction<{ error: string }>('flowFest/ffOpenPackFailure');

export const ffShowPackRequest = createAction('flowFest/ffShowPackRequest');
export const ffShowPackResponse = createAction('flowFest/ffShowPackResponse');
export const ffShowPackFailure = createAction('flowFest/ffShowPackFailure');

export const getFlowFestPackStateRequest = createAction('flowFest/getFlowFestPackStateRequest');
export const getFlowFestPackStateResponse = createAction('flowFest/getFlowFestPackStateResponse');
export const getFlowFestPackStateFailure = createAction('flowFest/getFlowFestPackStateFailure');

export const ffValidateCaptchaRequest = createAction('flowFest/ffValidateCaptchaRequest');
export const ffValidateCaptchaResponse = createAction('flowFest/ffValidateCaptchaResponse');
export const ffValidateCaptchaFailure = createAction('flowFest/ffValidateCaptchaFailure');

export const redeemCodeRequest = createAction('flowFest/redeemCodeRequest');
export const redeemCodeResponse = createAction('flowFest/redeemCodeResponse');
export const redeemCodeFailure = createAction('flowFest/redeemCodeFailure');
export const redeemCodeReset = createAction('flowFest/redeemCodeReset');

export const ffViewCardRequest = createAction('flowFest/ffViewCardRequest');
export const ffViewCardResponse = createAction('flowFest/ffViewCardResponse');
export const ffViewCardFailure = createAction('flowFest/ffViewCardFailure');
export const ffViewPackRequest = createAction('flowFest/ffViewPackRequest');
export const ffViewPackResponse = createAction('flowFest/ffViewPackResponse');
export const ffViewPackFailure = createAction('flowFest/ffViewPackFailure');

export const ffClearReservePackRequest = createAction('flowFest/ffClearReservePackRequest');
export const ffClearReservePackResponse = createAction('flowFest/ffClearReservePackResponse');

export const flowFestCheckWalletRequest = createAction('flowFest/checkWallet/request');
export const flowFestCheckWalletResponse = createAction('flowFest/checkWallet/response');
export const flowFestCheckWalletFailure = createAction('flowFest/checkWallet/failure');

export const resetFlowFestState = createAction('flowFest/resetFlowFestState');
export const resetIsFFCardPurchased = createAction('flowFest/resetIsFFCardPurchased');
export const resetFFCards = createAction('flowFest/resetFFCards');

export const setIsFFCardsLoading = createAction<boolean>('flowFest/setIsFFCardsLoading');
export const setIsMoreFFCardsLoading = createAction<boolean>('flowFest/setIsMoreFFCardsLoading');

export const saleFFCardRequest = createAction<{
  projectId: FlowFestProjectId,
  itemId: number,
  price: number,
}>('ffCard/SALE_FFCARD_REQUEST');
export const saleFFCardResponse = createAction('ffCard/SALE_FFCARD_RESPONSE');
export const saleFFCardFailure = createAction('ffCard/SALE_FFCARD_FAILURE');

export const removeFromSaleFFCardRequest = createAction<RemoveFromSaleFFCardRequest>('ffCard/REMOVE_FROM_SALE_FF_CARD_REQUEST');
export const removeFromSaleFFCardResponse = createAction('ffCard/REMOVE_FROM_SALE_FF_CARD_RESPONSE');
export const removeFromSaleFFCardFailure = createAction('ffCard/REMOVE_FROM_SALE_FF_CARD_FAILURE');

export const loadMyFFCardsRequest = createAction<string>('ffCards/LOAD_MY_FF_CARDS_REQUEST');
export const loadMyFFCardsResponse = createAction<any>('ffCards/LOAD_MY_FF_CARDS_RESPONSE');
export const loadMyFFCardsFailure = createAction<any>('ffCards/LOAD_MY_FF_CARDS_FAILURE');

export const getFFCardListingRequest = createAction<{
  storeFrontAddress: string,
  parentProjectId: FlowFestProjectId,
  nftItemId: number,
}>('ffCards/GET_FF_CARD_LISTING_REQUEST');
export const getFFCardListingResponse = createAction<any>('ffCards/GET_FF_CARD_LISTING_RESPONSE');
export const getFFCardListingFailure = createAction<any>('ffCards/GET_FF_CARD_LISTING_FAILURE');
