import styled from 'styled-components';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';

export const ContentPopUpWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${mediaDesktop} {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: grid;
    place-content: center;
  }
`;

export const CloseBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: right;
  user-select: none;
  position: absolute;
  top: 16px;
  right: 24px;
  ${mediaDesktop} {
    position: static;
  }
`;

export const PopUpContainer = styled.div`
  background: ${theme.colors.dark};
  color: #fff;
  position: relative;
  height: 100%;
  flex: 1;
  
  ${mediaDesktop} {
    width: 976px;
    max-height: 760px;
    display: grid;
    padding: 32px 32px 0;
    border: 1px solid ${theme.colors.lightGray};
  }
`;
