import styled, { css } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../theme';

export const ReactSelectContainer = styled.div<{
  withLabel?: boolean,
  isFocused?: boolean,
  fluid?: boolean,
  withoutTopMargin?: boolean
  transparent?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;

  ${mediaDesktop} {
    flex-direction: row;
    align-items: center;
    width: ${({ fluid }) => (fluid ? '100%' : 'calc(50% - 25px)')};
  }
  
  & > p {
    white-space: nowrap;
    font-size: 13px;
  }

  & > div {
    width: 100%;
    margin-left: 0;

    ${mediaDesktop} {
      margin-left: ${({ withLabel }) => (withLabel ? '15' : '0')}px;
    }

    .react-select__control {
      margin-top: ${({ withoutTopMargin }) => (withoutTopMargin ? '0px' : '5px')};
      box-shadow: none;
      border: solid 2px ${theme.colors.white};
      border-radius: 0;
      background-color: ${({ transparent }) => (transparent ? 'transparent' : theme.colors.dark)};

      .react-select__value-container {
        .react-select__single-value {
          text-transform: uppercase;
          font-family: ${theme.fonts.primary};
          font-size: 13px;
          font-weight: 600;
          color: ${theme.colors.white};
          // Remove default text from input
          ${({ isFocused }) => (isFocused ? css`
            font-size: 0;
          ` : '')}
        }

        .react-select__input-container {
          font-family: ${theme.fonts.primary};
          font-size: 13px;
          font-weight: 600;
          color: ${theme.colors.white};
          // Remove visual issue with cropped letters
          ${({ isFocused }) => (isFocused ? css`
            grid-template-columns: 0 1fr;
          ` : '')}
          .react-select__input {
            text-transform: uppercase;
          }
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }

        .react-select__indicator {
          color: ${theme.colors.white};
          cursor: pointer;
        }
      }
    }

    .react-select__menu {
      margin: 0;
      border-radius: 0;
      border: solid 2px ${theme.colors.white};
      border-top: 0;
      background-color: ${theme.colors.black};
      z-index: 2;
      

      .react-select__menu-list {
        padding: 0;
        
        -webkit-transform: translate3d(0, 0, 0);
        & * {
          -webkit-transform: translate3d(0, 0, 0);
        }
        
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-backface-visibility: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        
        .react-select__option {
          -webkit-backface-visibility: hidden;
          padding: 10px 10px;
          text-transform: uppercase;
          font-family: ${theme.fonts.primary};
          font-size: 13px;
          font-weight: 600;
          color: ${theme.colors.white};
          cursor: pointer;
          box-shadow: none !important;
          outline: none !important;
          background-color: ${theme.colors.black} !important;
          
          &--is-selected {
            background-color: ${theme.colors.black} !important;
            color: ${theme.colors.white};
          }
          
          &--is-focused {
            background-color: ${theme.colors.white} !important;
            color: ${theme.colors.black};
          }
        }
      }
    }
  }
`;
