import React from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/PopUp';
import Button from 'components/Button';
import closeIcon from 'static/ic-close-black.svg';
import Input from 'components/Input';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { emojiRegex } from 'global/constants';
import { useDispatch } from 'react-redux';
import { authLoginEmailRequest } from 'store/auth/authActions';
import { useHistory } from 'react-router';
import { getValidationScheme } from './validation';
import {
  CloseIcon, SecondInput, ButtonsContainer,
} from './styled';

interface EmailAuthProps {
  closeModal: () => void;
}
interface FormValues {
  modalEmail: string;
  password: string;
}

const EmailAuthModal = ({ closeModal }: EmailAuthProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(getValidationScheme()),
  });
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => e.target.value.replace(emojiRegex, '').substr(0, 30);
  const handleLogin: SubmitHandler<FormValues> = ({ modalEmail, password }) => {
    dispatch(authLoginEmailRequest({
      email: modalEmail, password, closeModal, history,
    }));
    reset({ modalEmail: '' });
  };

  return (
    <PopUp text={t('auth.emailLogin')}>
      <CloseIcon src={closeIcon} onClick={closeModal} />
      <Input
        key="modal-input-email"
        data-test-id="email_input"
        id="modal-email"
        type="email"
        label={t('auth.email')}
        placeholder={t('auth.email')}
        register={register}
        name="modalEmail"
        errors={errors}
        onChange={onInputChange}
        control={control}
      />
      <SecondInput
        data-test-id="password_input"
        id="password"
        label={t('auth.password')}
        placeholder={t('auth.password')}
        register={register}
        name="password"
        errors={errors}
        onChange={onInputChange}
        type="password"
        control={control}
      />
      <ButtonsContainer>
        <Button data-test-id="email_login" background="black" onClick={handleSubmit(handleLogin)}>{t('settings.login')}</Button>
        <Button background="black" onClick={closeModal}>{t('common.close')}</Button>
      </ButtonsContainer>
    </PopUp>
  );
};

export default EmailAuthModal;
