import React, {
  useState, useEffect, useRef, forwardRef,
} from 'react';
import { PictureProps } from './types';
import { PictureImage, PictureWrapper } from './styled';

// Inline transparent gif
const TRANSPARENT_GIF = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const Picture = forwardRef<HTMLDivElement, PictureProps>(({
  url,
  lazy,
  width,
  height,
  alt,
  title,
  className,
  onClick,
  onLoad,
  'data-test-id': dataTestId,
}, parentRef) => {
  const [pictureSrc, setPictureSrc] = useState<string>(lazy ? TRANSPARENT_GIF : url);
  const [pictureLoaded, setPictureLoaded] = useState<boolean>(!lazy);
  const pictureWidth = typeof width === 'number' ? `${width}px` : width || 'auto';
  const pictureHeight = typeof height === 'number' ? `${height}px` : height || 'auto';
  const imageRef = useRef<HTMLImageElement>(null);

  /**
   * Load image only when it's available in viewport
   */
  useEffect(() => {
    if (!window.IntersectionObserver) return null;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target as HTMLImageElement;

          setPictureLoaded(false);
          setPictureSrc(url || '');

          if (image.complete) {
            setPictureLoaded(true);
          }

          image.addEventListener('load', () => {
            setPictureLoaded(true);
          });
        } else {
          setPictureLoaded(false);
        }
      });
    }, {
      threshold: 0,
      rootMargin: '100px',
    });

    if (lazy && imageRef.current) {
      if (imageRef.current.complete) {
        setPictureLoaded(false);
      }

      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [imageRef]);

  useEffect(() => {
    if (!lazy) {
      setPictureSrc(url);
    }
  }, [url]);

  return (
    <PictureWrapper
      data-test-id="picture_wrapper"
      ref={parentRef}
      $width={pictureWidth}
      $height={pictureHeight}
      $loaded={pictureLoaded}
      className={className}
      onClick={onClick}
    >
      <PictureImage
        data-test-id={dataTestId}
        ref={imageRef}
        src={pictureSrc}
        alt={alt}
        title={title}
        onLoad={onLoad}
      />
    </PictureWrapper>
  );
});
export default Picture;
