import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from '../../theme';
import bellIcon from '../../../static/ic-notifications.svg';
import downIcon from '../../../static/ic-menu-dropdown.svg';

type HeaderIconTypes = 'bell' | 'down';

export interface HeaderIconProps {
  type: HeaderIconTypes,
  isRed?: boolean,
  onClick: () => void,
}

const IconContainer = styled.div`
  cursor: pointer;
  height: 39px;
  width: 39px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: grid;
  place-content: center;
`;

const RedDot = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  background: ${theme.colors.dark};
  border-radius: 100%;
  height: 15px;
  width: 15px;
  display: grid;
  place-content: center;
  &:after {
    content: '';
    height: 12px;
    width: 12px;
    background: ${theme.colors.red};
    border-radius: inherit;
  }
`;

const icons : { [key in HeaderIconTypes]: string } = {
  bell: bellIcon,
  down: downIcon,
};

const HeaderIcon: FC<HeaderIconProps> = ({
  onClick, isRed, type, children,
}) => (
  <IconContainer onClick={onClick} data-test-id={`menu_drop_${type}`}>
    <img src={icons[type]} alt={type} />
    {isRed && <RedDot />}
    {children}
  </IconContainer>
);

export default HeaderIcon;
