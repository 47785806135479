import { createReducer } from '@reduxjs/toolkit';
import {
  cardForSaleError, cardForSalePurchased,
  cardForSaleReset,
  cardForSaleSuccess,
  cardFromSaleRemoving,
  cardSaleSetProgress,
} from './saleCardActions';

export type SaleCardStates = 'success' | 'error' | 'purchased' | 'initial' | 'removing';

interface SaleCardState {
  state: SaleCardStates
  inProgress: boolean
}

const initialState: SaleCardState = {
  state: 'initial',
  inProgress: false,
};

const saleCardReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(cardForSaleError, (state) => {
        state.state = 'error';
      })
      .addCase(cardForSaleSuccess, (state) => {
        state.state = 'success';
      })
      .addCase(cardFromSaleRemoving, (state) => {
        state.state = 'removing';
      })
      .addCase(cardForSaleReset, (state) => {
        state.state = 'initial';
      })
      .addCase(cardForSalePurchased, (state) => {
        state.state = 'purchased';
      })
      .addCase(cardSaleSetProgress, (state, { payload: { inProgress } }) => {
        state.inProgress = inProgress;
      });
  },
);

export default saleCardReducer;
