import { createReducer } from '@reduxjs/toolkit';
import { toggleSideMenu } from './sideMenuActions';

export interface SideMenuSlice {
  sideMenuIsOpen: boolean
}

const initialState: SideMenuSlice = {
  sideMenuIsOpen: false,
};

const sideMenuReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(toggleSideMenu, (state, { payload }) => {
      state.sideMenuIsOpen = payload;
    }),
);

export default sideMenuReducer;
