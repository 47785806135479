/* eslint-disable @typescript-eslint/indent */
import styled, { keyframes, css } from 'styled-components';
import theme from 'components/theme';
import Loader from 'components/Loader';
import Typography from 'components/Typography';
import { mediaDesktop, mediaWideDesktop } from 'breakpoints';
import flowFestBg from 'static/flow_fest_background.png';

const animationTime = '0.5s';

export const FlowFestContainer = styled.div`
  padding-top: 96px;
  padding-bottom: 32px;
  background: #60dde7 url("${flowFestBg}");
  background-size: cover;
  background-position: center;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaDesktop} {
    padding: 0;
    min-height: 610px;
  }

  ${mediaWideDesktop} {
    align-content: center;
  }
`;

export const FontPreloaderContainer = styled.div`
  dosplay: inline-block;
  position: absolute;
  opacity: 0;
  z-index: -100;
`;

export const FontPreloaderItem = styled.div<{ font: string }>`
  content: ' ';
  font-family: ${({ font }) => (font)};
`;

export const LoaderStyled = styled(Loader)`
`;

export const FlowFestLogoContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 0 16px;
  max-width: ${theme.width.mainContainer};

  ${mediaDesktop} {
    padding: 0;
  }
`;

export const FlowFestLogo = styled.img`
  width: 200px;
  height: 32px;
  top: 32px;
  left: 20px;
  ${mediaDesktop} {
    width: 315px;
    height: 48px;
    left: 169px;
  }
  ${mediaWideDesktop} {
    left: 409px;
  }
`;

export const FlowPackContainer = styled.div<{ isComingSoon?: boolean }>`
  border: 5px solid #fff;
  z-index: 1;
  padding: 42px 24px 32px;
  display: grid;
  height: max-content;
  background-image: linear-gradient(33deg, #5e3aa2 3%, #c885e7);

  ${mediaDesktop} {
    background-image: linear-gradient(65deg, #5e3aa2 1%, #c885e7);
    grid-template-columns: max-content 400px;
    grid-template-rows: max-content max-content;
    grid-row-gap: ${({ isComingSoon }) => (isComingSoon ? '42px' : '50px')};
    padding: 0 88px 0 0;
  }
`;

const discordLogoAnimation = keyframes`
  from {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  99% {
    opacity: 50%;
  }

  to {
    opacity: 1;
  }
`;

export const StyledDiscordLink = styled.a<{ isAnimated?: boolean, disappeared?: boolean }>`
  display: inline;
  pointer-events: all;
  height: 48px;
  width: 48px;
  z-index: 2;
  position: sticky;
  border-radius: 100%;
  bottom: 16px;
  ${mediaDesktop} {
    bottom: 17px;
    height: 94px;
    width: 94px;
  }
  ${({ disappeared }) => (disappeared ? (css`
    transition: .2s opacity;
    opacity: 0;
  `) : '')}
  ${({ isAnimated }) => (isAnimated ? (css`
      animation: 4s ${discordLogoAnimation};
    `) : '')};
`;

export const DiscordStickyContainer = styled.div`
  display: grid;
  pointer-events: none;
  place-content: end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  ${mediaDesktop} {
    padding: 17px;
  }
`;

export const DiscordLogo = styled.img`
  width: 94px;
  height: 94px;
  object-fit: contain;
`;

export const TextContainer = styled.div`
  color: #fff;
  text-align: center;
  ${mediaDesktop} {
    text-align: start;
    grid-column: 2 / -1;
    align-self: end;
  }
`;

export const PackImage = styled.img`
  grid-row: 2 / 3;
  justify-self: center;
  width: 256px;
  height: 321px;
  ${mediaDesktop} {
    width: 292px;
    height: 366px;
    margin: 0 5px 0 0;
    object-fit: contain;
    grid-row: 1 / -1;
  }
`;

export const FlowFestWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

export const FlowDiscordWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const FlowFestContainer2 = styled.div<{ $styles: string }>`
  ${({ $styles }) => $styles}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: ${theme.width.mainContainer};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 11;

  @media (max-height: 650px) and (orientation: portrait) {
    position: relative;
    top: 0;
  }

  @media (max-height: 850px) and (orientation: landscape) {
    position: relative;
    top: -100px;
  }

  ${mediaDesktop} {
    top: -100px;
    margin: 0 auto;
    justify-content: flex-start;
    position: relative;
    top: -100px;
  }

  & .rec-arrow {
    width: 20px;
    min-width: 15px;
    background: transparent;
    box-shadow: none;
    z-index: 999;
    color: #333;

    &:hover:enabled, &:focus:enabled {
      background: transparent;
      box-shadow: none;
      color: #333;
    }

    &:disabled {
      opacity: 0;
    }
  }

  & .rec-slider-container {
    margin: 0;
    transform: scale(1.1);
  }

  & .rec-item-wrapper > div {
    ${mediaDesktop}{
      width: 90%;
    }
  }

  & .rec-carousel-wrapper {
    padding: 6vh 0;
  }
`;

export const Headline = styled.h3`
  font-family: Syncopate;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 21px;
  text-transform: uppercase;
  width: 274px;
  ${mediaDesktop} {
    width: auto;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 1.42px;
  }
`;

export const Subline = styled.p`
  font-family: Kanit;
  letter-spacing: .5px;
  font-size: 12px;
  ${mediaDesktop} {
    letter-spacing: 2px;
    font-size: 16px;
  }
`;

export const LowerSpan = styled.span`
  font-size: 14px;
  ${mediaDesktop} {
    font-size: 26px;
    text-transform: lowercase;
  }
`;

export const HCaptchaContainer = styled.div`
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: opacity ${animationTime} ease-in-out;
  position: absolute;
`;

export const ClaimWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: content-box;
  pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: opacity ${animationTime} ease-in-out;
`;

export const ClaimInputRow = styled.div<{ isActive?: boolean }>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 32px;

  ${mediaDesktop} {
    height: 64px;
    padding: 0;
  }
`;

export const ClaimCancelButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  appearance: none;
  --size: 40px;
  
  padding: 10px;
  
  height: var(--size);
  width: var(--size);
  margin-right: 2px;
  background-color: #171717;
  
  transition: 0.2s;
  
  &:hover{
    background-color: #292929;
  }

  & > img{
    height: 20px;
    width: 20px;
  }

  ${mediaDesktop} {
    --size: 64px;

    & > img{
      height: 32px;
      width: 32px;
    }
  }
`;

export const ClaimInput = styled.input`
  appearance: none;
  height: 100%;
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #FFFFFF;
  padding: 0 10px;
  border: 0;
  flex-grow: 1;
  background-color: #171717;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  ${mediaDesktop} {
    font-family: Gibson;
    font-size: 18px;
    letter-spacing: 2px;
    padding: 0 20px;
  }
`;

export const ClaimBtn = styled.div`
  background-color: #1aff99;
  color: #000000;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 12px 0;
  display: grid;
  place-content: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  letter-spacing: 1.5px;
  transition: background-color .2s ease-in;
  line-height: 1em !important;

  &:hover {
    cursor: pointer;
  }

  ${mediaDesktop} {
    font-size: 18px;
    padding: 0 32px;
    font-family: Gibson;
  }
`;

export const ClaimError = styled.div`
  font-family: Gibson;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #fa2f00;
  padding-top: 8px;

  ${mediaDesktop} {
    font-size: 21px;
    padding-top: 28px;
  }
`;

export const PopUpText = styled.div`
  font-family: ${theme.fonts.secondary};
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 50px;
`;

export const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  background: transparent;
`;

export const CountdownTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin-bottom: 16px;
  font-family: ${theme.fonts.primary};

  ${mediaDesktop} {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

export const CountdownNumberRow = styled.div`
  display: flex;
`;

export const CountdownNumberBox = styled.div`
  font-family: Digital-7Mono;
  font-size: 28px;
  text-align: center;
  color: #1aff99;

  ${mediaDesktop} {
    font-size: 42px;
  }

  &:not(:last-child):after {
    content: ':';
  }
`;

export const CountdownContainer = styled.div<{ isActive?: boolean }>`
  position: absolute;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: opacity ${animationTime} ease-in-out;

  & > button {
    max-width: 200px;
    height: 40px;

    ${mediaDesktop} {
      max-width: 210px;
      height: 50px;
    }
  }
`;

export const BottomContainer = styled.div`
  position: relative;
  bottom: 120px;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-height: 650px) and (orientation: portrait) {
    position: relative;
    bottom: 120px;
    max-width: 460px;
    width: 100%;
    align-items: flex-start;
  }

  @media (max-height: 850px) and (orientation: landscape) {
    position: relative;
    bottom: 0;
    max-width: 460px;
    width: 100%;
    margin-top: -240px;
    align-items: flex-start;
  }

  ${mediaDesktop} {
    position: relative;
    bottom: 0;
    width: 460px;
    margin-top: -240px;
    align-items: flex-start;
  }
`;

const mobileScale = 2.3;

const openPack = (styles: string) => keyframes`
  0% {
    transform: rotate(-9deg) ${styles};
  }
  5% {
    transform: rotate(7deg) ${styles};
  }
  12.5% {
    transform: rotate(-5deg) ${styles};
  }
  20% {
    transform: rotate(3deg) ${styles};
  }
  30% {
    transform: rotate(-1deg) ${styles};
  }

  40% {
    transform: rotate(0deg) ${styles};
  }

  50%, 60%, 70%, 80%, 90%, 100% {
    transform: rotate(0deg) ${styles};
  }
`;

const openPackAnim = (styles: string) => css`
  animation: 2s ease-in ${openPack(styles)};
  animation-iteration-count: infinite;
`;

export const VideoContainer = styled.div<{ $isVisible?: boolean, $isTransform: boolean }>`
  width: 100vw;
  ${({ $isVisible }) => ({ display: $isVisible ? 'flex' : 'none' })}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  overflow: hidden;

  @media (max-height: 650px) and (orientation: portrait) {
    max-height: 510px;
    overflow: hidden;
  }

  ${mediaDesktop} {
    margin-bottom: 0;
  }

  & > div:first-child {
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media (max-height: 650px) and (orientation: portrait) {
      min-height: 650px !important;
      max-height: 650px;
      overflow: hidden;
    }

    @media (max-height: 850px) and (orientation: landscape) {
      min-height: 850px !important;
    }
  }

  & video {
    ${({ $isTransform }) => (!$isTransform
      ? openPackAnim(`scale(${mobileScale}) translateY(-10px)`)
      : `transform: scale(${mobileScale}) translateY(-10px);`
    )}
    width: 100% !important;

    @media (max-height: 850px) and (orientation: landscape) {
      ${({ $isTransform }) => (!$isTransform
        ? openPackAnim('scale3d(1, 1, 1)')
        : 'transform: scale3d(1, 1, 1);'
      )}
      width: 80% !important;
      min-width: 900px;
    }

    ${mediaDesktop} {
      ${({ $isTransform }) => (!$isTransform
        ? openPackAnim('scale3d(1, 1, 1)')
        : 'transform: scale3d(1, 1, 1);'
      )}
      width: 80% !important;
      min-width: 900px;
    }
  }
`;

const flashAnimation = keyframes`
  0% {
    opacity: 0
  }

  25% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const Flash = styled.div`
  animation: 1s ease-in ${flashAnimation};
  animation-delay: 2s;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  opacity: 0;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 35px;

  ${mediaDesktop} {
    margin-top: 0;
    padding: 20px 0 40px;
  }
`;

export const RevealText = styled.div<{ textAlign?: CanvasTextAlign }>`
  margin-top: 50px;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: normal;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  color: #d8d8d8;
  display: none;
  padding: 0 12px;


  ${mediaDesktop} {
    display: block;
  }
`;

export const RevealTextWithButton = styled.div`
  display: flex;
`;

export const StyledLink = styled.a<{ active?: boolean }>`
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  color: ${({ active }) => (active ? theme.colors.lightGreen : theme.colors.textWhite)};
  text-decoration: none;
  
  padding: 1px 9px;
  min-width: 80px;
  background-color: #1f1f1f;
  
  margin-bottom: 4px;
  &:last-child{
    margin-bottom: 0;
  }
  
  ${mediaDesktop} {
    margin-bottom: 0;
    margin-right: 4px;
    padding: 1px 6px;

    &:last-child{
      margin-right: 0;
    } 
  }
`;

export const FlowPackContainerWrapper = styled.div`
  text-align: center;
`;

export const FlowLink = styled.a`
  color: #fff;
  font-size: 12px;
  display: inline-block;
  letter-spacing: .5px;
  font-family: Kanit;
  text-decoration: none;
  text-transform: uppercase;
  margin: 24px auto auto;

  ${mediaDesktop} {
    margin: 24px auto auto;
    letter-spacing: 2px;
    font-size: 16px;
  }
`;

export const FlowComingSoonButton = styled.div`
  display: flex;
  font-family: ${theme.fonts.primary};
  justify-self: center;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: .5px;
  font-weight: bold;
  justify-content: center;
  font-size: 12px;
  width: 240px;
  height: 40px;
  color: #fff;
  border: 2px dashed #fff;
  ${mediaDesktop} {
    justify-self: start;
    font-size: 18px;
    letter-spacing: 2px;
    padding: 17px 24px;
    width: 400px;
    height: 60px;
  }
`;

export const SecondaryButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  line-height: normal;
  
  padding: 6px 24px;
  font-weight: 600;
  text-align: center;
  border: solid 2px ${theme.colors.white};
  color: ${theme.colors.white};
  text-transform: uppercase;
  white-space: nowrap;
  
  ${mediaDesktop}{
    margin-top: 0;
    padding: 9px 16px;
  }
`;

export const LinkIcon = styled.img`
  margin-left: 4px;
  height: 12px;
  width: 12px;
`;

export const StyledTitle = styled(Typography)`
  display: none;

  ${mediaDesktop} {
    display: initial;
    margin-bottom: 30px;
    letter-spacing: 1.5px;
  }
`;

export const StyledEmptyLabel = styled(Typography)`
  margin-top: 20px;
  letter-spacing: 1px;
  font-family: Gibson;
  font-weight: 600;

  ${mediaDesktop} {
    margin-top: 0;
  }
`;
