import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'components/theme';

export const StyledFlowFestButton = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const StyledLinksWrapper = styled.div<{ $isActive: boolean }>`
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  position: absolute;
  right: 0;
  padding-top: 25px;
`;

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  border-radius: 12px;
  border: solid 1px ${theme.colors.lightTransparent};
  background-color: ${theme.colors.black};
`;

export const StyledLink = styled(Link)`
  white-space: nowrap;
  padding: 15px 20px;
  border-radius: 12px;
  text-decoration: none;
  background-color: transparent;
  transition: background-color .5s;
  font-family: ${theme.fonts.secondary};
  font-size: 20px;
  letter-spacing: 1px;
  color: ${theme.colors.white};

  &:hover {
    background-color: ${theme.colors.dark};
  }
`;
