import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HeaderLink from './HeaderLink';
import Typography from '../../Typography';
import theme from '../../theme';
import { MenuItemProps } from '../../SideMenu/MenuItem/MenuItem';
import { authLogoutRequest } from '../../../store/auth/authActions';
import { setAuthLogout } from '../../../store/login/loginActions';

import dropdownArrow from '../../../static/ic-dropdown-menu-pointer.svg';
import { isIos } from '../../../helpers/isIos';

const MenuContainer = styled.div`
  width: 206px;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  background-color: #000000;
`;

const NavBar = styled.div`
  padding: 17px 12px;
  max-height: 580px;
  overflow: scroll;
  border-radius: 12px;

  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & ${Typography} {
    border-radius: 12px;
    color: ${theme.colors.textWhite};
    padding: 20px 18px 18px;
    line-height: 1;
    transition: all .3s;
    letter-spacing: 1px;

    &:hover {
      color: #fff;
      background: ${theme.colors.dark};
    }
  }
`;

const LinkListItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const LinkListItemText = styled.div<{ isMacOs?: boolean }>`
  align-items: center;
  align-content: center;
  max-width: 100%;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${({ isMacOs }) => (isMacOs ? '17px' : '20px')};
`;

const LinkIListItem = styled.div`
  color: ${theme.colors.lightGray};
  font-size: 20px;
  letter-spacing: 1px;
  font-family: ${theme.fonts.secondary};
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;
  align-items: center;
  margin-left: 18px;
  margin-bottom: 14px;
  padding-right: 18px;
  &:last-of-type {
    margin-bottom: 18px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const ListLinkContainer = styled.div`
  position: sticky;
  top: -17px;
  padding-left: 12px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #1c1c1c 1%, #2a2a2a 99%);
  border-radius: 12px 12px 0 0;
  margin: -17px -12px 12px;
  & * {
    background: transparent !important;
  }
  
`;

const HeaderLinkInnerContainer = styled.span`
  display: flex;
  //align-items: center;
  justify-content: space-between;
`;

const HeaderLinkArrow = styled.img`
  margin-bottom: 4px;
`;

const RedSpan = styled.span<{ isRed?: boolean }>`
  color: ${({ isRed }) => (isRed ? theme.colors.red : 'inherit')};
`;

const MenuNav: FC<{ menuItems: MenuItemProps[] }> = ({
  menuItems,
}) => {
  const { t } = useTranslation();
  const [isMacOs] = useState(isIos());
  const filteredItems = menuItems.slice(3).filter(({ text }) => ![
    t('header.notifications'),
    t('header.myPage'),
    t('header.staking'),
    t('header.token'),
    t('header.ranking'),
  ].includes(text));
  const dispatch = useDispatch();
  const onLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(setAuthLogout({ isLogout: true }));
    dispatch(authLogoutRequest({}));
  };
  return (
    <MenuContainer data-test-id="menu_open">
      <NavBar>
        {
          filteredItems.map(({
            linkPath, text, amount, isRed,
            list,
          }) => (list ? (
            <ListLinkContainer key={`${linkPath}:${text}`}>
              <StyledLink to={linkPath}>
                <HeaderLink>
                  <HeaderLinkInnerContainer>
                    {text}
                    <HeaderLinkArrow src={dropdownArrow} />
                  </HeaderLinkInnerContainer>
                </HeaderLink>
                {
                  list.map(({ logo, value }) => (
                    <LinkIListItem>
                      <LinkListItemIcon src={logo} alt={`logo-${value}`} />
                      <LinkListItemText isMacOs={isMacOs}>
                        {value}
                      </LinkListItemText>
                    </LinkIListItem>
                  ))
                }
              </StyledLink>
            </ListLinkContainer>
          )
            : (
              <StyledLink key={`${linkPath}:${text}`} to={linkPath} onClick={text.toLowerCase().trim() === 'logout' ? onLogout : undefined}>
                <HeaderLink data-test-id={text.toLowerCase().trim() === 'logout' ? 'menu_logout' : linkPath}>
                  <HeaderLinkInnerContainer>
                    <span>
                      {text}
                      {' '}
                      { amount && (
                      <RedSpan isRed={isRed}>
                        (
                        {amount}
                        )
                      </RedSpan>
                      )}
                    </span>
                    <HeaderLinkArrow src={dropdownArrow} />
                  </HeaderLinkInnerContainer>
                </HeaderLink>
              </StyledLink>
            )))
        }
      </NavBar>
    </MenuContainer>
  );
};

export default MenuNav;
