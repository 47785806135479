import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import Typography from '../../Typography';
import theme from '../../theme';
import { checkIsActivePage } from '../../../helpers/checkIsActivePage';
import { authLogoutRequest } from '../../../store/auth/authActions';
import { setAuthLogout } from '../../../store/login/loginActions';

export interface MenuItemListProps {
  logo: string
  value: string
}

export interface MenuItemProps {
  linkPath: string,
  isExactLink?: boolean,
  text: string,
  amount?: string,
  isRed?: boolean,
  list?: MenuItemListProps[]
}

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
`;

const ListChild = styled.li`
  align-items: center;
  margin-bottom: 39px;
  & > ${Typography} {
    letter-spacing: 2px;
  }
`;
const ListChildText = styled(Typography)`
  display: inline-block;
  line-height: 19px;
  padding-top: 4px;
`;
const RedSpan = styled.span<{ isRed?: boolean }>`
  color: ${({ isRed }) => (isRed ? theme.colors.red : 'inherit')}
`;

const MenuItem: FC<MenuItemProps> = ({
  linkPath, text, amount, isRed,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isCurrentPage = checkIsActivePage(linkPath, location.pathname);
  const onLogout = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setAuthLogout({ isLogout: true }));
    dispatch(authLogoutRequest({}));
  };
  const testMobileLinkText = text.toLowerCase().trim().replaceAll(' ', '_');
  return (
    <ListChild>
      <ListChildText uppercase textColor={isCurrentPage ? 'primary' : 'white'} size="header3" font="secondary" bold>
        <LinkWrapper
          to={linkPath}
          onClick={text.toLowerCase().trim() === 'logout' ? onLogout : undefined}
          data-test-id={`mobile_${testMobileLinkText}`}
        >
          {text}
          {
          amount
          && (
            <RedSpan isRed={isRed}>
              {' '}
              (
              {amount}
              )
            </RedSpan>
          )
        }
        </LinkWrapper>
      </ListChildText>
    </ListChild>
  );
};

export default MenuItem;
