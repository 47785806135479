import { createAction } from '@reduxjs/toolkit';
import { Notification } from '@starly/starly-types';
import { DocumentSnapshot } from '../../global/firebase';

export interface NotificationsResponsePayload {
  notifications: Notification[]
}

export interface NotificationsRequestPayload {
  pageNumber: DocumentSnapshot | null,
}

export interface SetLoadingPayload {
  isLoading: boolean,
}

export interface SetHasMorePayload {
  hasMoreToLoad: boolean,
}

export interface SetDropdownPagePayload {
  page: DocumentSnapshot | null,
}

export interface UnreadNotificationsPayload {
  unread: number,
}

export interface SetMaxCountPayload {
  maxCount: number,
}

export const notificationsRequest = createAction<NotificationsRequestPayload>('notifications/request');

export const notificationsResponse = createAction<NotificationsResponsePayload>('notifications/response');

export const notificationsLoading = createAction<SetLoadingPayload>('notifications/set-loading');

export const notificationsMoreToLoad = createAction<SetHasMorePayload>('notifications/set-has-more');

export const notificationsSetPage = createAction<SetDropdownPagePayload>('notifications/set-dropdown-page');

export const notificationsUnreadRequest = createAction('notifications/unread-request');

export const notificationsUnreadResponse = createAction<UnreadNotificationsPayload>('notifications/unread-response');

export const notificationsLastSeen = createAction('notifications/last-seen');

export const notificationsSetMaxCount = createAction<SetMaxCountPayload>('notifications/set-max-count');

export const resetNotifications = createAction('notifications/reset-notifications');

export const notificationsSetIsOpen = createAction<{ isOpen: boolean }>('notifications/set-is-open');

export const notificationsSetDelayRequest = createAction<{ isDelayRequest: boolean }>('notifications/set-delay-request');

export const notificationsSetRealtimeListener = createAction('notifications/realtime-listener');

export const notificationLogoutReset = createAction('notifications/notification-logout-reset');

export const notificationsLoad = createAction<{ isLoad: boolean }>('notifications/notificationsLoad');
