import { createSelector } from '@reduxjs/toolkit';
import { Media, Notification, Rarity } from '@starly/starly-types';
import { t } from 'i18next';
import { RootState } from '../store';
import { NotificationProps } from '../../components/Notification/types';
import { capitalize } from '../../helpers/TextHelpers';
import { RouteTypes } from '../../RouteTypes';
import legendaryPack from '../../static/ic-thumbnail-pack-legendary@3x.png';
import rarePack from '../../static/ic-thumbnail-pack-rare@3x.png';
import commonPack from '../../static/ic-thumbnail-pack-common@3x.png';
import rewardPack from '../../static/ic-thumbnail-pack-reward@3x.png';
import mysteryPack from '../../static/ic-mystery-pack-reward@3x.png';

export const selectNotificationsFromStore = (state: RootState) => state.notifications;

const packThumbnails: { [key in Rarity]: string } = {
  legendary: legendaryPack,
  rare: rarePack,
  common: commonPack,
  legendary_plus: rewardPack,
  rare_plus: rewardPack,
  common_plus: rewardPack,
  mystery: mysteryPack,
};

const getThumbnailUrl = (notification: Notification): string | Media => {
  if (notification.type === 'reward_received') {
    return packThumbnails[<Rarity>notification.reward_received?.rarity];
  }
  if (notification.type === 'pack_sold') {
    return packThumbnails[<Rarity>notification.pack_sold?.rarity];
  }
  return notification[notification.type]?.card_avatar as Media;
};

const getNotificationsText = (notification: Notification): string => {
  const packType = notification.pack_sold?.rarity;
  switch (notification.type) {
    case 'reward_received':
      return t('notifications.rewardReceived', { sets: notification.reward_received?.sets, collectionName: notification.reward_received?.collection_name });
    case 'card_sold':
      if (!notification.card_sold?.currency || notification.card_sold.currency === 'FUSD') {
        return t('notifications.cardSold', { amount: `$${notification.card_sold?.price.toFixed(2)}` });
      }
      return t('notifications.cardSold', { amount: `${notification.card_sold?.price.toFixed(2)} ${notification.card_sold?.currency}` });
    case 'pack_sold':
      if (!notification.pack_sold?.currency || notification.pack_sold.currency === 'FUSD') {
        return t('notifications.packSold', { packType: capitalize(packType || ''), amount: `$${notification.pack_sold?.price.toFixed(2)}` });
      }
      return t('notifications.packSold', { packType: capitalize(packType || ''), amount: `${notification.pack_sold?.price.toFixed(2)} ${notification.pack_sold?.currency}` });
    case 'royalty_received':
      if (!notification.royalty_received?.currency || notification.royalty_received.currency === 'FUSD') {
        return t('notifications.royaltyReceived', { amount: `$${notification.royalty_received?.royalty.toFixed(2)}` });
      }
      return t('notifications.royaltyReceived', { amount: `${notification.royalty_received?.royalty.toFixed(2)} ${notification.royalty_received?.currency}` });
    case 'minting_royalty_received':
      if (!notification.minting_royalty_received?.currency || notification.minting_royalty_received.currency === 'FUSD') {
        return t('notifications.mintingRoyaltyReceived', { amount: `$${notification.minting_royalty_received?.royalty.toFixed(2)}` });
      }
      return t('notifications.mintingRoyaltyReceived', { amount: `${notification.minting_royalty_received?.royalty.toFixed(2)} ${notification.minting_royalty_received?.currency}` });
    default:
      return '';
  }
};

const getSecondaryText = (notification: Notification) => {
  if (notification.type === 'royalty_received') {
    return (!notification.royalty_received?.currency || notification.royalty_received.currency === 'FUSD')
      ? t('notifications.royaltyReceivedSecondary', { amount: `$${notification.royalty_received?.price.toFixed(2)}` })
      : t('notifications.royaltyReceivedSecondary', { amount: `${notification.royalty_received?.price.toFixed(2)} ${notification.royalty_received?.currency}` });
  }
  if (notification.type === 'minting_royalty_received') {
    return (!notification.minting_royalty_received?.currency || notification.minting_royalty_received.currency === 'FUSD')
      ? t('notifications.mintingRoyaltyReceivedSecondary', { amount: `$${notification.minting_royalty_received?.price.toFixed(2)}` })
      : t('notifications.mintingRoyaltyReceivedSecondary', { amount: `${notification.minting_royalty_received?.price.toFixed(2)} ${notification.minting_royalty_received?.currency}` });
  }
  return '';
};

const getLinkPath = (notification: Notification): string => {
  if (notification.type === 'pack_sold') {
    const collectionId = notification[notification.type]?.collection_id || '';
    const collectionUsername = notification[notification.type]?.collection_creator_username || '';
    return RouteTypes.Collection
      .replace(':username', collectionUsername)
      .replace(':collectionId', collectionId);
  }
  if (notification.type === 'reward_received') {
    return RouteTypes.Packs;
  }
  const cardSold = notification[notification.type];
  const cardPath = RouteTypes.Card
    // @ts-ignore
    .replace(':username', cardSold?.buyer_username || '')
    .replace(':collectionId', cardSold?.collection_id || '')
    .replace(':cardId', cardSold?.card_id.toString() || '')
    .replace(':edition?', cardSold?.edition.toString() || '');
  return cardPath;
};

const getAvatar = (notification) => {
  if (notification.type === 'reward_received') {
    return notification.reward_received.creator_avatar as Media;
  }
  return notification[notification.type]?.buyer_avatar as Media;
};

export const selectNotifications = createSelector(
  selectNotificationsFromStore,
  ({ notifications }) => {
    const notificationProps: NotificationProps[] = notifications.map((notification) => {
      const username = notification.type === 'reward_received' ? notification[notification.type]?.collection_creator_username : notification[notification.type]?.buyer_username;
      const prop: NotificationProps = {
        date: notification.create_time,
        linkPath: getLinkPath(notification),
        secondaryText: getSecondaryText(notification),
        notificationText: getNotificationsText(notification),
        thumbnail: getThumbnailUrl(notification),
        userAvatar: getAvatar(notification),
        userId: notification[notification.type]?.buyer_user_id || '',
        username: username || '',
        collectionId: notification[notification.type]?.collection_id,
        type: notification.type,
      };
      return prop;
    });
    return notificationProps;
  },
);

export const selectNotificationsPaginationInfo = createSelector(
  selectNotificationsFromStore,
  ({
    isLoading, hasMoreToLoad, page, isLoad,
  }) => ({
    isLoading,
    hasMoreToLoad,
    page,
    isLoad,
  }),
);

export const selectUnreadNotificationsCount = createSelector(
  selectNotificationsFromStore,
  ({ unread }) => unread,
);
