/* eslint-disable arrow-body-style */
import { RouteTypes } from '../RouteTypes';

export const useProtocolHostnamePort = () => {
  return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
};

export const createInstagramCallbackUri = () => {
  return useProtocolHostnamePort() + RouteTypes.OAuthInstagramCallback;
};

export const createTwitterCallbackUri = () => {
  return useProtocolHostnamePort() + RouteTypes.OAuthTwitterCallback;
};

export const createWechatCallbackUri = () => {
  return useProtocolHostnamePort() + RouteTypes.OAuthWechatCallback;
};

export const createYoutubeCallbackUri = () => {
  return useProtocolHostnamePort() + RouteTypes.OAuthYoutubeCallback;
};

export const getLinkAndRedirect = async (link: string) => {
  try {
    // TODO replace with just link
    const response = await fetch(link);
    const { data } = await response.json();

    window.open(data, '_parent', data);
  } catch (error) {
    console.error(error);
  }
};
