import { createReducer } from '@reduxjs/toolkit';
import {
  coinMarketCapResponse,
  setPrevPathname, toggleFooter, toggleHeader,
  setActiveCard, setMoonpayUrl,
} from './appActions';

export interface PrevPathname {
  pathname: string,
  prev: boolean
}

export interface AppSlice {
  isHeaderVisible: boolean,
  isFooterVisible: boolean,
  activeCard: string | null,
  prevPathname: PrevPathname
  coinMarketCapInfo: {
    timestamp: number;
    price: number;
    priceChangePercent: number;
    marketCap: number;
    marketCapChangePercent: number;
  } | null,
  moonpayUrl: string,
}

const initialState: AppSlice = {
  isHeaderVisible: true,
  isFooterVisible: true,
  activeCard: null,
  moonpayUrl: '',
  coinMarketCapInfo: null,
  prevPathname: {
    pathname: '',
    prev: false,
  },
};

const appReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(toggleFooter, (state, { payload }) => {
      state.isFooterVisible = payload;
    })
    .addCase(coinMarketCapResponse, (state, { payload }) => {
      state.coinMarketCapInfo = payload;
    })
    .addCase(toggleHeader, (state, { payload }) => {
      state.isHeaderVisible = payload;
    })
    .addCase(setActiveCard, (state, { payload }) => {
      state.activeCard = payload;
    })
    .addCase(setPrevPathname, (state, { payload }) => {
      state.prevPathname = payload;
    })
    .addCase(setMoonpayUrl, (state, { payload: { url } }) => {
      state.moonpayUrl = url;
    }),
);

export default appReducer;
