import { FC } from 'react';
import ReactDOM from 'react-dom';

const root = document.querySelector('#fullscreen-root') as Element;

const FullscreenPortal: FC = ({ children }) => ReactDOM.createPortal(
  children,
  root,
);

export default FullscreenPortal;
