import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { firestore, DocumentSnapshot } from '../../global/firebase';
import { dropsLoading, dropsRequest, dropsResponse } from './dropsAction';

function* dropsRequestWatcher() {
  yield takeEvery(dropsRequest, function* dropsRequestWorker() {
    yield put(dropsLoading({ isLoading: true }));
    const cachedDropsDocumentSnapshot: DocumentSnapshot = yield call(() => firestore
      .collection('cache')
      .doc('drops')
      .get());
    const drops:any = cachedDropsDocumentSnapshot.data()?._cached_drops;
    yield put(dropsResponse({ drops }));
    yield put(dropsLoading({ isLoading: false }));
  });
}

export function* dropsSaga() {
  yield all([
    fork(dropsRequestWatcher),
  ]);
}
