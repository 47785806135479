import { createReducer } from '@reduxjs/toolkit';
import {
  CardRarity, FungibleTokenType, Media, User,
} from '@starly/starly-types';
import {
  buyPackResponse,
  initOpenPackPageResponse,
  initPackResponse,
  initUserPacksResponse,
  openPackResponse,
  setBuyToDefault,
  switchOpenPackLoading,
  startLoad,
  revealCard,
  revealAll,
  userPacksCountResponse,
  PackPurchaseStatus,
  setPackPurchaseStatus,
  initPackRequest,
  setPackPageLoading,
  openPackRequest,
  openPackFailure, packBattleLeaderResponse,
} from './packActions';

interface PackInfo {

}

export interface CardToOpen {
  id: number;
  data: {
    edition: number;
    title?: string;
    media?: Media;
    description?: string;
    order?: number;
    qrcode?: string;
    editions?: number;
    rarity: CardRarity;
    score: number;
  };
  isActive: boolean;
  collectionTitle: string;
  creator: {
    name: string
    avatar?: Media
  };
  rarity: string;
  viewed?: boolean
}

export interface PackReducerState {
  isLoading: boolean,
  isPackOpening: boolean,
  packPage: {
    allUserPacks: PackInfo[],
    allUserActivePacks: PackInfo[],
    currPage: number,
    isLoading: boolean,
  },
  buying: {
    author: {
      [id: string]: any
    },
    collection: {
      id: string,
      title: string,
      price: number,
      token: FungibleTokenType,
    }
    isLoad: boolean
    isBuyingEnd: boolean
    status: string
    purchaseStatus: PackPurchaseStatus
  }
  open: {
    isOpen: boolean
    isLoad: boolean
    openError: boolean
    isError: boolean
    cardsToOpen: CardToOpen[]
  }
  packBattleMap: {
    [collectionId: string]: {
      user: User,
      score: number
      packBattleEnded: boolean
    }[]
  }
  userPacksCount: number
  packBattleModal: boolean
}

const initialState: PackReducerState = {
  isLoading: false,
  isPackOpening: false,
  packPage: {
    allUserPacks: [],
    currPage: 1,
    allUserActivePacks: [],
    isLoading: false,
  },
  buying: {
    isLoad: false,
    isBuyingEnd: false,
    status: 'default',
    author: {
      name: '',
      avatar: '',
    },
    collection: {
      id: '',
      title: '',
      price: 0,
      token: 'FUSD',
    },
    purchaseStatus: 'initial',
  },
  open: {
    isOpen: false,
    openError: false,
    isLoad: false,
    isError: false,
    cardsToOpen: [],
  },
  packBattleMap: {},
  userPacksCount: 0,
  packBattleModal: false,
};

const packReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(initPackRequest, (state) => {
        state.isLoading = true;
      })
      .addCase(initPackResponse, (state, {
        payload: {
          authorName,
          avatar,
          collectionId,
          collectionTitle,
          packDistribution,
          packPricing,
          price,
          token,
          authorUsername,
        },
      }) => {
        state.buying.author = { name: authorName, avatar, username: authorUsername };
        state.buying.collection = {
          id: collectionId, title: collectionTitle, packDistribution, packPricing, price, token,
        };
        state.buying.isLoad = true;
        state.isLoading = false;
      })
      .addCase(buyPackResponse, (state, { payload: { status } }) => {
        state.buying.status = status;
        state.buying.isBuyingEnd = true;
      })
      .addCase(setBuyToDefault, (state) => {
        state.buying.status = 'default';
        state.buying.isBuyingEnd = false;
      })
      .addCase(initUserPacksResponse, (state, { payload: { data } }) => {
        state.packPage.allUserPacks = data;
        state.packPage.allUserActivePacks = data.filter((pack) => pack.creatorName);
      })
      .addCase(switchOpenPackLoading, (state) => {
        state.open.isLoad = !state.open.isLoad;
      })
      .addCase(startLoad, (state) => {
        state.open.isLoad = false;
      })
      .addCase(initOpenPackPageResponse, (state, { payload: { cardsToOpen, error } }) => {
        state.open.isError = false;
        state.open.isOpen = false;
        if (cardsToOpen) state.open.cardsToOpen = cardsToOpen;
        if (error) state.open.isError = true;
        state.open.isLoad = true;
      })
      .addCase(openPackRequest, (state) => {
        state.open.openError = false;
        state.open.isOpen = false;
        state.isPackOpening = true;
      })
      .addCase(openPackResponse, (state) => {
        state.open.openError = false;
        state.open.isOpen = true;
        state.isPackOpening = false;
      })
      .addCase(openPackFailure, (state) => {
        state.open.openError = true;
        state.open.isOpen = false;
        state.isPackOpening = false;
      })
      .addCase(revealCard, (state, { payload: { id } }) => {
        state.open.cardsToOpen[id].isActive = true;
      })
      .addCase(revealAll, (state) => {
        state.open.cardsToOpen = state.open.cardsToOpen.map((card: any) => (
          { ...card, isActive: true }
        ));
      })
      .addCase(userPacksCountResponse, (state, { payload: { count } }) => {
        state.userPacksCount = count;
      })
      .addCase(packBattleLeaderResponse, ((state, { payload: { collectionId, data } }) => {
        state.packBattleMap[collectionId] = data;
      }))
      .addCase(setPackPurchaseStatus, (state, { payload: { status } }) => {
        state.buying.purchaseStatus = status;
      })
      .addCase(setPackPageLoading, (state, { payload: { isLoading } }) => {
        state.packPage.isLoading = isLoading;
      });
  },
);

export default packReducer;
