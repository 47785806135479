import {
  all, fork, call, takeEvery, put, select,
} from 'redux-saga/effects';
import * as fcl from '@onflow/fcl';
import i18n from 'i18next';
import {
  firestore, DocumentSnapshot, auth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider, UserCredential, trackEvent, trackException, functions,
  IdTokenResult,
} from 'global/firebase';
import { ISignInPayload, UserSchema } from 'types';
import { push } from 'connected-react-router';
import { RouteTypes } from 'RouteTypes';
import { flowLogoutRequest } from 'store/flow/flowActions';
import sessionStorage from 'redux-persist/es/storage/session';
import {
  authLoginEmailRequest,
  authLoginEmailResponse,
  authLoginGoogleRequest,
  authLoginGoogleResponse,
  authLoginTwitterRequest,
  authLoginTwitterResponse,
  authLoginFacebookRequest,
  authLoginFacebookResponse,
  authLoginWechatRequest,
  authLoginWechatResponse,
  authLogoutRequest,
  authLogoutResponse,
  authSignupEmailRequest,
  authSignupEmailResponse,
  authError,
  authSetIsProfileCreating,
  authSetIsAuthorized,
  authLoginCustomTokenRequest,
  authLoginCustomTokenResponse,
} from './authActions';

import { AuthResponsePayload, AuthErrorPayload } from '../../types';
import { activateAuthRedirect, setAuthRedirect } from '../login/loginActions';
import { notificationLogoutReset } from '../notifications/notificationsActions';
import {
  createWechatCallbackUri,
  getLinkAndRedirect,
} from '../../helpers/oauthCallbacks';

function* watchLogoutRequest() {
  yield takeEvery(authLogoutRequest, function* takeEveryLogoutRequest(action) {
    try {
      const { payload: { email } } = action;
      sessionStorage.setItem('FFwaitLogin', false);
      yield call(() => auth.signOut());
      yield put(authLogoutResponse({ email }));
      yield put(notificationLogoutReset());
      trackEvent('user_logout');
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginCustomTokenRequest() {
  yield takeEvery(authLoginCustomTokenRequest, function* takeEveryCustomTokenRequest(action) {
    const { payload: { id, closeModal, history } } = action;
    try {
      const token: { data: { token: string } } = yield call(() => functions.httpsCallable('createCustomUserToken')({ id }));
      yield put(authLogoutRequest({}));
      const {
        credential, additionalUserInfo, user: { uid },
      }: AuthResponsePayload = yield call(() => (
        auth.signInWithCustomToken(token.data.token)
      ));

      const idTokenResult : IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = userRef.data() || {} as UserSchema;

      yield put(authLoginCustomTokenResponse({
        credential,
        additionalUserInfo,
        uid,
        isAdmin: idTokenResult.claims?.role === 'admin',
      }));

      yield call(closeModal);

      history.push(RouteTypes.Drops);
      trackEvent('user_login');

      const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

      if (addr && user.flow_account === addr) {
        fcl.authenticate().then((e: any) => console.error(e));
      } else {
        yield put(flowLogoutRequest({}));
      }
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchLoginEmailRequest() {
  yield takeEvery(authLoginEmailRequest, function* takeEveryLoginEmailRequest(action) {
    const {
      payload: {
        email, password, closeModal, history,
      },
    } = action;
    try {
      const {
        credential, additionalUserInfo, user: { uid },
      }: AuthResponsePayload = yield call(() => (
        auth.signInWithEmailAndPassword(email, password)
      ));

      const idTokenResult : IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = userRef.data() || {} as UserSchema;

      yield put(authLoginEmailResponse({
        credential,
        additionalUserInfo,
        uid,
        isAdmin: idTokenResult.claims?.role === 'admin',
      }));

      yield call(closeModal);

      if (!user.username) {
        yield put(authSetIsProfileCreating(true));
        yield put(authSetIsAuthorized(false));
        history.push(RouteTypes.CreateProfile);
      }

      const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

      if (addr && user.flow_account === addr) {
        fcl.authenticate().then((e: any) => console.error(e));
      } else {
        yield put(flowLogoutRequest({}));
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authSignupEmailRequest({
        email, password, closeModal, history,
      }));
    }
  });
}

const signInWithPopup = (provider: any) => auth.signInWithPopup(provider)
  .then((response) => ({ response }))
  .catch((error) => ({ error }));

function* watchLoginGoogleRequest() {
  yield takeEvery(authLoginGoogleRequest, function* takeEveryGoogleLoginRequest(action) {
    try {
      const { payload: { history } } = action;
      const provider = new GoogleAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        /* eslint-disable-next-line */
        const _error: AuthErrorPayload = { code: '400', message: error.message };
        trackException(error.message);
        yield put(authError({ error: _error }));
      } else {
        const {
          credential,
          additionalUserInfo,
          user: { uid },
        } = response;

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = userRef.data() || {} as UserSchema;

        yield put(authLoginGoogleResponse({
          credential,
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          history.push(RouteTypes.CreateProfile);
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginTwitterRequest() {
  yield takeEvery(authLoginTwitterRequest, function* takeEveryTwitterLoginRequest(action) {
    try {
      const { payload: { history } } = action;
      const provider = new TwitterAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        /* eslint-disable-next-line */
        const _error: AuthErrorPayload = { code: '400', message: error.message };

        yield put(authError({ error: _error }));
      } else {
        const {
          credential,
          additionalUserInfo,
          user: { uid },
        } = response;

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = userRef.data() || {} as UserSchema;

        yield put(authLoginTwitterResponse({
          credential,
          additionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          history.push(RouteTypes.CreateProfile);
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginFacebookRequest() {
  yield takeEvery(authLoginFacebookRequest, function* takeEveryFacebookLoginRequest(action) {
    try {
      const { payload: { history } } = action;
      const provider = new FacebookAuthProvider();

      const { response, error }: ISignInPayload = yield call(signInWithPopup, provider);

      if (error) {
        /* eslint-disable-next-line */
        const _error: AuthErrorPayload = { code: '400', message: error.message };

        yield put(authError({ error: _error }));
      } else {
        const {
          credential,
          additionalUserInfo,
          user: { uid },
        } = response;

        const { profile: { picture } } = additionalUserInfo;
        let newAdditionalUserInfo = {};

        if (typeof picture === 'object') {
          newAdditionalUserInfo = {
            ...additionalUserInfo,
            profile: {
              ...additionalUserInfo.profile,
              picture: picture.data.url,
            },
          };
        } else newAdditionalUserInfo = { ...additionalUserInfo };

        const idTokenResult: IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
        const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
        const user = userRef.data() || {} as UserSchema;

        yield put(authLoginFacebookResponse({
          credential,
          additionalUserInfo: newAdditionalUserInfo,
          uid,
          isAdmin: idTokenResult.claims?.role === 'admin',
        }));

        if (!user.username) {
          yield put(authSetIsProfileCreating(true));
          yield put(authSetIsAuthorized(false));

          history.push(RouteTypes.CreateProfile);
          trackEvent('user_sign_up');
        } else {
          yield put(authSetIsProfileCreating(false));
          yield put(authSetIsAuthorized(true));
          trackEvent('user_login');
        }

        const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

        if (addr && user.flow_account === addr) {
          fcl.authenticate().then((e: any) => console.error(e));
        } else {
          yield put(flowLogoutRequest({}));
        }
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchLoginWechatRequest() {
  yield takeEvery(authLoginWechatRequest, function* takeEveryWechatLoginRequest(action) {
    try {
      const { payload: { history, token } } = action;
      if (token) {
        const userCredential: UserCredential = yield call(() => auth.signInWithCustomToken(token));
        if (userCredential && userCredential.user?.uid) {
          const {
            credential,
            additionalUserInfo,
            user: { uid },
          } = userCredential;

          const idTokenResult: IdTokenResult = yield call(
            () => auth.currentUser?.getIdTokenResult(),
          );
          const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
          const user = userRef.data() as UserSchema || {} as UserSchema;

          yield put(authLoginWechatResponse({
            credential,
            additionalUserInfo,
            uid,
            isAdmin: idTokenResult.claims?.role === 'admin',
          }));

          if (!user.username) {
            yield put(authSetIsProfileCreating(true));
            yield put(authSetIsAuthorized(false));
            yield call(() => sessionStorage.setItem('wechatRegister', 'true'));

            history.push(RouteTypes.CreateProfile);
            trackEvent('user_sign_up');
          } else {
            yield put(authSetIsProfileCreating(false));
            yield put(authSetIsAuthorized(true));
            trackEvent('user_login');
          }

          const { addr }: { addr: string | undefined } = yield call(fcl.currentUser().snapshot);

          if (addr && user.flow_account === addr) {
            fcl.authenticate().then((e: any) => console.error(e));
          } else {
            yield put(flowLogoutRequest({}));
          }
        }
      } else if (!auth.currentUser) {
        yield put(authSetIsProfileCreating(true));
        const redirect: string = yield call(() => sessionStorage.getItem('onAuthRedirect'));
        if (!redirect) yield call(() => sessionStorage.setItem('onAuthRedirect', window.location.pathname));

        yield getLinkAndRedirect(`/api/generateWechatAuthLink?redirectUri=${createWechatCallbackUri()}`);
      }
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: error as AuthErrorPayload,
      }));
    }
  });
}

function* watchSignupEmailRequest() {
  yield takeEvery(authSignupEmailRequest, function* takeEverySignupEmailRequest(action) {
    const {
      payload: {
        email, password, closeModal, history,
      },
    } = action;
    try {
      const {
        credential, additionalUserInfo, user: { uid },
      }: AuthResponsePayload = yield call(() => (
        auth.createUserWithEmailAndPassword(email, password)
      ));

      const idTokenResult : IdTokenResult = yield call(() => auth.currentUser?.getIdTokenResult());
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(uid).get());
      const user = userRef.data() || {} as UserSchema;

      yield put(authSignupEmailResponse({
        credential,
        additionalUserInfo,
        uid,
        isAdmin: idTokenResult.claims?.role === 'admin',
      }));

      yield call(closeModal);

      if (!user.username) {
        yield put(authSetIsProfileCreating(true));
        yield put(authSetIsAuthorized(false));
        history.push(RouteTypes.CreateProfile);
      } else {
        history.push(RouteTypes.Drops);
      }
      trackEvent('user_sign_up');
    } catch (error: any) {
      trackException(error.message);
      yield put(authError({
        error: {
          code: error.code,
          message: error.code === 'auth/email-already-in-use'
            ? i18n.t('auth.error.emailPassword')
            : error.message,
        } as AuthErrorPayload,
      }));
      yield call(closeModal);
    }
  });
}

function* watchActivateAuthRedirect() {
  yield takeEvery(activateAuthRedirect, function* activateAuthRedirectWorker() {
    const path: string | undefined = yield select((state) => state.login.onAuthRedirect);
    if (path) {
      /* eslint-disable-next-line */
      if (path !== location.pathname) { yield put(push(path)); }
      yield put(setAuthRedirect({ onAuthRedirect: '' }));
    }
  });
}

export default function* collectionSaga() {
  yield all([
    fork(watchLogoutRequest),
    fork(watchLoginCustomTokenRequest),
    fork(watchLoginEmailRequest),
    fork(watchLoginGoogleRequest),
    fork(watchLoginTwitterRequest),
    fork(watchLoginFacebookRequest),
    fork(watchLoginWechatRequest),
    fork(watchSignupEmailRequest),
    fork(watchActivateAuthRedirect),
  ]);
}
