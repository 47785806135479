import { CollectedCollection } from '@starly/starly-types';

interface ICreatedCollection {
  create_date: {
    seconds: number
  }
}

interface ICollectedCollection {
  last_purchase_time: {
    seconds: number
  };
  collected_cards: [];
}

const createdCollectionsComparator = (a: ICreatedCollection, b: ICreatedCollection) => {
  if (a.create_date.seconds > b.create_date.seconds) {
    return -1;
  }
  if (a.create_date.seconds < b.create_date.seconds) {
    return 1;
  }
  return 0;
};

const collectedCollectionsComparator = (a: ICollectedCollection, b: ICollectedCollection) => {
  if (a.last_purchase_time.seconds > b.last_purchase_time.seconds) {
    return -1;
  }
  if (a.last_purchase_time.seconds < b.last_purchase_time.seconds) {
    return 1;
  }
  return 0;
};

export const sortCollectedCollections = (collections: {}) => {
  const arr: ICollectedCollection[] = Object.values(collections || {});
  return arr.sort(collectedCollectionsComparator);
};

export const sortCreatedCollections = (collections: {}) => {
  const arr: ICreatedCollection[] = Object.values(collections || {});
  return arr.sort(createdCollectionsComparator);
};

interface RarityById {
  [key: string]: { rarity: string, amount: number };
}

interface Rarities {
  [key: string]: { amount: number, lowestAmount: number };
}

export const getTags = (collection: CollectedCollection) => {
  const raritiesById: RarityById = Object.values(collection.collected_cards)
    .reduce((acc, v) => ({
      ...acc,
      [v.id]: {
        rarity: v.rarity,
        amount: acc[v.id] ? acc[v.id]?.amount + 1 : 1,
      },
    }), {});

  const rarities: Rarities = Object.values(raritiesById)
    .reduce((acc, rarity) => ({
      ...acc,
      [rarity.rarity]: {
        amount: acc[rarity.rarity]?.amount + 1,
        // eslint-disable-next-line
        lowestAmount: acc[rarity.rarity]?.lowestAmount === 0
          ? rarity?.amount : rarity.amount < acc[rarity.rarity]?.lowestAmount
            ? rarity?.amount : acc[rarity.rarity]?.lowestAmount,
      },
    }), {
      rare: { amount: 0, lowestAmount: 0 },
      legendary: { amount: 0, lowestAmount: 0 },
      common: { amount: 0, lowestAmount: 0 },
    });

  const distribution = collection?.card_information?.distribution;

  return ['legendary', 'rare', 'common']
    .map((rarity) => (!!rarities[rarity].amount
      && rarities[rarity].amount >= distribution[rarity]?.distinct_cards
      ? { rarity, number: rarities[rarity].lowestAmount } : null))
    .filter(<TValue>(value: TValue | null): value is TValue => value !== null);
};
