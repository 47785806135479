import { createAction } from '@reduxjs/toolkit';
import { TopCollectorTokenRewardPayout } from '@starly/starly-types';
import { RankingCollectorsRowProps, RankingRowProps } from '../../views/Ranking/types';
import { DocumentSnapshot } from '../../global/firebase';

interface RankingCollectionsRequestPayload {
  days: number,
  page: number,
}

interface RankingCollectorsRequestPayload {
  pageLastEl: DocumentSnapshot | null,
  pageNumber: number
}

interface UserRankingScoreRequestPayload {
  isOwnProfile?: boolean
  id?: string
}

interface UserRankingScoreResponsePayload {
  ranking: RankingCollectorsRowProps
  id: string
  isOwnProfile?: boolean
}

interface SetRankingLoadStatePayload {
  isLoad?: boolean,
  isLoading?: boolean,
  isMoreToLoad?: boolean
}

interface SetUserRankingLoadState {
  isLoading: boolean
}

interface SetRankingPagePayload {
  page: number,
}

interface SetRankingCollectorsPagePayload {
  pageNumber: number,
  pageLastEl: DocumentSnapshot | null
}

interface RankingResponsePayload<T> {
  rankings: T[],
}

interface SetRankingFiltersPayload {
  days: number,
}

interface RewardSnapshotRequestPayload {
}

interface RewardSnapshotResponsePayload {
  rewards: [Date, TopCollectorTokenRewardPayout][]
}

interface RewardToClaimResponsePayload {
  reward: number | null
}

interface RewardConfigResponse {
  rewardPool: number,
  collectorsCount: number
}

export const setRankingFilters = createAction<SetRankingFiltersPayload>('ranking/SetRankingFilters');
export const setRankingPage = createAction<SetRankingPagePayload>('ranking/SetRankingRage');

export const setRankingCollectorsPage = createAction<SetRankingCollectorsPagePayload>('ranking/SetRankingCollectorsPage');

export const userRankingScoreRequest = createAction<UserRankingScoreRequestPayload>('ranking/UserOwnScoreRequest');
export const userRankingScoreResponse = createAction<UserRankingScoreResponsePayload>('ranking/UserOwnScoreResponse');

export const setUserRankingLoadState = createAction<SetUserRankingLoadState>('ranking/SetUserRankingLoadState');

export const resetUserRankingState = createAction('ranking/ResetUserRankingState');

export const rankingCollectorsRequest = createAction<RankingCollectorsRequestPayload>('ranking/RankingCollectorsRequest');
export const rankingCollectorsResponse = createAction<RankingResponsePayload<RankingCollectorsRowProps>>('ranking/RankingCollectorsResponse');

export const rankingCollectionsRequest = createAction<RankingCollectionsRequestPayload>('ranking/RankingCollectionsRequest');
export const rankingCollectionsResponse = createAction<RankingResponsePayload<RankingRowProps>>('ranking/RankingCollectionsResponse');

export const setRankingLoadState = createAction<SetRankingLoadStatePayload>('ranking/setRankingLoadState');
export const setRankingCollectorsLoadState = createAction<SetRankingLoadStatePayload>('ranking/setRankingCollectorsLoadState');

export const resetRankingState = createAction('ranking/resetRankingState');
export const resetRankingCollectorsState = createAction('ranking/resetRankingCollectorsState');

export const rewardSnapshotsRequest = createAction<RewardSnapshotRequestPayload>('ranking/rewardSnapshotRequest');
export const rewardSnapshotsResponse = createAction<RewardSnapshotResponsePayload>('ranking/rewardSnapshotResponse');

export const rewardConfigRequest = createAction('ranking/rewardConfigRequest');
export const rewardConfigResponse = createAction<RewardConfigResponse>('ranking/rewardConfigResponse');

export const rewardToClaimRequest = createAction<{ userId: string }>('ranking/rewardToClaimRequest');
export const rewardToClaimResponse = createAction<RewardToClaimResponsePayload>('ranking/rewardToClaimRequest');

export const claimRewardRequest = createAction('ranking/claimRewardRequest');

export const setClaimRewardClaiming = createAction<{ isClaiming: boolean }>('ranking/setClaimRewardClaiming');
export const setClaimingStatus = createAction<{ status: 'success' | 'error' | 'initial' }>('ranking/setClaimingStatus');

export const resetRewardClaimState = createAction('ranking/resetRewardClaimState');
