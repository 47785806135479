import React, { FC, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PackRarity } from 'global/constants';
import {
  selectAuthAuthorized,
  selectAuthIsProfileCreating,
} from 'store/auth/authSelectors';
import { toggleSideMenu } from 'store/sideMenu/sideMenuActions';
import { selectAuthLogout } from 'store/login/loginSelectors';
import { setAuthLogout, setAuthRedirect, setLoginModal } from 'store/login/loginActions';
import { setPrevPathname } from 'store/app/appActions';

import { RouteTypes } from 'RouteTypes';
import Loader from 'components/Loader';
import HowToPlay from './views/HowToPlayView';

/**
 * All application pages
 */
const Blog = React.lazy(() => import(/* webpackChunkName: "Blog" */ './views/Blog'));
const CardPage = React.lazy(() => import(/* webpackChunkName: "CardPage" */ './views/Card'));
const CardPreview = React.lazy(() => import(/* webpackChunkName: "CardPreview" */ './views/CardPreview'));
const CardSalePage = React.lazy(() => import(/* webpackChunkName: "CardSalePage" */ './views/PlaceCardForSale'));
const CreateProfile = React.lazy(() => import(/* webpackChunkName: "CreateProfile" */ './views/CreateProfile'));
const CurrentCollection = React.lazy(() => import(/* webpackChunkName: "CurrentCollection" */ './views/CurrentCollection'));
const DropsView = React.lazy(() => import(/* webpackChunkName: "DropsView" */ './views/Drops'));
const EditCardsView = React.lazy(() => import(/* webpackChunkName: "EditCardsView" */ './views/EditCards'));
const EditCollectionView = React.lazy(() => import(/* webpackChunkName: "EditCollectionView" */ './views/EditCollection'));
const FlowFestView = React.lazy(() => import(/* webpackChunkName: "FlowFestView" */ './views/FlowFestView/FlowFestView'));
const FlowNFTNYCView = React.lazy(() => import(/* webpackChunkName: "FlowFestView" */ './views/FlowNFTNYCView/FlowNFTNYCView'));
const FaceControlPage = React.lazy(() => import(/* webpackChunkName: "FaceControlPage" */ './views/FaceControl'));
const KycView = React.lazy(() => import(/* webpackChunkName: "KycView" */ './views/Kyc'));
const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound" */ './views/NotFound'));
const NotificationsView = React.lazy(() => import(/* webpackChunkName: "NotificationsView" */ './views/Notifications'));
const Marketplace = React.lazy(() => import(/* webpackChunkName: "Marketplace" */ './views/Marketplace'));
const OAuthCallback = React.lazy(() => import(/* webpackChunkName: "OAuthCallback" */ './views/OAuthCallback'));
const PackPurchaseView = React.lazy(() => import(/* webpackChunkName: "PackPurchaseView" */ './views/PackPurchase'));
const PacksView = React.lazy(() => import(/* webpackChunkName: "PacksView" */ './views/PacksPage'));
const RevealPackView = React.lazy(() => import(/* webpackChunkName: "RevealPackView" */ './views/RevealPack'));
const Settings = React.lazy(() => import(/* webpackChunkName: "Settings" */ './views/Settings'));
const Staking = React.lazy(() => import(/* webpackChunkName: "Staking" */ './views/Staking'));
const StepsPage = React.lazy(() => import(/* webpackChunkName: "StepsPage" */ './views/Steps'));
const TestView = React.lazy(() => import(/* webpackChunkName: "TestView" */ './views/Test'));
const TermsAndPrivacyView = React.lazy(() => import(/* webpackChunkName: "TermsAndPrivacyView" */ './views/TermsAndPrivacy'));
const TokenLanding = React.lazy(() => import(/* webpackChunkName: "TokenLanding" */ './views/TokenLanding'));
const TransactionsView = React.lazy(() => import(/* webpackChunkName: "TransactionsView" */ './views/Transactions'));
const Ranking = React.lazy(() => import(/* webpackChunkName: "Ranking" */ './views/Ranking'));
const UserView = React.lazy(() => import(/* webpackChunkName: "UserView" */ './views/User'));
const VestingPrize = React.lazy(() => import(/* webpackChunkName: "VestingPrize" */ './views/VestingPrize'));
const VestingHistoryFlow = React.lazy(() => import(/* webpackChunkName: "VestingHistoryFlow" */ './views/VestingHistory/VestingHistoryFlow'));
const Vesting = React.lazy(() => import(/* webpackChunkName: "Vesting" */ './views/Vesting'));
const VestingHistory = React.lazy(() => import(/* webpackChunkName: "VestingHistory" */ './views/VestingHistory/VestingHistoryInvestor'));
const Wallet = React.lazy(() => import(/* webpackChunkName: "Wallet" */ './views/Wallet'));
const WriteUs = React.lazy(() => import(/* webpackChunkName: "WriteUs" */ './views/WriteUs'));

const IsLoginRoute = () => ({
  component: Component,
  redirectTo: to,
  authCallback,
  routeProps,
  isAuthorized,
  isRegisterPage,
  ...args
}: any) => {
  const { key, pathname } = useLocation();
  const isLogout = useSelector(selectAuthLogout);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (isLogout && typeof authCallback === 'function') {
      history.push(RouteTypes.Drops);
      dispatch(setAuthLogout({ isLogout: false }));
      return;
    }
    if (!isAuthorized && typeof authCallback === 'function') {
      // temporary solution
      const withoutRedirect = sessionStorage.getItem('wechatRegister') || isRegisterPage;
      sessionStorage.removeItem('wechatRegister');
      if (history.length > 1 && !withoutRedirect) history.goBack();
      else if (!withoutRedirect) history.push(RouteTypes.Drops);
      authCallback(pathname);
    }
  }, [key, isAuthorized, isLogout]);
  return (
    <Route {...args} render={(props) => <Component {...props} {...routeProps} />} />
  );
};

const RoutesInner: FC = React.memo(() => {
  const authorized = useSelector(selectAuthAuthorized);
  const isProfileCreating = useSelector(selectAuthIsProfileCreating);
  const dispatch = useDispatch();
  const AuthRoute = IsLoginRoute();
  const isLogout = useSelector(selectAuthLogout);

  const login = (action: string) => {
    dispatch(setLoginModal({ isOpen: true }));
    dispatch(setAuthRedirect({ onAuthRedirect: action }));
  };
  const loginWithRedirect = (action: string) => {
    sessionStorage.setItem('authRedirect', RouteTypes.FaceControl);
    dispatch(setLoginModal({ isOpen: true }));
    dispatch(setAuthRedirect({ onAuthRedirect: action }));
  };

  const voidCallback = () => {};
  useEffect(() => {
    if (isLogout) dispatch(setAuthLogout({ isLogout: false }));
  }, [isLogout]);
  return (
    <Switch>
      <Route path={RouteTypes.BlogPost} component={Blog} />
      <Route path={RouteTypes.Blog} component={Blog} />
      <Route path={RouteTypes.CardQR} component={CardPage} exact />
      <Route exact path={RouteTypes.Drops} component={DropsView} />
      {
        Object.values(PackRarity).map((cardType: string) => (
          <AuthRoute
            key={cardType}
            path={`${RouteTypes.EditCards}/${cardType}`}
            component={EditCardsView}
            authCallback={login}
            isAuthorized={authorized}
            routeProps={{ editCardsType: cardType }}
            exact
          />
        ))
      }
      <AuthRoute
        path={RouteTypes.EditCollection}
        component={EditCollectionView}
        authCallback={login}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Wallet}
        component={Wallet}
        authCallback={login}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Transactions}
        component={TransactionsView}
        authCallback={login}
        isAuthorized={authorized}
      />
      <AuthRoute
        path={RouteTypes.PackPurchase}
        authCallback={login}
        isAuthorized={authorized}
        component={PackPurchaseView}
        exact
      />
      <AuthRoute
        path={RouteTypes.Packs}
        component={PacksView}
        authCallback={login}
        isAuthorized={authorized}
        exact
        isRegisterPage
      />
      <Route path={`${RouteTypes.RevealPack}/:collectionId/:packId/:rarity`} component={RevealPackView} exact />
      <AuthRoute
        path={RouteTypes.PlaceCardForSale}
        component={CardSalePage}
        authCallback={login}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={`${RouteTypes.PackPurchase}/:collectionId/:rarity`}
        component={PackPurchaseView}
        isAuthorized={authorized}
        authCallback={login}
        exact
      />
      <AuthRoute
        path={RouteTypes.Notifications}
        component={NotificationsView}
        authCallback={login}
        isAuthorized={authorized}
      />
      <AuthRoute
        path={RouteTypes.StepDefault}
        authCallback={loginWithRedirect}
        component={StepsPage}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Steps}
        authCallback={loginWithRedirect}
        component={StepsPage}
        isAuthorized={authorized}
      />
      <AuthRoute
        path={RouteTypes.CreateProfile}
        component={CreateProfile}
        authCallback={voidCallback}
        isAuthorized={isProfileCreating}
        isRegisterPage
        exact
      />
      <AuthRoute
        path={RouteTypes.Settings}
        component={Settings}
        authCallback={login}
        isAuthorized={authorized}
        exact
        isRegisterPage
      />
      <AuthRoute
        path={RouteTypes.Staking}
        component={Staking}
        authCallback={login}
        isAuthorized={authorized}
      />
      <AuthRoute
        path={RouteTypes.VestingPrize}
        component={VestingPrize}
        authCallback={login}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.VestingHistoryFlow}
        component={VestingHistoryFlow}
        authCallback={login}
        isAuthorized={authorized}
        exact
      />
      <Route
        path={RouteTypes.Vesting}
        component={Vesting}
        exact
      />
      <Route
        path={RouteTypes.VestingHistory}
        component={VestingHistory}
        exact
      />
      <AuthRoute
        path={RouteTypes.FaceControl}
        component={FaceControlPage}
        authCallback={loginWithRedirect}
        isAuthorized={authorized}
        exact
      />
      <AuthRoute
        path={RouteTypes.Test}
        component={TestView}
        authCallback={login}
        isAuthorized={authorized}
      />
      <Route
        path={RouteTypes.FlowFest}
        component={FlowFestView}
      />
      <Route
        path={RouteTypes.FlowNFTNYC}
        component={FlowNFTNYCView}
      />
      <Route path={RouteTypes.TermsOfUse} component={TermsAndPrivacyView} />
      <Route path={RouteTypes.PrivacyPolicy} component={TermsAndPrivacyView} />
      <Route path={RouteTypes.WriteUs} component={WriteUs} />
      <Route path={RouteTypes.Kyc} component={KycView} />
      <Route path={RouteTypes.NotFound} component={NotFound} />
      <Route exact path={RouteTypes.OAuthInstagramCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthTwitterCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthWechatCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.OAuthYoutubeCallback} component={OAuthCallback} />
      <Route exact path={RouteTypes.HowToPlay} component={HowToPlay} />
      {/* TODO remove after testing */}
      <Route path={RouteTypes.UserById} component={UserView} />
      <Route path={RouteTypes.Ranking} component={Ranking} />
      <Route path={RouteTypes.Marketplace} component={Marketplace} />
      <Route exact path={RouteTypes.Collection} component={CurrentCollection} />
      <Route exact path={RouteTypes.CardPreview} component={CardPreview} />
      <Route exact path={RouteTypes.Card} component={CardPage} />
      <Route exact path={RouteTypes.TokenLanding} component={TokenLanding} />
      <Route path={RouteTypes.User} component={UserView} />
      <Route path={RouteTypes.Any} component={NotFound} />
    </Switch>
  );
});

const Routes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pathname, setPathname] = useState<string>(history.location.pathname);
  useEffect(() => {
    dispatch(setPrevPathname({
      prev: false,
      pathname,
    }));
    history.listen((location) => {
      setPathname((prevPathname) => {
        dispatch(setPrevPathname({
          prev: true,
          pathname: prevPathname,
        }));
        return location.pathname;
      });
    });
  }, []);
  useEffect(() => {
    dispatch(toggleSideMenu(false));
  }, []);
  return (<React.Suspense fallback={<Loader />}><RoutesInner /></React.Suspense>);
};

export default Routes;
