import store from 'store/store';
import Web3 from 'web3';
import Web3Modal, { getInjectedProvider } from 'web3modal';
// import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethConfig } from 'util/constants';

import { ethereumToggleModal, ethereumLoginRequest, ethereumToggleLoading } from '../store/ethereum/ethereumActions';

const web3Modal = new Web3Modal({
  network: 'mainnet',
  theme: 'dark',
  providerOptions: {
    // binancechainwallet: {
    //   package: true,
    // },
    // walletconnect: {
    //   package: WalletConnectProvider,
    //   options: {
    //     rpc: {
    //       56: process.env.REACT_APP_RPC_NODE_URL_MAINNET,
    //       97: process.env.REACT_APP_RPC_NODE_URL_TESTNET,
    //     },
    //   },
    // },
  },
});

export default class Wallet {
  static web3: Web3;

  static provider: any;

  static providerId: string;

  static checkInjected() {
    return getInjectedProvider();
  }

  static async requestToSwitchChains() {
    try {
      await Wallet.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Wallet.web3.utils.toHex(ethConfig.chain.id) }],
      });
      store.dispatch(ethereumToggleLoading(true));
      setTimeout(async () => {
        store.dispatch(ethereumToggleModal({ isOpen: false }));
        store.dispatch(ethereumLoginRequest({ providerId: Wallet.providerId }));
      }, 2000);
    } catch (err) {
      if (err.code === 4902) {
        await Wallet.provider.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: Wallet.web3.utils.toHex(ethConfig.chain.id),
            chainName: ethConfig.chain.name,
            nativeCurrency: {
              symbol: ethConfig.chain.symbol,
              decimals: 18,
            },
            rpcUrls: [ethConfig.chain.rpcUrl],
            blockExplorerUrls: [ethConfig.chain.blockExplorerUrl],
          }],
        });
      }
    }
  }

  static getProvidersInfo(): any[] {
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    /* eslint-disable-next-line func-names */
    const isSafari = /constructor/i.test(window.HTMLElement) || ((function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification))) || navigator.vendor.includes('Apple');

    const providers = [];
    if (!isOpera && !isSafari) {
      providers.push(Wallet.checkInjected());
      // , getProviderInfoById('binancechainwallet')
    }
    // providers.push(getProviderInfoById('walletconnect'));

    return providers.filter((provider) => provider);
  }

  static async connectTo(id: string) {
    try {
      const provider = await web3Modal.connectTo(id);
      Wallet.provider = provider;
      Wallet.providerId = id;
      const web3: Web3 = new Web3(provider);
      Wallet.web3 = web3;

      return provider;
    } catch (err) {
      return null;
    }
  }

  static subscribeProvider(
    provider: any, onDisconnect: Function, onAccountsChanged: Function, onChainChanged: Function,
  ) {
    if (!provider.on) {
      return;
    }
    provider.on('disconnect', onDisconnect);
    provider.on('accountsChanged', onAccountsChanged);
    provider.on('chainChanged', onChainChanged);
  }

  static unsubscribeProvider(
    provider: any, onDisconnect: Function, onAccountsChanged: Function, onChainChanged: Function,
  ) {
    try {
      provider.removeListener('disconnect', onDisconnect);
      provider.removeListener('accountsChanged', onAccountsChanged);
      provider.removeListener('chainChanged', onChainChanged);
    } catch (err) {
      window.location.reload();
    }
  }

  static sendTransaction(data: object) {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      const gasPrice: string = await Wallet.web3.eth.getGasPrice();
      const accounts: string[] = await Wallet.web3.eth.getAccounts();
      const from = accounts[0];

      Wallet.web3.eth
        .sendTransaction({
          ...data,
          from,
          gasPrice,
        })
        .on('receipt', (receipt: object) => resolve(receipt))
        .on('error', reject);
    });
  }

  static clearCachedProvider() {
    web3Modal.clearCachedProvider();
  }
}
