import { createAction } from '@reduxjs/toolkit';
import {
  MarketplaceCard, MarketplacePurchasingState, Option, FilterOption,
} from './marketplaceTypes';
import { DocumentSnapshot } from '../../global/firebase';
import { MarketplaceSalesRowProps } from '../../views/Marketplace/components/MarketplaceSaleRow/types';

export interface MarketplaceSetPagePayload {
  page: DocumentSnapshot | null,
}

export interface MarketplaceSetLoadStatePayload {
  isLoad?: boolean,
  isLoading?: boolean,
  isMoreToLoad?: boolean,
}

export interface MarketplaceRequestPayload {
  page: DocumentSnapshot | null,
  rarity: FilterOption[],
  collection: Option,
  sortBy: string,
}

export interface SetMarketplaceFiltersPayload {
  rarity?: FilterOption[],
  collection?: Option,
  sortBy?: string,
  isScannedUrl?: boolean,
  withoutUrlChange?: boolean,
}

export interface MarketplaceResponsePayload {
  cards: MarketplaceCard[]
}

export interface MarketplaceSalesResponsePayload {
  saleItems: MarketplaceSalesRowProps[]
}

export interface SetMarketplacePurchasingStatus {
  isPurchasing?: boolean,
  state?: MarketplacePurchasingState,
  cardPageUrl?: string,
}

export interface SetMarketplaceCardPageUrlByNfrIdPayload {
  nftItemId: number,
}

export interface SetMarketplaceCardProgressPayload {
  nftItemId: number
  inProgress?: boolean,
  purchased?: boolean,
}

export interface SetMarketplaceScroll {
  scroll: number
}

export interface CollectionsMarketplaceResponsePayload {
  collections: Option[],
}

export const collectionsMarketplaceRequest = createAction('marketplace/CollectionsRequest');
export const collectionMarketplaceResponse = createAction<CollectionsMarketplaceResponsePayload>('marketplace/CollectionsResponse');

export const cardMarketplaceRequest = createAction<MarketplaceRequestPayload>('marketplace/CardRequest');
export const cardMarketplaceResponse = createAction<MarketplaceResponsePayload>('marketplace/CardResponse');

export const salesMarketplaceRequest = createAction<MarketplaceRequestPayload>('marketplace/SalesRequest');
export const salesMarketplaceResponse = createAction<MarketplaceSalesResponsePayload>('marketplace/SalesResponse');

export const setMarketplacePurchasingState = createAction<SetMarketplacePurchasingStatus>('marketplace/SetPurchasingStatus');
export const setMarketplaceCardPageUrlByNftId = createAction<SetMarketplaceCardPageUrlByNfrIdPayload>('marketplace/SetCardPageUrlByNftId');
export const setMarketplaceCardPurchaseState = createAction<SetMarketplaceCardProgressPayload>('marketplace/SetCardProgress');

export const setMarketplacePage = createAction<MarketplaceSetPagePayload>('marketplace/SetPage');
export const setMarketplaceLoadState = createAction<MarketplaceSetLoadStatePayload>('marketplace/SetLoadState');

export const setMarketplaceSalesPage = createAction<MarketplaceSetPagePayload>('marketplace/SetSalesPage');
export const setMarketplaceSalesLoadState = createAction<MarketplaceSetLoadStatePayload>('marketplace/SetSalesLoadState');

export const setMarketplaceFilters = createAction<SetMarketplaceFiltersPayload>('marketplace/SetFilters');
export const setMarketplaceSalesFilters = createAction<SetMarketplaceFiltersPayload>('marketplace/SetSalesFilters');
export const setMarketplaceScrollPosition = createAction<SetMarketplaceScroll>('marketplace/SetScroll');

export const resetMarketplaceCardState = createAction('marketplace/resetState');
export const resetMarketplaceSalesState = createAction('marketplace/resetSalesState');
