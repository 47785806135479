import { createReducer } from '@reduxjs/toolkit';
import { remoteConfig } from 'global/firebase';
import {
  setGoogleLogin,
  setTwitterLogin,
  setFacebookLogin,
  setWechatLogin,
  setEmailLogin,
  setCustomTokenLogin,
} from './firebaseConfigActions';

interface FirebaseConfigSlice {
  googleLogin: boolean,
  twitterLogin: boolean,
  facebookLogin: boolean,
  wechatLogin: boolean,
  emailLogin: boolean,
  customTokenLogin: boolean,
}

const initialState: FirebaseConfigSlice = {
  googleLogin: remoteConfig.getValue('googleLogin').asBoolean(),
  twitterLogin: remoteConfig.getValue('twitterLogin').asBoolean(),
  facebookLogin: remoteConfig.getValue('facebookLogin').asBoolean(),
  // TODO get rid of that kostil
  wechatLogin: remoteConfig.getValue('wechatLogin').asBoolean() || true,
  emailLogin: remoteConfig.getValue('emailLogin').asBoolean(),
  customTokenLogin: remoteConfig.getValue('customTokenLogin').asBoolean(),
};

const firebaseConfigReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setGoogleLogin, (state, { payload: { googleLogin } }) => {
      state.googleLogin = googleLogin;
    })
    .addCase(setTwitterLogin, (state, { payload: { twitterLogin } }) => {
      state.twitterLogin = twitterLogin;
    })
    .addCase(setFacebookLogin, (state, { payload: { facebookLogin } }) => {
      state.facebookLogin = facebookLogin;
    })
    .addCase(setWechatLogin, (state, { payload: { wechatLogin } }) => {
      state.wechatLogin = wechatLogin;
    })
    .addCase(setEmailLogin, (state, { payload: { emailLogin } }) => {
      state.emailLogin = emailLogin;
    })
    .addCase(setCustomTokenLogin, (state, { payload: { customTokenLogin } }) => {
      state.customTokenLogin = customTokenLogin;
    });
});

export default firebaseConfigReducer;
