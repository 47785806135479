import { createAction } from '@reduxjs/toolkit';
import { VestingClaimHistoryRecord } from '@starly/starly-types';

export type SupportedBlockchain = 'flow' | 'ethereum';

export interface VestingWallet {
  id: string;
  start: number;
  duration: number;
  released: number;
  vestedAmount: number;
  totalAmount: number;
  nextUnlock?: { [key: string]: string };
  decimals: number;
  vestingType?: {
    rawValue: number;
  };
  isLoading?: boolean;
}

type VestingLoginResponsePayload = {
  wallet: {
    blockchain: SupportedBlockchain,
    address: string,
    providerId?: string,
  }
};

type VestingLoginRequestPayload = {
  blockchain: SupportedBlockchain,
  providerId?: string,
};

type VestingBalanceResponsePayload = {
  balance: {
    [key: string]: VestingWallet,
  },
  currentTime: number,
};

type VestingClaimRequestPayload = {
  walletId: string,
  isReward: boolean,
};

type VestingHistoryRequestPayload = {
  address: string,
};

type VestingHistoryResponsePayload = {
  transactions: VestingClaimHistoryRecord[];
};

type VestingOtherPayload = any;

export const vestingLoginRequest = createAction<VestingLoginRequestPayload>('vesting/login/request');

export const vestingLoginResponse = createAction<VestingLoginResponsePayload>('vesting/login/response');

export const vestingFetchVestings = createAction('vesting/vestings/fetch');

export const vestingBalanceResponse = createAction<VestingBalanceResponsePayload>('vesting/balance/response');

export const vestingRewardBalanceResponse = createAction<VestingBalanceResponsePayload>('vesting/rewardBalance/response');

export const vestingClaimRequest = createAction<VestingClaimRequestPayload>('vesting/claim/request');

export const vestingHistoryRequest = createAction<VestingHistoryRequestPayload>('vesting/history/request');

export const vestingHistoryResponse = createAction<VestingHistoryResponsePayload>('vesting/history/response');

export const vestingToggleWalletLoading = createAction<VestingClaimRequestPayload>('vesting/wallet/loading');

export const vestingLogout = createAction('vesting/logout');

export const vestingReset = createAction('vesting/reset');

export const vestingToggleModal = createAction<VestingOtherPayload>('vesting/TOGGLE_MODAL');

export const vestingToggleLoading = createAction<VestingOtherPayload>('vesting/TOGGLE_LOADING');
