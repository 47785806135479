const customMedia = (width: number) => `@media screen and (min-width: ${width}px)`;

export const desktopPhone = 374;
export const phoneSamsung = 320;
export const desktopBreakpoint = 900;
export const laptopBreakpoint = 1439;
export const wideDesktop = 1919;
export const desktopSmartPhones = 424;
export const iPad3 = 1024;
export const mediaDesktopSmartPhones = customMedia(desktopSmartPhones);
export const mediaDesktop = customMedia(desktopBreakpoint);
export const mediaLaptop = customMedia(laptopBreakpoint);
export const mediaSmartPhones = customMedia(desktopSmartPhones);
export const mediaPhone = customMedia(desktopPhone);
export const mediaSamsung = customMedia(phoneSamsung);
export const mediaIPad3 = customMedia(iPad3);
export const mediaWideDesktop = customMedia(wideDesktop);
