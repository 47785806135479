import styled from 'styled-components';
import Media from '../../../../components/Media';
import Typography from '../../../../components/Typography';
import { Row } from '../../../../components/Table';
import { mediaDesktop } from '../../../../breakpoints';

export const MarketplaceStyledRow = styled(Row)`
  grid-gap: 21px;
  grid-template-columns: 244px 90px 110px 120px 120px 90px 90px max-content;
  justify-items: center;
  padding-right: 16px;
  padding-left: 16px;
  ${mediaDesktop} {
    padding-right: 25px;
    padding-left: 25px;  
  }
`;

export const MarketplaceCardRow = styled(MarketplaceStyledRow)`
  padding-bottom: 24px;
  padding-top: 24px;
`;

export const MediaCardContainer = styled.div`
  width: 47px;
  height: 62px;
  border-radius: 8px;
  grid-row: 1 / 4;
`;

export const MediaThumbnail = styled(Media)`
  border-radius: 8px;
  width: inherit;
  height: inherit;
`;

export const MarketplaceSaleCardContainer = styled.div`
  height: 62px;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: max-content 176px;
`;

export const SaleTableTypography = styled(Typography)`
  max-width: 100%;
  overflow: hidden;
  font-size: 15px;
  display: inline-block;;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
