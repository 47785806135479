import Typography from 'components/Typography';
import { mediaDesktop, mediaDesktopSmartPhones } from 'breakpoints';
import styled from 'styled-components/macro';
import theme from 'components/theme';
import Button from 'components/Button';

export const ContentsContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  color: #171717;

  ${mediaDesktop} {
    grid-gap: 20px; 
  };
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const SelectWalletButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;

  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;

export const SelectEthButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;

  /* ${mediaDesktop} { */
    /* grid-gap: 20px;  */
  /* }; */
`;

export const EthPointerIcon = styled.img`
  width: 15px;
  height: 20px;
  margin-left: 16px;
  display: none;

  ${mediaDesktop} {
    display: block;
  }
`;

export const EthOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 109px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;

  box-shadow: none;
  border: solid 1px #d8d8d8;
  border-radius: 0;
  background-color: ${theme.colors.white};

  ${mediaDesktop} {
    padding: 32px 20px 32px 32px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    background-color: #F9F9F9;
  }
`;

export const EthBalanceContainer = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #d8d8d8;
  padding: 0 16px;
  align-items: center;

  ${mediaDesktop} {
    border: none;
    justify-self: right;
    text-align: right;
    height: auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    float: right;
  }
`;

export const EthBalanceRow = styled.div`
  display: flex;
  line-height: 38px;
  font-size: 13px;
  letter-spacing: 0.5px;

  ${mediaDesktop} {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    width: 75px;
    display: inline;
  }
  & > div {
    margin-left: 5px;
  }
`;

export const BackBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: left;
  user-select: none;
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const OptionContainer = styled.div<{ $expand?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${({ $expand }) => ($expand ? { height: '128px' } : { height: '109px' })};
  padding: 0 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;

  box-shadow: none;
  border: solid 1px #d8d8d8;
  border-radius: 0;
  background-color: ${theme.colors.white};

  ${mediaDesktop} {
    padding: 32px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    background-color: #F9F9F9;
  }
`;

export const CurrencyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;

  ${mediaDesktop} {
    width: 50%;
  }
`;

export const CurrencySubContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: Kanit;
  letter-spacing: 1px;
  font-weight: 600;
  ${mediaDesktop} {
    font-size: 32px;
    letter-spacing: 1.6px;
  }
`;

export const ProviderLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  ${mediaDesktop} {
    margin-right: 16px;
    width: 42px;
    height: 42px;
  }
`;

export const PointerIcon = styled.img`
  width: 10px;
  height: 19px;

  ${mediaDesktop} {
    display: none;
  }
`;

export const CloseBtn = styled.img`
  cursor: pointer;
  object-fit: contain;
  justify-self: right;
  user-select: none;
  position: absolute;
  top: 20px;
  right: 20px;
  filter: invert(100%);
`;

export const BalanceContainer = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #d8d8d8;
  padding: 0 16px;

  ${mediaDesktop} {
    border: none;
    justify-self: right;
    text-align: right;
    width: 75px;
    height: auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    float: right;
  }
`;

export const BalanceRow = styled.div`
  display: flex;
  line-height: 38px;
  font-size: 13px;
  letter-spacing: 0.5px;

  ${mediaDesktop} {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    width: 100%;
    display: inline;
  }
  & > div {
    margin-left: 5px;
  }
`;

export const WalletDescriptionContainer = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 57px;
  border-top: solid 1px #d8d8d8;
  padding: 10px 16px;
  letter-spacing: 0.5px;
  text-align: left;

  ${mediaDesktop} {
    font-size: 16px;
    border: none;
    text-align: right;
    /* width: 200px; */
    /* margin: 0; */
    height: auto;
    padding: 0;
  }
`;

export const SupportedTokens = styled.div`
  display: inline;
  font-weight: 600;

  ${mediaDesktop} {
    display: block;
  }
`;

export const BalanceTopup = styled(Button)`
  color: ${theme.colors.green};
  letter-spacing: 0.5px;
  background-color: transparent;
  ${mediaDesktop} {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: black;
    letter-spacing: 0.44px;
    background-color: #f0f0f0;
    text-transform: uppercase;
    height: 23px;
    min-width: 75px;
  }
  &:hover:enabled {
    background-color: #1AFF99;
  }
`;

export const TittleText = styled(Typography)`
  margin-bottom: 50px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  white-space: pre-wrap;
  ${mediaDesktop} {
    line-height: 37px;
  };
`;
export const ConnectingButtonContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  > div {
    min-width: 30px;
  }
  ${mediaDesktop} {
    top: 9px;
    > div {
      min-width: 40px;
    }
  }
`;

export const OverviewTittleText = styled(Typography)`
  color: #00ef8b;
  font-size: 20px;
  margin-bottom: 35px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  ${mediaDesktop} {
    font-size: 40px;
    margin-bottom: 50px;
    letter-spacing: 2px;
    line-height: 37px;
  };
`;

export const OverviewContainer = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  color: ${theme.colors.lightGray};
  margin-bottom: 32px;
  ${mediaDesktop} {
    gap: 12px;
    margin-bottom: 50px;
  }
`;

export const OverviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: ${theme.fonts.secondary};


  ${mediaDesktop} {
    font-size: 30px;
    letter-spacing: 1.25px;
  }
`;

export const OverviewMainItem = styled(OverviewItem)`
  color: ${theme.colors.black};
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  ${mediaDesktop} {
    grid-gap: 20px; 
  };
`;

export const IframeContainer = styled.div`
  position: fixed;
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  z-index: 120;
  background: rgba(166, 174, 185, 0.7);
`;

export const IframeWrapper = styled.div<{ isLoaded?: boolean }>`
  border-radius: 8px;
  height: 500px;
  width: 400px;
  margin: 16px 0;

  ${({ isLoaded }) => (isLoaded ? 'border: 2px solid rgba(6, 6, 6, .1);' : '')}
  & iframe {
    border-radius: 8px;
  }
`;

export const IframeCloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 26px;
`;

export const IframeTitle = styled.h3`
  font-family: Kanit, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: black;
`;

export const IframePoweredBy = styled.img`
  width: 180px;
`;

// Cool name bob
export const IframeFrame = styled.div<{ isLoaded?: boolean }>`
  position: relative;
  border-radius: 10px;
  text-align: center;
  transform: scale(.75);
  ${({ isLoaded }) => (isLoaded ? `
    background: #fff;
    padding: 17px 32px;
  ` : '')}
  ${mediaDesktopSmartPhones} {
    transform: scale(.9);
  }
  
  ${mediaDesktop} {
    transform: scale(1);
  }
`;

export const ModalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #EDEEF3;
  border-radius: 74px;
  width: 152px;
  height: 56px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2B2D56;
  text-transform: uppercase;
`;

export const ModalButtonContainer = styled.div`
  width: 318px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  & > ${ModalButton}:last-child {
    background: #DD3E56;
    box-shadow: 0px 8px 34px rgb(221 62 86 / 40%);
    color: #fff;
    border-color: transparent;
  }
`;

export const ModalContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  width: 678px;
  height: 276px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(.65);
  z-index: 9999;
  box-shadow: 0px 54px 200px rgb(36 37 57 / 20%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 35px;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  
  ${mediaDesktopSmartPhones} {
    transform: translate(-50%, -50%) scale(.8);
  }
  
  ${mediaDesktop} {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const ModalText = styled.div`
  margin: 40px auto 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #242539;
`;
