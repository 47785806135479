import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import Typography from '../Typography';
import Media from '../Media';

export const NotificationWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
  color: ${({ theme }) => theme.colors.textWhite};

  & ${Typography}:first-of-type {
    cursor: pointer;
  }

  ${mediaDesktop} {
    grid-template-columns: max-content 270px max-content;
    grid-column-gap: 23px;

    & > ${Typography}:first-of-type {
      align-self: end;
    }
  }
`;

const thumbnailCss = css`
  cursor: pointer;
  grid-row: 1 / -1;
  grid-column: -2 / -1;
  border-radius: 2px;
  width: 29px;
  height: 42px;
  object-fit: cover;
  align-self: start;
`;

export const Thumbnail = styled(Media)`
  ${thumbnailCss}
`;

export const ImgThumbnail = styled.img`
 ${thumbnailCss}
`;

export const NotificationAvatar = styled(Media)`
  grid-row: 1 / -1;
  cursor: pointer;
  align-self: start;
  object-fit: cover;
  border-radius: 100%;
  width: 42px;
  height: 42px;
`;

export const InfoContainer = styled.div`
  display: grid;
  line-height: 1.2;
  ${mediaDesktop} {
    line-height: 1.45;
  }
`;
