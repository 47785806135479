import { Notification } from '@starly/starly-types';
import { createReducer } from '@reduxjs/toolkit';
import {
  notificationLogoutReset, notificationsLoad,
  notificationsLoading,
  notificationsMoreToLoad,
  notificationsResponse, notificationsSetDelayRequest, notificationsSetIsOpen,
  notificationsSetPage,
  notificationsUnreadResponse,
  resetNotifications,
} from './notificationsActions';
import { DocumentSnapshot } from '../../global/firebase';

export interface NotificationsSlice {
  notifications: Notification[],
  isLoading: boolean,
  isLoad: boolean,
  unread: number,
  hasMoreToLoad: boolean,
  isNotificationsOpen: boolean,
  isDelayRequest: boolean,
  page: DocumentSnapshot | null,
}

const initialState: NotificationsSlice = {
  notifications: [],
  isLoading: false,
  isLoad: false,
  isNotificationsOpen: false,
  isDelayRequest: false,
  hasMoreToLoad: true,
  unread: 0,
  page: null,
};

export const notificationsReducer = createReducer<NotificationsSlice>(initialState, (builder) => {
  builder.addCase(notificationsResponse, (state, { payload: { notifications } }) => {
    state.notifications = [...state.notifications, ...notifications];
  });
  builder.addCase(notificationsLoad, (state, { payload: { isLoad } }) => {
    state.isLoad = isLoad;
  });
  builder.addCase(notificationsMoreToLoad, (state, { payload: { hasMoreToLoad } }) => {
    state.hasMoreToLoad = hasMoreToLoad;
  });
  builder.addCase(notificationsLoading, (state, { payload: { isLoading } }) => {
    state.isLoading = isLoading;
  });
  builder.addCase(notificationsSetPage, (state, { payload: { page } }) => {
    state.page = page;
  });
  builder.addCase(notificationsUnreadResponse, (state, { payload: { unread } }) => {
    state.unread = unread;
  });
  builder.addCase(resetNotifications, (state) => {
    state.notifications = [];
    state.page = null;
    state.isLoading = false;
    state.isLoad = false;
    state.hasMoreToLoad = true;
  });
  builder.addCase(notificationsSetIsOpen, (state, { payload: { isOpen } }) => {
    state.isNotificationsOpen = isOpen;
  });
  builder.addCase(notificationsSetDelayRequest, (state, { payload: { isDelayRequest } }) => {
    state.isDelayRequest = isDelayRequest;
  });
  builder.addCase(notificationLogoutReset, (state) => {
    state.notifications = [];
    state.page = null;
    state.isLoading = false;
    state.isLoad = false;
    state.hasMoreToLoad = true;
    state.isDelayRequest = false;
    state.unread = 0;
  });
});
