import {
  all,
  delay,
  fork,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { FungibleTokenType } from '@starly/starly-types';
import Web3 from 'web3';
import { isAddress } from 'web3-utils';
import i18n from 'i18next';

import { trackEvent, trackException } from 'global/firebase';
import {
  ERC_20_ABI, STARLY_PACK_ABI, ethTokensMap, ethConfig,
} from 'util/constants';
import { getStarlyRate } from 'store/flow/flowSaga';
import store from 'store/store';

import {
  // ethereumBalanceRequest,
  ethereumBalanceResponse,
  ethereumBuyPackRequest,
  ethereumLoginRequest,
  ethereumLoginResponse,
  ethereumWalletUpdate,
  ethereumLogout,
  ethereumToggleModal,
} from './ethereumActions';

import { selectEthereumWalletAddr } from './ethereumSelectors';

import { updateEthWallet } from '../user/userActions';

import { buyPackResponse, setPackPurchaseStatus } from '../pack/packActions';

import wallet from '../../helpers/ethereumWallet';

function* checkAllowance(userAddress: string, creatorAddress: string, token: FungibleTokenType) {
  const { web3 } = wallet;

  const tokenContract = new web3.eth.Contract(
    ERC_20_ABI,
    ethTokensMap.get(token.toUpperCase())?.address,
  );

  const allowance: number = yield call(
    tokenContract.methods.allowance(userAddress, creatorAddress).call,
  );

  return allowance.toString() !== '0';
}

function* getTokenDecimals(token: FungibleTokenType) {
  const { web3 } = wallet;

  const tokenContract = new web3.eth.Contract(
    ERC_20_ABI,
    ethTokensMap.get(token.toUpperCase())?.address,
  );

  const decimals: number = yield call(
    tokenContract.methods.decimals().call,
  );

  return decimals;
}

function* ethereumApproveRequest(token: FungibleTokenType) {
  try {
    const userAddress: string = yield select(selectEthereumWalletAddr);
    const { web3 } = wallet;

    const tokenContract = new web3.eth.Contract(
      ERC_20_ABI,
      ethTokensMap.get(token.toUpperCase())?.address,
    );

    const approve = tokenContract.methods.approve(
      process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    );

    const data = approve.encodeABI();

    let gas: number = yield call(approve.estimateGas, { from: userAddress });
    gas = +(gas + gas * 0.25).toFixed(0);

    const receipt: object = yield call(wallet.sendTransaction, {
      to: ethTokensMap.get(token.toUpperCase())?.address,
      value: '0',
      gas,
      data,
    });

    return receipt;
  } catch (error: any) {
    console.error('er', error);
    trackException(error.message);
    yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.approveFailed') }));
    yield put(ethereumLogout());
    yield put(setPackPurchaseStatus({ status: 'initial' }));
    return null;
  }
}

// function* watchEthereumBalanceRequest() {
//   yield takeEvery(ethereumBalanceRequest, function* takeEveryLogout(action) {
//     try {
//       const {
//         payload: {
//           token,
//         },
//       } = action;
//       const userAddress: string = yield select((state) => state.ethereum.wallet.address);
//       const { web3 } = wallet;

//       const tokenContract = new web3.eth.Contract(
//         ERC_20_ABI,
//         ethTokensMap.get(token.toUpperCase())?.address,
//       );

//       const balance: number = yield call(
//         tokenContract.methods.balanceOf(userAddress).call,
//       );
//       yield put(ethereumBalanceResponse({
//         balance,
//       }));
//     } catch (error) {
//       console.error(error);
//     }
//   });
// }

function* watchEthereumBuyPackRequest() {
  yield takeEvery(ethereumBuyPackRequest, function* takeEveryEthereumBuyPackRequest(action) {
    const {
      payload: {
        collectionId,
        packIds,
        currency,
        paymentCurrency,
        beneficiaryAddress,
        beneficiaryCutPercent,
        creatorAddress,
        creatorCutPercent,
        additionalCuts,
      },
    } = action;
    let { price } = action.payload;

    if (!isAddress(creatorAddress) || !isAddress(beneficiaryAddress)) {
      trackException('Invalid purchase receiver address');
    }

    const userId: string = yield select((state) => state.auth.userId);
    const userAddress: string = yield select((state) => state.ethereum.wallet.address);
    const { web3 } = wallet;

    if (currency === 'FUSD' && paymentCurrency === 'STARLY') {
      const rate: any = yield call(getStarlyRate);
      price /= rate.rate;
    }

    try {
      const isAllowanceSet: boolean = yield call(
        checkAllowance,
        userAddress,
        process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK, paymentCurrency,
      );
      if (!isAllowanceSet) {
        const isApproved: boolean = yield call(ethereumApproveRequest, paymentCurrency);
        if (!isApproved) return;
      }

      const starlyPack = new web3.eth.Contract(
        STARLY_PACK_ABI, process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK,
      );

      const tokenDecimals: number = yield call(getTokenDecimals, paymentCurrency);
      price = Math.floor(price * 10 ** tokenDecimals);
      let beneficiaryAmount = Math.round((price * (beneficiaryCutPercent * 100) / 100));
      const creatorAmount = Math.floor((price * (creatorCutPercent * 100) / 100));
      const cuts = additionalCuts.map((cut) => ({
        receiverAddress: cut.address,
        amount: Math.floor((price * (cut.percent * 100) / 100)),
      }));
      beneficiaryAmount += price - beneficiaryAmount - creatorAmount
        - cuts.reduce((acc, cur) => acc + cur.amount, 0);

      const purchase = starlyPack.methods.purchase(collectionId,
        packIds,
        userId,
        price.toString(),
        ethTokensMap.get(paymentCurrency.toUpperCase())?.address,
        {
          receiverAddress: beneficiaryAddress,
          amount: beneficiaryAmount.toString(),
        },
        {
          receiverAddress: creatorAddress,
          amount: creatorAmount.toString(),
        },
        cuts);

      const data = purchase.encodeABI();

      let gas: number = yield call(purchase.estimateGas, { from: userAddress });
      gas = +(gas + gas * 0.25).toFixed(0);

      yield call(wallet.sendTransaction, {
        to: process.env.REACT_APP_CONTRACT_ETH_STARLY_PACK,
        value: '0',
        gas,
        data,
      });

      yield delay(20000);

      yield put(buyPackResponse({ status: 'allowed' }));

      trackEvent('pack_purchase', {
        quantity: packIds.length,
        total: Number(price).toFixed(8).toString(),
        beneficiaryTotal: (beneficiaryCutPercent * price).toFixed(8).toString(),
        creatorTotal: (creatorCutPercent * price).toFixed(8).toString(),
        currency: paymentCurrency,
      });
    } catch (error) {
      console.error('er2', error);
      yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.purchaseFailed') }));
    }

    yield put(ethereumLogout());
    yield put(setPackPurchaseStatus({ status: 'initial' }));
  });
}

function* getTokenBalance(token: FungibleTokenType) {
  const userAddress: string = yield select((state) => state.ethereum.wallet.address);
  const { web3 } = wallet;

  const tokenContract = new web3.eth.Contract(
    ERC_20_ABI,
    ethTokensMap.get(token.toUpperCase())?.address,
  );

  const balance: number = yield call(
    tokenContract.methods.balanceOf(userAddress).call,
  );

  const decimals: number = yield call(tokenContract.methods.decimals().call);

  return {
    balance,
    decimals,
  };
}

function* updateTokenBalance(tokens: FungibleTokenType[]) {
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];
    const balance: number = yield call(getTokenBalance, token);
    yield put(ethereumBalanceResponse({
      token,
      balance,
    }));
  }
}

function* watchEthereumWalletUpdate() {
  yield takeEvery(ethereumWalletUpdate, function* takeEveryLogout(action) {
    try {
      const {
        payload: {
          address,
        },
      } = action;
      const userId: string = yield select((state) => state.auth.userId);

      yield put(updateEthWallet({
        id: userId,
        address,
      }));

      yield call(updateTokenBalance, Array.from(ethTokensMap.keys()) as FungibleTokenType[]);
    } catch (error) {
      console.error(error);
    }
  });
}

function onDisconnect(error: Error) {
  trackException(`Disconnected from RPC: ${error}`);
  store.dispatch(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.disconnect') }));
  store.dispatch(ethereumLogout());
}

function onAccountsChanged(accounts: string[]) {
  trackEvent('accounts_changed');

  if (accounts.length === 0) {
    store.dispatch(ethereumLogout());
    return;
  }
  store.dispatch(ethereumWalletUpdate({ address: accounts[0] }));
}

function onChainChanged() {
  trackException('Invalid ChainId');
  store.dispatch(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
  store.dispatch(ethereumLogout());
}

function* watchEthereumLoginRequest() {
  yield takeEvery(ethereumLoginRequest, function* takeEveryEthereumLoginRequest(action) {
    try {
      const {
        payload: {
          providerId,
        },
      } = action;
      const provider = yield call(wallet.connectTo, providerId);
      if (!provider) return;

      const web3: Web3 = new Web3(provider);
      const chainId: number = yield call(web3.eth.getChainId);

      if (chainId !== ethConfig.chain.id) {
        trackException('Invalid ChainId');
        yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
        yield put(ethereumLogout());
        return;
      }

      yield call(
        wallet.subscribeProvider, provider, onDisconnect, onAccountsChanged, onChainChanged,
      );
      const accounts: string[] = yield call(web3.eth.getAccounts);

      const address = accounts[0];

      yield put(ethereumWalletUpdate({ address }));

      yield put(ethereumLoginResponse({ address }));

      trackEvent('wallet_connect');
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(ethereumToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.loginFailed') }));
    }
  });
}

function* watchEthereumLogout() {
  yield takeEvery(ethereumLogout, function* takeEveryLogout() {
    try {
      if (wallet.web3?.currentProvider?.disconnect) {
        yield wallet.web3.currentProvider.disconnect();
      }
      wallet.unsubscribeProvider(wallet.provider, onDisconnect, onAccountsChanged, onChainChanged);
      yield wallet.clearCachedProvider();
      trackEvent('wallet_disconnect');
    } catch (err) {
      console.error(err);
    }
  });
}

export default function* flowSaga() {
  yield all([
    // fork(watchEthereumBalanceRequest),
    fork(watchEthereumBuyPackRequest),
    fork(watchEthereumLoginRequest),
    fork(watchEthereumLogout),
    fork(watchEthereumWalletUpdate),
  ]);
}
