import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RampInstantSDK, RampInstantEventTypes } from '@ramp-network/ramp-instant-sdk';
import { useTranslation } from 'react-i18next';
import { auth, remoteConfig } from 'global/firebase';
import {
  flowBalanceRequest,
} from 'store/flow/flowActions';
import {
  selectFlowWalletAddr,
} from 'store/flow/flowSelectors';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { useWindowSize } from 'hooks/useWindowSize';
import { desktopBreakpoint } from 'breakpoints';
import {
  IframeCloseIcon,
  IframeContainer,
  IframeFrame,
  IframePoweredBy,
  IframeTitle,
  IframeWrapper,
  ModalButton,
  ModalButtonContainer,
  ModalContainer,
  ModalText,
} from './styled';
import FullscreenPortal from '../../../components/FullscreenPortal/FullscreenPortal';
import { useOutsideClickHandle } from '../../../hooks/useOutsideClick';

import closeIcon from '../../../static/ic-close-black.svg';
import poweredBy from '../../../static/img-powered-by-moonpay@2x.png';
import { isMiBrowser } from '../../../helpers/isIos';
import { getMoonpayUrlRequest, setMoonpayUrl } from '../../../store/app/appActions';
import { selectMoonpayUrl } from '../../../store/app/appSelectors';
import { TopUpProps } from './types';

export const RAMP_API_KEY = process.env.REACT_APP_RAMP_API_KEY || '';
export const RAMP_WIDGET_URL = process.env.REACT_APP_RAMP_WIDGET_URL || 'https://buy.ramp.network/';

export const MOONPAY_API_KEY = process.env.REACT_APP_MOONPAY_API_KEY || '';
export const MOONPAY_SECRET_KEY = process.env.REACT_APP_MOONPAY_SECRET_KEY || '';
export const MOONPAY_URL = process.env.REACT_APP_MOONPAY_URL || 'https://buy.moonpay.com/';

const TopUpModal: FC<TopUpProps> = ({ currency, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isXiaomi = isMiBrowser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFrameLoaded, setIsFrameLoaded] = useState(isXiaomi);
  const addr = useSelector(selectFlowWalletAddr);
  const moonpayUrl = useSelector(selectMoonpayUrl);
  const ref = useRef<any>(null);
  const isMobile = useWindowSize() < desktopBreakpoint;

  useOutsideClickHandle(ref, useCallback(
    () => {
      if (isOpen && isFrameLoaded) setIsModalOpen(true);
    }, [isFrameLoaded, isOpen, isModalOpen],
  ));

  const onTopUp = () => {
    const topUpMode = remoteConfig.getValue('topUpMode').asString();
    if (topUpMode === 'moonpay' || currency.toLowerCase() === 'flow') {
      // TODO improve
      const topUpEmail = auth.currentUser && auth.currentUser.email;
      const url = `${MOONPAY_URL}?apiKey=${MOONPAY_API_KEY}&currencyCode=${currency}&walletAddress=${addr}${topUpEmail ? `&email=${encodeURIComponent(topUpEmail)}` : ''}`;
      dispatch(getMoonpayUrlRequest({ url }));
    } else {
      setIsOpen(!isOpen);
      const instance = new RampInstantSDK({
        userAddress: addr,
        hostAppName: 'Starly',
        hostLogoUrl: 'https://storage.googleapis.com/starly-prod.appspot.com/assets/starly-logo-776x184.png',
        fiatValue: '50',
        swapAsset: 'FLOW_FUSD',
        defaultAsset: 'FLOW_FUSD',
        hostApiKey: RAMP_API_KEY,
        url: RAMP_WIDGET_URL,
        variant: isMobile ? 'mobile' : 'desktop',
      });
      instance.show();
      instance.on(
        RampInstantEventTypes.WIDGET_CLOSE,
        () => {
          dispatch(flowBalanceRequest({}));
        },
      );
    }
  };

  useEffect(() => () => {
    dispatch(setMoonpayUrl({ url: '' }));
  }, []);
  useEffect(() => {
    onTopUp();
  }, [currency]);

  return (
    <FullscreenPortal>
      <IframeContainer>
        {moonpayUrl ? (
          <>
            <IframeFrame ref={ref} isLoaded={isFrameLoaded}>
              { isFrameLoaded && (
              <IframeCloseIcon
                src={closeIcon}
                onClick={() => setIsModalOpen(true)}
              />
              )}
              { isFrameLoaded && <IframeTitle>{t('wallet.topUp.title')}</IframeTitle> }
              <IframeWrapper isLoaded={isFrameLoaded}>
                <iframe
                  title="moonpay-frame"
                  allow="accelerometer; autoplay; camera; gyroscope; payment"
                  src={moonpayUrl}
                  frameBorder="0"
                  onLoad={() => setIsFrameLoaded(true)}
                  height="100%"
                  width="100%"
                >
                  <p>{t('wallet.topUp.iframeError')}</p>
                </iframe>
              </IframeWrapper>
              { isFrameLoaded && <IframePoweredBy src={poweredBy} />}
            </IframeFrame>
            { isModalOpen && (
              <ModalContainer>
                <ModalText>{t('wallet.topUp.exitConfirmation')}</ModalText>
                <ModalButtonContainer>
                  <ModalButton ref={ref} onClick={() => setIsModalOpen(false)}>{t('wallet.topUp.cancel')}</ModalButton>
                  <ModalButton onClick={() => {
                    dispatch(flowBalanceRequest({}));
                    setIsModalOpen(false);
                    setIsFrameLoaded(isXiaomi);
                    setIsOpen(false);
                  }}
                  >
                    {t('wallet.topUp.exit')}
                  </ModalButton>
                </ModalButtonContainer>
              </ModalContainer>
            )}
          </>
        ) : <NotificationsLoader isBlack transparent />}
      </IframeContainer>
    </FullscreenPortal>
  );
};

export default TopUpModal;
