// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import MetadataViews from 0xMETADATA_VIEWS_ADDRESS
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyTokenVesting from 0xSTARLY_TOKEN_VESTING_ADDRESS

pub fun main(address: Address): {String: AnyStruct} {
    if let vestingCollectionRef = getAccount(address).getCapability(StarlyTokenVesting.CollectionPublicPath)!
        .borrow<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>() {

        let ret: {UInt64: AnyStruct} = {}
        let type = Type<StarlyTokenVesting.VestingMetadataView>()
        let vestingIDs = vestingCollectionRef.getIDs();
        for vestingID in vestingIDs {
            let viewResolverRef = vestingCollectionRef.borrowViewResolver(id: vestingID)
            ret.insert(key: vestingID, viewResolverRef.resolveView(type)!)
        }
        return {
          "vestings": ret,
          "currentTime": getCurrentBlock().timestamp
        }
    }
    return {}
}`;

export function flowFetchStarlyTokenVestingsScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
    ]),
  ]).then(fcl.decode);
}
