import { Media } from '@starly/starly-types';
import React, { FC } from 'react';
import { Column } from '../Table';
import {
  RewardRow, RewardRowMedia,
  StyledColumnLink,
  RewardRowUserContainer, RewardRowUserTextContainer,
} from './styled';
import { RewardTableRowProps } from './types';
import { numberWithCommas } from '../../helpers/TextHelpers';
import { RouteTypes } from '../../RouteTypes';

const RewardTableRow: FC<RewardTableRowProps> = ({
  reward: {
    user_avatar,
    username,
    collector_score,
    amount,
    name,
  },
  place,
}) => (
  <RewardRow data-test-id="reward_table_row">
    <Column data-test-id="reward_table_number" textAlign="center" bold>
      {place}
    </Column>
    <Column data-test-id="reward_table_collection">
      <StyledColumnLink
        to={RouteTypes.User.replace(':username', username || '')}
        target="_blank"
      >
        <RewardRowUserContainer>
          <RewardRowMedia data-test-id="reward_table_collection_media" media={user_avatar as Media} />
          <RewardRowUserTextContainer data-test-id="reward_table_collection_text" data-name-attribute={name} data-username-attribute={username}>
            <p>{name}</p>
            <p>
              @
              {username}
            </p>
          </RewardRowUserTextContainer>
        </RewardRowUserContainer>
      </StyledColumnLink>
    </Column>
    <Column data-test-id="reward_table_score" data-score-attribute={collector_score} textAlign="center" bold>
      {numberWithCommas(collector_score, 0)}
    </Column>
    <Column data-test-id="reward_table_amount" data-amount-attribute={amount} textAlign="right" bold>
      {numberWithCommas(amount, 2)}
      {' '}
      $STARLY
    </Column>
  </RewardRow>
);

export default RewardTableRow;
