import React, { FC } from 'react';
import moment from 'moment';
import { MarketplaceExactLink, MarketplaceLink } from 'views/Marketplace/styled';
import { Column } from '../../../../components/Table';
import { MarketplaceSalesRowProps } from './types';
import { capitalize, numberWithCommas } from '../../../../helpers/TextHelpers';
import goTo from '../../../../static/ic-go-to.svg';
import {
  MarketplaceCardRow,
  MarketplaceSaleCardContainer,
  MediaCardContainer,
  MediaThumbnail,
  SaleTableTypography,
} from './styled';

const MarketplaceSaleRow: FC<MarketplaceSalesRowProps> = ({
  rarity, allEditions, time,
  edition, buyerUsername, sellerUsername,
  price, flowTransactionLink, card: {
    media, cardTitle, cardLink, collectionTitle, creatorName,
  },
}) => (
  <MarketplaceCardRow data-test-id="mp_card_row" isDark>
    <Column justify="left" data-test-id="mp_card_data">
      <MarketplaceLink to={cardLink} target="_blank">
        <MarketplaceSaleCardContainer>
          <MediaCardContainer>
            <MediaThumbnail media={media} />
          </MediaCardContainer>
          <SaleTableTypography data-test-id="data_card_title" uppercase bold>{cardTitle}</SaleTableTypography>
          <SaleTableTypography data-test-id="data_collection_title" uppercase>{collectionTitle}</SaleTableTypography>
          <SaleTableTypography data-test-id="data_creator_name">{creatorName}</SaleTableTypography>
        </MarketplaceSaleCardContainer>
      </MarketplaceLink>
    </Column>
    <Column data-test-id="mp_card_edition" data-edition-attribute={`${edition}`}>
      #
      {edition}
      /
      {allEditions}
    </Column>
    <Column data-test-id="mp_card_rarity">{capitalize(rarity).replaceAll('_', ' ')}</Column>
    <Column data-test-id="mp_card_buyer">
      <MarketplaceLink to={`/${buyerUsername}`} target="_blank">
        <SaleTableTypography>
          @
          {buyerUsername}
        </SaleTableTypography>
      </MarketplaceLink>
    </Column>
    <Column data-test-id="mp_card_seller">
      <MarketplaceLink to={`/${sellerUsername}`} target="_blank">
        <SaleTableTypography>
          @
          {sellerUsername}
        </SaleTableTypography>
      </MarketplaceLink>
    </Column>
    <Column data-test-id="mp_card_price" data-price-attribute={`${price}`} bold>
      $
      {numberWithCommas(price, 0)}
    </Column>
    <Column data-test-id="mp_card_time" data-date-attribute={`${moment(time).format('hh:mm A MMM D YYYY')}`} textAlign="center">
      {moment(time).format('hh:mm A')}
      <div />
      {moment(time).format('MMM D, YYYY')}
    </Column>
    <Column data-test-id="mp_transaction_link">
      <MarketplaceExactLink href={flowTransactionLink} target="_blank">
        <img src={goTo} alt="go to" />
      </MarketplaceExactLink>
    </Column>
  </MarketplaceCardRow>
);

export default MarketplaceSaleRow;
