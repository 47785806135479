import {
  all, call, fork, put, select, takeEvery,
} from 'redux-saga/effects';
import {
  Media, TopCollectorTokenRewardPayout,
  UserCollectorScore, UserTokenReward,
} from '@starly/starly-types';
import {
  claimRewardRequest,
  rankingCollectionsRequest,
  rankingCollectionsResponse, rankingCollectorsRequest,
  rankingCollectorsResponse, rewardConfigRequest, rewardConfigResponse, rewardSnapshotsRequest,
  rewardSnapshotsResponse, rewardToClaimRequest,
  rewardToClaimResponse, setClaimingStatus, setClaimRewardClaiming,
  setRankingCollectorsLoadState, setRankingCollectorsPage,
  setRankingLoadState,
  setRankingPage, setUserRankingLoadState,
  userRankingScoreRequest, userRankingScoreResponse,
} from './rankingActions';
import {
  DocumentSnapshot, firestore, functions, QuerySnapshot,
} from '../../global/firebase';
import { RankingCollectorsRowProps, RankingRowProps } from '../../views/Ranking/types';
import { RouteTypes } from '../../RouteTypes';

interface RankingFnData {
  collection_id: string,
  collection_title: string,
  collection_creator_name: string,
  collection_creator_username: string,
  sales: number,
  buyers: number,
  transactions: number,
  change: number | null,
  cards_in_circulation: number,
}

interface CollectionCache {
  _cached_collections_meta: {
    id: string,
    cover_media: Media
  }[]
}

const RANKING_REQUEST_LIMIT = 20;
const RANKING_COLLECTORS_REQUEST_LIMIT = 100;
const RANKING_COLLECTORS_FIRST_REQUEST_LIMIT = 300;
const LAST_RANKING_COLLECTORS_PAGE = 9;

function* watchRankingRequest() {
  yield takeEvery(rankingCollectionsRequest, function* rankingCollectionRequestWorker({
    payload: {
      days,
      page,
    },
  }) {
    try {
      yield put(setRankingLoadState({ isLoading: true }));
      const getRankings = functions.httpsCallable('getCollectionRanking');
      const { data: rankingData }: { data: RankingFnData[] } = yield call(() => getRankings({
        limit: RANKING_REQUEST_LIMIT,
        offset: page * RANKING_REQUEST_LIMIT,
        days,
      }));
      const collectionsMetaSnap: DocumentSnapshot = yield call(() => firestore
        .collection('cache')
        .doc('collectionsMeta')
        .get());
      const collectionMetaMap: { [key: string]: Media } = {};

      const collectionsMeta = collectionsMetaSnap.data() as CollectionCache;
      collectionsMeta._cached_collections_meta.forEach((
        { cover_media, id },
      ) => {
        collectionMetaMap[id || ''] = cover_media;
      });

      const result: RankingRowProps[] = [];
      rankingData.forEach(({
        collection_id, collection_title, collection_creator_name, collection_creator_username,
        cards_in_circulation, change, buyers, transactions,
        sales,
      }, i) => {
        const rowProps: RankingRowProps = {
          cardInCirculations: cards_in_circulation,
          collectionLink: RouteTypes.Collection
            .replace(':username', collection_creator_username)
            .replace(':collectionId', collection_id),
          collectionAuthor: collection_creator_name,
          collectionName: collection_title,
          media: collectionMetaMap[collection_id],
          rank: RANKING_REQUEST_LIMIT * page + i + 1,
          sales,
          transactions,
          buyers,
          change,
        };
        result.push(rowProps);
      });

      if (result.length < RANKING_REQUEST_LIMIT) {
        yield put(setRankingLoadState({ isMoreToLoad: false }));
      }

      yield put(setRankingPage({ page: page + 1 }));
      yield put(rankingCollectionsResponse({ rankings: result }));
      yield put(setRankingLoadState({ isLoad: true, isLoading: false }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchUserRankingRequest() {
  yield takeEvery(userRankingScoreRequest, function* userRankingScoreRequestWorker({
    payload: { id, isOwnProfile },
  }) {
    try {
      yield put(setUserRankingLoadState({ isLoading: true }));

      const userId: string = yield select((root) => root.auth.userId) || '';
      const userSore: DocumentSnapshot = yield call(() => firestore
        .collection('userCollectorScores')
        .doc(id || userId)
        .get());

      const docData = userSore.data() as UserCollectorScore | undefined;
      if (docData) {
        yield put(userRankingScoreResponse({
          ranking: {
            link: RouteTypes.User
              .replace(':username', docData.username || ''),
            collectorsScore: docData,
            isOwnProfile,
          },
          id: id || userId,
        }));
      }

      yield put(setUserRankingLoadState({ isLoading: false }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchRewardRankingRequest() {
  yield takeEvery(rewardSnapshotsRequest, function* rewardRequestWorker() {
    try {
      const rewardsSnap: QuerySnapshot = yield call(() => firestore
        .collection('tokenRewardPayouts')
        .orderBy('timestamp', 'desc')
        .get());

      const rewardsTuple: [Date, TopCollectorTokenRewardPayout][] = rewardsSnap.docs.map((doc) => {
        const tokenReward = doc.data() as TopCollectorTokenRewardPayout;
        return [tokenReward.timestamp.toDate(), tokenReward];
      });

      yield put(rewardSnapshotsResponse({ rewards: rewardsTuple }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchRewardConfigRequest() {
  yield takeEvery(rewardConfigRequest, function* rewardRequestWorker() {
    try {
      const rewardsSnap: DocumentSnapshot = yield call(() => firestore
        .doc('config/topCollectorRewardPayout')
        .get());

      const {
        collectorCount,
        totalTokenAmount,
      } = rewardsSnap.data() as { collectorCount: number, totalTokenAmount: number };

      yield put(rewardConfigResponse({
        collectorsCount: collectorCount,
        rewardPool: totalTokenAmount,
      }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchRewardToClaimRequest() {
  yield takeEvery(rewardToClaimRequest, function* rewardToClaimRequestWorker({
    payload: { userId },
  }) {
    try {
      const rewardsSnap: QuerySnapshot = yield call(() => firestore
        .collection(`userTokenRewards/${userId}/rewards`)
        .where('state', '==', 'new')
        .orderBy('timestamp', 'desc')
        .get());

      if (rewardsSnap.docs.length === 0) {
        return;
      }
      const reward = rewardsSnap.docs[0].data() as UserTokenReward;

      yield put(rewardToClaimResponse({
        reward: reward.amount,
      }));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchClaimRewardRequest() {
  yield takeEvery(claimRewardRequest, function* rewardToClaimRequestWorker() {
    try {
      const claimReward = functions.httpsCallable('payTokenReward');
      yield put(setClaimRewardClaiming({ isClaiming: true }));
      yield call(() => claimReward());
      yield put(setClaimRewardClaiming({ isClaiming: false }));
      yield put(setClaimingStatus({ status: 'success' }));
    } catch (e) {
      yield put(setClaimRewardClaiming({ isClaiming: false }));
      yield put(setClaimingStatus({ status: 'error' }));
      console.error(e);
    }
  });
}

function* watchRankingCollectorsRequest() {
  yield takeEvery(rankingCollectorsRequest, function* rankingCollectorsRequestWorker({
    payload: { pageLastEl, pageNumber },
  }) {
    try {
      yield put(setRankingCollectorsLoadState({ isLoading: true }));
      let query = firestore
        .collection('userCollectorScores')
        .orderBy('collector_score', 'desc')
        .limit(
          pageNumber !== 0
            ? RANKING_COLLECTORS_REQUEST_LIMIT
            : RANKING_COLLECTORS_FIRST_REQUEST_LIMIT,
        );

      if (pageLastEl) {
        query = query.startAfter(pageLastEl);
      }

      const collectorsSnap: QuerySnapshot = yield call(() => query.get());
      let newLastEl = null;
      const result = collectorsSnap.docs.map((doc, i) => {
        const score = doc.data() as UserCollectorScore;
        const props: RankingCollectorsRowProps = {
          collectorsScore: score,
          link: RouteTypes.User.replace(':username', score.username || ''),
          place: i + RANKING_COLLECTORS_REQUEST_LIMIT * pageNumber + 1,
        };
        props.collectorsScore.collector_score = props.collectorsScore.collector_score || 0;

        if (i === collectorsSnap.docs.length - 1) {
          newLastEl = doc;
        }

        return props;
      });

      let isMoreToLoad = true;
      if (
        pageNumber === LAST_RANKING_COLLECTORS_PAGE
        || result.length < RANKING_COLLECTORS_REQUEST_LIMIT
      ) {
        isMoreToLoad = false;
      }

      if (pageNumber === 0) {
        const userId: string = yield select((root) => root.auth.userId) || '';
        if (userId) {
          const userScore = result.find(({ collectorsScore: { user_id } }) => user_id === userId);
          if (userScore) {
            yield put(userRankingScoreResponse({
              ranking: userScore, id: userId, isOwnProfile: true,
            }));
          }
        }
      }

      yield put(rankingCollectorsResponse({ rankings: result }));
      yield put(setRankingCollectorsPage({ pageLastEl: newLastEl, pageNumber: pageNumber + 1 }));
      yield put(setRankingCollectorsLoadState({ isLoading: false, isLoad: true, isMoreToLoad }));
    } catch (e) {
      console.error(e);
    }
  });
}

export function* rankingSaga() {
  yield all([
    fork(watchRankingRequest),
    fork(watchUserRankingRequest),
    fork(watchRewardConfigRequest),
    fork(watchRankingCollectorsRequest),
    fork(watchRewardRankingRequest),
    fork(watchRewardToClaimRequest),
    fork(watchClaimRewardRequest),
  ]);
}
