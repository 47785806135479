import styled, { css } from 'styled-components';
import { mediaDesktop } from 'breakpoints';
import theme from 'components/theme';
import Button from 'components/Button';

export const PopUpOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.lightTransparentDark};
  touch-action: none;
`;

export const PopUpWrapper = styled.div<{ $padding?: string, $mobilePadding?: string, $width?: string }>`
  width: 100%;
  ${({ $mobilePadding }) => $mobilePadding && { padding: $mobilePadding } || { padding: '64px 48px' }};
  background-color: ${theme.colors.white};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 90vh;
  ${mediaDesktop} {
    width: 652px;
    ${({ $padding }) => $padding && { padding: $padding } || { padding: '64px 120px' }};
    ${({ $width }) => ($width ? css`width: ${$width}` : '')}
  }
`;

export const PopUpPortalWrapper = styled(PopUpWrapper)`
  max-height: 80vh;
  padding: 0;
`;

export const PopUpText = styled.p`
  text-transform: uppercase;
  font-family: ${theme.fonts.secondary}, sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.colors.black};
  margin-bottom: 64px;
  ${mediaDesktop} {
    margin-bottom: 50px;
    font-size: 30px;
  }
`;

export const PopUpTextMain = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 32px;
  ${mediaDesktop}{
    font-size: 30px;
  }
`;

export const PopUpClose = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
`;

export const PopUpGroupBtn = styled(Button)<{ $background?: string; $color?: string }>`
  width: 100%;
  margin-top: 20px;
  ${({ $background }) => $background && { backgroundColor: theme.colors[($background as TypographyColor) || 'inherit'] }};
  ${({ $color }) => $color && { color: $color }};
`;
