import React, { FC, useRef } from 'react';
import { CloseBtn, ContentPopUpWrapper, PopUpContainer } from './styled';
import { useOutsideClickHandle } from '../../hooks/useOutsideClick';
import { ContentPopUpProps } from './types';

import closeIcon from '../../static/ic-close.svg';

const ContentPopUp: FC<ContentPopUpProps> = ({ children, onClose, 'data-test-id': dataTestId }) => {
  const ref = useRef(null);
  useOutsideClickHandle(ref, onClose, 'mousedown');
  return (
    <ContentPopUpWrapper>
      <PopUpContainer data-test-id={dataTestId} ref={ref}>
        <CloseBtn data-test-id="close_icon" src={closeIcon} onClick={onClose} />
        {children}
      </PopUpContainer>
    </ContentPopUpWrapper>
  );
};

export default ContentPopUp;
