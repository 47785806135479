export const capitalize = (str: string) => str && str.split(' ').map((substr) => substr[0].toUpperCase() + substr.toLowerCase().substr(1)).join(' ');

export const numberWithCommas = (x: number, fr?: number): string => Number(x).toLocaleString('en-US', { minimumFractionDigits: fr ?? 2, maximumFractionDigits: fr ?? 2 });

export const numberForScore = (x: number, fr?: number): string => Number(x).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: fr ?? 2 });

export const formatToken = (num: string | number | null | undefined = 0) => Number(num).toLocaleString('en-US', { minimumFractionDigits: Number(num) % 1 !== 0 ? 0 : 2, maximumFractionDigits: 8 });

export const formatFirebaseError = (error: string): string => {
  const newError = error
    .replace(/ *\([^)]*\) */g, '')
    .replace('Firebase: ', '')
    .replace(/\.{2,}/g, '.');

  if (newError === 'Error') return 'Oops! Something went wrong!';

  return newError;
};
