import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
  delay,
} from 'redux-saga/effects';
import { RouteTypes } from 'RouteTypes';
import { push } from 'connected-react-router';
import {
  functions, firestorage, firestore, DocumentSnapshot, trackEvent, trackException,
} from 'global/firebase';
import { UserSchema } from 'types';
import {
  userRequest,
  userRequestByUsername,
  userResponse,
  userUpdateRequest,
  userUpdateResponse,
  writeAvatarImageRequest,
  writeAvatarImageResponse,
  updateAvatar,
  updateUsernameRequest,
  updateUsernameResponse,
  updateUsernameFailure,
  updateWalletRequest,
  updateWalletResponse,
  disconnectUserSocialRequest,
  disconnectUserSocialResponse,
  disconnectUserSocialFailure,
  sendFeedbackMessage,
  subscribe,
  userToggleLoading,
  packBattleClose,
} from './userActions';
import { getFileExtension, isSupportedWebMediaFormat } from '../../util/supportedMedia';

function waitForConversion(userId: string) {
  return new Promise((resolve) => {
    const unsubscribe = firestore.collection('users').doc(userId)
      .onSnapshot((snapshot) => {
        const snapData = snapshot.data();
        if (snapData && snapData.avatar.state === 'processed') {
          unsubscribe();
          resolve(snapData);
        }
      });
  });
}

function* watchUserRequest() {
  yield takeEvery(userRequest, function* takeEveryUserRequest(action) {
    try {
      const { payload: { id } } = action;
      const userRef: DocumentSnapshot = yield call(() => firestore.collection('users').doc(id).get());
      const user = userRef.data() || {} as UserSchema;

      if (!user.id) user.id = id;

      yield put(userResponse({ user }));

      if (user.avatar.state === 'unprocessed') {
        yield put(userToggleLoading(true));
        const { avatar }: UserSchema = yield call(waitForConversion, id);
        yield put(writeAvatarImageResponse({
          id,
          avatar,
        }));
        yield put(userToggleLoading(false));
      }
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchUserUpdateRequest() {
  yield takeEvery(userUpdateRequest, function* takeEveryUserUpdateRequest(action) {
    try {
      const user = action.payload;

      if (!user.notifications) {
        user.notifications = { allow_emails: true };
      }

      yield call(() => firestore.collection('users').doc(user.id).set(user, { merge: true }));

      yield put(userUpdateResponse({ user }));
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchWriteAvatarImageRequest() {
  yield takeEvery(writeAvatarImageRequest, function* takeEveryWriteAvatarImageRequest(action) {
    try {
      const {
        payload: {
          file,
          userId,
          avatarObj,
        },
      } = action;

      const extension = getFileExtension(file.name);
      const path = `users/${userId}/avatar${Date.now()}${extension}`;

      // TODO find way to get task snapshot type
      // @ts-ignore
      const uploadTaskSnapshot:any = yield call(() => firestorage.ref().child(path).put(file));

      let avatar = avatarObj;
      if (avatarObj.type === 'video' && !isSupportedWebMediaFormat(file)) {
        yield delay(2000);
        const user: UserSchema = yield call(waitForConversion, userId);
        avatar = user.avatar;
      } else {
        const downloadUrl: string = yield call(() => uploadTaskSnapshot.ref.getDownloadURL());

        let newSize;
        if (avatarObj.type === 'video') {
          newSize = {
            mp4: downloadUrl,
          };
        } else if (avatarObj.type === 'image') {
          newSize = {
            url: downloadUrl,
          };
        }
        avatar.sizes = [newSize];
      }

      yield put(writeAvatarImageResponse({
        id: userId,
        avatar,
      }));
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchUpdateAvatar() {
  yield takeEvery(updateAvatar, function* takeEveryUpdateAvatar(action) {
    try {
      const {
        payload: {
          avatarUrl,
          userId,
        },
      } = action;
      const avatar = {
        type: 'image',
        sizes: [{
          url: avatarUrl,
        }],
        state: 'processed',
      };

      if (avatar) {
        yield firestore.collection('users').doc(userId).set({ avatar }, { merge: true });
        yield put(writeAvatarImageResponse({ id: userId, avatar }));
      }
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchUserRequestByUsername() {
  yield takeEvery(userRequestByUsername, function* takeEveryUserRequestByUsername(action) {
    try {
      const { payload: { username } } = action;

      // @ts-ignore
      const snapshot:QuerySnapshot = yield call(() => firestore.collection('users')
        .where('username', '==', username.toLowerCase())
        .limit(1)
        .get());

      // TODO error handling? What will happen if username does not exist?
      const user = snapshot.docs[0].data() as UserSchema;

      yield put(userResponse({ user }));
    } catch (error) {
      console.error(error);
    }
  });
}

function* watchUpdateWallet() {
  yield takeEvery(updateWalletRequest, function* takeEveryUpdateWalletRequest(action) {
    const { payload: { id, address } } = action;

    try {
      yield put(updateWalletResponse({
        id, address,
      }));
    } catch (e) {
      console.error(e.message);
    }
  });
}

function* watchUpdateUsername() {
  yield takeEvery(updateUsernameRequest, function* takeEveryUpdateUsernameRequest(action) {
    const { payload: { id, username } } = action;

    try {
      const updateUsername = functions.httpsCallable('updateUsername');

      // @ts-ignore
      yield call(() => updateUsername({ username }));
      yield put(updateUsernameResponse({ id, username }));
    } catch (error) {
      console.error(error);
      yield put(updateUsernameFailure({ id, error: error.message }));
    }
  });
}

function* watchDisconnectSocial() {
  yield takeEvery(
    disconnectUserSocialRequest,
    function* disconnectSocialWorker({ payload: { social } }) {
      const userId: string = yield select(({ auth }) => auth.userId);
      const socialName = social.replace(social[0], social[0].toUpperCase());

      try {
        const deleteSocial = functions.httpsCallable(`delete${socialName}`);

        yield call(() => deleteSocial());
        yield put(disconnectUserSocialResponse({ id: userId, social }));
      } catch (e) {
        console.error(e);
        yield put(disconnectUserSocialFailure({ id: userId, social }));
      }
    },
  );
}

function* watchSendMessage() {
  yield takeEvery(sendFeedbackMessage, function* sendMessageWorker({ payload: { messageData } }) {
    try {
      yield call(() => firestore.collection('contactUs').add(messageData));
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchSubscribe() {
  yield takeEvery(subscribe, function* subscribeWorker({ payload: { email } }) {
    try {
      const addSubscriber = functions.httpsCallable('addSubscriber');

      yield call(() => addSubscriber({ email }));
      trackEvent('subscribe');
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchPackBattleClose() {
  yield takeEvery(packBattleClose, function* packBattleCloseWorker({ payload: { id, redirect } }) {
    try {
      const clearPackBattleWinner = functions.httpsCallable('clearPackBattleWinner');

      yield call(() => clearPackBattleWinner({ id }));

      trackEvent('close_pack_battle');

      yield put(userRequest({ id }));

      if (redirect) {
        yield put(push(RouteTypes.VestingPrize));
      }
    } catch (e) {
      console.error(e);
    }
  });
}

export default function* userSaga() {
  yield all([
    fork(watchUserRequestByUsername),
    fork(watchUserRequest),
    fork(watchUserUpdateRequest),
    fork(watchWriteAvatarImageRequest),
    fork(watchUpdateAvatar),
    fork(watchUpdateUsername),
    fork(watchUpdateWallet),
    fork(watchDisconnectSocial),
    fork(watchSendMessage),
    fork(watchSubscribe),
    fork(watchPackBattleClose),
  ]);
}
