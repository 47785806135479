import Typography from 'components/Typography';
import React, { FC } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteTypes } from 'RouteTypes';
import {
  InfoContainer, NotificationAvatar, Thumbnail, NotificationWrapper, ImgThumbnail,
} from './styled';
import { NotificationProps } from './types';

const getTime = (date: Date): string => {
  const thisMoment = moment();
  if (thisMoment.diff(date, 'seconds') < 60) return 'just now';
  const minutesAgo = thisMoment.diff(date, 'minutes');
  if (minutesAgo < 60) return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  const hoursAgo = thisMoment.diff(date, 'hours');
  if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  if (hoursAgo < 48) return 'yesterday';
  const yearsAgo = thisMoment.diff(date, 'years');
  if (yearsAgo < 1) return moment(date).format('MMMM D');
  return moment(date).format('MMMM D, YYYY');
};

const Notification: FC<NotificationProps> = ({
  userAvatar, notificationText, date, thumbnail,
  linkPath,
  secondaryText,
  username,
  callbackRef,
}) => {
  const dispatch = useDispatch();
  const userPageRedirect = () => dispatch(push(RouteTypes.User.replace(':username', username)));
  const linkPathRedirect = () => dispatch(push(linkPath));
  return (
    <NotificationWrapper ref={callbackRef}>
      <NotificationAvatar
        media={userAvatar}
        onClick={userPageRedirect}
      />
      <InfoContainer>
        <Typography size="title" bold onClick={linkPathRedirect}>
          {notificationText}
        </Typography>
        <Typography size="title">
          {secondaryText && <div>{secondaryText}</div>}
          {getTime(date)}
        </Typography>
      </InfoContainer>
      {
        typeof thumbnail !== 'string'
          ? (
            <Thumbnail
              media={thumbnail}
              onClick={linkPathRedirect}
            />
          ) : (
            <ImgThumbnail
              src={thumbnail}
              onClick={linkPathRedirect}
            />
          )
      }
    </NotificationWrapper>
  );
};

export default Notification;
