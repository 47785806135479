import {
  FC, MouseEvent, useState, useEffect,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FungibleTokenType } from '@starly/starly-types';
import PopUp from 'components/PopUp';
import { selectFUSDBalance, selectFlowBalance, selectStarlyBalance } from 'store/flow/flowSelectors';
import { selectEthereumWalletAddr, selectBalance } from 'store/ethereum/ethereumSelectors';
import { ethereumLogout } from 'store/ethereum/ethereumActions';

import wallet from 'helpers/ethereumWallet';
import { numberWithCommas } from 'helpers/TextHelpers';
import starlyLogo from 'static/wallet/ic-starly2.svg';
import flowLogo from 'static/wallet/ic-flow.svg';
import fusdLogo from 'static/wallet/ic-fusd.svg';
import closeIcon from 'static/ic-close.svg';
import backIcon from 'static//wallet/ic-back.svg';
import pointerIcon from 'static/wallet/pointer.svg';
import { ethTokensMap } from 'util/constants';
import * as S from './styled';
import TopUp from './TopUp';
import { SelectWalletModalProps } from './types';

const SelectWalletModal: FC<SelectWalletModalProps> = ({
  ethTokens, flowTokens = ['FUSD', 'FLOW'], closeWalletModal, onBuy, onBuyEth,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fusdBalance = useSelector(selectFUSDBalance);
  const flowBalance = useSelector(selectFlowBalance);
  const starlyBalance = useSelector(selectStarlyBalance);
  const ethereumAddress = useSelector(selectEthereumWalletAddr);
  const ethereumBalance = useSelector(selectBalance);
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);
  const [topUpCurrency, setTopUpCurrency] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);

  const onTopUp = (e: MouseEvent, currency: string) => {
    e.stopPropagation();
    e.preventDefault();
    setIsTopUpOpen(true);
    setTopUpCurrency(currency);
  };

  const flowCurrencies: { [key in FungibleTokenType]?: any } = {
    STARLY: {
      name: 'STARLY',
      balance: numberWithCommas(+starlyBalance),
      logo: starlyLogo,
      isTopUp: false,
    },
    FLOW: {
      name: 'FLOW',
      balance: (Number(+flowBalance) / 100000000).toFixed(2),
      logo: flowLogo,
      isTopUp: true,
    },
    FUSD: {
      name: 'FUSD',
      balance: numberWithCommas(+fusdBalance),
      logo: fusdLogo,
      isTopUp: true,
    },
  };

  return (
    <PopUp padding="64px 50px" data-test-id="wallet_popup">
      <S.ContentsContainer>
        <S.CloseBtn
          data-test-id="close_icon"
          src={closeIcon}
          onClick={() => {
            closeWalletModal();
            if (ethereumAddress) dispatch(ethereumLogout());
          }}
        />
        <S.TittleText size="header2" textColor="green" font="secondary">
          {t('collection.packs.choosePayment')}
        </S.TittleText>
        {!ethereumAddress
          ? (
            <S.SelectWalletButtonsContainer>
              {flowTokens.map((currency) => (
                <S.OptionContainer onClick={() => onBuy(flowCurrencies[currency].name)}>
                  <S.CurrencyContainer>
                    <S.CurrencySubContainer>
                      <S.ProviderLogo src={flowCurrencies[currency].logo} />
                      {flowCurrencies[currency].name}
                    </S.CurrencySubContainer>
                    <S.PointerIcon src={pointerIcon} />
                  </S.CurrencyContainer>
                  <S.BalanceContainer>
                    <S.BalanceRow>
                      <Trans i18nKey="wallet.purchaseModal.balance">
                        Balance:
                        <div>
                          {{ balance: flowCurrencies[currency].balance }}
                        </div>
                      </Trans>
                    </S.BalanceRow>
                    {flowCurrencies[currency].isTopUp && <S.BalanceTopup size="small" onClick={(e) => onTopUp(e, flowCurrencies[currency].name.toLowerCase())}>{t('wallet.balance.topUp')}</S.BalanceTopup>}
                  </S.BalanceContainer>
                </S.OptionContainer>
              ))}
              {ethTokens?.length > 0
                && wallet.getProvidersInfo().map((provider) => (
                  <S.OptionContainer
                    key={provider.id}
                    $expand
                    onClick={() => onBuyEth({ providerId: provider.id })}
                  >
                    <S.CurrencyContainer>
                      <S.CurrencySubContainer>
                        <S.ProviderLogo src={provider.logo} />
                        {provider.name}
                      </S.CurrencySubContainer>
                      <S.PointerIcon src={pointerIcon} />
                    </S.CurrencyContainer>
                    <S.WalletDescriptionContainer>
                      <Trans i18nKey="wallet.purchaseModal.ethTokenSupport">
                        Supported:
                        <S.SupportedTokens>{{ tokens: 'STARLY, USDT, USDC, BUSD' }}</S.SupportedTokens>
                        on Binance Smart Chain
                      </Trans>
                    </S.WalletDescriptionContainer>
                  </S.OptionContainer>
                ))}
              {isTopUpOpen
                && (
                  <TopUp
                    currency={topUpCurrency}
                    isOpen={isTopUpOpen}
                    setIsOpen={setIsTopUpOpen}
                  />
                )}
            </S.SelectWalletButtonsContainer>
          )
          : (
            <S.SelectEthButtonsContainer>
              <S.BackBtn data-test-id="close_icon" src={backIcon} onClick={() => dispatch(ethereumLogout())} />
              {ethTokens?.map((token: string) => ethTokensMap.has(token) && (
                <S.EthOptionContainer
                  key={token}
                  onClick={() => onBuyEth({ paymentCurrency: token })}
                >
                  <S.CurrencyContainer>
                    <S.CurrencySubContainer>
                      <S.ProviderLogo src={ethTokensMap.get(token.toUpperCase())?.icon} />
                      {token}
                    </S.CurrencySubContainer>
                    <S.PointerIcon src={pointerIcon} />
                  </S.CurrencyContainer>
                  <S.EthBalanceContainer>
                    <S.EthBalanceRow>
                      <Trans i18nKey="wallet.purchaseModal.balance">
                        Balance:
                        <b>
                          {{
                            balance: ethereumBalance[token]
                              ? (ethereumBalance[token].balance
                              / 10 ** ethereumBalance[token].decimals).toFixed(2)
                              : '0.00',
                          }}
                        </b>
                      </Trans>
                    </S.EthBalanceRow>
                    <S.EthPointerIcon src={pointerIcon} />
                  </S.EthBalanceContainer>
                </S.EthOptionContainer>
              ))}
            </S.SelectEthButtonsContainer>
          )}
      </S.ContentsContainer>
    </PopUp>
  );
};

export default SelectWalletModal;
