// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import { Address } from '@onflow/types';

const CODE = fcl.cdc`
import FungibleToken from 0xFUNGIBLE_TOKEN_ADDRESS
import FUSD from 0xFUSD_ADDRESS

pub fun main(address: Address): UFix64? {
    if let vault = getAccount(address).getCapability<&{FungibleToken.Balance}>(/public/fusdBalance).borrow() {
        return vault.balance
    }
    return nil
}`;

export function flowFetchFUSDBalanceScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, Address),
    ]),
  ]).then(fcl.decode);
}
