import React, {
  FC, CSSProperties,
} from 'react';
import styled from 'styled-components';

import loaderSvg from '../../static/starly-spinner.svg';
import { mediaDesktop } from '../../breakpoints';

type LoaderProps = {
  style?: CSSProperties,
};

const LoaderContainer = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  & > object {
    width: 60px;
    ${mediaDesktop} {
      width: 120px;
    }
  }
`;

const Loader: FC<LoaderProps> = ({ style = {} }) => (
  <LoaderContainer data-test-id="loading" style={style}>
    <object type="image/svg+xml" data={loaderSvg} aria-label="loading" />
  </LoaderContainer>
);

export default Loader;
