import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Notification from '../../../Notification';
import Typography from '../../../Typography';
import {
  notificationsLastSeen,
  notificationsRequest, notificationsSetIsOpen,
} from '../../../../store/notifications/notificationsActions';
import { selectNotificationsPaginationInfo, selectNotifications } from '../../../../store/notifications/notificationsSelectors';
import NotificationsLoader from '../../../NotificationsLoader/NotificationsLoader';
import { NotificationContainer, NotificationDropdownContainer, StyledNotification } from './styled';

const NotificationMenu : FC = () => {
  const observer = useRef<IntersectionObserver>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notifications = useSelector(selectNotifications);
  const {
    isLoading, hasMoreToLoad, page, isLoad,
  } = useSelector(selectNotificationsPaginationInfo);
  const lastNotificationRef = useCallback((node) => {
    if (isLoading || !node) return;
    if (observer.current) observer.current?.disconnect();
    if (!hasMoreToLoad) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) dispatch(notificationsRequest({ pageNumber: page }));
    });
    observer.current?.observe(node);
  }, [isLoading, hasMoreToLoad]);

  useEffect(() => {
    if (isLoad || isLoading) return;
    dispatch(notificationsRequest({ pageNumber: page }));
  }, []);

  useEffect(() => {
    dispatch(notificationsSetIsOpen({ isOpen: true }));
    dispatch(notificationsLastSeen());
    return () => {
      dispatch(notificationsSetIsOpen({ isOpen: false }));
    };
  }, []);

  return (
    <NotificationDropdownContainer>
      <NotificationContainer>
        {notifications.map((props, i) => (
          <StyledNotification
            ref={i === notifications.length - 1 ? lastNotificationRef : null}
            key={`${props.date}:${i}`}
          >
            <Notification
              {...props}
            />
          </StyledNotification>
        ))}
        {notifications.length === 0
          && !isLoading && isLoad
          && <Typography data-test-id="notification_window" align="center" textColor="textWhite" bold>{t('notifications.noNotifications')}</Typography>}
        {isLoading && <NotificationsLoader />}
      </NotificationContainer>
    </NotificationDropdownContainer>
  );
};

export default NotificationMenu;
