import {
  all, call, fork, put, delay, race, select, takeEvery,
} from 'redux-saga/effects';
import {
  firestore, functions, DocumentSnapshot, QuerySnapshot, trackException,
} from 'global/firebase';
import { push } from 'connected-react-router';
import {
  CardEdition, Collection, Pack, User,
} from '@starly/starly-types';
import { RouteTypes } from 'RouteTypes';
import {
  initPackRequest,
  initPackResponse,
  initUserPacksRequest,
  buyPackRequest,
  buyPackResponse,
  initUserPacksResponse,
  openPackRequest,
  initOpenPackPageRequest,
  initOpenPackPageResponse,
  openPackResponse,
  userPacksCountRequest,
  userPacksCountResponse,
  setPackPurchaseStatus,
  setPackPageLoading,
  openPackFailure, packBattleLeaderRequest, packBattleLeaderResponse,
} from './packActions';

import {
  flowBuyPackRequest,
} from '../flow/flowActions';
import { ethereumBuyPackRequest } from '../ethereum/ethereumActions';
import { BENEFICIARY_ADDRESS, BENEFICIARY_PACK_CUT_PERCENT } from '../../global/constants';
import { chunkArray } from '../../util/chunkArray';

function* watchInitPackRequest() {
  yield takeEvery(initPackRequest,
    function* takeEveryInitPack({ payload: { collectionId, rarity } }) {
      try {
        const collectionRef: DocumentSnapshot = yield call(() => firestore.collection('collections').doc(collectionId).get());
        const collection = collectionRef.data() as Collection;
        const { pricing } = collection.pack_information;
        let price = pricing[rarity] ?? 0;
        if (pricing.preferred_token === 'FLOW' && pricing.options?.FLOW) {
          price = pricing.options.FLOW[rarity] ?? 0;
        }
        yield put(initPackResponse({
          collectionTitle: collection.title,
          authorName: collection.creator.name,
          authorUsername: collection.creator.username,
          avatar: collection.creator.avatar ?? '',
          collectionId: collection.id ?? '',
          packDistribution: collection.pack_information.distribution,
          packPricing: collection.pack_information.pricing,
          price,
          token: pricing.preferred_token ?? 'FUSD',
        }));
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchBuyPackRequest() {
  yield takeEvery(buyPackRequest,
    function* takeBuyPack({
      payload: {
        collectionId, count, rarity, price, token, paymentCurrency, chain,
      },
    }) {
      yield put(setPackPurchaseStatus({ status: 'purchasing' }));
      const reservePack = functions.httpsCallable('reservePack');
      try {
        const collectionSnap: DocumentSnapshot = yield call(() => firestore
          .collection('collections')
          .doc(collectionId)
          .get());
        const collection: Collection = collectionSnap.data() as Collection;
        const { data } = yield call(() => reservePack({ collectionId, count, rarity }));
        if (chain === 'bsc') {
          yield put(ethereumBuyPackRequest({
            collectionId,
            packIds: data,
            price: count * price,
            currency: token,
            paymentCurrency,
            beneficiaryAddress:
              collection.pack_information.pricing.sale_cuts?.beneficiary.bsc_address
                || BENEFICIARY_ADDRESS,
            beneficiaryCutPercent:
              collection.pack_information.pricing.sale_cuts?.beneficiary.percent
              || BENEFICIARY_PACK_CUT_PERCENT,
            creatorAddress:
              collection.pack_information.pricing.sale_cuts?.creator.bsc_address as string,
            creatorCutPercent: collection.pack_information.pricing.sale_cuts?.creator.percent
              || (1.0 - BENEFICIARY_PACK_CUT_PERCENT),
            additionalCuts: collection.pack_information.pricing.sale_cuts?.additional || [],
          }));
        } else {
          yield put(flowBuyPackRequest({
            collectionId,
            packIds: data,
            price: count * price,
            currency: token,
            paymentCurrency,
            beneficiaryAddress: collection.pack_information.pricing.sale_cuts?.beneficiary.address
              || BENEFICIARY_ADDRESS,
            beneficiaryCutPercent:
              collection.pack_information.pricing.sale_cuts?.beneficiary.percent
              || BENEFICIARY_PACK_CUT_PERCENT,
            creatorAddress: collection.pack_information.pricing.sale_cuts?.creator.address as string
              || collection.creator.flow_account as string,
            creatorCutPercent: collection.pack_information.pricing.sale_cuts?.creator.percent
              || (1.0 - BENEFICIARY_PACK_CUT_PERCENT),
            additionalCuts: collection.pack_information.pricing.sale_cuts?.additional || [],
          }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(setPackPurchaseStatus({ status: 'initial' }));
        yield put(buyPackResponse({ status: 'error' }));
      }
    });
}

function* watchInitPacksRequest() {
  yield takeEvery(initUserPacksRequest,
    function* takeInitPacks({ payload: { userId } }) {
      yield put(setPackPageLoading({ isLoading: true }));
      try {
        let currPacks: any[] = [];
        const packs: QuerySnapshot = yield call(
          () => firestore
            .collectionGroup('packs')
            .where('owner.id', '==', userId)
            .where('state', '==', 'purchased')
            .orderBy('purchase_date', 'desc')
            .get(),
        );

        // eslint-disable-next-line
        for (const pack of packs.docs) {
          const data = pack.data() as { rarity: string, type: string, cards: string[] };
          const collectionId = pack.ref.path.split('/')[1];

          currPacks.push({
            id: pack.id,
            rarity: data.rarity,
            type: data.type,
            _cards: data.cards,
            collectionId,
          });
        }

        const arrColId = currPacks.map((pack: any) => pack.collectionId);
        const arrColIdSet = Array.from(new Set(arrColId));
        let collectionsQuery: QuerySnapshot;
        const docs = [];

        if (arrColIdSet.length > 0) {
          const chunks = chunkArray(arrColIdSet, 10);
          for (let i = 0; i < chunks.length; i += 1) {
            const chunk = chunks[i];
            collectionsQuery = yield call(() => firestore.collectionGroup('collections')
              .where('state', '==', 'created')
              .where('id', 'in', chunk)
              .get());
            docs.push(...collectionsQuery.docs);
          }

          // eslint-disable-next-line
          for (const collection of docs) {
            const {
              creator,
              title,
              id,
              pack_information: { pricing, distribution },
              card_information: cardInfo,
            } = collection.data();

            currPacks = currPacks.map((pack: any) => {
              let cardObj;
              if (pack.type === 'reward') {
                let common = 0;
                let rare = 0;
                let legendary = 0;

                const cardCount = cardInfo.distribution.common.distinct_cards
                  + cardInfo.distribution.rare.distinct_cards
                  + cardInfo.distribution.legendary.distinct_cards;

                pack._cards.forEach((card) => {
                  if (card.startsWith(cardCount + 1)) legendary += 1;
                  if (card.startsWith(cardCount + 2)) rare += 1;
                  if (card.startsWith(cardCount + 3)) common += 1;
                });

                cardObj = {
                  cards: {
                    common, rare, legendary,
                  },
                };
              }

              let price = pricing[pack.rarity];
              const currency = pricing.preferred_token;
              if (currency === 'FLOW') {
                price = pricing.options.FLOW[pack.rarity];
              }
              return ({
                ...pack,
                ...cardObj,
                ...(pack.collectionId === id && {
                  creatorName: creator.name,
                  creatorUsername: creator.username,
                  title,
                  price,
                  packDistribution: distribution,
                  currency,
                  avatar: creator.avatar,
                }),
              });
            });
          }
        }

        yield put(initUserPacksResponse({ data: currPacks }));
      } catch (error: any) {
        trackException(error.message);
      }
      yield put(setPackPageLoading({ isLoading: false }));
    });
}

function* watchInitOpenPackPageRequest() {
  yield takeEvery(initOpenPackPageRequest,
    function* takeInitOpenPackPage({ payload: { collectionId, packId } }) {
      try {
        const collectionRef: DocumentSnapshot = yield call(() => firestore.collection('collections').doc(collectionId).get());
        const packRef: DocumentSnapshot = yield call(() => firestore.collection(`collections/${collectionId}/packs`).doc(packId).get());
        const collection = collectionRef.data() as Collection;
        const pack = packRef.data() as Pack;
        if (pack.state === 'opened') yield put(initOpenPackPageResponse({ error: true }));

        const cachedCardsData = collection._cached_cards?.map(({
          title,
          media,
          description,
          order,
          qrcode,
          editions,
          rarity,
        }) => ({
          title,
          media,
          description,
          order,
          qrcode,
          editions,
          rarity,
        }));
        const cardsToOpen = [];
        for (let i = 0; i < pack.cards.length; i += 1) {
          const card = pack.cards[i];
          const cardId = +card.split('/')[0];
          const edition = +card.split('/')[1];
          const cardEdition: DocumentSnapshot = yield call(() => firestore
            .doc(`collections/${collectionId}/cards/${cardId}/editions/${edition}`)
            .get());
          const cardEditionData = cardEdition.data() as CardEdition;
          const data = cachedCardsData?.find((cachedCard: any) => (
            cachedCard.order === cardId
          ));
          cardsToOpen.push({
            id: cardId,
            data: {
              ...data,
              score: cardEditionData.collector_score,
              edition,
            },
            isActive: false,
            collectionTitle: collection.title,
            creator: {
              name: collection.creator.name,
              avatar: collection.creator.avatar,
            },
            rarity: data?.rarity,
          });
        }

        yield put(initOpenPackPageResponse({ cardsToOpen }));
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* waitForConfirmation(collectionId: string, packId: string) {
  const promise = new Promise((resolve) => {
    firestore.collection('collections').doc(collectionId).collection('packs').doc(packId)
      .onSnapshot((snapshot) => {
        const snapData = snapshot.data();
        if (snapData && snapData.state === 'opened' && snapData.flow_transaction_id) {
          resolve(snapData);
        }
      });
  });

  yield race({
    result: promise,
    delay: delay(15000),
  });
}

function* watchPackBattleLeaderReuest() {
  yield takeEvery(packBattleLeaderRequest,
    function* packBattleRequestWorker({ payload: { collectionId } }) {
      const getHighestScorePack = functions.httpsCallable('getHighestScorePack');
      const { data }: { data: {
        user: User, score: number, packBattleEnded: boolean
      }[] } = yield call(
        () => getHighestScorePack({ collectionId }),
      );
      yield put(packBattleLeaderResponse({ data, collectionId }));
    });
}

function* watchOpenPackRequest() {
  yield takeEvery(openPackRequest,
    function* takeOpenPack({ payload: { collectionId, packId } }) {
      try {
        const openPack = functions.httpsCallable('openPack');

        yield call(() => openPack({ collectionId, packId }));

        yield call(waitForConfirmation, collectionId, packId);
        yield put(openPackResponse());
      } catch (error: any) {
        trackException(error.message);
        yield put(openPackFailure());
        yield put(push(RouteTypes.Packs));
      }
      yield put(userPacksCountRequest());
    });
}

function* watchPacksCountRequest() {
  yield takeEvery(userPacksCountRequest, function* activePackRequestWorker() {
    const userId: string = yield select(({ auth }) => auth.userId);
    try {
      const packs: QuerySnapshot = yield call(() => firestore.collectionGroup('packs')
        .where('owner.id', '==', userId)
        .where('state', '==', 'purchased')
        .get());
      yield put(userPacksCountResponse({ count: packs.docs.length }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

export default function* packSaga() {
  yield all([
    fork(watchInitPackRequest),
    fork(watchBuyPackRequest),
    fork(watchInitPacksRequest),
    fork(watchOpenPackRequest),
    fork(watchPackBattleLeaderReuest),
    fork(watchInitOpenPackPageRequest),
    fork(watchPacksCountRequest),
  ]);
}
