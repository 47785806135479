import React from 'react';
import { DiscordFloatingLogo } from 'views/FlowFestView/components/DiscordFloatingLogo';
import * as S from './styled';
import { useWindowSize } from '../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../breakpoints';
import Step1Img from '../../static/how-to-play/img-1-open-nft-pack@2x.png';
import Step2Img from '../../static/how-to-play/img-2-know-your-cards@2x.png';
import Step3Img from '../../static/how-to-play/img-3-marketplace@2x.png';
import Step4Img from '../../static/how-to-play/ic-4-rewards@2x.png';
import Step5Img from '../../static/how-to-play/img-5-stake-nfts@2x.png';
import Step6Img from '../../static/how-to-play/img-6-earn@2x.png';

const howToPlayImages = [
  Step1Img,
  Step2Img,
  Step3Img,
  Step4Img,
  Step5Img,
  Step6Img,
];

const howToPlayData = [
  {
    title: 'OPEN NFT PACK',
    description: `
        <p> 
            The best way to start your journey on Starly is by opening an NFT pack. NFT packs become available when a new drop goes live.
        </p>
        <p> 
            Each drop consists of unique art pieces in a form of collectible NFT cards with different rarity classes, serial numbers and card scores. These cards are distributed in Mystery packs. When you buy a pack you don’t know what cards you’ll get exactly.
        </p>
        <p> 
            Each pack participates in the Pack Battle for a grand prize of 10,000 $STARLY.
        </p>
        <p> 
            You could be the lucky one - open a pack to find out.
        </p>
    `,
    mobileDescription: `
      Exclusive access for Platinum members only for the first 30 minutes of the sale. First 31-60 minutes exclusive for Platinum and Gold members.
    `,
  },
  {
    title: 'KNOW YOUR CARDS',
    description: `
      <p>
        Each NFT card has a card score that’s derived from its serial number and rarity class (Common / Common Plus / Rare / Rare Plus / Legendary / Legendary Plus).
      </p>
      <p style="font-weight: bold;"> 
        Card score is important:
      </p>
      <p></p>  
    1. It is equal to the initial amount of $STARLY tokens that are locked inside of it as an NFT staking reward.
    <br> 
    2. $STARLY tokens within NFT are unlocked gradually via NFT staking with a daily rate: card-score/365.
    <br> 
    3. The card score summary of all NFTs in your inventory makes up your collector’s score.
    `,
    mobileDescription: `
      Each NFT card has a card score that’s derrived from it’s serial number and rarity class (Common / Common Plus / Rare / Rare Plus / Legendary / Legendary Plus).
      Card score is important:
      <br>
      1. Card score is equal to the initial amount of $STARLY tokens that are locked inside of it as an NFT staking reward.
      <br>
      2. $STARLY tokens within NFT are unlocked lineary via NFT staking with a daily rate: card-score/365.
      <br>
      3. The card score summary of all NFTs in your inventory makes up your collector’s score.
    `,
  },
  {
    title: 'BUY / SELL NFT CARDS ON MARKETPLACE',
    description: `
      <p>
        If you missed the drop and there are no packs available at the moment - you can get NFTs sold by other users on the secondary marketplace.
      </p>
      <p>
        This is a place where you too can flip the NFTs you got, discover special deals, and hunt the NFTs you need to complete a collection.
      </p>
    `,
    mobileDescription: `
      If you missed the drop and there are no packs available at the moment - you can get NFTs sold by other users on secondary marketplace.
      <br>
      This is a place where you too can flip the NFTs you got, discover special deals, and hunt the NFTs you’re missing to complete a collection.
    `,
  },
  {
    title: 'COMPLETE CARD SETS & GET REWARDS',
    description: `
    <p>
        Each collection consists of 21 unique NFT cards: 4 Legendary, 6 Rare and 11 Common cards. Collectors compete to collect whole sets of Common, Rare and/or Legendary cards in the first 30 days of the drop to be rewarded with extra special Plus cards.
    </p> 
    <p>
        Common Plus, Rare Plus and Legendary Plus cards are more valuable NFTs with higher card scores, that are minted only in the amount of sets actually completed by collectors.
    </p>
    <p>
        Plus cards are randomly distributed in sealed Reward packs. You never know what serial you will get so better cross your fingers. Lowest serial numbers might give you a huge score and a lot of $STARLY tokens.
    </p>
    `,
    mobileDescription: `
    Each collection consists of 21 unique NFT cards: 4 Legendary, 6 Rare and 11 Common cards. Collectors compete to collect whole sets of Common, Rare and/or Legendary cards in the first 30 days of the drop to be rewarded with extra special Plus cards.
    <br>
    Common Plus, Rare Plus and Legendary Plus cards are more valuable NFTs with higher card scores, that are minted only in the ammount of sets actually completed by collectors.
    <br>
    Plus cards are randomly distributed in sealed Reward packs. You never know what serial you might get so better cross your fingers. Lowest serial numbers might give you a huge score and a lot of $STARLY tokens.
    `,
  },
  {
    title: 'STAKE YOUR NFTs',
    description: `
    <p>
        Each card has a finite amount of $STARLY tokens locked inside of it. The intial amount of tokens the card contains is equal to its card score.
    </p>
    <p>
        You can stake the NFTs you own to gradually unlock the tokens they hold inside as an NFT staking reward.
    </p>
    <p>
        To claim NFT staking reward you have to stake $STARLY tokens as well. Your daily claim limit for NFT staking reward is 1/100 of your total staked $STARLY tokens amount.
    </p>
    `,
    mobileDescription: `
      Each card has a finite amount of $STARLY tokens locked inside of it. The intial amount of tokens the card contains is equal to its card score.
      <br> 
      You can stake the NFTs you own to gradually unlock the tokens they hold inside as an NFT staking reward.
      <br>  
      To claim NFT staking reward you have to stake $STARLY tokens as well. Your daily claim limit for NFT staking reward is 1/100 of your total staked $STARLY tokens amount.
    `,
  },
  {
    title: 'EARN',
    description: `
    <p>
      Starly builds social economies around gamified NFT collections with many opportunities for collectors to earn:
    </p>
    <p>
        You can sell your NFTs on the secondary Marketplace, hold on to them for their artistic value, grow your collector score and get Top Collector rewards or set up passive income by staking your NFT inventory.
    </p>
    <p> 
        You can use your $STARLY tokens to participate in new drops, get missing NFTs, stake them to increase your NFT reward claim rate & earn 15% APY or simply swap them to $USDC any time you want.
    </p>
    `,
    mobileDescription: `
    Starly builds social economies around gamified NFT collections with many opportunities for collectors to earn:
    <br> 
    You can sell your NFTs on the secondary Marketplace, hold on to them for their artistic value, grow your collector score and get Top Collector rewards or set up passive income by staking your NFT inventory.
    <br>
    You can use your $STARLY tokens to participate in new drops, get missing NFTs, stake them to increase your NFT reward claim rate & earn 15% APY or simply swap them to $USDC any time you want.
    `,
  },
];

const HowToPlay = () => {
  const isMobile = useWindowSize() < desktopBreakpoint;
  return (
    <S.HowToPlayContainer>
      <S.HowToPlayHeading data-test-id="htp_heading">How to play</S.HowToPlayHeading>
      <DiscordFloatingLogo data-test-id="discord_logo" hideOnMobile />
      <S.HowToPlayFlexContainer>
        {
          !isMobile && (
            <S.HowToPlayImagesContainer>
              {
                howToPlayImages.map((img, i) => (
                  <S.HoToPlayImage data-test-id="htp_image" src={img} alt={`img-${i}`} />
                ))
              }
            </S.HowToPlayImagesContainer>
          )
        }
        <S.HowToPlayStepsContainer>
          <S.RoadLine />
          {
            howToPlayData.map(({ title, description }, i) => (
              <S.HowToPlayStepContainer
                key={title + i}
              >
                {isMobile && <S.HowToPlayStepImageContainer bgImage={howToPlayImages[i]} />}
                <S.NumberContainer data-test-id="htp_number_container">{i + 1}</S.NumberContainer>
                <S.StepTitle data-test-id="htp_step_title">{title}</S.StepTitle>
                <S.StepDescription
                  data-test-id="htp_step_description"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </S.HowToPlayStepContainer>
            ))
          }
        </S.HowToPlayStepsContainer>
      </S.HowToPlayFlexContainer>
    </S.HowToPlayContainer>
  );
};

export default HowToPlay;
