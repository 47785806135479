import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  coinMarketCapRequest, coinMarketCapResponse, getMoonpayUrlRequest, setMoonpayUrl,
} from './appActions';
import { functions } from '../../global/firebase';

function* watchGetMoonpayUrl() {
  yield takeEvery(getMoonpayUrlRequest, function* getMoonpayUrlRequestWorker({ payload: { url } }) {
    const mpUrl: { data: { url: string } } = yield call(() => functions.httpsCallable('signMoonpayUrl')({ url }));
    yield put(setMoonpayUrl({ url: mpUrl.data.url }));
  });
}

function* watchCoinMarketCapRequest() {
  yield takeEvery(coinMarketCapRequest, function* coinMarketCapRequestWorker() {
    const getStarlyTokenRate = functions.httpsCallable('getStarlyTokenRateFromCoinMarketCap');
    const { data }: any = yield call(() => getStarlyTokenRate());
    yield put(coinMarketCapResponse(data as any));
  });
}

export default function* appSaga() {
  yield all([
    fork(watchGetMoonpayUrl),
    fork(watchCoinMarketCapRequest),
  ]);
}
