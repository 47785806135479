import styled from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../theme';
import Button from '../Button';

export const KucoinBuyLabelContainer = styled.a`
  text-decoration: none;
  background-image: linear-gradient(to bottom, #00b290, #1dccab 75%);
  ${mediaDesktop} {
    background-image: linear-gradient(to right, #00b290, #1dccab);
  }
`;

export const KucoinLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  align-items: center;
  ${mediaDesktop} {
    padding: 16px 0;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1100px;
    margin: auto;
  }
`;

export const KucoinText = styled.p`
  font-family: ${theme.fonts.primary};
  text-align: center;
  font-weight: bold;
  letter-spacing: .5px;
  font-size: 16px;
  color: #fff;
  margin-bottom:12px;
  ${mediaDesktop} {
    text-align: left;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-size: 21px;
  }
`;

export const KucoinBtnText = styled.span`
  color: #00b290;
  text-decoration: double;
`;

export const KucoinButton = styled(Button)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-around;
  height: 28px;
  width: 152px;
  ${mediaDesktop} {
    justify-content: space-between;
    width: 217px;
    height: 40px;
  }
`;

export const KucoinIcon = styled.img`
  height: 14px;
  ${mediaDesktop} {
    height: auto;
  }
`;
