import { createAction } from '@reduxjs/toolkit';

interface SetPrevPathnamePayload {
  pathname: string,
  prev: boolean,
}

interface MoonpayUrlPayload {
  url: string
}

interface CoinMarketCapResponsePayload {
  timestamp: number;
  price: number;
  priceChangePercent: number;
  marketCap: number;
  marketCapChangePercent: number;
}

export const toggleHeader = createAction<boolean>('app/TOGGLE_HEADER');

export const toggleFooter = createAction<boolean>('app/TOGGLE_FOOTER');

export const setActiveCard = createAction<string | null>('app/SET_ACTIVE_CARD');

export const setPrevPathname = createAction<SetPrevPathnamePayload>('app/SET_PREV_PATHNAME');

export const getMoonpayUrlRequest = createAction<MoonpayUrlPayload>('app/GET_MOONPAY_URL_REQUEST');

export const setMoonpayUrl = createAction<MoonpayUrlPayload>('app/GET_MOONPAY_URL_RESPONSE');

export const coinMarketCapRequest = createAction('app/coinMarketCapRequest');

export const coinMarketCapResponse = createAction<CoinMarketCapResponsePayload>('app/coinMarketCapResponse');
