import { createSelector } from '@reduxjs/toolkit';

// TODO replace with root state
const selectFlow = ({ flow }: any) => flow;

// TODO remove when possible
const selectState = (state: any) => state;

export const selectFlowWallet = createSelector(selectFlow, ({ wallet }) => wallet);

// Hack for KYC
// export const selectFlowWalletAddr = createSelector(selectFlow, ({ wallet }) => wallet?.addr);
export const selectFlowWalletAddr = createSelector(selectState, ({ auth, flow, user }) => {
  const id = auth?.userId;
  const addr = flow?.wallet?.addr;
  const current = user?.items?.[id];

  if (current && current.flow_account && addr) {
    return current.flow_account;
  }

  return undefined;
});

export const selectFlowWalletCid = createSelector(selectFlow, ({ wallet }) => wallet?.cid);

// eslint-disable-next-line max-len
export const selectFlowWalletServices = createSelector(selectFlow, ({ wallet }) => wallet?.services);

export const selectFlowBalance = createSelector(selectFlow, ({ flowBalance }) => flowBalance);

export const selectFUSDBalance = createSelector(selectFlow, ({ fusdBalance }) => fusdBalance);

export const selectStarlyBalance = createSelector(selectFlow, ({ starlyBalance }) => starlyBalance);

export const selectWrongModal = createSelector(selectFlow,
  ({ isWrongModalOpen }) => isWrongModalOpen);

export const selectFlowModal = createSelector(selectFlow,
  ({ isWalletModalOpen }) => isWalletModalOpen);

export const selectLowBalanceModal = createSelector(selectFlow,
  ({ isLowBalanceModalOpen }) => isLowBalanceModalOpen);

export const selectLowBalanceFlowModal = createSelector(selectFlow,
  ({ isLowBalanceFlow }) => isLowBalanceFlow);

export const selectFlowIsLoading = createSelector(selectFlow,
  ({ isLoading }) => isLoading);

export const selectBadNFTIds = createSelector(selectFlow,
  ({ badNFTIds }) => badNFTIds);

export const selectFlowFestModal = createSelector(selectFlow,
  ({ isFlowFestWalletModalOpen }) => isFlowFestWalletModalOpen);

export const selectFlowNFTNYCModal = createSelector(selectFlow,
  ({ isFlowNFTNYCWalletModalOpen }) => isFlowNFTNYCWalletModalOpen);
