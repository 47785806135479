import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectLogin = ({ login }: RootState) => login;

export const selectIsLoginModalOpen = createSelector(
  selectLogin, ({ isOpen }) => isOpen,
);

export const selectAuthLogout = createSelector(
  selectLogin, ({ isLogout }) => isLogout,
);

export const selectLoginCreateProfileRedirect = createSelector(
  selectLogin, ({ createProfileRedirect }) => createProfileRedirect,
);
