/* eslint-disable react/jsx-no-comment-textnodes */
import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';
import Typography from 'components/Typography';
import { setActiveCard } from 'store/app/appActions';
import { selectActiveCard } from 'store/app/appSelectors';
import NotificationsLoader from 'components/NotificationsLoader/NotificationsLoader';
import { ChildRefFunc } from 'components/Media';
import { useParams } from 'react-router';
import { isIosMobile } from 'helpers/isIos';
import { CardProps } from './types';

import {
  AnimatedDots,
  StyledCardContainer,
  StyledCardInfoContainer,
  StyledImageContainer,
  StyledQRCode,
  StyledQRCodeContainer,
  StyledTypeContainer,
  StyledCardBackground,
  StyledVideoMediaLoadContainer,
  MutedIcon,
  StyledCardFixedWrapper,
  StyledVideoContainerWrapper,
  Processing,
  StyledHeader,
  StyledContentText,
  StyledContent,
  FullScreenMask,
  FullScreenVideoContainer,
  MediaLoader,
  StyledImageContainerWrapper,
} from './styled';
import CardFullScreen from './CardFullscreen';

const Card: FC<{
  card: CardProps;
  description?: string,
  isCardPage?: boolean,
  isVideoEnded?: boolean,
  cardUrl?: string,
  collectionId?: string,
  isTrimmedCard?: boolean,
  isPreview?: boolean,
  isFlowFest?: boolean,
  beforeRedirect?: (path: string) => void,
}> = ({
  card: {
    media,
    media_original,
    title,
    rarity,
    author,
    collection,
    totalEditions,
    qrCodeUrl,
    edition,
    editions,
    uploadProgress,
    state: mediaState,
    cardId,
    projectName = '',
    'data-test-id': dataTestId,
    'data-date-attribute': dataDatetId,
  },
  description = '',
  isCardPage = false,
  isVideoEnded,
  cardUrl,
  collectionId = '',
  isTrimmedCard = false,
  isPreview = false,
  isFlowFest,
  beforeRedirect,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ cardId?: string }>();
  const cardContainer = useRef(null);
  const videoContainer = useRef<ChildRefFunc>(null);
  const fullscreenVideoContainer = useRef<ChildRefFunc>(null);
  const activeCard = useSelector(selectActiveCard);
  const isActiveCard = isFlowFest
    ? activeCard === cardId
    : activeCard === `${collectionId}/${cardId}/${edition}`;
  const qrCodeWithEdition = process.env.REACT_APP_CHAIN_ENV !== 'mainnet'
    ? `https://canary.starly.io/qr/${collectionId}/${cardId}/${edition}`
    : `https://starly.io/qr/${collectionId}/${cardId}/${edition}`;
  const url = new URL(window.location.href);
  const headless = url.searchParams.get('headless');

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(media?.type === 'video' || false);
  const [isMuted, setIsMuted] = useState<boolean>(!isIosMobile() && !isActiveCard);
  const [preloadPadding, setPreloadPadding] = useState<string>('162%');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [fullScreenVideoWidth, setFullScreenVideoWidth] = useState<number>(0);
  const fullScreenContainerRef = useRef<HTMLDivElement>(null);
  // Defalut values in px
  const [adaptiveStyles, setAdaptiveStyles] = useState({
    fonts: {
      header: 8,
      title: 24,
      cardNumber: 24,
      cardCount: 13,
    },
    height: {
      title: 98,
      header: 22,
    },
    minHeight: {
      qrCode: 62,
    },
    maxHeight: {
      videoContainer: 455,
    },
    width: {
      expandBtn: 22,
    },
  });

  const switchMuted = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);

    if (isFlowFest) {
      if (cardId) {
        dispatch(setActiveCard(!isMuted ? cardId : null));
      }
    } else if (cardId && collectionId) {
      dispatch(setActiveCard(!isMuted ? `${collectionId}/${cardId}/${edition}` : null));
    }
  };

  const setMuted = () => {
    setIsMuted(false);
  };

  // check ios conditional
  useEffect(() => {
    if (isIosMobile() && isFullScreen) setIsMuted(false);
  }, [isFullScreen]);

  const onLoad = () => {
    setIsLoaded(true);
  };

  const onResize = () => {
    if (cardContainer.current) {
      // @ts-ignore
      const { width, height } = cardContainer.current.getBoundingClientRect();

      setAdaptiveStyles({
        fonts: {
          header: width / 64,
          title: width / 21,
          cardNumber: width / 21,
          cardCount: width / 39,
        },
        height: {
          title: Math.ceil(width * 1.2 * 9 / 64),
          header: height / 33,
        },
        minHeight: {
          qrCode: height / 10,
        },
        maxHeight: {
          videoContainer: width * 1.083,
        },
        width: {
          expandBtn: width / 20,
        },
      });
    }
  };

  const switchFullScreen = () => {
    const header = document.getElementsByTagName('header');
    if (isFullScreen) {
      document.body.style.overflow = 'auto';
      if (header[0].parentElement) {
        header[0].parentElement.style.zIndex = '40';
      }
    }
    setIsFullScreen(!isFullScreen);
  };
  const pauseFullScreenVideo = () => {
    if (fullscreenVideoContainer.current?.pauseVideo) {
      fullscreenVideoContainer.current?.pauseVideo();
    }
  };
  const pauseVideo = () => {
    if (videoContainer.current?.pauseVideo) {
      videoContainer.current?.pauseVideo();
    }
  };
  const playVideo = () => {
    if (videoContainer.current?.playVideo) {
      videoContainer.current?.playVideo();
    }
  };
  const playFullScreenVideo = () => {
    if (fullscreenVideoContainer.current?.playVideo) {
      fullscreenVideoContainer.current?.playVideo();
    }
  };
  const onTabBlur = () => {
    if (isFullScreen) {
      pauseFullScreenVideo();
    } else {
      pauseVideo();
    }
  };

  const onFocusTab = () => {
    if (isFullScreen) {
      playFullScreenVideo();
    } else {
      playVideo();
    }
  };

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      onFocusTab();
    } else {
      onTabBlur();
    }
  };

  useEffect(() => {
    setPreloadPadding('0');
    onResize();
    window.addEventListener('resize', onResize);
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [isFullScreen]);

  useLayoutEffect(() => {
    const timerId = setTimeout(onResize);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    onResize();
  }, [isFullScreen, isVideoEnded]);

  useEffect(() => {
    if (!window.IntersectionObserver) return undefined;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (videoContainer && videoContainer.current) {
            playVideo();
          }
        } else if (videoContainer && videoContainer.current) {
          pauseVideo();
        }
      });
    });

    if (cardContainer.current && isVideo) {
      observer.observe(cardContainer.current);
    }

    return () => {
      if (cardContainer.current) {
        observer.unobserve(cardContainer.current);
      }
    };
  }, [cardContainer]);

  useEffect(() => {
    setIsVideo(media?.type === 'video' || false);
  }, [media]);

  useEffect(() => {
    if (!params.cardId) {
      setIsMuted(isActiveCard);
    }
  }, [isActiveCard]);

  // clear sound on
  useEffect(() => () => {
    if (isActiveCard) dispatch(setActiveCard(null));
  }, []);

  useEffect(() => {
    const timerId = setTimeout(onResize, 100);
    return () => {
      clearTimeout(timerId);
    };
  }, [isLoaded]);

  // Because card's inner element's size needs to be recalculated via js
  // Some cards calc sizing while animation playing (at scale 0.5 for example) and sizing stuck
  // This prevent calculating element sizing while animation playing
  useEffect(() => {
    // Safari includes ResizeObserver by default from 13.1 version
    if (typeof window?.ResizeObserver !== 'undefined') {
      const observer = new ResizeObserver(() => {
        setTimeout(onResize);
      });
      observer.observe(cardContainer.current!);
    }
  }, []);

  // get video width if fullscreen mode
  useEffect(() => {
    if (isVideo && isFullScreen) {
      const video = document.getElementsByTagName('video');
      if (video.length !== 0) {
        setFullScreenVideoWidth(video[0].clientWidth);
      }
    } else {
      setFullScreenVideoWidth(0);
    }
  }, [isFullScreen, isVideo]);

  // switch video if full screen mode and media original type is image
  useEffect(() => {
    if (media_original && media_original.type === 'image') {
      if (isFullScreen) {
        pauseVideo();
      } else {
        playVideo();
      }
    }
  }, [isFullScreen, media_original, videoContainer]);
  return (
    <>
      <StyledCardFixedWrapper
        data-testid="card_fixed_wrapper"
        data-test-id={dataTestId}
        data-date-attribute={dataDatetId}
        to={cardUrl}
        onClick={(e) => {
          if (!cardUrl) e.preventDefault();
          else if (beforeRedirect) beforeRedirect(cardUrl);
        }}
        description={description}
        $isPreview={isPreview}
        className="starly-card"
      >
        <StyledCardBackground data-test-id={`rarity_card_value_${rarity}`} data-rarity-attribute={`${rarity}`} rarity={rarity} style={{ paddingBottom: preloadPadding }}>
          <StyledCardContainer data-test-id="card_container" ref={cardContainer}>
            {!isVideo && media ? (
              <StyledImageContainerWrapper>
                <MediaLoader $isLoaded={isLoaded}>
                  <NotificationsLoader small={false} />
                </MediaLoader>
                <StyledImageContainer
                  data-test-id="card_media_picture"
                  media={media}
                  onClick={() => isCardPage && switchFullScreen()}
                  lazy
                  isLoaded={onLoad}
                />
              </StyledImageContainerWrapper>
            ) : (
              <StyledVideoContainerWrapper data-test-id="video_wrapper" onClick={() => isCardPage && switchFullScreen()}>
                <MediaLoader $isLoaded={isLoaded}>
                  <NotificationsLoader small={false} />
                </MediaLoader>
                {!isTrimmedCard && (
                  <MutedIcon
                    data-test-id="mute_icon"
                    onClick={switchMuted}
                    $fullscreen={isFullScreen}
                    $isCardPage={isCardPage}
                    $headless={!!headless}
                    videoWidth={videoContainer.current?.width}
                    style={{
                      width: adaptiveStyles.width.expandBtn,
                      height: adaptiveStyles.width.expandBtn,
                    }}
                  >
                    {!isMuted ? <SoundOn /> : <SoundOff />}
                  </MutedIcon>
                )}
                {(mediaState === 'unprocessed' || (uploadProgress && uploadProgress < 100)) && (
                  <Processing>
                    <AnimatedDots>{t('card.processing')}</AnimatedDots>
                  </Processing>
                )}
                <StyledVideoMediaLoadContainer
                  ref={videoContainer}
                  media={media}
                  controls={false}
                  isMuted={!isMuted || isFullScreen}
                  lazy={!isCardPage}
                  isLoaded={onLoad}
                  isVideoPlaying={isVideoEnded}
                  $fullscreen={isFullScreen}
                  $videoWidth={videoContainer.current?.width}
                  data-test-id="card_media_video"
                  setMuted={setMuted}
                />
                {isFullScreen && media_original?.type !== 'image' && (
                  <>
                    <FullScreenMask />
                    <FullScreenVideoContainer data-test-id="full_screen_video" $width={fullScreenVideoWidth} />
                  </>
                )}
              </StyledVideoContainerWrapper>
            )}
            <StyledCardInfoContainer data-test-id="card_info_container">
              <StyledHeader
                data-test-id="card_info_container_header"
                style={{
                  height: 2 * adaptiveStyles.height.header,
                  lineHeight: `${adaptiveStyles.height.header}px`,
                }}
              >
                <Typography
                  data-test-id="card_author_and_collection_name"
                  data-author-collection-attribute={`${author}: ${collection}`}
                  lSpacing={0.8}
                  lineHeight="normal"
                  uppercase
                  font="spaceMono"
                  textColor="white"
                  style={{
                    fontSize: `${adaptiveStyles.fonts.header}px`,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  {`${author} // `}
                  <span style={{ fontWeight: 'bold' }}>
                    {isFlowFest ? projectName : collection}
                  </span>
                </Typography>
              </StyledHeader>
              <StyledContent>
                <StyledContentText
                  data-test-id="card_content_text"
                  data-title-attribute={`${title || t('card.titleNa')}`}
                  lSpacing={-0.75}
                  align="left"
                  bold
                  uppercase
                  style={{
                    maxHeight: `${adaptiveStyles.height.title}px`,
                    fontSize: `${adaptiveStyles.fonts.title}px`,
                  }}
                >
                  {title || t('card.titleNa')}
                </StyledContentText>
                <StyledQRCodeContainer data-test-id="qr_code_container" style={{ minHeight: adaptiveStyles.minHeight.qrCode }}>
                  <StyledQRCode
                    data-test-id="qr_code"
                    src={edition && !isFlowFest ? qrCodeWithEdition : qrCodeUrl}
                  />
                </StyledQRCodeContainer>
              </StyledContent>
              <StyledTypeContainer
                data-test-id="card_type_edition"
                data-edition-attribute={edition}
                data-total-edition-attribute={totalEditions}
                edition={edition}
                $isAvailable={Boolean(edition || editions || totalEditions)}
              >
                <Typography
                  lSpacing={0.5}
                  font="syncopate"
                  style={{
                    fontSize: `${edition ? adaptiveStyles.fonts.cardNumber : adaptiveStyles.fonts.cardCount}px`,
                    marginRight: `${edition ? '1px' : '0px'}`,
                    color: edition ? 'white' : '#aaaaaa',
                  }}
                  bold
                >
                  {edition ? `#${edition}` : '#'}
                </Typography>
                <Typography
                  lSpacing={-0.75}
                  font="syncopate"
                  style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                  bold
                >
                  /
                </Typography>
                <Typography
                  lSpacing={-0.75}
                  font="syncopate"
                  style={{ fontSize: `${adaptiveStyles.fonts.cardCount}px`, color: '#aaaaaa' }}
                  bold
                >
                  {totalEditions || editions}
                </Typography>
              </StyledTypeContainer>
            </StyledCardInfoContainer>
          </StyledCardContainer>
        </StyledCardBackground>
      </StyledCardFixedWrapper>
      {
        isFullScreen && (
          <div ref={fullScreenContainerRef}>
            <CardFullScreen
              media={media_original || media}
              onClose={switchFullScreen}
              isMuted={!isMuted}
              switchMuted={setIsMuted}
              fullScreen={isFullScreen}
              videoRef={videoContainer}
              fullscreenVideoRef={fullscreenVideoContainer}
            />
          </div>
        )
      }
    </>
  );
};

export default Card;
