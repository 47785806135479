import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/PopUp';
import Button from 'components/Button';
import { copyToClipboard } from 'helpers/copyTextToClipboard';
import type IShare from './types';

import {
  TitleText,
  ShareWrapper,
  ShareInput,
  ShareBtn,
  CopiedText,
} from './styled';

const Share: FC<IShare> = ({ url, onHide, type }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  return (
    <PopUp onHide={onHide} data-test-id="share_popup">
      <CopiedText data-test-id="popup_copied_text" isCopied={isCopied}>{t('share.copied')}</CopiedText>
      <TitleText data-test-id="share_popup_title">
        {t('share.title', { type })}
      </TitleText>
      <ShareWrapper>
        <ShareInput data-test-id="share_popup_input" disabled value={url} />
        <ShareBtn
          data-test-id="share_button"
          onClick={() => {
            copyToClipboard(url);
            setIsCopied(true);
          }}
        >
          {t('common.copy')}
        </ShareBtn>
      </ShareWrapper>
      <Button data-test-id="close_button" background="black" onClick={onHide} fluid>{t('common.close')}</Button>
    </PopUp>
  );
};

export default Share;
