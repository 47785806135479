export enum RouteTypes {
  Any = '*',
  Blog = '/blog',
  BlogPost = '/blog/:slug+',
  CardQR = '/c/:collectionId/:cardId/:edition?',
  Card = '/:username/:collectionId/:cardId/:edition?',
  CardPreview = '/preview/:username/:collectionId/:cardId/:edition?',
  Collection = '/:username/:collectionId',
  Login = '/login',
  User = '/:username',
  UserById = '/u/:id',
  PackPurchase = '/pack-purchase',
  PackEdit = '/packs-price',
  PlaceCardForSale = '/card-for-sale/:collectionId/:cardId/:edition',
  Packs = '/packs',
  RevealPack = '/reveal-pack',
  StepDefault = '/steps/',
  Steps = '/steps/:id?/:mode?/:cardType?',
  EditCards = '/edit-cards',
  EditCollection = '/edit-collection/:collectionId',
  StepsEditCards = '/steps/2/edit-cards',
  Settings = '/settings',
  Staking = '/staking',
  StakingNft = '/staking/nft',
  VestingPrize = '/vesting-on-flow',
  VestingHistoryFlow = '/vesting-on-flow/history',
  Vesting = '/vesting',
  VestingHistory = '/vesting/history',
  Marketplace = '/marketplace',
  MarketplaceSales = '/marketplace/sales',
  Ranking = '/ranking',
  RankingCollections = '/ranking/collections',
  Notifications = '/notifications',
  NotFound = '/not-found',
  FaceControl = '/creator-verification',
  CreateProfile = '/create-profile',
  TermsOfUse = '/terms',
  PrivacyPolicy = '/privacy',
  WriteUs = '/write-us',
  Wallet = '/wallet',
  HowToPlay = '/how-to-play',
  Transactions = '/wallet/transactions',
  OAuthInstagramCallback = '/oauth/instagram/callback',
  OAuthTwitterCallback = '/oauth/twitter/callback',
  OAuthWechatCallback = '/oauth/wechat/callback',
  OAuthYoutubeCallback = '/oauth/youtube/callback',
  Drops = '/',
  FlowFest = '/flowfest',
  FlowNFTNYC = '/nftnyc',
  FlowFestMarketplace = '/flowfest/marketplace',
  FlowFestWallet = '/flowfest/wallet',
  TokenLanding = '/token',
  Test = '/test',
  Kyc = '/kyc',
}
