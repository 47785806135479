export const shareUrl = async (
  url: string,
  text: string | undefined,
  title: string | undefined,
  callBack: () => void,
) => {
  if (navigator) {
    try {
      await navigator.share({
        title,
        url,
        text,
      });
      callBack();
      return true;
    } catch (e) {
      console.error(e);
      callBack();
      return false;
    }
  }
  callBack();
  return null;
};
