import styled, { css } from 'styled-components';
import { mediaDesktop } from 'breakpoints';
import theme from 'components/theme';
import ic_check_green from 'static/ic_check_green.svg';

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: ${theme.colors.white};
  position: relative;
  ${mediaDesktop} {
    font-size: 24px;
  }

  &::before {
    content: '';
    border-radius: 4.8px;
    border: solid 2px ${theme.colors.inputPlaceholder};
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 14px;
    vertical-align: top;
    color: transparent;
    transition: transform 0.2s;
    ${mediaDesktop} {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }  
  }
  &::after {
    content: '';
    width: 18px;
    height: 18px;
    top: 50%;
    left: 4.5px;
    margin-top: -9px;
    position: absolute;

    ${mediaDesktop} {
      left: 7.5px;
    } 
  }
}
`;

export const CustomCheckbox = styled.input`
  display: none;
  &:checked + label {
    &:after {
      background-image: url("${ic_check_green}");
      background-repeat: no-repeat;
    }
  }
`;

const checkboxFilterCss = css`
  ${CheckboxLabel} {
    ${mediaDesktop} {
      font-size: 16px;
    }
    
    &::before {
      background: ${theme.colors.dark100};
      border-radius: 0;
      border: none;
      min-width: 24px;
      width: 24px;
      height: 24px;
    }
    
    &::after {
      left: 2px;
    }
  }
`;

export const CheckboxItem = styled.div<{ $filter?: boolean }>`
  ${({ $filter }) => ($filter ? checkboxFilterCss : '')}
`;
