import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Select, { SingleValue } from 'react-select';
import { Option } from 'components/Filters/utils/types';
import { useLocation } from 'react-router';
import { StyledSelector } from './styled';
import { TabsSelectorProps } from './types';
import ComponentPortal from '../ComponentPortal';
import { AppContext } from '../../context/context';

const TabsSelector: FC<TabsSelectorProps> = ({ links }) => {
  const dispatch = useDispatch();
  const options = links.map((link) => ({ value: link.id, label: link.label, href: link.href }));
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState(
    options.find(({ href }) => href === pathname) || options[0],
  );

  useEffect(() => {
    setCurrentTab(options.find(({ href }) => href === pathname) || options[0]);
  }, [pathname]);

  const onChange = (option: SingleValue<Option>) => {
    dispatch(push((`${links.find((link) => link.id === option?.value)?.href}`)));
  };

  const { topRef } = useContext(AppContext);

  return (
    <ComponentPortal element={topRef?.current}>
      <StyledSelector>
        <Select
          classNamePrefix="react-select"
          value={currentTab}
          options={options}
          isSearchable={false}
          onChange={onChange}
        />
      </StyledSelector>
    </ComponentPortal>
  );
};

export default TabsSelector;
