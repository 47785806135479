import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { TransactionHistoryItem } from '@starly/starly-types';
import { firestore, QuerySnapshot } from 'global/firebase';
import { transactionsLoading, transactionsRequest, transactionsResponse } from './transactionsActions';

function* transactionsRequestWatcher() {
  yield takeEvery(transactionsRequest, function* notificationRequestWorker(action) {
    const { payload: { addr } } = action;

    yield put(transactionsLoading({ isLoading: true }));

    const transactionsRef:QuerySnapshot = yield call(() => firestore
      .collection('flowAccounts')
      .doc(addr)
      .collection('transactions')
      .orderBy('create_time', 'desc')
      .get());

    const transactions = transactionsRef.docs.map(
      (transaction) => ({
        id: transaction.id,
        ...transaction.data(),
      }),
    ) as TransactionHistoryItem[];

    yield put(transactionsResponse({ transactions }));
    yield put(transactionsLoading({ isLoading: false }));
  });
}

export function* transactionsSaga() {
  yield all([
    fork(transactionsRequestWatcher),
  ]);
}
