import React, { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { desktopBreakpoint } from 'breakpoints';
import HeaderLinkWrapper from '../HeaderLinkWrapper';
import { StyledHeaderLink as StyledLabel } from '../../styled';
import {
  StyledFlowFestButton,
  StyledLinks,
  StyledLink,
  StyledLinksWrapper,
} from './styled';
import { TabSelectOption } from '../../../TabsSelector/types';

const DropdownHeaderLink: FC<{
  links: TabSelectOption[],
  pathKey?: string
  path?: string,
  to: string,
  isGreen?: boolean,
  'data-test-id'?: string
}> = ({
  path,
  pathKey,
  links,
  children,
  isGreen,
  to,
  'data-test-id': dataTestId,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isMobile: boolean = useWindowSize() < desktopBreakpoint;

  useEffect(() => {
    setIsActive(false);
  }, [path, pathKey]);

  const onMouseEnter = () => setIsActive(true);
  const onMouseLeave = () => setIsActive(false);

  return isMobile ? (
    <HeaderLinkWrapper data-test-id={dataTestId} to={to} isGreen={isGreen}>
      <StyledLabel>{children}</StyledLabel>
    </HeaderLinkWrapper>
  ) : (
    <StyledFlowFestButton onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <HeaderLinkWrapper data-test-id={dataTestId} to={to} isGreen={isGreen}>
        <StyledLabel>{children}</StyledLabel>
        <StyledLinksWrapper data-test-id={`drop_down_menu_${dataTestId}`} $isActive={isActive}>
          <StyledLinks>
            {links.map((link) => (
              <StyledLink
                key={link.id}
                id={link.id}
                to={link.href}
              >
                {link.label}
              </StyledLink>
            ))}
          </StyledLinks>
        </StyledLinksWrapper>
      </HeaderLinkWrapper>
    </StyledFlowFestButton>
  );
};

export default DropdownHeaderLink;
