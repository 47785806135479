/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC, useRef, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { Media } from '@starly/starly-types';
import { ReactComponent as SoundOn } from 'static/ic-sound-off.svg';
import { ReactComponent as SoundOff } from 'static/ic-sound-on.svg';
import {
  FullscreenMedia,
  FullscreenMediaContainer,
  FullscreenWrapper,
  MutedIcon,
} from './styled';
import { useOutsideClickHandle } from '../../hooks/useOutsideClick';

const CardPortal: FC = ({ children }) => {
  const portalElement = document.querySelector('#fullscreen-root') as Element;

  return ReactDOM.createPortal(
    children,
    portalElement,
  );
};

const CardFullScreen: FC<{
  media: Media,
  onClose: () => void,
  isMuted: boolean,
  fullScreen: boolean,
}> = ({
  media,
  onClose,
  isMuted,
  switchMuted,
  fullScreen,
  videoRef,
  fullscreenVideoRef,
}) => {
  const ref = useRef<any>(null);
  useOutsideClickHandle(ref, () => onClose());

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const fullScreenMuted = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    switchMuted(isMuted);
  };

  useEffect(() => {
    if (fullScreen) videoRef.current?.pauseVideo();

    return () => videoRef.current?.playVideo();
  }, []);

  let mediaHeight;
  let mediaWidth;
  const maxHeight = window.innerHeight - 40;
  const maxWidth = window.innerWidth - 40;
  const windowRatio = maxWidth / maxHeight;
  const largestSize = media.sizes[media.sizes.length - 1];

  const mediaRatio = largestSize.width / largestSize.height;

  if (windowRatio > mediaRatio) {
    mediaHeight = maxHeight;
    mediaWidth = mediaHeight * mediaRatio;
  } else {
    mediaWidth = maxWidth;
    mediaHeight = mediaWidth / mediaRatio;
  }

  return (
    <CardPortal>
      <FullscreenWrapper>
        <FullscreenMediaContainer
          data-test-id="full_screen_media"
          $width={mediaWidth}
          $height={mediaHeight}
          ref={ref}
          onClick={onClose}
        >
          <FullscreenMedia
            media={media}
            lazy
            isMuted={isMuted}
            ref={fullscreenVideoRef}
          />
          {media.type !== 'image' && (
            <MutedIcon
              data-test-id="mute_icon"
              onClick={fullScreenMuted}
              style={{
                width: 30,
                height: 30,
              }}
            >
              {isMuted ? <SoundOn /> : <SoundOff />}
            </MutedIcon>
          )}
        </FullscreenMediaContainer>
      </FullscreenWrapper>
    </CardPortal>
  );
};

export default CardFullScreen;
