import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Typography from '../Typography';
import Button from '../Button';
import { Column, Row } from '../Table';
import theme from '../theme';
import Table from '../Table/Table';
import { mediaDesktop } from '../../breakpoints';

export const PurchaseTable = styled(Table)`
  max-width: 100%;
`;

export const TableContainer = styled.div`
  overflow-x: hidden;
  max-width: 100%;
  padding-bottom: 32px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background:rgba(255, 255, 255, 0.5) ;
  }
  &::-webkit-scrollbar-track-piece {
    //margin: 15px 0;
    margin-bottom: 30px;
    position: relative;
  }
`;

export const HeadContainer = styled.div`
  padding: 16px 24px 0;
  ${mediaDesktop} {
    padding: 0;
  }
`;

export const PurchaseHeadline = styled(Typography)`
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 16px;
  ${mediaDesktop} {
    font-size: 30px;
    margin-left: 30px;
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }
`;

export const PurchaseRow = styled(Row)<{ header?: boolean }>`
  letter-spacing: 1px;
  grid-template-columns: 72px 140px 135px minmax(220px, 1fr) 83px;
  grid-gap: 32px;
  ${({ header }) => (header ? css`
    top: 0;
    z-index: 4;
    position: sticky;
  ` : '')}
  ${mediaDesktop} {
    grid-template-columns: 72px 140px 135px 275px 91px;
  }
`;

export const InfoPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(max-content, 1fr));
  grid-column-gap: 28px;
  grid-row-gap: 16px;
  padding-bottom: 16px;
  ${mediaDesktop} {
    padding: 32px 0 24px 30px;
    grid-template-columns: 139px 187px 254px 1fr;
    grid-gap: 0;
  }
`;

export const PopUpSelectContainer = styled.div`
  z-index: 5;
  grid-column: 1 / -1;
  ${mediaDesktop} {
    grid-column: auto;
  }
`;

export const PurchaseLabel = styled.div`
  font-family: ${theme.fonts.secondary};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  ${mediaDesktop} {
    font-size: 16px;
  }
`;

export const PurchaseValue = styled.span`
  display: block;
  line-height: 1;
  font-family: inherit;
  font-size: 28px;
  margin-top: 6px;
  ${mediaDesktop} {
    font-size: 40px;
    margin-top: 12px;
    letter-spacing: 2px;
  }
`;

export const BuyBtn = styled(Button)`
  position: sticky;
  font-size: 14px;
  align-self: end;
  min-width: 63px;
  height: 32px;
  letter-spacing: 1px;
  ${mediaDesktop} {
    font-size: 14px;
    min-width: 91px;
    width: 91px;
  }
`;

export const BuyBtnColumn = styled(Column)`
  position: sticky;
  right: 0;
`;
