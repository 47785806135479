import {
  all, fork, call, takeEvery, put, take, select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { sleep } from 'util/sleep';
import { Collection as CollectionSchema, Media } from '@starly/starly-types';
import {
  database,
  firestore,
  firestorage,
  functions,
  DocumentSnapshot,
  QuerySnapshot,
  trackException,
  trackEvent,
} from 'global/firebase';
import {
  getDiffByRarity,
  getRarityByOrder,
  getDiffByOrder,
} from 'util/rarity';
import { RouteTypes } from 'RouteTypes';
import { goBack, push } from 'connected-react-router';
import {
  collectionRequest,
  collectionResponse,
  activeCollectionRequest,
  activeCollectionResponse,
  writeCoverImageRequest,
  writeCoverImageResponse,
  deleteCollectionRequest,
  deleteCollectionError,
  deleteCollectionSuccess,
  burnUnsoldCardsRequest,
  updateStep1Request,
  updateStep1Response,
  updateStep3Request,
  updateStep3Response,
  uploadingCoverMedia,
  writeCardImageRequest,
  postStep2Request,
  postStep2Response,
  publishCollectionResponse,
  publishCollectionError,
  publishCollectionRequest,
  uploadingMedia,
  setMedia,
  setMediaState,
  setCoverMediaState,
  topCollectorsRequest,
  topCollectorsResponse,
  countersRequest,
  countersResponse,
  lastCollectionRequest,
  lastCollectionResponse,
  lastCollectionTopCollectorsResponse,
  burnUnsoldCardsError,
  burnUnsoldCardsSuccess,
  updateCollectionError,
  setDeleteCollectionMessage,
  updateFlag, setCreateModal, collectionStatsRequest, CollectionStats, collectionStatsResponse,
} from './collectionActions';
import { getFileExtension, getFileType } from '../../util/supportedMedia';
import { PREV_PAGE_REDIRECT_KEY } from '../../global/constants';
import { PrevPathname } from '../app/appReducer';

function* watchCollectionRequest() {
  yield takeEvery(collectionRequest, function* takeEveryCollectionRequest(action) {
    try {
      const { payload: { id, restoreTopCollectors } } = action;
      const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('collections').doc(id).get());
      const collection = collectionRef.data() as CollectionSchema;

      if (restoreTopCollectors) {
        const topCollectorsRef:DocumentSnapshot = yield call(() => firestore.collection('topCollectors').doc(id).get());
        const data = topCollectorsRef.data() as any;
        const topCollectors = data._cached_top_collectors;

        yield put(collectionResponse({
          collection: {
            ...collection,
            _cached_top_collectors: topCollectors,
          },
        }));
      } else {
        yield put(collectionResponse({ collection }));
      }
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchCollectionStatsRequest() {
  yield takeEvery(collectionStatsRequest, function* collectionStatsRequestWorker({
    payload: { collectionId },
  }) {
    const getCollectionStats = functions.httpsCallable('getCollectionStats');
    const { data: stats } : { data: CollectionStats } = yield getCollectionStats({ collectionId });
    yield put(collectionStatsResponse({
      collectionId,
      stats,
    }));
  });
}

function* watchActiveCollectionRequest() {
  yield takeEvery(
    activeCollectionRequest,
    function* takeEveryActiveCollectionRequest({
      payload: {
        defaultStep, checkState, errorCallback,
      },
    }) {
      try {
        const createCollection = functions.httpsCallable('createCollection');

        const { data: { collectionId, state } } = yield call(() => createCollection());

        if (checkState && state === 'created') {
          const redirect = sessionStorage.getItem(PREV_PAGE_REDIRECT_KEY);
          sessionStorage.removeItem(PREV_PAGE_REDIRECT_KEY);
          const { prev, pathname }: PrevPathname = yield select(({ app }) => app.prevPathname);
          if (redirect) {
            yield put(push(redirect));
          } else if (prev && pathname !== RouteTypes.FaceControl) {
            yield put(goBack());
          } else {
            yield put(push(RouteTypes.Drops));
          }
          yield put(setCreateModal({ isOpen: true }));
          return;
        }

        const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('collections').doc(collectionId).get());
        const collection = collectionRef.data() as CollectionSchema;
        const collectionCardsQuery:QuerySnapshot = yield call(() => firestore.collection(`collections/${collectionId}/cards`)
          .where('state', '==', 'new')
          .get());
        const collectionCards: any[] = collectionCardsQuery.docs.map((item: any) => ({
          ...item.data(),
          order: +item.id,
        // eslint-disable-next-line
        })).sort((a: any, b) => (a.order - b.order > 0 ? 1 : -1));
        yield put(activeCollectionResponse({
          collectionId,
          title: collection.title ?? '',
          description: collection.description ?? '',
          promo_video_url: collection.promo_video_url ?? '',
          imgCover: collection.cover_media?.sizes?.[0].url ?? '',
          mediaObj: collection.cover_media,
          priceIndex: collection.priceIndex,
          dropDurIndex: collection.dropDurIndex,
          saleDurIndex: collection.saleDurIndex,
          _cached_cards: collectionCards,
          defaultStep: defaultStep ? +defaultStep : undefined,
          cardInfo: collection.card_information.distribution,
          packDistribution: collection?.pack_information?.distribution,
        }));
      } catch (error: any) {
        yield call(errorCallback);
        trackException(error.message);
      }
    },
  );
}

function createVideoUpload(file: File, path: string) {
  return eventChannel((emittter: any) => {
    const uploadTask = firestorage.ref().child(path).put(file);
    uploadTask.on('state_change',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        emittter({ progress });
      },
      (error: any) => {
        console.error('load media error - ', error);
        emittter({ error });
      },
      () => {
        firestorage.ref().child(path)
          .getDownloadURL()
          .then((url: string) => {
            emittter({ url });
          });
      });
    return () => {};
  });
}

function checkMediaState() {
  return eventChannel((emitter: any) => {
    const iv = setInterval(() => emitter(true), 5000);

    return () => {
      clearInterval(iv);
    };
  });
}

function* writeCollectionCoverImageRequest() {
  yield takeEvery(writeCoverImageRequest,
    function* takeCoverImageRequest({
      payload: {
        file,
        userId,
        colId,
        otherFields,
      },
    }) {
      try {
        const type = getFileType(file);
        let extension = '';
        if (file.name.includes('.')) {
          extension = `.${file.name.split('.').pop()}`;
        }
        const path = `users/${userId}/collections/${colId}/cover-image${Date.now()}${extension}`;
        // @ts-ignore
        const channel = yield call(createVideoUpload, file, path);
        while (true) {
          const { progress, err, url } = yield take(channel);
          if (err) {
            // TODO error handling
            channel.close();
            return;
          }
          if (url) {
            let mediaObj:Media;
            if (type === 'video') {
              mediaObj = {
                type,
                sizes: [{
                  height: 400, width: 300, url, mp4: url,
                }],
                state: 'unprocessed',
              };
            } else {
              mediaObj = { type, sizes: [{ height: 400, width: 300, url }], state: 'unprocessed' };
            }
            yield put(writeCoverImageResponse({
              base64: url,
              mediaObj,
              otherFields,
            }));
            channel.close();

            const chan = yield call(checkMediaState);
            const colRequest = () => firestore.collection('collections').doc(colId).get();

            while (true) {
              // @ts-ignore
              yield take(chan);
              const collectionRef:DocumentSnapshot = yield call(colRequest);
              const collection = collectionRef.data() as CollectionSchema;
              const coverMedia = collection?.cover_media;
              if (coverMedia && coverMedia.state && (coverMedia.state !== 'unprocessed')) {
                yield put(setCoverMediaState({ media: coverMedia, colId }));
                chan.close();
              }
            }
            return;
          }
          yield put(uploadingCoverMedia({ progress }));
        }
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* writeCollectionCardImageRequest() {
  yield takeEvery(writeCardImageRequest,
    function* takeCardImage({
      payload: {
        colId,
        userId,
        cardId,
        file,
        values,
      },
    }) {
      try {
        // @ts-ignore
        const cardInfo = yield select((state) => state.collections.activeCollection.cardInfo);
        const extension = getFileExtension(file.name);
        const path = `users/${userId}/collections/${colId}/cards/${cardId}/cover${Date.now()}${extension}`;
        const rarity = getRarityByOrder(cardId, cardInfo);
        const index = cardId - getDiffByOrder(cardId, cardInfo) - 1;
        // @ts-ignore
        const channel = yield call(createVideoUpload, file, path);
        while (true) {
          const { progress, err, url } = yield take(channel);
          if (err) {
            // TODO error handling
            channel.close();
            return;
          }
          if (url) {
            const type = getFileType(file);
            yield put(setMedia({
              url,
              index,
              rarity,
              type,
              values,
            }));
            // eslint-disable-next-line max-len
            // yield call(() => firestore.collection(`collections/${colId}/cards`).doc(`${cardId}`).set({
            //   ...(type === 'video' ? { media: { sizes: [{ url }], state: 'new' } }
            //     : { media: { sizes: [{ height: 400, width: 300, url }], state: 'new' } }),
            //   type,
            // }, { merge: true }));
            channel.close();
            // @ts-ignore
            const chan = yield call(checkMediaState);
            const order = getDiffByRarity(rarity, cardInfo) + 1 + index;

            while (true) {
              // @ts-ignore
              yield take(chan);
              const collectionCardsQuery:QuerySnapshot = yield call(() => firestore.collection(`collections/${colId}/cards`)
                .where('state', '==', 'new')
                .get());
              const media: { state: string } = collectionCardsQuery.docs.map((item: any) => ({
                ...item.data(),
              })).find((card) => card.order === order)?.media;
              if (media && media.state && (media.state !== 'unprocessed')) {
                yield put(setMediaState({
                  media,
                  index,
                  rarity,
                }));
                chan.close();
              }
            }
            return;
          }
          yield put(uploadingMedia({ progress, index, rarity }));
        }
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchUpdate1StepRequest() {
  yield takeEvery(updateStep1Request,
    function* takeUpdate1Step({
      payload: {
        title,
        description,
        promo_video_url,
        colId,
        userId,
        isUpdate,
      },
    }) {
      try {
        yield call(() => firestore.collection('collections').doc(colId).set({
          title,
          description,
          promo_video_url,
        }, { merge: true }));

        yield put(updateStep1Response({
          title,
          description,
          promo_video_url,
          colId,
          userId,
        }));

        if (isUpdate) {
          yield put(collectionRequest({ id: colId }));
          yield put(updateFlag({ id: colId, isUpdated: true }));
          yield sleep(3000);
          yield put(updateFlag({ id: colId, isUpdated: false }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(updateCollectionError({ error: error.message || 'error' }));
      }
    });
}

function* watchPostStep2Request() {
  yield takeEvery(postStep2Request,
    function* takePostStep2({
      payload: {
        type,
        data,
        collectionId,
      },
    }) {
      try {
        // @ts-ignore
        const cardInfo = yield select((state) => state.collections.activeCollection.cardInfo);
        const cardsCount = cardInfo[type].distinct_cards;
        const diff = getDiffByRarity(type, cardInfo);
        for (let i = 1; i <= cardsCount; i += 1) {
          const path = `collections/${collectionId}/cards`;
          if (data[`cardTitle${i}`] || data[`cardDesc${i}`] || data[`cardTitle${i}`] === '' || data[`cardDesc${i}`] === '') {
            firestore.collection(path).doc(`${i + diff}`).set({
              ...(data[`cardTitle${i}`] || data[`cardTitle${i}`] === '' ? { title: data[`cardTitle${i}`] } : {}),
              ...(data[`cardDesc${i}`] || data[`cardDesc${i}`] === '' ? { description: data[`cardDesc${i}`] } : {}),
            }, { merge: true });
          }
        }

        yield put(postStep2Response());
      } catch (error: any) {
        trackException(error.message);
      }
    });
}
function* watchUpdate3StepRequest() {
  yield takeEvery(updateStep3Request,
    function* takeUpdate3Step({
      payload: {
        pricing,
        colId,
        activeStep,
        schedule_nft_drop,
        sale_duration,
        priceIndex,
        dropDurIndex,
        saleDurIndex,
      },
    }) {
      try {
        yield call(() => firestore.collection('collections').doc(colId).set({
          pack_information: {
            pricing,
          },
          priceIndex,
          schedule_nft_drop,
          dropDurIndex,
          saleDurIndex,
          sale_duration,
        }, { merge: true }));
        yield put(updateStep3Response({
          colId,
          activeStep,
          priceIndex,
          dropDurIndex,
          saleDurIndex,
        }));
        trackEvent('collection_creation_complete');
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchBurnUnsoldCardsRequest() {
  yield takeEvery(burnUnsoldCardsRequest,
    function* takeBurnUnsoldCollection({ payload: { collectionId } }) {
      try {
        const burnUnsoldCards = functions.httpsCallable('burnUnsoldCards');
        yield call(() => burnUnsoldCards({ collectionId }));
        yield put(burnUnsoldCardsSuccess());
      } catch (error: any) {
        trackException(error.message);
        yield put(burnUnsoldCardsError({ error: error.message || 'error' }));
      }
    });
}

function* watchDeleteCollectionRequest() {
  yield takeEvery(deleteCollectionRequest,
    function* takeDeleteCollection({ payload: { colId, cb } }) {
      const deleteCollection = functions.httpsCallable('deleteCollection');
      try {
        yield call(() => deleteCollection({ id: colId }));
      } catch (error: any) {
        trackException(error.message);
        yield put(deleteCollectionError({ error: error.message || 'error' }));
      }
      if (cb) {
        cb();
        yield put(setDeleteCollectionMessage());
      } else {
        yield put(activeCollectionRequest({ defaultStep: 1 }));
      }
      yield put(deleteCollectionSuccess({ id: colId }));
    });
}

function* watchPublishCollectionRequest() {
  yield takeEvery(publishCollectionRequest,
    function* takePublishCollection({ payload: { id, history, username } }) {
      try {
        const publishCurrCollection = functions.httpsCallable('publishCollection');
        yield call(() => publishCurrCollection({ id }));
        if (history) {
          history.push(RouteTypes.Collection.replace(':username', username).replace(':collectionId', id));
        }
        yield put(publishCollectionResponse());
      } catch (error: any) {
        trackException(error.message);
        yield put(publishCollectionError({ error: error.message || 'error' }));
      }
    });
}

function* watchTopCollectorsRequest() {
  yield takeEvery(topCollectorsRequest, function* takeEveryTopCollectorsRequest(action) {
    const { payload: { id } } = action;
    const topCollectorsRef:DocumentSnapshot = yield call(() => firestore.collection('topCollectors').doc(id).get());
    const data = topCollectorsRef.data() as any;
    const topCollectors = data._cached_top_collectors;
    yield put(topCollectorsResponse({ topCollectors, id }));
    yield put(lastCollectionTopCollectorsResponse({ topCollectors }));
  });
}

let counterChannel = null;

function* watchCountersRequest() {
  yield takeEvery(countersRequest, function* takeEveryCountersRequest(action) {
    const { payload: { id } } = action;
    // @ts-ignore
    // eslint-disable-next-line
    if (counterChannel) {
      yield counterChannel.close();
    }
    const channel = eventChannel((emitter) => {
      const listener = database.ref(`counters/${id}`);
      listener.on('value', (snapshot) => {
        emitter({ data: snapshot.val() || {} });
      });
      return () => {
        // @ts-ignore
        listener.off();
      };
    });
    counterChannel = channel;
    while (true) {
      const { data } = yield take(channel);
      yield put(countersResponse({ counterData: data, id }));
    }
  });
}

function* watchLastCreatedCollectionRequest() {
  yield takeEvery(lastCollectionRequest, function* takeEveryLastCreatedCollectionRequest(action) {
    try {
      const { payload: { id } } = action;

      const lastStartDateRef: QuerySnapshot = yield call(() => firestore.collection('collections')
        .where('creator.id', '==', id)
        .where('state', '==', 'created')
        .orderBy('sale_start_date', 'desc')
        .limit(1)
        .get());

      const lastSaleDateRef: QuerySnapshot = yield call(() => firestore.collection('collections')
        .where('creator.id', '==', id)
        .where('state', '==', 'created')
        .orderBy('sale_end_date', 'desc')
        .limit(1)
        .get());

      const lastStart: any = lastStartDateRef?.docs[0]?.data() as any;
      const lastSale: any = lastSaleDateRef?.docs[0]?.data() as any;

      yield put(lastCollectionResponse({ data: lastSale || lastStart }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

export default function* collectionSaga() {
  yield all([
    fork(watchCollectionRequest),
    fork(watchActiveCollectionRequest),
    fork(writeCollectionCoverImageRequest),
    fork(watchDeleteCollectionRequest),
    fork(watchBurnUnsoldCardsRequest),
    fork(watchUpdate1StepRequest),
    fork(watchUpdate3StepRequest),
    fork(writeCollectionCardImageRequest),
    fork(watchPostStep2Request),
    fork(watchPublishCollectionRequest),
    fork(watchTopCollectorsRequest),
    fork(watchCountersRequest),
    fork(watchLastCreatedCollectionRequest),
    fork(watchCollectionStatsRequest),
  ]);
}
