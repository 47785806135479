import React, { FC, useRef } from 'react';
import { DropdownContainer, DropdownMenuContainer, Overlay } from './styled';
import { DropdownProps } from './types';
import { useOutsideClickHandle } from '../../hooks/useOutsideClick';
import FullscreenPortal from '../FullscreenPortal/FullscreenPortal';

const Dropdown: FC<DropdownProps> = ({
  children,
  menu,
  isOpen,
  onOutsideClick,
  translate,
  fluid,
  overlay,
  mobileTranslate,
  'data-test-id': dataTestId,
}) => {
  const ref = useRef<any>();
  useOutsideClickHandle(ref, onOutsideClick, 'click');
  return (
    <DropdownContainer data-test-id={`dropdown_closed_${dataTestId}`} isOpen={isOpen} overlay={overlay} ref={ref}>
      {children}
      {
        isOpen && overlay && (
          <FullscreenPortal>
            <Overlay />
          </FullscreenPortal>
        )
      }
      {
        isOpen && (
          <DropdownMenuContainer
            data-test-id={`dropdown_open_${dataTestId}`}
            $overlay={overlay}
            isOpen={isOpen}
            $fluid={fluid}
            $translate={translate}
            $mobileTranslate={mobileTranslate}
          >
            {menu}
          </DropdownMenuContainer>
        )
      }
    </DropdownContainer>
  );
};

export default Dropdown;
