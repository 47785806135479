import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectFirebaseConfig = ({ firebaseConfig }: RootState) => firebaseConfig;

export const selectGoogleLogin = createSelector(
  selectFirebaseConfig, ({ googleLogin }) => googleLogin,
);

export const selectTwitterLogin = createSelector(
  selectFirebaseConfig, ({ twitterLogin }) => twitterLogin,
);

export const selectFacebookLogin = createSelector(
  selectFirebaseConfig, ({ facebookLogin }) => facebookLogin,
);

export const selectWechatLogin = createSelector(
  selectFirebaseConfig, ({ wechatLogin }) => wechatLogin,
);

export const selectEmailLogin = createSelector(
  selectFirebaseConfig, ({ emailLogin }) => emailLogin,
);
export const selectCustomTokenLogin = createSelector(
  selectFirebaseConfig, ({ customTokenLogin }) => customTokenLogin,
);
