import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PopUp from '../../PopUp';
import Button from '../../Button';
import { mediaDesktop } from '../../../breakpoints';

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 264px;
  ${mediaDesktop} {
    grid-gap: 20px;
    width: 412px;
  }
`;

const CreateModal: FC<{
  onClose: () => void;
  onCollectionOpen: () => void;
}> = ({ onClose, onCollectionOpen }) => {
  const { t } = useTranslation();

  return (
    <PopUp text={t('collection.duplicate')} onHide={onClose}>
      <ButtonContainer>
        <Button background="black" fluid onClick={onCollectionOpen}>{t('collection.goToYour')}</Button>
        <Button background="black" fluid onClick={onClose}>{t('common.close')}</Button>
      </ButtonContainer>
    </PopUp>
  );
};

export default CreateModal;
