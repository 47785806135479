import { createSelector } from '@reduxjs/toolkit';

// TODO replace with root state
const selectEthereum = ({ ethereum }: any) => ethereum;

export const selectFlowWallet = createSelector(selectEthereum, ({ wallet }) => wallet);

export const selectEthereumWalletAddr = createSelector(
  selectEthereum, ({ wallet }) => wallet?.address,
);

export const selectBalance = createSelector(
  selectEthereum, ({ balance }) => balance,
);

export const selectEthereumModal = createSelector(selectEthereum,
  ({ modal }) => modal);

export const selectEthereumIsLoading = createSelector(selectEthereum,
  ({ isLoading }) => isLoading);
