import { createReducer } from '@reduxjs/toolkit';
import {
  ethereumLoginResponse,
  ethereumWalletUpdate,
  ethereumLogout,
  ethereumBalanceResponse,
  ethereumLoginRequest,
  ethereumToggleModal,
  ethereumToggleLoading,
} from './ethereumActions';

export interface EthereumSlice {
  wallet?: any,
  balance?: { [key: string]: string }
  modal: {
    isOpen: boolean,
    error: {
      message: string,
      code?: number,
    }
  }
  isLoading: boolean
}

const initialState: EthereumSlice = {
  wallet: {},
  balance: {},
  modal: {
    isOpen: false,
    error: {
      message: '',
      code: 0,
    },
  },
  isLoading: false,
};

const authReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(ethereumToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(ethereumLoginRequest, () => {
      // state.isLoading = true;
    })
    .addCase(ethereumLoginResponse, (state, {
      payload: { address },
    }) => {
      state.wallet = { address };
      state.isLoading = false;
    })
    .addCase(ethereumWalletUpdate, (state, {
      payload: { address },
    }) => {
      state.wallet.address = address;
    })
    .addCase(ethereumLogout, (state) => {
      state.wallet = {};
      state.balance = {};
    })
    .addCase(ethereumBalanceResponse, (state, {
      payload: {
        token, balance,
      },
    }) => {
      state.balance[token] = balance;
    })
    .addCase(ethereumToggleModal, (state, { payload: { isOpen, errorMessage, errorCode } }) => {
      state.modal.isOpen = isOpen;
      state.modal.error.message = errorMessage && errorMessage.length > 0 ? errorMessage : '';
      state.modal.error.code = errorCode || 0;
    }),
);

export default authReducer;
