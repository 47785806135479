import { createAction } from '@reduxjs/toolkit';
import { CollectedCardEdition, ContactUs } from '@starly/starly-types';
import { SocialTypes } from '../../views/FaceControl/types';

type UserRequestPayload = {
  id: string
};

type UserRequestByUsernamePayload = {
  username: string
};

type UserResponsePayload = {
  user: any
};

type UpdateUserRequestPayload = {
  id?: string,
  username?: string,
  name?: string,
  bio?: string,
  url?: string,
  email?: string,
};

type UpdateUserResponsePayload = {
  id?: string,
  username?: string,
  name?: string,
  bio?: string,
  url?: string,
  email?: string,
};

// TODO define correct type
type WriteAvatarImagePayloadRequest = any;

// TODO define correct type
type WriteAvatarImagePayloadResponse = any;

type UpdateAvatar = {
  avatarUrl: string
  userId: string
};

type UpdateUsernameRequest = {
  id: string
  username: string
};

type UpdateUsernameResponse = {
  id: string
  username: string
};

type UpdateUsernameFailure = {
  id: string
  error: string
};

type UpdateWalletRequest = {
  id: string,
  address: string,
};

type UpdateEthWallet = {
  id: string,
  address: string,
};

type UpdateWalletResponse = {
  id: string
  address: string
};

type UpdateCardsStakedStatusPayload = {
  userId: string
  cards: (Pick<CollectedCardEdition, 'staked' | 'stake_id'> & { id: string, collection_id: string })[]
};

type DisconnectSocialRequestPayload = {
  id: string,
  social: SocialTypes,
};

type DisconnectSocialResponsePayload = {
  id: string,
  social: SocialTypes,
};

type DisconnectSocialFailurePayload = {
  id: string,
  social: SocialTypes,
};

type SendMessagePayload = {
  messageData: ContactUs
};

type SubscribePayload = {
  email: string
};

type PackBattleClosePayload = {
  id: string;
  redirect: boolean;
};

export const userRequest = createAction<UserRequestPayload>('user/Request');
export const userResponse = createAction<UserResponsePayload>('user/Response');
export const userRequestByUsername = createAction<UserRequestByUsernamePayload>('user/requestByUsername');

// TODO deprecated
export const updateUserRequest = createAction<UpdateUserRequestPayload>('updateUser/Request');
// TODO deprecated
export const updateUserResponse = createAction<UpdateUserResponsePayload>('updateUser/Response');
export const updateCardsStakedStatus = createAction<UpdateCardsStakedStatusPayload>('user/UPDATED_CARDS_STAKED_STATUS');

export const userUpdateRequest = createAction<any>('user/update/request');
export const userUpdateResponse = createAction<any>('user/update/response');

export const resetUserState = createAction('user/resetUserState');

export const writeAvatarImageRequest = createAction<WriteAvatarImagePayloadRequest>('writeAvatarImage/Request');
export const writeAvatarImageResponse = createAction<WriteAvatarImagePayloadResponse>('user/avatar/response');
export const updateAvatar = createAction<UpdateAvatar>('user/UPDATE_AVATAR');

export const updateUsernameRequest = createAction<UpdateUsernameRequest>('user/UPDATE_USERNAME_REQUEST');
export const updateUsernameResponse = createAction<UpdateUsernameResponse>('user/UPDATE_USERNAME_RESPONSE');
export const updateUsernameFailure = createAction<UpdateUsernameFailure>('user/UPDATE_USERNAME_FAILURE');

export const updateEthWallet = createAction<UpdateEthWallet>('user/update/eth_wallet');

export const updateWalletRequest = createAction<UpdateWalletRequest>('user/wallet/request');
export const updateWalletResponse = createAction<UpdateWalletResponse>('user/wallet/response');

export const disconnectUserSocialRequest = createAction<DisconnectSocialRequestPayload>('user/DISCONNECT_SOCIAL_REQUEST');
export const disconnectUserSocialResponse = createAction<DisconnectSocialResponsePayload>('user/DISCONNECT_SOCIAL_RESPONSE');
export const disconnectUserSocialFailure = createAction<DisconnectSocialFailurePayload>('user/DISCONNECT_SOCIAL_FAILURE');

export const sendFeedbackMessage = createAction<SendMessagePayload>('user/send-message');

export const subscribe = createAction<SubscribePayload>('user/subscribe');
export const userToggleLoading = createAction<any>('user/TOGGLE_LOADING');
export const packBattleClose = createAction<PackBattleClosePayload>('user/PACK_BATTLE_CLOSE');
