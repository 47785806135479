import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import JoinDiscordLabel from '../JoinDiscordLabel/JoinDiscordLabel';
import KucoinBuyLabel from '../KucoinBuyLabel';
import { selectAuthAuthorized } from '../../store/auth/authSelectors';
import { RouteTypes } from '../../RouteTypes';

const RandomTopLabel = () => {
  const { pathname } = useLocation();

  if (pathname === RouteTypes.FlowNFTNYC) {
    return null;
  }

  const isAuthorized = useSelector(selectAuthAuthorized);
  const [rand, setRand] = useState(!!Math.round(Math.random()));

  useEffect(() => {
    setRand(!!Math.round(Math.random()));
  }, [pathname]);

  const isDiscordAccepted = !isAuthorized && pathname !== RouteTypes.Blog;
  const isKucoinAccepted = pathname === RouteTypes.Drops || pathname === RouteTypes.TokenLanding;

  const renderDiscord = isDiscordAccepted && (rand || !isKucoinAccepted);
  const renderKucoin = isKucoinAccepted && !renderDiscord;

  return (
    <>
      {
        renderDiscord && <JoinDiscordLabel />
      }
      {
        renderKucoin && <KucoinBuyLabel />
      }
    </>
  );
};

export default RandomTopLabel;
