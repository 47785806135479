import { createReducer } from '@reduxjs/toolkit';
import { FlowWallet } from '../../types';
import {
  flowLoginResponse,
  flowLogoutResponse,
  flowBalanceResponse,
  flowWrongModal,
  flowToggleModal,
  flowLowBalanceToggleModal,
  flowLoginRequest,
  flowBloctoLoginRequest,
  flowToggleLoading,
  flowFetchStarlyCardItemIDsResponse,
  flowFestLoginRequest,
  flowFestLoginResponse,
  flowFestToggleModal,
  flowFestToggleLoading,
  flowNFTNYCToggleModal, flowNFTNYCToggleLoading, flowNFTNYCLoginRequest, flowNFTNYCLoginResponse,
} from './flowActions';
import { knownBadNFTIds } from '../../global/constants';

export interface FlowSlice {
  wallet?: FlowWallet,
  flowBalance?: number
  fusdBalance?: string
  starlyBalance?: string
  isWrongModalOpen: boolean
  isWalletModalOpen: boolean
  isLowBalanceModalOpen: boolean
  isLowBalanceFlow: boolean
  isLoading: boolean
  isFlowFestWalletModalOpen: boolean
  isFlowNFTNYCWalletModalOpen: boolean
  badNFTIds: number[]
}

const initialState: FlowSlice = {
  wallet: undefined,
  flowBalance: undefined,
  fusdBalance: undefined,
  starlyBalance: undefined,
  isWrongModalOpen: false,
  isWalletModalOpen: false,
  isLowBalanceModalOpen: false,
  isLowBalanceFlow: false,
  isLoading: false,
  isFlowFestWalletModalOpen: false,
  isFlowNFTNYCWalletModalOpen: false,
  badNFTIds: [],
};

const authReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(flowToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowFestToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowNFTNYCToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(flowFestLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowNFTNYCLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowBloctoLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowFestLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.isLoading = false;
    })
    .addCase(flowNFTNYCLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.isLoading = false;
    })
    .addCase(flowFestToggleModal, (state, { payload }) => {
      state.isFlowFestWalletModalOpen = payload;
    })
    .addCase(flowNFTNYCToggleModal, (state, { payload }) => {
      state.isFlowNFTNYCWalletModalOpen = payload;
    })
    .addCase(flowLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(flowLoginResponse, (state, {
      payload: {
        wallet, flowBalance, fusdBalance, starlyBalance,
      },
    }) => {
      state.wallet = wallet;
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
      state.isLoading = false;
    })
    .addCase(flowLogoutResponse, (state) => {
      state.wallet = undefined;
      state.flowBalance = undefined;
      state.fusdBalance = undefined;
      state.starlyBalance = undefined;
    })
    .addCase(flowBalanceResponse, (state, {
      payload: {
        flowBalance, fusdBalance, starlyBalance,
      },
    }) => {
      state.flowBalance = flowBalance;
      state.fusdBalance = fusdBalance;
      state.starlyBalance = starlyBalance;
    })
    .addCase(flowWrongModal, (state, { payload }) => {
      state.isWrongModalOpen = payload;
    })
    .addCase(flowToggleModal, (state, { payload }) => {
      state.isWalletModalOpen = payload;
    })
    .addCase(flowLowBalanceToggleModal, (state, { payload: { isFlow, isOpen } }) => {
      state.isLowBalanceModalOpen = isOpen;
      state.isLowBalanceFlow = (isFlow && isOpen) || false;
    })
    .addCase(flowFetchStarlyCardItemIDsResponse, (state, { payload }) => {
      const knownBadNFTIdsSet = new Set(knownBadNFTIds);
      // eslint-disable-next-line max-len
      state.badNFTIds = Array.from(new Set([...payload.starlyCardIDs].filter((x) => knownBadNFTIdsSet.has(x))));
    }),
);

export default authReducer;
