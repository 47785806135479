import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'components/theme';
import { mediaDesktop } from 'breakpoints';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const margin = 1;

export const StyledTabLink = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(50% - ${margin}px);
  height: 50px;
  margin-top: ${margin * 2}px;
  background-color: ${theme.colors.dark};
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${({ $isActive }) => ($isActive ? theme.colors.white : theme.colors.lightGray)};
  text-transform: uppercase;
  text-decoration: none;
`;

export const StyledSelector = styled.div`
  z-index: 10;

  .react-select__control {
    height: 50px;
    margin-top: 0;
    border: 0;
          
    border-top: solid 2px ${theme.colors.black} !important;
    box-shadow: none !important;
    border-radius: 0;
    background-color: ${theme.colors.dark};

    .react-select__value-container {
      .react-select__single-value {
        text-transform: uppercase;
        font-family: ${theme.fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${theme.colors.white};

        ${mediaDesktop} {
          font-size: 16px;
        }
      }

      .react-select__input-container {
        font-family: ${theme.fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${theme.colors.white};

        ${mediaDesktop} {
          font-size: 16px;
        }

        .react-select__input {
          text-transform: uppercase;
        }
      }
    }

    .react-select__indicators {
      justify-content: center;
      width: 50px;
      background-color: #282828;
      border-left: solid 2px ${theme.colors.black};

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        color: ${theme.colors.white};
        cursor: pointer;
      }
    }
  }

  .react-select__menu {
    margin: 0;
    border-radius: 0;
    border-top: 0;
    background-color: #282828;

    .react-select__menu-list {
      padding: 0;

      .react-select__option {
        padding: 10px 10px;
        text-transform: uppercase;
        font-family: ${theme.fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${theme.colors.white};
        border-top: solid 2px ${theme.colors.black};
        cursor: pointer;
        box-shadow: none;
        outline: none;
        background-color: #282828 !important;

        ${mediaDesktop} {
          font-size: 16px;
        }

        &--is-selected {
          background-color: #282828;
          color: ${theme.colors.white};
        }
        
        &--is-focused {
          background-color: #282828;
          color: ${theme.colors.white};
        }
      }
    }
  }
`;
