import React, { FC, useEffect } from 'react';
import {
  Route, Switch, useLocation,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  MarketplaceContainer,
  MarketplaceHeader,
  MarketplaceLink,
  MarketplaceLinkSeparator,
  MarketplaceWrapper,
} from './styled';
import Typography from '../../components/Typography';
import { Option } from '../../components/Filters/utils/types';
import MarketplacePage from './components/MarketplacePage/MarketplacePage';
import { RouteTypes } from '../../RouteTypes';
import { useWindowSize } from '../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../breakpoints';
import TabsSelector from '../../components/TabsSelector';
import MarketplaceSalesPage from './components/MarketplaceSalesPage/MarketplaceSalesPage';
import { collectionsMarketplaceRequest } from '../../store/marketplace/marketplaceAction';
import { selectMarketplaceCollectionsState } from '../../store/marketplace/marketplaceSelectors';
import { DiscordFloatingLogo } from '../FlowFestView/components/DiscordFloatingLogo';

// TODO refactor this with tuple-values
export const sortTypes: Option[] = [
  {
    label: 'Most recent',
    value: 'create_time desc',
  },
  {
    label: 'Price (low to high)',
    value: 'price asc edition_id asc',
  },
  {
    label: 'Price (high to low)',
    value: 'price desc edition_id asc',
  },
  {
    label: 'Edition (low to high)',
    value: 'edition_id asc price asc',
  },
  {
    label: 'Edition (high to low)',
    value: 'edition_id desc price asc',
  },
  {
    label: 'Score (high to low)',
    value: 'collector_score desc price asc',
  },
];
export const extendedSortTypes: Option[] = [...sortTypes];
extendedSortTypes.splice(
  1,
  0,
  {
    label: 'Best deals',
    value: 'score_price_ratio desc',
  },
);

export const marketplacePages = [
  {
    id: 'marketplace',
    label: 'marketplace',
    href: RouteTypes.Marketplace,
  },
  {
    id: 'recent sales',
    label: 'recent sales',
    href: RouteTypes.MarketplaceSales,
  },
];

const Marketplace: FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sortTypesTr: Option[] = sortTypes.map((type) => ({
    ...type,
    label: t(`common.filters.sort.${type.value}`),
  }));
  const extendedSortTypesTr = extendedSortTypes.map((type) => ({
    ...type,
    label: t(`common.filters.sort.${type.value}`),
  }));
  const isMobile = useWindowSize() < desktopBreakpoint;

  const { collections } = useSelector(selectMarketplaceCollectionsState);

  useEffect(() => {
    if (collections.length <= 1) {
      dispatch(collectionsMarketplaceRequest());
    }
  }, [collections]);

  return (
    <MarketplaceWrapper>
      {isMobile && <TabsSelector links={marketplacePages} />}
      <MarketplaceContainer>
        {
          !isMobile && (
            <MarketplaceHeader>
              <Typography
                data-test-id="marketplace_header"
                font="secondary"
                size="popUpTitle"
                textColor="textGray"
                lSpacing={1.5}
                as="div"
              >
                <MarketplaceLink
                  data-test-id="marketplace_link"
                  to={RouteTypes.Marketplace}
                  isActive={RouteTypes.Marketplace === pathname}
                >
                  {t('header.marketplace')}
                </MarketplaceLink>
                <MarketplaceLinkSeparator>
                  |
                </MarketplaceLinkSeparator>
                <MarketplaceLink
                  data-test-id="marketplace_sales_link"
                  to={RouteTypes.MarketplaceSales}
                  isActive={RouteTypes.MarketplaceSales === pathname}
                >
                  {t('header.recentSales')}
                </MarketplaceLink>
              </Typography>
            </MarketplaceHeader>
          )
        }
        <Switch>
          <Route exact path={RouteTypes.Marketplace}>
            <MarketplacePage sortTypes={extendedSortTypesTr} />
          </Route>
          <Route exact path={RouteTypes.MarketplaceSales}>
            <MarketplaceSalesPage sortTypes={sortTypesTr} />
          </Route>
        </Switch>
        <DiscordFloatingLogo hideOnMobile />
      </MarketplaceContainer>
    </MarketplaceWrapper>
  );
};

export default Marketplace;
