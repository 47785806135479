import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Video from 'components/Video';
import Picture from 'components/Picture';
import { MediaSize } from '@starly/starly-types';
import { ChildRefFunc, MediaProps } from './types';
import { MediaWrapper } from './styled';

export const getBestMediaSize = (
  sizes: MediaSize[],
  desiredWidth: number,
  scale: number,
): MediaSize => {
  let currentItem = sizes[0];

  const originalWidth = desiredWidth * (scale > 0 ? scale : 1);

  if (currentItem.width < originalWidth) {
    for (let i = 0; i < sizes.length; i += 1) {
      currentItem = sizes[i];

      if (currentItem.width >= originalWidth) {
        break;
      }
    }
  }

  return currentItem;
};

const Media = forwardRef<ChildRefFunc, MediaProps>(({
  className,
  media,
  lazy,
  onClick,
  isLoaded,
  isMuted = true,
  autoplay = true,
  isVideoPlaying,
  'data-test-id': dataTestId,
  setMuted,
}, ref) => {
  const mediaRef = useRef<HTMLDivElement & ChildRefFunc>(null);
  const [mediaSize, setMediaSize] = useState<MediaSize>();
  useImperativeHandle(ref, () => ({
    pauseVideo() {
      if (mediaRef.current?.pauseVideo) {
        mediaRef.current?.pauseVideo();
      }
    },
    playVideo() {
      if (mediaRef.current?.playVideo) {
        mediaRef.current?.playVideo();
      }
    },
  }));
  /**
   * Load image according to placeholder width
   */
  useEffect(() => {
    if (mediaRef.current && media?.sizes) {
      const size = getBestMediaSize(
        media.sizes,
        mediaRef.current.offsetWidth,
        window.devicePixelRatio || 1,
      );

      setMediaSize(size);
    }
  }, [mediaRef, media]);
  /**
   * TODO Remove url support w/o sizes
   */
  if (media && (typeof media === 'string')) {
    return (
      <Picture
        data-test-id={`image_${dataTestId}`}
        ref={mediaRef}
        className={className}
        url={String(media)}
        onLoad={isLoaded}
      />
    );
  }

  /**
   * Load image via Picture component
   */
  if (media?.type === 'image' && mediaSize?.url) {
    return (
      <Picture
        data-test-id={`image_${dataTestId}`}
        key={mediaSize.url}
        ref={mediaRef}
        className={className}
        url={mediaSize.url}
        lazy={lazy}
        onClick={onClick}
        onLoad={isLoaded}
      />
    );
  }

  /**
   * Load video via Video component
   */
  if (media?.type === 'video' && mediaSize?.mp4) {
    return (
      <Video
        data-test-id={`video_${dataTestId}`}
        ref={mediaRef}
        key={mediaSize.mp4}
        className={className}
        loop
        muted={isMuted}
        autoplay={isVideoPlaying === undefined ? autoplay : isVideoPlaying}
        poster={mediaSize.screenshot}
        mp4={mediaSize.mp4}
        ogv={mediaSize.ogv}
        webm={mediaSize.webm}
        onClick={onClick}
        onLoad={isLoaded}
        lazy={lazy}
        isVideoPlaying={isVideoPlaying}
        setMuted={setMuted}
      />
    );
  }

  /**
   * Fallback for the first load + invalid media
   */
  return (
    <MediaWrapper
      data-test-id="media_placeholder"
      ref={mediaRef}
      className={className}
    />
  );
});

export default Media;
