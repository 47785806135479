import styled from 'styled-components/macro';
import { CardRarity } from '@starly/starly-types';
import Media from 'components/Media';
import theme from 'components/theme';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import { mediaDesktop } from '../../breakpoints';
import { frames } from './constants';

export const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  color: ${theme.colors.inputPlaceholder};
  font-family: ${theme.fonts.spaceMono};
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export const UploadTitle = styled.p`
  font-size: 13px;
  letter-spacing: normal;
`;

export const UploadValue = styled.p`
  font-size: 36px;
  line-height: normal;
  letter-spacing: 1px;
`;

export const StyledCardBackground = styled.div<{ rarity: CardRarity, $fullscreen?: boolean }>`
  background-image: url(${({ rarity }) => frames[rarity]});
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  border-radius: inherit;
  padding: 1px;

  ${({ $fullscreen }) => ($fullscreen ? `
    margin-bottom: 40px;
    width: 320px;
    min-height: 540px;
    ${mediaDesktop} {
      margin-bottom: 80px;
      width: 620px;
      min-height: 1080px;
    }
  ` : {
    width: '100%',
    height: '100%',
  })}
`;

export const StyledCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${theme.colors.textWhite};
  border-radius: inherit;
  letter-spacing: 1px;
  line-height: 1;
`;

const sideMargin = 9.5;
const sidePadding = 3;

export const StyledImageContainerWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(100% - 19%);
  margin: 10.5% ${sideMargin}% 0;
  padding-bottom: calc(129% - 19%);
`;

export const StyledImageContainer = styled(Media)<{ $fullscreen?: boolean }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 6px 6px 0 0;
  position: absolute;

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  ${({ $fullscreen }) => ($fullscreen && `
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      // transform: translateX(-50%);
      z-index: 100;
  `)}
  ${mediaDesktop} {
    border-radius: 10px 10px 0 0;
  }
`;

export const StyledQRCodeContainer = styled.div`
  width: 21%;
  height: auto;
`;

export const StyledQRCode = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledVideoContainerWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(100% - 19%);
  margin: 10.5% ${sideMargin}% 0;
  padding-bottom: calc(129% - 19%);
`;

export const StyledVideoLoadContainer = styled.video<{ $fullscreen?: boolean, isCreate?: boolean, controls?: boolean }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  ${({ $fullscreen, isCreate }) => !$fullscreen && isCreate && { marginTop: '-16px' }}
  border-radius: 4px 4px 0 0;
  position: relative;
  object-fit: cover;
  ${({ controls }) => (!controls && `
    &::-webkit-media-controls {
      display: none;
    }
    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }
  `)}

  ${mediaDesktop} {
    border-radius: 6px 6px 0 0;
  }
`;
export const FullScreenMask = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    z-index: 90;
`;
export const StyledVideoMediaLoadContainer = styled(Media)<{ $fullscreen?: boolean, isCreate?: boolean, controls?: boolean, $videoWidth?: number }>`
  width: 100%;
  background-size: cover;
  background-position: center;
  ${({ $fullscreen, isCreate }) => !$fullscreen && isCreate && { marginTop: '-18px' }}
  border-radius: 4px 4px 0 0;
  object-fit: cover;
  height: 100%;
  position: absolute;
  margin: 0;
  ${({ $fullscreen, $videoWidth }) => ($fullscreen && `
      position: fixed;
      width: ${$videoWidth ? `${$videoWidth}px` : '100%'};
      height: 100vh;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
  `)}
  ${({ controls }) => (!controls && `
    &::-webkit-media-controls {
      display: none;
    }
    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }
  `)}

  ${mediaDesktop} {
    border-radius: 6px 6px 0 0;
    ${({ $fullscreen }) => ($fullscreen && `
      width: auto;
  `)}
  }
`;

export const StyledCardFixedWrapper = styled(Link)<{
  $fullscreen?: boolean,
  description: string,
  isCreateCard?: boolean,
  $isPreview?: boolean,
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.black};
  z-index: 0;
  border-radius: 6px;
  text-decoration: none;

  ${({ $isPreview }) => ($isPreview ? `
    cursor: default;
  ` : `
    cursor: pointer;
  `)}

  ${({ isCreateCard }) => (isCreateCard ? `
    cursor: default;
    width: 100%;

    ${mediaDesktop} {
      width: 35%;
    }
  ` : `
    flex-basis: 174px;
    flex-shrink: 1;

    ${mediaDesktop} {
      width: auto;
    }
  `)}

  ${({ $fullscreen }) => ($fullscreen ? `
    position: fixed;
    inset: 0;
    zIndex: 12;
    overflow: auto;
    padding-top: 80px;
  ` : `
    position: relative;
    zIndex: 11;
  `)}
  ${({ $fullscreen, description }) => ($fullscreen && description && `
    &::after {
      content: '${description}';
      max-width: 600px;
      text-align: center;
      padding-bottom: 50px;
      display: block;
      position: relative;
      font-family: ${theme.fonts.primary};
      font-size: 21px;
      letter-spacing: 1px;
      color: ${theme.colors.textWhite};
    }
  `)}

`;

export const StyledVideoWrapper = styled.div`
  width: 100%;
  padding-bottom: 137%;
`;

export const StyledVideoWrapperInner = styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

export const MutedIcon = styled.div<{ $fullscreen: boolean, videoWidth?: number, $isCardPage: boolean, $headless: boolean }>`
  position: absolute;
  bottom: 1.5%;
  right: 1.5%;
  display: ${({ $headless }) => ($headless ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  bottom: ${({ $isCardPage }) => ($isCardPage ? '1.5%' : '5px')};
  right: ${({ $isCardPage }) => ($isCardPage ? '1.5%' : '5px')};
  & svg {
    width: 16px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  ${({ $fullscreen }) => ($fullscreen && `
    position: fixed;
    bottom: 10px;
    right: 10px;
  `)}
`;

export const FullScreenIcon = styled.div<{ $fullscreen?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  top: 7.5%;
  right: 11%;
`;

export const StyledCardInfoContainer = styled.div<{ $fullscreen?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 ${sideMargin}% 7.9%;
  padding: ${sidePadding}%;
  border-radius: 0 0 6px 6px;
  background-color: #0f0f0f;
  line-height: 1.25;
  box-shadow: none;
  border-top: none;
  z-index: -1;

  ${mediaDesktop} {
    border-radius: 0 0 10px 10px;
  }

  & > p {
    padding-bottom: 10px;
  }
`;

export const StyledTypeContainer = styled.div<{ edition?: string, $isAvailable?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ edition }) => (edition ? 'margin-top: 1%;' : 'margin-top: 4%; margin-bottom: 1%;')}
  line-height: 1.3;
  letter-spacing: 1px;
  transform: translateY(-30%);
  opacity: ${({ $isAvailable }) => ($isAvailable ? '1;' : '0;')};
`;

export const StyledHeader = styled.div`
  position: absolute;
  max-width: calc(100% - ${2 * sidePadding + 1}%);
  display: -webkit-box;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transform: translateY(-4px);

  & > p:nth-child(2) {
    margin: 0 5px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7%;
`;

export const Processing = styled.p`
  font-family: ${theme.fonts.spaceMono};
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-shadow: 0 0 8px black;
  padding: 5px 8px;
  font-weight: bold;
`;

export const AnimatedDots = styled.span`
  position: relative;
  &::after {
    overflow: hidden;
    position: absolute;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4,end) 2000ms infinite;
    content: "...";
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 36px;
    }
  }
`;

export const ChangeBtn = styled.label<{ htmlFor: string }>`
  background-color: ${theme.colors.lightTransparentDark};
  border-radius: 4px;
  font-family: ${theme.fonts.primary};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 6px 6px 6px 6px;
  position: relative;
  left: 5px;
  z-index: 3;
  top: 8px;
  cursor: pointer;
`;

export const StyledActualImage = styled.div<{ $fullscreen?: boolean, url: string }>`
  width: 100%;
  padding-bottom: 137%;
  background-color: ${theme.colors.black};
  background-image: url('${({ url }) => url}');
  ${({ $fullscreen }) => !$fullscreen && { marginTop: '-18px' }}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: ${({ $fullscreen }) => (!$fullscreen ? '6px 6px 0 0' : '16px 16px 0 0')}};
`;

export const StyledImageLoadContainer = styled.div<{ currMedia: any, isUpload: boolean, $fullscreen: boolean }>`
  box-shadow: inset 0 0 0 2px ${theme.colors.transparent};
  margin: 10px 10px 0;
  ${({ $fullscreen, isUpload, currMedia }) => ($fullscreen ? `
    width: 300px;
    height: 400px;
    ${mediaDesktop} {
      width: 600px;
      height: 800px;
    }
  ` : {
    width: `calc(100% - ${sideMargin * 2}%)`,
    margin: `10.5% ${sideMargin}% 0`,
    paddingBottom: !(isUpload && currMedia) ? `calc(129% - ${sideMargin * 2}%)}` : 0,
  })}
  background-size: cover;
  background-position: center;
  border-radius: 6px 6px 0 0;
  position: relative;

  grid-row: 1 / 2;
  grid-column: 1 / -1;

  &:hover ${ChangeBtn} {
    opacity: 1;
  }
`;

export const StyledImage = styled.input<{ type: string }>`
  display: none;
`;

export const StyledImageText = styled.p`
  text-transform: uppercase;
  padding-top: 15px;
  font-family: ${theme.fonts.spaceMono};
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  font-weight: 600;
  color: ${theme.colors.red};
`;

export const StyledContentText = styled(Typography)`
  font-family: ${theme.fonts.syncopate};
  color: ${theme.colors.white};
  max-width: 76%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${mediaDesktop} {
    line-height: 1.2;
  }
`;

export const StyledEmptyImage = styled.label<{ error: boolean, htmlFor: string }>`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color ${theme.colors.dark};
  ${({ error }) => (!error && `
    ${StyledImageText} {
      color: ${theme.colors.inputPlaceholder};
    }
  `)};
`;

export const FullscreenWrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .9);
  display: grid;
  place-content: center;
  & * {
    touch-action: inherit;
  }
`;

export const FullscreenMedia = styled(Media)`
    width: inherit;
    height: inherit;
`;

export const FullscreenOffBtn = styled.div`
  background: rgba(0 ,0, 0, .5);
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  ${mediaDesktop} {
    width: 28px;
    height: 28px;
    right: 16px;
    top: 16px;
  }
`;

export const FullscreenMediaContainer = styled.div<{ $width: number, $height: number }>`
  position: relative;
  background: ${theme.colors.dark};
  ${({ $width, $height }) => (`
      width: ${$width}px;
      height: ${$height}px;
  `)}
`;
export const FullScreenVideoContainer = styled.div<{ $width?: number }>`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
  ${({ $width }) => (`
      width: ${$width}px;
  `)}
`;

export const MediaLoader = styled.div<{ $isLoaded: boolean }>`
  ${({ $isLoaded }) => $isLoaded && { display: 'none' } || { display: 'flex' }};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;
