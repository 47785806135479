import styled from 'styled-components';
import Button from '../Button';
import theme from '../theme';
import { mediaDesktop } from '../../breakpoints';
import { SpinnerContainer } from '../NotificationsLoader/styled';

export const ClaimRewardLabelWrapper = styled.div`
  background-image: linear-gradient(87deg, #45ffc1 0%, #d773ff 103%);
`;

export const ClaimRewardLabelContainer = styled.div`
  min-height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: ${theme.width.mainContainer};
  padding: 16px;
  margin: auto;
  line-height: 24px;
  letter-spacing: 1px;
  ${mediaDesktop} {
    flex-direction: row;
    padding: 0;
  }
`;

export const ClaimRewardLabelText = styled.p`
  font-family: ${theme.fonts.primary};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
  ${mediaDesktop} {
    margin-bottom: 0;
    text-align: left;
    font-size: 21px;
  }
`;

export const NotificationContainer = styled.div`
  ${SpinnerContainer} {
    transform: translate(-2px, 7px);
    ${mediaDesktop} {
      transform: translate(-2px, 5px);
    }
  }
`;

export const ClaimRewardButton = styled(Button)`
  padding: 0 20px;
  height: 32px;
  font-size: 15px;
  min-width: min-content;
  ${mediaDesktop} {
    width: 200px;
    font-size: 19px;
    height: 40px;
  }
`;
