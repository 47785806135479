import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import * as fcl from '@onflow/fcl';
import {
  DocumentSnapshot,
  firestore,
  QuerySnapshot,
  trackEvent, trackException,
} from 'global/firebase';
import i18n from 'i18next';
import Web3 from 'web3';
import { VestingClaimHistoryRecord } from '@starly/starly-types';
import { ethConfig, VESTING_WALLET_ABI } from 'util/constants';
import Wallet from 'helpers/ethereumWallet';
import { FlowWallet } from 'types';
import { flowIsVestingInitializedScript } from 'flow/vesting/isVestingInitialized.script';
import { flowInitializeVestingTransaction } from 'flow/vesting/initializeVesting.tx';
import { flowFetchStarlyTokenVestingsScript } from 'flow/vesting/fetchVestings.script';
import { flowReleaseStarlyTokenVestingTransaction } from 'flow/vesting/release.tx';
import store from 'store/store';

import { flowBalanceRequest } from 'store/flow/flowActions';
import { selectFlowWalletAddr } from 'store/flow/flowSelectors';
import {
  vestingLoginRequest,
  vestingLoginResponse,
  vestingClaimRequest,
  vestingToggleModal,
  vestingLogout,
  vestingBalanceResponse,
  vestingReset,
  vestingToggleWalletLoading,
  vestingHistoryRequest,
  vestingHistoryResponse,
  VestingWallet,
  vestingToggleLoading,
  vestingFetchVestings,
  vestingRewardBalanceResponse,
} from './vestingActions';
import { selectVestingWallet } from './vestingSelectors';

interface FlowVestingInit {
  StarlyTokenVesting: boolean
}

interface ResponseArray<T extends Array<any>> extends Array<any> {
  length: T['length'];
}

interface FlowVestingWallet {
  beneficiary: string;
  endTimestamp: string;
  id: number;
  initialVestedAmount: string;
  nextUnlock: { [key: string]: string }
  releasableAmount: string
  releasePercent: string
  remainingVestedAmount: string
  startTimestamp: string
  vestingType: { rawValue: number }
}
interface VestingResponse {
  vestings: { [key: string]: FlowVestingWallet };
  currentTime: string
}

function onDisconnect(error: Error) {
  trackException(`Disconnected from RPC: ${error}`);
  store.dispatch(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.disconnect') }));
  store.dispatch(vestingLogout());
}

function onAccountsChanged(accounts: string[]) {
  trackEvent('accounts_changed');

  if (accounts.length === 0) {
    store.dispatch(vestingLogout());
    // return;
  }
  // TODO
  // store.dispatch(ethereumWalletUpdate({ address: accounts[0] }));
}

function onChainChanged() {
  trackException('Invalid ChainId');
  store.dispatch(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
  store.dispatch(vestingLogout());
}

function* ethereumLogin(providerId: string) {
  const provider: any = yield call(Wallet.connectTo, providerId);
  if (!provider) return null;

  const web3: Web3 = new Web3(provider);
  const chainId: number = yield call(web3.eth.getChainId);

  if (chainId !== ethConfig.chain.id) {
    trackException('Invalid ChainId');
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.invalidChain'), errorCode: 1 }));
    yield put(vestingLogout());
    return null;
  }

  yield call(Wallet.subscribeProvider, provider, onDisconnect, onAccountsChanged, onChainChanged);
  const accounts: string[] = yield call(web3.eth.getAccounts);

  const address = accounts[0];

  return address;
}

function* flowLogin() {
  const flowWallet: FlowWallet = yield call(fcl.logIn);

  const init: FlowVestingInit = yield call(flowIsVestingInitializedScript, flowWallet.addr);

  if (!init.StarlyTokenVesting) {
    yield call(flowInitializeVestingTransaction, flowWallet.addr);

    /**
     * Check is user approved init or not
     */
    const recheck: FlowVestingInit = yield call(flowIsVestingInitializedScript, flowWallet.addr);

    if (!recheck.StarlyTokenVesting) return null;
  }

  return flowWallet.addr;
}

function makeBatchRequest<T extends Array<any>>(calls: T): Promise<ResponseArray<T>> {
  const { web3 } = Wallet;

  const batch = new web3.BatchRequest();

  const promises = calls.map((txCall) => new Promise((res, rej) => {
    const req = txCall.request({}, (err: unknown, data: (string | number)) => {
      if (err) rej(err);
      else res(data);
    });
    batch.add(req);
  }));
  batch.execute();

  return Promise.all(promises);
}

function* ethereumFetchVestings(address: string) {
  const docRef: DocumentSnapshot = yield call(() => firestore.collection('bscVestingAccounts').doc(address).get());
  const doc = docRef.data();

  if (!doc?.wallets.length) {
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('vesting.error.noVesting') }));
    return;
  }

  const { web3, providerId } = Wallet;
  const block: { timestamp: number } = yield call(web3.eth.getBlock, 'latest');

  yield put(vestingLoginResponse({ wallet: { address, blockchain: 'ethereum', providerId } }));

  const vestingWallets: { [key: string]: VestingWallet } = {};
  yield call(() => Promise.all(
    doc?.wallets.map(async (walletAddress: string) => {
      const vestingWallet = new web3.eth.Contract(
        VESTING_WALLET_ABI, walletAddress,
      );

      const [
        start,
        duration,
        released,
        vestedAmount,
        totalAmount,
      ] = await makeBatchRequest([
        vestingWallet.methods.start().call,
        vestingWallet.methods.duration().call,
        vestingWallet.methods.released(process.env.REACT_APP_CONTRACT_ETH_STARLY).call,
        vestingWallet.methods.vestedAmount(
          process.env.REACT_APP_CONTRACT_ETH_STARLY, Math.round(Date.now() / 1000),
        ).call,
        vestingWallet.methods.vestedAmount(
          process.env.REACT_APP_CONTRACT_ETH_STARLY, Date.now(),
        ).call,
      ]) as string[];

      const vesting = {
        id: walletAddress,
        start: Number(start),
        duration: Number(duration),
        released: Number(released),
        vestedAmount: Number(totalAmount) - Number(vestedAmount),
        totalAmount: Number(totalAmount),
        decimals: 8,
      };

      vestingWallets[walletAddress] = vesting;

      return vesting;
    }),
  ));

  yield put(vestingBalanceResponse({ balance: vestingWallets, currentTime: block.timestamp }));
}

function* watchVestingFetchVestings() {
  yield takeEvery(vestingFetchVestings, function* takeEveryVestingFetchVestings() {
    try {
      yield put(vestingToggleLoading(true));
      const address: string = yield select(selectFlowWalletAddr);
      if (!address) {
        return;
      }

      const vesting: VestingResponse = yield call(flowFetchStarlyTokenVestingsScript, address);

      if (!vesting.vestings) {
        return;
      }

      const vestingWallets: { [key: string]: VestingWallet } = {};

      Object.values(vesting.vestings).forEach((wallet) => {
        const initialVestedAmount = Number(wallet.initialVestedAmount) * 10 ** 8;
        const remainingVestedAmount = Number(wallet.remainingVestedAmount) * 10 ** 8;
        const releasableAmount = Number(wallet.releasableAmount) * 10 ** 8;
        vestingWallets[wallet.id.toString()] = {
          id: wallet.id.toString(),
          nextUnlock: wallet.nextUnlock,
          start: Number(wallet.startTimestamp),
          duration: Number(wallet.endTimestamp) - Number(wallet.startTimestamp),
          released: initialVestedAmount - remainingVestedAmount,
          vestedAmount: remainingVestedAmount - releasableAmount,
          totalAmount: initialVestedAmount,
          decimals: 8,
          vestingType: {
            rawValue: wallet.vestingType.rawValue,
          },
        };
      });

      yield put(vestingRewardBalanceResponse(
        { balance: vestingWallets, currentTime: Number(vesting.currentTime) },
      ));
    } catch (e) {
      console.error(e);
    } finally {
      yield put(vestingToggleLoading(false));
    }
  });
}

function* flowFetchVestings(address: string) {
  const vesting: VestingResponse = yield call(flowFetchStarlyTokenVestingsScript, address);

  if (!vesting?.vestings || !Object.keys(vesting.vestings).length) {
    yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('vesting.error.noVesting') }));
    yield call(fcl.unauthenticate);
    return;
  }
  yield put(vestingLoginResponse({ wallet: { address, blockchain: 'flow' } }));

  const vestingWallets: { [key: string]: VestingWallet } = {};

  Object.values(vesting.vestings).forEach((wallet) => {
    const initialVestedAmount = Number(wallet.initialVestedAmount) * 10 ** 8;
    const remainingVestedAmount = Number(wallet.remainingVestedAmount) * 10 ** 8;
    const releasableAmount = Number(wallet.releasableAmount) * 10 ** 8;
    vestingWallets[wallet.id.toString()] = {
      id: wallet.id.toString(),
      nextUnlock: wallet.nextUnlock,
      start: Number(wallet.startTimestamp),
      duration: Number(wallet.endTimestamp) - Number(wallet.startTimestamp),
      released: initialVestedAmount - remainingVestedAmount,
      vestedAmount: remainingVestedAmount - releasableAmount,
      totalAmount: initialVestedAmount,
      decimals: 8,
      vestingType: {
        rawValue: wallet.vestingType.rawValue,
      },
    };
  });

  yield put(vestingBalanceResponse({
    balance: vestingWallets, currentTime: Number(vesting.currentTime),
  }));
}

function* watchVestingLoginRequest() {
  yield takeEvery(vestingLoginRequest, function* takeEveryVestingLoginRequest(action) {
    try {
      const {
        payload: {
          blockchain,
          providerId,
        },
      } = action;

      let address: string;
      if (blockchain === 'ethereum' && providerId) {
        address = yield call(ethereumLogin, providerId);
        if (!address) {
          yield put(vestingToggleLoading(false));
          return;
        }

        yield call(ethereumFetchVestings, address);
      } else {
        address = yield call(flowLogin);
        if (!address) {
          yield put(vestingToggleLoading(false));
          return;
        }

        yield call(flowFetchVestings, address);
      }

      trackEvent('wallet_connect');
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('ethereum.error.loginFailed') }));
      yield put(vestingReset());
    } finally {
      yield put(vestingToggleLoading(false));
    }
  });
}

function* watchVestingClaimRequest() {
  yield takeEvery(vestingClaimRequest, function* takeEveryVestingClaimRequest(action) {
    const {
      payload: {
        walletId,
        isReward,
      },
    } = action;
    try {
      const vestingWallet: ReturnType<typeof selectVestingWallet> = yield select(
        selectVestingWallet,
      );

      if (vestingWallet?.blockchain === 'ethereum') {
        const { web3 } = Wallet;

        const vestingWalletContract = new web3.eth.Contract(VESTING_WALLET_ABI, walletId);

        const release = vestingWalletContract.methods
          .release(process.env.REACT_APP_CONTRACT_ETH_STARLY);
        const data = release.encodeABI();

        let gas: number = yield call(() => release.estimateGas({ from: vestingWallet.address }));
        gas = +(gas + gas * 0.25).toFixed(0);

        yield call(Wallet.sendTransaction, {
          to: walletId,
          value: '0',
          gas,
          data,
        });

        yield fork(ethereumFetchVestings, vestingWallet.address);
      } if (vestingWallet?.blockchain === 'flow' || isReward) {
        const result = yield call(flowReleaseStarlyTokenVestingTransaction, walletId);
        const errMessage = result ? result?.errorMessage as string : '';

        if (result && errMessage) {
          return;
        }
        if (isReward) {
          yield put(vestingFetchVestings());
        } else {
          yield fork(flowFetchVestings, vestingWallet!.address);
        }
      }
      yield put(flowBalanceRequest({}));
    } catch (error: any) {
      console.error('er', error);
      trackException(error?.message);
      yield put(vestingToggleModal({ isOpen: true, errorMessage: i18n.t('Transaction failed') }));
    } finally {
      yield put(vestingToggleWalletLoading({ walletId, isReward }));
    }
  });
}

function* vestingHistoryRequestWatcher() {
  yield takeEvery(vestingHistoryRequest, function* notificationRequestWorker(action) {
    const {
      payload: {
        address,
      },
    } = action;
    let transactions: VestingClaimHistoryRecord[] = [];
    const transactionsRef: QuerySnapshot = yield call(() => firestore
      .collection('vestingClaimHistory')
      .doc(address)
      .collection('claims')
      .orderBy('timestamp', 'desc')
      .get());

    transactions = transactionsRef.docs.map((doc) => doc.data() as VestingClaimHistoryRecord);
    yield put(vestingHistoryResponse({ transactions }));
  });
}

function* watchFlowLogoutRequest() {
  yield takeEvery(vestingLogout, function* takeEveryLogoutRequest() {
    const vestingWallet: ReturnType<typeof selectVestingWallet> = yield select(selectVestingWallet);
    if (vestingWallet?.blockchain === 'ethereum') {
      try {
        if (Wallet.web3?.currentProvider?.disconnect) {
          yield Wallet.web3.currentProvider.disconnect();
        }
        Wallet.unsubscribeProvider(
          Wallet.provider, onDisconnect, onAccountsChanged, onChainChanged,
        );
        yield Wallet.clearCachedProvider();
        trackEvent('wallet_disconnect');
      } catch (err) {
        console.error(err);
      }
    } else if (vestingWallet?.blockchain === 'flow') {
      yield call(fcl.unauthenticate);
    }
    yield put(vestingReset());
  });
}

export default function* flowSaga() {
  yield all([
    fork(watchVestingLoginRequest),
    fork(watchVestingFetchVestings),
    fork(watchVestingClaimRequest),
    fork(vestingHistoryRequestWatcher),
    fork(watchFlowLogoutRequest),
  ]);
}
