import React, { FC } from 'react';
import placeholder from 'static/starly-cover-placeholder.jpg';
import { Helmet } from 'react-helmet';
import ic_check from 'static/ic_check.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { HelmetProps } from './types';

const HelmetWrapper: FC<HelmetProps> = ({
  title = 'Starly',
  image,
  description,
  canonical,
}) => {
  const coverImage = useSelector((state: RootState) => (
    state.collections.activeCollection.step1.imgCover
  ));
  return (
    <Helmet encodeSpecialCharacters={false}>
      <meta charSet="utf-8" />
      {/* Preload images */}
      <link rel="preload" as="image" href={ic_check} />
      {coverImage && <link rel="preload" as="image" href={coverImage} />}
      <link rel="preload" as="image" href={placeholder} />

      {canonical && <link href={canonical} rel="canonical" />}

      {title && <title>{window.decodeURI(title.replaceAll('%', '%25'))}</title>}
      {description && <meta name="description" content={description} />}

      {/* Twitter MarketplaceCard */}
      <meta name="twitter:card" content="summary_large_image" />
      {description && <meta name="twitter:description" content={description} />}
      {title && <meta name="twitter:title" content={title} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Starly" />
      {description && <meta property="og:description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
};

export default HelmetWrapper;
