import React, { FC } from 'react';
import moment from 'moment';
import { TopCollectorsRewardInfoProps } from './types';
import { numberWithCommas } from '../../helpers/TextHelpers';
import {
  TopCollectorsRewardContainer, TopCollectorsRewardImg,
  TopCollectorsRewardTextInfo, TopCollectorsRegularText,
  TopCollectorsRewardInfoContainer, TopCollectorsRewardTextContainer,
  TopCollectorsRewardInfoHeading, TopCollectorsRewardInfoText,
} from './styled';
import starlyToken from '../../static/reward-img/img-starly-token@2x.png';

const TopCollectorsRewardInfo: FC<TopCollectorsRewardInfoProps> = ({
  qualifiedForRewards,
  rewardPool,
  onPopUpOpen,
}) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const snapshotDate = new Date(today.getFullYear(), month + (day > 1 ? 1 : 0), 1, 12);
  return (
    <TopCollectorsRewardContainer data-test-id="top_collectors_reward_container">
      <TopCollectorsRewardImg data-test-id="top_collectors_starly_image" src={starlyToken} alt="starly token" />
      <TopCollectorsRewardTextContainer>
        <TopCollectorsRewardInfoContainer>
          <TopCollectorsRewardTextInfo>
            <TopCollectorsRewardInfoHeading data-test-id="top_collectors_headline_1">
              Reward pool:
            </TopCollectorsRewardInfoHeading>
            <TopCollectorsRewardInfoText data-test-id="top_collectors_reward_pool" data-value-attribute={rewardPool}>
              {numberWithCommas(rewardPool, 0)}
              {' '}
              $STARLY
            </TopCollectorsRewardInfoText>
          </TopCollectorsRewardTextInfo>
          <TopCollectorsRewardTextInfo>
            <TopCollectorsRewardInfoHeading data-test-id="top_collectors_headline_2">
              Snapshot date:
            </TopCollectorsRewardInfoHeading>
            <TopCollectorsRewardInfoText data-test-id="top_collectors_reward_snapshot_date">
              {moment(snapshotDate).format('MMM D, YYYY h:mm A (UTC)')}
            </TopCollectorsRewardInfoText>
          </TopCollectorsRewardTextInfo>
          <TopCollectorsRewardTextInfo>
            <TopCollectorsRewardInfoHeading data-test-id="top_collectors_headline_3">
              Qualified for a reward:
            </TopCollectorsRewardInfoHeading>
            <TopCollectorsRewardInfoText data-test-id="top_collectors_reward_qualified" data-value-attribute={qualifiedForRewards}>
              Top
              {' '}
              {qualifiedForRewards}
              {' '}
              collectors
            </TopCollectorsRewardInfoText>
          </TopCollectorsRewardTextInfo>
        </TopCollectorsRewardInfoContainer>
        <TopCollectorsRegularText data-test-id="top_collectors_regular_text">
          Top collectors are regularly rewarded with $STARLY tokens proportionally to their score.
        </TopCollectorsRegularText>
        <TopCollectorsRegularText
          data-test-id="top_collectors_list_collectors"
          onClick={onPopUpOpen}
          isLink
        >
          See the list of rewarded collectors
        </TopCollectorsRegularText>
      </TopCollectorsRewardTextContainer>
    </TopCollectorsRewardContainer>
  );
};

export default TopCollectorsRewardInfo;
