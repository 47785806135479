import {
  FC, useEffect, useLayoutEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Table, { Column } from 'components/Table/Table';

import Filters from '../../../../components/Filters';
import Helmet from '../../../../components/Helmet/Helmet';
import Loader from '../../../../components/Loader';
import NotificationsLoader from '../../../../components/NotificationsLoader/NotificationsLoader';
import Typography from '../../../../components/Typography';
import {
  resetMarketplaceSalesState, salesMarketplaceRequest, setMarketplaceSalesFilters,
} from '../../../../store/marketplace/marketplaceAction';
import {
  selectMarketplaceCollectionsState, selectMarketplaceSalesFilters, selectMarketplaceSalesState,
} from '../../../../store/marketplace/marketplaceSelectors';
import { checkIsDefaultFilters, isPayloadEqualsToFilters, paramsToFilters } from '../../helpers';
import {
  LoadMarketplaceButton, MarketplaceSalesContainer, MarketplaceTableContainer,
} from '../../styled';
import MarketplaceSaleRow from '../MarketplaceSaleRow/MarketplaceSaleRow';
import { MarketplaceStyledRow } from '../MarketplaceSaleRow/styled';
import { MarketplaceSalesPageProps } from './types';

const MarketplaceSalesPage: FC<MarketplaceSalesPageProps> = ({ sortTypes }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();
  const {
    rarity,
    sortBy,
    collection,
    scannedUrlState,
  } = useSelector(selectMarketplaceSalesFilters);
  const { collections, isLoad: isCollectionsLoad } = useSelector(selectMarketplaceCollectionsState);
  const {
    saleItems, page, isMoreToLoad, isLoad, isLoading,
  } = useSelector(selectMarketplaceSalesState);
  const [urlFilters] = useState(paramsToFilters(search));
  const [isFilterActive, setIsFilterActive] = useState(false);
  const defaultCollectionOption = {
    value: '',
    label: t('common.filters.allCollections'),
  };

  useEffect(() => {
    setIsFilterActive(!checkIsDefaultFilters({ rarity }));
  }, [rarity]);

  useEffect(() => () => {
    dispatch(resetMarketplaceSalesState());
  }, []);

  useLayoutEffect(() => {
    const isFiltersEquals = isPayloadEqualsToFilters({ rarity, sortBy, collection }, urlFilters);
    if (!isFiltersEquals && !scannedUrlState) {
      dispatch(setMarketplaceSalesFilters({ ...urlFilters, isScannedUrl: true }));
      dispatch(resetMarketplaceSalesState());
    } else if (isFiltersEquals && !scannedUrlState) {
      dispatch(setMarketplaceSalesFilters({ isScannedUrl: true, withoutUrlChange: true }));
    } else if (!isLoad && scannedUrlState) {
      dispatch(salesMarketplaceRequest({
        rarity, sortBy, collection, page,
      }));
    }
  }, [rarity, sortBy, collection, scannedUrlState]);
  useLayoutEffect(() => () => {
    dispatch(setMarketplaceSalesFilters({ isScannedUrl: false, withoutUrlChange: true }));
  }, []);

  return (
    <>
      <Helmet title={t('helmet.recentSales')} />
      <Filters
        filters={{ rarity }}
        onFiltersApply={(rar) => dispatch(setMarketplaceSalesFilters({ rarity: rar.rarity }))}
        isLoading={!isCollectionsLoad}
        isDisabled={false}
        defaultShowOption={
          urlFilters.collection ? {
            label: urlFilters.collection.label,
            value: urlFilters.collection.value,
          } : undefined
        }
        defaultSortOption={
          urlFilters.sortBy ? sortTypes.find(
            ({ value: v }) => v === urlFilters.sortBy,
          ) : undefined
        }
        options={[defaultCollectionOption, ...collections]}
        onChange={(newValue) => {
          if (newValue) {
            dispatch(setMarketplaceSalesFilters({ collection: newValue }));
          }
        }}
        sortValue={sortBy ? sortTypes.find(
          ({ value: v }) => v === sortBy,
        ) : undefined}
        onSortChange={(newValue) => {
          dispatch(setMarketplaceSalesFilters({ sortBy: newValue?.value }));
        }}
        sortTypes={sortTypes}
        isFilterActive={isFilterActive}
        data-test-id="filter_box"
      />
      <MarketplaceSalesContainer>
        {
          isLoad && !saleItems.length && (
            <Typography data-test-id="marketplace_empty_listed_label" size="unmedia21" textColor="textWhite" bold>{t('marketplace.noListings')}</Typography>
          )
        }
        {!isLoad && <Loader />}
        {
          !!saleItems.length && (
            <MarketplaceTableContainer>
              <Table>
                <MarketplaceStyledRow data-test-id="sales_table_header" header isDark>
                  <Column data-test-id="column_card_header" justify="left">{t('card.sale.history.card')}</Column>
                  <Column data-test-id="column_serial_header">{t('card.sale.history.serial')}</Column>
                  <Column data-test-id="column_rarity_header">{t('card.sale.history.rarity')}</Column>
                  <Column data-test-id="column_buyer_header">{t('card.sale.history.buyer')}</Column>
                  <Column data-test-id="column_seller_header">{t('card.sale.history.seller')}</Column>
                  <Column data-test-id="column_price_header">{t('card.sale.history.price')}</Column>
                  <Column data-test-id="column_time_header">{t('card.sale.history.time')}</Column>
                  <Column />
                </MarketplaceStyledRow>
                {
                  saleItems.map((props) => (
                    <MarketplaceSaleRow key={props.flowTransactionLink} {...props} />))
                }
              </Table>
            </MarketplaceTableContainer>
          )
        }
        {
          isLoad && isMoreToLoad && (
            <LoadMarketplaceButton
              data-test-id="mp_load_more_button"
              onClick={() => {
                dispatch(salesMarketplaceRequest({
                  rarity, sortBy, collection, page,
                }));
              }}
              background="dark"
              noneHover={isLoading}
              disabled={isLoading}
              $loading={isLoading}
            >
              {!isLoading && t('common.loadMore')}
              {isLoading && <NotificationsLoader inline />}
            </LoadMarketplaceButton>
          )
        }
      </MarketplaceSalesContainer>
    </>
  );
};

export default MarketplaceSalesPage;
