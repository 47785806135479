import { createAction } from '@reduxjs/toolkit';

export interface DropsResponsePayload {
  drops: any
}

export interface SetLoadingPayload {
  isLoading: boolean,
}

export const dropsRequest = createAction('drops/request');

export const dropsResponse = createAction<DropsResponsePayload>('drops/response');

export const dropsLoading = createAction<SetLoadingPayload>('drops/set-loading');
