import { FungibleTokenType, SaleCut } from '@starly/starly-types';
import { createAction } from '@reduxjs/toolkit';

type EthereumOtherPayload = any;

type EthereumLoginRequestPayload = {
  providerId: string;
};

type EthereumBuyPackRequestPayload = {
  collectionId: string
  packIds: string[]
  price: number
  currency: FungibleTokenType
  paymentCurrency: FungibleTokenType
  beneficiaryAddress: string
  beneficiaryCutPercent: number
  creatorAddress: string
  creatorCutPercent: number
  additionalCuts: SaleCut[]
};

type EthereumToggleModalPayload = {
  isOpen: boolean,
  errorMessage?: string,
  errorCode?: number,
};

// export const ethereumBalanceRequest =
// createAction<EthereumOtherPayload>('ethereum/balance/request');

export const ethereumToggleLoading = createAction<EthereumOtherPayload>('ethereum/TOGGLE_LOADING');

export const ethereumBalanceResponse = createAction<EthereumOtherPayload>('ethereum/balance/response');

export const ethereumBuyPackRequest = createAction<EthereumBuyPackRequestPayload>('ethereum/buyPack/request');

export const ethereumBuyPackResponse = createAction<EthereumOtherPayload>('ethereum/buyPack/response');

export const ethereumLoginRequest = createAction<EthereumLoginRequestPayload>('ethereum/login/request');

export const ethereumLoginResponse = createAction<EthereumOtherPayload>('ethereum/login/response');

export const ethereumWalletUpdate = createAction<EthereumOtherPayload>('ethereum/wallet/update');

export const ethereumLogout = createAction('ethereum/logout/request');

export const ethereumToggleModal = createAction<EthereumToggleModalPayload>('ethereum/TOGGLE_MODAL');
