import * as yup from 'yup';

export const getValidationScheme = () => yup.object().shape({
  modalEmail: yup
    .string()
    .max(200, 'user.error.emailMax')
    .email('user.error.emailInvalid')
    .required('user.error.emailRequired'),
  password: yup
    .string()
    .min(6)
    .required('auth.error.passwordRequired'),
});
