// @ts-ignore
import * as fcl from '@onflow/fcl';
import { onError } from 'helpers/onError';
// @ts-ignore
import { tx } from './util/tx';

const CODE = fcl.cdc`
import NonFungibleToken from 0xNON_FUNGIBLE_TOKEN_ADDRESS
import StarlyCard from 0xSTARLY_CARD_ADDRESS

transaction() {
    prepare(account: AuthAccount) {
        let collection <- account.load<@StarlyCard.Collection>(from: StarlyCard.CollectionStoragePath)!
        for burnID in collection.getIDs() {
            let nft <- collection.withdraw(withdrawID: burnID)
            destroy nft
        }
        account.save(<-collection, to: StarlyCard.CollectionStoragePath)
    }
}`;

export async function flowBurnAllCardsTransaction(
  opts = { onError },
) {
  // Get latest block info
  const block = await fcl.send([fcl.getBlock(false)]).then(fcl.decode);

  return tx(
    [
      fcl.transaction(CODE),
      fcl.args([]),
      fcl.payer(fcl.authz),
      fcl.proposer(fcl.authz),
      fcl.authorizations([fcl.authz]),
      fcl.limit(1000),
      fcl.ref(block.id),
    ],
    opts,
  );
}
