import styled from 'styled-components';
import { mediaDesktop } from '../../breakpoints';
import theme from '../../components/theme';
import Button from '../../components/Button';
import Typography from '../../components/Typography';

export const GetInWrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, .8);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GetInContainer = styled.div`
  position: relative;
  text-align: center;
  display: grid;
  grid-gap: 50px;
  padding: 50px 24px;
  line-height: 1;
  letter-spacing: 1.4px;
  width: 100%;
  background: ${theme.colors.white};
  overflow-y: auto;
  
  ${mediaDesktop} {
    width: auto;
    letter-spacing: 2px;
    grid-gap: 68px;
    padding: 72px 100px 72px;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  text-align: center;
  justify-items: center;
  letter-spacing: 0;
  & > ${Typography} {
    margin-top: 4px;
    width: 280px;
    font-size: 13px;
    line-break: loose;
    line-height: 1.2;
  }
  
  ${mediaDesktop} {
    justify-items: stretch;
    grid-gap: 32px;
    width: 450px;
    & > ${Typography} {
      margin-top: 0;
      font-family: ${theme.fonts.primary};
      font-size: 18px;
      width: auto;
    }
  }
`;

export const GetInButton = styled(Button)`
    position: relative;
    align-items: center;
    justify-content: center;
    height: 37px;
    padding-left: 52px;
    min-width: 200px;
    max-width: 312px;
    &:hover:enabled {
      border-color: #000 ;
    }

  ${mediaDesktop} {
    max-width: 100%;
    padding-left: 100px;
    font-size: 18px;
    height: 54px;
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;

  ${mediaDesktop} {
    left: 32px;
    height: 32px;
    width: 32px;
  }
`;

export const WhiteSpan = styled.a`
  color: inherit;
  font-weight: bold;
  text-decoration: none;
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  padding: 10px 12px;
  background-color: ${theme.colors.red};
  font-weight: 600;
  letter-spacing: .3px;
  text-align: center;
  color: ${theme.colors.white};

  ${mediaDesktop} {
    width: 650px;
    padding: 16px 32px;
  }
`;
