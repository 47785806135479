import React, {
  FC, useEffect, useLayoutEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FungibleTokenType } from '@starly/starly-types';
import SelectWalletModal from 'views/Modals/WalletModal/SelectWalletModal';
import { fetchFusdToFlowAmount } from 'flow/fetchFusdToFlowAmount.script';
import {
  selectMarketplaceCardsState,
  selectMarketplaceCollectionsState,
  selectMarketplaceFilters,
  selectMarketplacePopUpState,
  selectMarketplacePurchasingState,
  selectMarketplaceScroll,
} from '../../../../store/marketplace/marketplaceSelectors';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../../../breakpoints';
import { selectAuthAuthorized } from '../../../../store/auth/authSelectors';
import { selectFlowWalletAddr, selectFUSDBalance, selectFlowBalance } from '../../../../store/flow/flowSelectors';
import { checkIsDefaultFilters, isPayloadEqualsToFilters, paramsToFilters } from '../../helpers';
import { flowAcceptSaleOfferRequest, flowLowBalanceToggleModal, flowToggleModal } from '../../../../store/flow/flowActions';
import {
  cardMarketplaceRequest,
  resetMarketplaceCardState,
  setMarketplaceCardPageUrlByNftId, setMarketplaceCardPurchaseState, setMarketplaceFilters,
  setMarketplacePurchasingState, setMarketplaceScrollPosition,
} from '../../../../store/marketplace/marketplaceAction';
import { setAuthRedirect, setLoginModal } from '../../../../store/login/loginActions';
import {
  CardsContainer,
  CardsWrapper,
  LoadMarketplaceButton,
} from '../../styled';
import Typography from '../../../../components/Typography';
import Filters from '../../../../components/Filters';
import MarketplaceCard from '../MarketplaceCard/MarketplaceCard';
import Loader from '../../../../components/Loader';
import NotificationsLoader from '../../../../components/NotificationsLoader/NotificationsLoader';
import CardPagePopUp from '../../../Card/componets/PopUp';
import Share from '../../../../components/Share';
import { MarketplacePageProps } from './types';
import Helmet from '../../../../components/Helmet/Helmet';
import { cardForSaleReset } from '../../../../store/saleCard/saleCardActions';
import { fmtFlow } from '../../../../util/fmtFlow';

const MarketplacePage: FC<MarketplacePageProps> = ({ sortTypes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    cards, isLoad, page, isMoreToLoad, isLoading,
  } = useSelector(selectMarketplaceCardsState);
  const isMobile = useWindowSize() < desktopBreakpoint;
  const {
    rarity, sortBy, collection, scannedUrlState,
  } = useSelector(selectMarketplaceFilters);
  const defaultCollectionOption = {
    value: '',
    label: t('common.filters.allCollections'),
  };

  const { collections, isLoad: isCollectionsLoad } = useSelector(selectMarketplaceCollectionsState);
  const { isPurchasing } = useSelector(selectMarketplacePurchasingState);
  const scroll = useSelector(selectMarketplaceScroll);

  const { pathname, search } = useLocation();
  const isAuthorized = useSelector(selectAuthAuthorized);
  const popUpState = useSelector(selectMarketplacePopUpState);
  const isWalletConnected = useSelector(selectFlowWalletAddr);
  const balance = useSelector(selectFUSDBalance) || 0;
  const flowBalance = useSelector(selectFlowBalance) || 0;
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    nftItemId: 0,
    price: 0,
    marketCollectionAddress: '',
  });

  const [isShareOpen, setIsShareOpen] = useState(false);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [urlFilters] = useState(paramsToFilters(search));

  const onBuyCard = async (paymentCurrency: FungibleTokenType) => {
    setIsWalletModalOpen(false);
    if (paymentCurrency === 'FUSD' && balance < cardInfo.price) {
      return dispatch(flowLowBalanceToggleModal({ isOpen: true }));
    }
    if (paymentCurrency === 'FLOW') {
      const priceInFlow = await fetchFusdToFlowAmount(cardInfo.price);
      if (Number(fmtFlow(flowBalance)) < priceInFlow) {
        return dispatch(flowLowBalanceToggleModal({ isOpen: true, isFlow: true }));
      }
    }
    if (isPurchasing) {
      return dispatch(setMarketplacePurchasingState({ state: 'warning' }));
    }
    dispatch(setMarketplacePurchasingState({ isPurchasing: true }));
    dispatch(setMarketplaceCardPageUrlByNftId({ nftItemId: cardInfo.nftItemId }));
    dispatch(setMarketplaceCardPurchaseState({
      nftItemId: cardInfo.nftItemId,
      inProgress: true,
    }));
    return dispatch(flowAcceptSaleOfferRequest({
      marketCollectionAddress: cardInfo.marketCollectionAddress,
      isMarketplace: true,
      nftItemId: cardInfo.nftItemId,
      paymentCurrency,
    }));
  };

  const onBuyClick = (nftItemId: number, marketCollectionAddress: string, price: number) => {
    if (isAuthorized) {
      if (!isWalletConnected) {
        return dispatch(flowToggleModal(true));
      }
    } else {
      dispatch(setLoginModal({ isOpen: true }));
      return dispatch(setAuthRedirect({ onAuthRedirect: pathname }));
    }

    setCardInfo({ nftItemId, marketCollectionAddress, price });
    return setIsWalletModalOpen(true);
  };
  useEffect(() => {
    setIsFilterActive(!checkIsDefaultFilters({ rarity }));
  }, [rarity]);

  useLayoutEffect(() => {
    const isFiltersEquals = isPayloadEqualsToFilters({ rarity, sortBy, collection }, urlFilters);
    if (!isFiltersEquals && !scannedUrlState) {
      dispatch(setMarketplaceFilters({ ...urlFilters, isScannedUrl: true }));
      dispatch(resetMarketplaceCardState());
    } else if (isFiltersEquals && !scannedUrlState) {
      dispatch(setMarketplaceFilters({ isScannedUrl: true, withoutUrlChange: true }));
      window.scrollTo({ top: scroll });
    } else if (!isLoad && scannedUrlState) {
      dispatch(cardMarketplaceRequest({
        rarity, sortBy, collection, page,
      }));
    }
  }, [rarity, sortBy, collection, scannedUrlState]);
  useLayoutEffect(() => () => {
    dispatch(setMarketplaceScrollPosition({ scroll: document.scrollingElement?.scrollTop || 0 }));
    dispatch(setMarketplaceFilters({ isScannedUrl: false, withoutUrlChange: true }));
  }, []);

  return (
    <>
      <Helmet title={t('helmet.marketplace')} />
      <Filters
        filters={{ rarity }}
        onFiltersApply={(rar) => dispatch(setMarketplaceFilters({ rarity: rar.rarity }))}
        isLoading={!isCollectionsLoad}
        isDisabled={false}
        defaultShowOption={
            urlFilters.collection ? {
              label: urlFilters.collection.label,
              value: urlFilters.collection.value,
            } : undefined
          }
        defaultSortOption={
            urlFilters.sortBy ? sortTypes.find(
              ({ value: v }) => v === urlFilters.sortBy,
            ) : undefined
          }
        options={[defaultCollectionOption, ...collections]}
        onChange={(newValue) => {
          if (newValue) {
            dispatch(setMarketplaceFilters({ collection: newValue }));
          }
        }}
        sortValue={sortBy ? sortTypes.find(
          ({ value: v }) => v === sortBy,
        ) : undefined}
        onSortChange={(newValue) => dispatch(setMarketplaceFilters({ sortBy: newValue?.value }))}
        sortTypes={sortTypes}
        isFilterActive={isFilterActive}
        data-test-id="filter_box"
      />
      <CardsWrapper data-test-id="mp_card_wrapper">
        {
          !!cards.length && (
            <CardsContainer>
              {
                cards.map((cardProps) => (
                  <MarketplaceCard
                    data-test-id="mp_card_container"
                    key={cardProps.nftItemId}
                    {...cardProps}
                    currentWalletAddress={isWalletConnected}
                    soundControl={!isMobile}
                    onBuyClick={onBuyClick}
                  />
                ))
              }
            </CardsContainer>
          )
        }
        {
          isLoad && !cards.length && (
            <Typography data-test-id="marketplace_empty_listed_label" size="unmedia21" textColor="textWhite" bold>{t('marketplace.noListings')}</Typography>
          )
        }
        { !isLoad && <Loader /> }
        {
          isLoad && isMoreToLoad && (
            <LoadMarketplaceButton
              data-test-id="mp_load_more_button"
              onClick={() => {
                dispatch(cardMarketplaceRequest({
                  rarity, sortBy, collection, page,
                }));
              }}
              background="dark"
              noneHover={isLoading}
              disabled={isLoading}
              $loading={isLoading}
            >
              {!isLoading && t('common.loadMore')}
              {isLoading && <NotificationsLoader inline />}
            </LoadMarketplaceButton>
          )
        }
      </CardsWrapper>
      {
        popUpState.isOpen && (
          <CardPagePopUp
            shareCallback={() => { }}
            onSaleReset={() => {
              dispatch(cardForSaleReset());
              dispatch(setMarketplacePurchasingState({ state: 'initial', cardPageUrl: '', isPurchasing: false }));
            }}
            onShareClick={() => {
              dispatch(cardForSaleReset());
              dispatch(setMarketplacePurchasingState({ state: 'initial', isPurchasing: false }));
              setIsShareOpen(true);
            }}
            {...popUpState}
          />
        )
      }
      {isShareOpen && (
        <Share
          onHide={() => setIsShareOpen(false)}
          type="card"
          url={`${window.location.protocol}//${window.location.host}${popUpState.cardUrl}`}
        />
      )}
      {isWalletModalOpen
        && (
          <SelectWalletModal
            ethTokens={[]}
            closeWalletModal={() => setIsWalletModalOpen(false)}
            onBuy={onBuyCard}
            onBuyEth={onBuyCard}
          />
        )}
    </>
  );
};

export default MarketplacePage;
