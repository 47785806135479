import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardPagePopUpProps } from './types';
import PopUp from '../../../../components/PopUp';
import Button from '../../../../components/Button';
import { ButtonsContainer } from './styled';
import { flowCancelSaleOfferRequest } from '../../../../store/flow/flowActions';
import { cardForSaleReset, cardSaleSetProgress } from '../../../../store/saleCard/saleCardActions';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../../../breakpoints';
import { isIosOrAndroidWithShare } from '../../../../util/isIosOrAndroidWithShare';
import { shareUrl } from '../../../../helpers/shareMobileUrl';

const CardPagePopUp: FC<CardPagePopUpProps> = ({
  modalText, forSaleState, onSaleReset, closeButtonText,
  nftItemId, onShareClick, shareCallback,
  cardUrl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onRemoveFromSaleClick = () => {
    dispatch(cardForSaleReset());
    dispatch(cardSaleSetProgress({ inProgress: true }));
    dispatch(flowCancelSaleOfferRequest({ nftItemId: nftItemId || -1 }));
  };
  const width = useWindowSize();
  const onShare = () => {
    const url = cardUrl || window.location.href;
    if (width <= desktopBreakpoint && isIosOrAndroidWithShare()) {
      shareUrl(url, '', '', shareCallback);
    } else {
      onShareClick();
    }
  };
  return (
    <>
      <PopUp
        text={modalText}
        onHide={onSaleReset}
      >
        <ButtonsContainer>
          { (forSaleState === 'success' || forSaleState === 'purchased') && <Button size="big" fluid onClick={onShare}>{t('common.share')}</Button> }
          { forSaleState === 'removing' && <Button size="big" background="yellow" onClick={onRemoveFromSaleClick} textColor="black" fluid>{t('card.sale.remove')}</Button>}
          <Button size="big" background="black" onClick={onSaleReset} fluid>{closeButtonText}</Button>
        </ButtonsContainer>
      </PopUp>
    </>
  );
};

export default CardPagePopUp;
