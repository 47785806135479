import React, { FC } from 'react';
import { Column, ColumnLink } from 'components/Table';
import {
  CollectionMedia, RankingChangeContent, RankingRowCollectionContainer,
  RankingRowCollectionText,
  RankingTableContentRow, RankingTableTypography,
  RankingDashRune,
} from '../styled';
import { RankingRowProps } from '../types';
import { numberWithCommas } from '../../../helpers/TextHelpers';
import changeUp from '../../../static/ic-change-up.svg';
import changeDown from '../../../static/ic-change-down.svg';

const RankingContentRow: FC<RankingRowProps> = ({
  rank, media, buyers, collectionName,
  collectionAuthor, cardInCirculations, sales,
  transactions, change, collectionLink,
}) => (
  <RankingTableContentRow data-test-id="ranking_table_row" isDark>
    <Column data-test-id="ranking_table_number" justify="center" bold>{rank}</Column>
    <Column>
      <ColumnLink
        data-test-id="ranking_table_collection"
        to={collectionLink}
        target="_blank"
        notInlineBlock
      >
        <RankingRowCollectionContainer>
          <CollectionMedia data-test-id="ranking_table_collection_media" media={media} />
          <RankingRowCollectionText>
            <RankingTableTypography data-test-id="ranking_table_collection_name" data-collectionName-attribute={`${collectionName}`} bold uppercase>
              {collectionName}
            </RankingTableTypography>
            <RankingTableTypography data-test-id="ranking_table_collection_author">
              {collectionAuthor}
            </RankingTableTypography>
          </RankingRowCollectionText>
        </RankingRowCollectionContainer>
      </ColumnLink>
    </Column>
    <Column data-test-id="ranking_table_sales" data-sales-attribute={`${sales}`} justify="center" bold>
      {`$${numberWithCommas(sales, 0)}`}
    </Column>
    <Column data-test-id="ranking_table_change" justify="center" bold>
      {
        typeof change === 'number' ? (
          <RankingChangeContent data-test-id="ranking_change_content" change={change}>
            <img src={change >= 0 ? changeUp : changeDown} alt="change" />
            {`${Math.abs(change * 100).toFixed(0)}%`}
          </RankingChangeContent>
        ) : (
          <RankingDashRune />
        )
      }
    </Column>
    <Column data-test-id="ranking_table_buyers" data-buyers-attribute={buyers} justify="center" bold>{numberWithCommas(buyers, 0)}</Column>
    <Column data-test-id="ranking_table_transactions" justify="center" bold>{numberWithCommas(transactions, 0)}</Column>
    <Column data-test-id="ranking_table_cards_in_circulation" data-crc-attribute={cardInCirculations} justify="center" bold>{numberWithCommas(cardInCirculations, 0)}</Column>
  </RankingTableContentRow>
);

export default RankingContentRow;
