import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
// import ns2 from './en/translations.json';
// import ns3 from './zh/translations.json';
// import moment from 'moment';

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'navigator'],
  caches: ['localStorage', 'cookie'],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    detection: options,
    // resources: {
    //   en: {
    //     translations: ns2,
    //   },
    //   zh: {
    //     translations: ns3,
    //   },
    // },
    ns: ['translations'],
    defaultNS: 'translations',
    react: {
      wait: true,
    },
    supportedLngs: ['en'],
    interpolation: { escapeValue: false },
  });

// i18n.on('languageChanged', async (lng) => {
//   if (lng === 'ja') await import(`moment/locale/${lng}`);
//   if (lng === 'zh') await import('moment/locale/zh-tw');
//   moment.locale(lng);
// });

export default i18n;
