import { createAction } from '@reduxjs/toolkit';
import {
  Collection as CollectionSchema, Media, PackDistribution, Rarity,
} from '@starly/starly-types';

type CollectionRequestPayload = {
  id: string
};

type CollectionResponsePayload = {
  collection: CollectionSchema
};

export type CollectionStats = {
  cards_in_circulation: number
  sales: number
  buyers: number
  top_sale: number
};

type CollectionStatsRequestPayload = {
  collectionId: string
};

type CollectionStatsResponsePayload = {
  collectionId: string
  stats: CollectionStats
};

type UpdateStep1Request = {
  title: string
  description: string
  promo_video_url: string
  completed_collection_reward: string
  colId: string
  userId: string
  isUpdate?: boolean
};

type UpdateStep1Response = {
  title: string
  description: string
  promo_video_url: string
  completed_collection_reward: string
  colId: string
  userId: string
};

type UpdateStep3Request = {
  colId: string
  pricing: {
    common: number,
    rare: number,
    legendary: number
  }
  priceIndex: number
  dropDurIndex: number
  saleDurIndex: number
  activeStep?: number
  schedule_nft_drop: string
  sale_duration: string
};

type UpdateStep3Response = {
  colId: string
  priceIndex: number | null
  dropDurIndex: number | null
  saleDurIndex: number | null
  activeStep?: number
};

type ChangeStep3SelectValues = {
  field: string
  value: number
  priceIndex: number | null
  dropDurIndex: number | null
  saleDurIndex: number | null
};

type ActiveCollectionRequestPayload = {
  defaultStep?: string
  checkState?: boolean
  errorCallback: () => void
};

type ActiveCollectionResponsePayload = {
  collectionId: string
  title: string
  description: string
  promo_video_url: string
  completed_collection_reward: string
  imgCover: string
  mediaObj: Media
  _cached_cards: any
  sale_duration?: string
  schedule_nft_drop?: string
  priceIndex?: number
  dropDurIndex?: number
  saleDurIndex?: number
  defaultStep?: number
  cardInfo: any,
  packDistribution: PackDistribution,
};

type WriteCoverImagePayloadRequest = {
  file: any
  userId: string
  colId: string
  otherFields: any
};

type DeleteCollectionRequestPayload = {
  colId: string
};

type BurnUnsoldCardsRequestPayload = {
  collectionId: string
  cb: () => void
};

type BurnUnsoldCardsErrorPayload = {
  error: any
};

type BurnUnsoldCardsSuccessPayload = {
  id: string
};

type WriteCardImagePayloadRequest = {
  colId: string
  userId: string
  file: File
  cardId: number
  values: any
};

type WriteCoverImagePayloadResponse = {
  base64: string
  mediaObj: Media
  otherFields: any
};

type WriteCardImagePayloadResponse = {
  file: File
  cardId: number
};

type PostStep2RequestPayload = {
  type: string
  data: any
  collectionId: string
  userId: string
};

type UpdateStep2Payload = {
  type: string
  data: any
  collectionId: string
  userId: string
};

type DeleteCollectionResponsePayload = {
  collectionId: string
};

type SetStepPayload = {
  value: number
};

type PublishCollectionRequest = {
  id: string
  username: string,
  history?: any
};

type PublishCollectionError = {
  error: any
};

type UploadMediaPayload = {
  progress: number
  rarity: Rarity
  index: number
};

type SetMediaPayload = {
  url: string
  type: string
  rarity: Rarity
  index: number
  values: any
};

type MediaStatePayload = {
  media: any
  rarity: Rarity
  index: number
};

type CoverMediaStatePayload = {
  media: any
  colId: string
};

type UploadCoverImagePayload = {
  progress: number
};

type TopCollectorsRequestPayload = {
  id: string
};

type TopCollectorsResponsePayload = {
  topCollectors: any;
  id: string;
};

type ViewAllCardsPayload = {
  id: string
  value: boolean
};

type CountersRequestPayload = {
  id: string
};

type CountersResponsePayload = {
  counterData: any;
  id?: string;
};

type LastCollectionRequest = {
  id?: string;
};

type LastCollectionResponse = {
  data?: any;
};

type LastCollectionReset = {
  data: {};
};

type LastCollectionTopCollectorsResponsePayload = {
  topCollectors: any;
};

type UpdateFlagPayload = {
  id: string
  isUpdated: boolean
};

type SetCreateModalPayload = {
  isOpen: boolean
};

export const collectionRequest = createAction<CollectionRequestPayload>('collection/Request');
export const collectionResponse = createAction<CollectionResponsePayload>('collection/Response');

export const collectionStatsRequest = createAction<CollectionStatsRequestPayload>('collection/StatsRequest');
export const collectionStatsResponse = createAction<CollectionStatsResponsePayload>('collection/StatsResponse');

export const startLoading = createAction('collection/loading');
export const toStartStep = createAction('steps/toStart');
export const setStep = createAction<SetStepPayload>('steps/setStep');

export const switchCreating = createAction('collection/Start');

export const publishCollectionRequest = createAction<PublishCollectionRequest>('collection/publishRequest');
export const publishCollectionResponse = createAction('collection/publishResponse');
export const publishCollectionError = createAction<PublishCollectionError>('collection/publishError');
export const publishCollectionResetStatus = createAction('collection/publishResetStatus');

// ActiveCollection

export const activeCollectionRequest = createAction<ActiveCollectionRequestPayload>('collection/activeCollectionRequest');
export const activeCollectionResponse = createAction<ActiveCollectionResponsePayload>('collection/activeCollectionResponse');
export const setActiveCollection = createAction<SetActiveCollectionPayload>('collection/setActiveCollection');

export const deleteCollectionRequest = createAction<DeleteCollectionRequestPayload>('collection/deleteCollectionRequest');
export const deleteCollectionResponse = createAction<DeleteCollectionResponsePayload>('collection/deleteCollectionResponse');
export const deleteCollectionError = createAction<BurnUnsoldCardsErrorPayload>('collection/deleteCollectionError');
export const deleteCollectionSuccess = createAction<BurnUnsoldCardsSuccessPayload>('collection/deleteCollectionSuccess');
export const deleteCollectionResetError = createAction('collection/deleteCollectionResetError');
export const setDeleteCollectionMessage = createAction('collection/setDeleteCollectionMessage');
export const removeDeleteCollectionMessage = createAction('collection/removeDeleteCollectionMessage');

export const burnUnsoldCardsRequest = createAction<BurnUnsoldCardsRequestPayload>('collection/burnUnsoldCardsRequest');
export const burnUnsoldCardsError = createAction<BurnUnsoldCardsErrorPayload>('collection/burnUnsoldCardsError');
export const burnUnsoldCardsSuccess = createAction('collection/burnUnsoldCardsSuccess');
export const burnUnsoldCardsResetError = createAction('collection/burnUnsoldCardsResetError');

export const updateCollectionError = createAction<BurnUnsoldCardsErrorPayload>('collection/updateCollectionError');

export const initEmptyActiveCollection = createAction('collection/initEmptyCollection');

// StepsUpdate

export const updateStep1Request = createAction<UpdateStep1Request>('collection/updateStep1Request');
export const updateStep1Response = createAction<UpdateStep1Response>('collection/updateStep1Response');
export const updateStep3Request = createAction<UpdateStep3Request>('collection/updateStep3Request');
export const updateStep3Response = createAction<UpdateStep3Response>('collection/updateStep3Response');
export const changeStep3SelectValues = createAction<ChangeStep3SelectValues>('collection/changeStep3SelectValues');

export const postStep2Request = createAction<PostStep2RequestPayload>('collection/postStep2Request');
export const postStep2Response = createAction('collection/postStep2Response');
export const startEdit = createAction('collection/startEdit');
export const updateStep2 = createAction<UpdateStep2Payload>('collection/updateStep2');
export const validateStep2 = createAction('collection/validateStep2');
export const resetValidationStep2 = createAction('collection/resetValidationStep2');

// ImageActions

export const writeCoverImageRequest = createAction<WriteCoverImagePayloadRequest>('coverImage/Request');
export const writeCoverImageResponse = createAction<WriteCoverImagePayloadResponse>('coverImage/Response');
export const uploadingCoverMedia = createAction<UploadCoverImagePayload>('coverImage/progress');

export const writeCardImageRequest = createAction<WriteCardImagePayloadRequest>('cardImage/Request');
export const writeCardImageResponse = createAction<WriteCardImagePayloadResponse>('cardImage/Response');

export const uploadingMedia = createAction<UploadMediaPayload>('media/upload');
export const setMedia = createAction<SetMediaPayload>('media/complete');
export const setMediaState = createAction<MediaStatePayload>('media/state');
export const setCoverMediaState = createAction<CoverMediaStatePayload>('coverImage/state');

export const coverImageLoading = createAction('coverImage/loaded');

export const topCollectorsRequest = createAction<TopCollectorsRequestPayload>('topCollectors/Request');
export const topCollectorsResponse = createAction<TopCollectorsResponsePayload>('topCollectors/Response');

export const viewAllCards = createAction<ViewAllCardsPayload>('collection/viewAllCards');

export const countersRequest = createAction<CountersRequestPayload>('counters/Request');
export const countersResponse = createAction<CountersResponsePayload>('counters/Response');

export const lastCollectionRequest = createAction<LastCollectionRequest>('lastCollection/Request');
export const lastCollectionResponse = createAction<LastCollectionResponse>('lastCollection/Response');
export const lastCollectionReset = createAction<LastCollectionReset>('lastCollection/Reset');
export const lastCollectionTopCollectorsResponse = createAction<LastCollectionTopCollectorsResponsePayload>('lastCollectionTopCollectorsResponse/Response');

export const updateFlag = createAction<UpdateFlagPayload>('collection/updateFlag');

// Create modal
export const setCreateModal = createAction<SetCreateModalPayload>('collection/setCreateModal');

export const setUnavailableModal = createAction<SetCreateModalPayload>('collection/setUnavailableModal');
