import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthAuthorized } from 'store/auth/authSelectors';

import { useWindowSize } from 'hooks/useWindowSize';
import { desktopBreakpoint } from 'breakpoints';
import discordLogo from 'static/ic-floating-discord@2x.png';
import { starlyDsLink } from 'global/constants';

import {
  DiscordLogo,
  DiscordStickyContainer,
  StyledDiscordLink,
} from '../styled';

export const DiscordFloatingLogo: FC<{
  hideOnMobile?: boolean, isAnimated?: boolean, disappeared?: boolean
}> = ({
  hideOnMobile, isAnimated, disappeared,
}) => {
  const width = useWindowSize();
  const isAuthorized = useSelector(selectAuthAuthorized);
  const isMobile = width < desktopBreakpoint;

  if ((isMobile && hideOnMobile) || !isAuthorized) return null;

  return (
    <DiscordStickyContainer>
      <StyledDiscordLink
        data-test-id="discord_logo"
        href={starlyDsLink}
        isAnimated={isAnimated}
        disappeared={disappeared}
        target="_blank"
        rel="noreferrer"
      >
        <DiscordLogo src={discordLogo} />
      </StyledDiscordLink>

    </DiscordStickyContainer>
  );
};
