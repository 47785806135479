import { FC } from 'react';
import ReactDOM from 'react-dom';

const ComponentPortal: FC<{
  element?: HTMLElement | null
  pureRender?: boolean
}> = ({ children, element, pureRender }) => {
  if (pureRender) {
    return children;
  }

  if (!element) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      children,
      element,
    )
  );
};

export default ComponentPortal;
