// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import { UFix64 } from '@onflow/types';

const CODE = fcl.cdc`
import FusdUsdtSwapPair from 0xFUSD_USDT_SWAP
import FlowSwapPair from 0xFLOW_SWAP

pub fun main(amount: UFix64): UFix64? {
  let amountUsdt = FusdUsdtSwapPair.quoteSwapToken2ForExactToken1(amount: amount)
  let amountIn = FlowSwapPair.quoteSwapToken1ForExactToken2(amount: amountUsdt) / (1.0 - FlowSwapPair.feePercentage)

  return amountIn
}`;

export function fetchFusdToFlowAmount(amount: number) {
  if (amount == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(amount.toFixed(8), UFix64),
    ]),
  ]).then(fcl.decode);
}
