import React, { FC } from 'react';
import shortid from 'shortid';
import {
  CheckboxItem,
  CustomCheckbox,
  CheckboxLabel,
} from './styled';
import { CheckBoxProps } from './types';

const CheckBox: FC<CheckBoxProps> = ({
  label,
  isEnable,
  name,
  register,
  onChange,
  id = shortid(),
  $filter,
  'data-test-id': dataTestId,
}) => (
  <CheckboxItem $filter={$filter}>
    <CustomCheckbox
      data-test-id={`cb_checkbox_${name}_${dataTestId}`}
      id={id}
      type="checkbox"
      name={name || 'notifications'}
      data-testid="checkbox"
      checked={isEnable}
      onChange={onChange}
      {...(register && name ? register(name) : {})}
    />
    <CheckboxLabel htmlFor={id} data-test-id={`cb_label_${name}_${dataTestId}`} data-testid="notifications_checkbox">{label}</CheckboxLabel>
  </CheckboxItem>
);

export default CheckBox;
