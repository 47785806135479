import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table, { Column } from 'components/Table/Table';
import {
  RankingCollectorsHeaderRow, RankingTableWrapper,
  RankingUserRowContainer, TopCollectorsRewardBlockContainer,
} from '../styled';
import RankingCollectorsContentRow from '../components/RankingCollectorsContentRow';
import {
  rankingCollectorsRequest,
  resetRankingCollectorsState,
  userRankingScoreRequest,
  resetUserRankingState, rewardSnapshotsRequest,
  rewardConfigRequest,
} from '../../../store/ranking/rankingActions';
import {
  selectRankingCollectorsState, selectRankingRewards,
  selectUserRanking, selectUserRankingScoreById,
} from '../../../store/ranking/rankingSelectors';
import Loader from '../../../components/Loader';
import { selectAuthAuthorized, selectAuthUserId } from '../../../store/auth/authSelectors';
import Helmet from '../../../components/Helmet/Helmet';
import TopCollectorsRewardInfo from '../../../components/TopCollectorsRewardInfo';

const RankingCollectors: FC<{
  setIsPopUpOpen: () => void
}> = ({ setIsPopUpOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    page, rankings, isLoad,
  } = useSelector(selectRankingCollectorsState);
  const isAuthorized = useSelector(selectAuthAuthorized);
  const userId = useSelector(selectAuthUserId);
  const { isLoading: isUserLoading } = useSelector(selectUserRanking);
  const userRanking = useSelector(selectUserRankingScoreById(userId));
  const { rewardsData } = useSelector(selectRankingRewards);

  useEffect(() => {
    dispatch(rewardConfigRequest());
    dispatch(rewardSnapshotsRequest({}));
    dispatch(rankingCollectorsRequest({ ...page }));
    return () => {
      dispatch(resetRankingCollectorsState());
    };
  }, []);

  useEffect(() => {
    if (isAuthorized && userId) {
      dispatch(userRankingScoreRequest({ isOwnProfile: true }));
    }
    return () => {
      dispatch(resetUserRankingState());
    };
  }, [isAuthorized]);

  return (
    <>
      <Helmet
        title={t('helmet.topCollectors')}
      />
      { (!isLoad || isUserLoading || !rewardsData) && <Loader /> }
      {
        isLoad && !isUserLoading && rewardsData.rewardPool && rewardsData.collectorsCount && (
          <TopCollectorsRewardBlockContainer>
            <TopCollectorsRewardInfo
              onPopUpOpen={setIsPopUpOpen}
              rewardPool={rewardsData.rewardPool}
              qualifiedForRewards={rewardsData.collectorsCount}
            />
          </TopCollectorsRewardBlockContainer>
        )
      }
      {
        isLoad && !isUserLoading && (
          <RankingTableWrapper data-test-id="top_collectors_table_wrapper">
            <Table>
              <RankingCollectorsHeaderRow data-test-id="top_collectors_table_header" header isDark>
                <Column textAlign="center">#</Column>
                <Column>Collector</Column>
                <Column textAlign="center">Score</Column>
                <Column textAlign="center">Legendary cards</Column>
                <Column textAlign="center">Rare cards</Column>
                <Column textAlign="center">Common cards</Column>
                <Column textAlign="center">Cards total</Column>
              </RankingCollectorsHeaderRow>
              {
                isAuthorized && userRanking && (
                  <RankingUserRowContainer>
                    <RankingCollectorsContentRow {...userRanking} />
                  </RankingUserRowContainer>
                )
              }
              {rankings.map((props) => (
                <RankingCollectorsContentRow {...props} key={`${props.place}${props.link}`} />
              ))}
            </Table>
          </RankingTableWrapper>
        )
      }
    </>
  );
};

export default RankingCollectors;
