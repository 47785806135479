import React, { FC, useRef } from 'react';
import { PopUpProps } from './types';

import {
  PopUpOverlay,
  PopUpWrapper,
  PopUpText,
} from './styled';

const PopUp: FC<PopUpProps> = ({
  children,
  text,
  onHide,
  padding,
  mobilePadding,
  width,
  'data-test-id': dataTestId,
}) => {
  const refModal = useRef<HTMLDivElement>(null);

  const closeModal = (e: any) => {
    if (refModal.current && !refModal.current.contains(e.target)) {
      if (onHide) onHide();
    }
  };

  return (
    <PopUpOverlay onClick={closeModal}>
      <PopUpWrapper
        data-test-id={dataTestId}
        ref={refModal}
        $padding={padding}
        $width={width}
        $mobilePadding={mobilePadding}
      >
        {!!text && <PopUpText>{text}</PopUpText>}
        {children}
      </PopUpWrapper>
    </PopUpOverlay>
  );
};

export default PopUp;
