import { createAction } from '@reduxjs/toolkit';

// TODO replace with real payload
export type AuthOtherPayload = any;

export const authLogoutRequest = createAction<AuthOtherPayload>('auth/logout/request');

export const authLogoutResponse = createAction<AuthOtherPayload>('auth/logout/response');

export const authLoginEmailRequest = createAction<AuthOtherPayload>('auth/login/email/request');

export const authLoginEmailResponse = createAction<AuthOtherPayload>('auth/login/email/response');

export const authLoginGoogleRequest = createAction<AuthOtherPayload>('auth/login/google/request');

export const authLoginGoogleResponse = createAction<AuthOtherPayload>('auth/login/google/response');

export const authLoginTwitterRequest = createAction<AuthOtherPayload>('auth/login/twitter/request');

export const authLoginTwitterResponse = createAction<AuthOtherPayload>('auth/login/twitter/response');

export const authLoginFacebookRequest = createAction<AuthOtherPayload>('auth/login/facebook/request');

export const authLoginFacebookResponse = createAction<AuthOtherPayload>('auth/login/facebook/response');

export const authLoginWechatRequest = createAction<AuthOtherPayload>('auth/login/wechat/request');

export const authLoginWechatResponse = createAction<AuthOtherPayload>('auth/login/wechat/response');

export const authLoginCustomTokenRequest = createAction<AuthOtherPayload>('auth/login/custom_token/request');

export const authLoginCustomTokenResponse = createAction<AuthOtherPayload>('auth/login/custom_token/response');

export const authSignupEmailRequest = createAction<AuthOtherPayload>('auth/signup/email/request');

export const authSignupEmailResponse = createAction<AuthOtherPayload>('auth/signup/email/response');

export const authError = createAction<AuthOtherPayload>('auth/error');

export const authSetIsAuthorized = createAction<AuthOtherPayload>('auth/SET_IS_AUTHORIZED');

export const authSetIsProfileCreating = createAction<AuthOtherPayload>('auth/SET_IS_PROFILE_CREATING');
