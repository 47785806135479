import {
  all, call, fork, put, takeEvery, delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
// @ts-ignore
import {
  firestore,
  functions,
  DocumentSnapshot,
  QuerySnapshot,
  trackException,
  trackEvent, Query,
} from 'global/firebase';
import {
  FlowFestCard,
  FlowFestPack,
  FlowFestProject,
} from '@starly/starly-types';
import { RouteTypes } from 'RouteTypes';
import {
  defaultProjectOption,
  sortMapping,
  sortTypes,
} from 'views/FlowMarketplace/utils/data';
import { flowFestBuyTransaction } from 'flow/flowfest/flowFestBuyFacade';
import { flowFestSellTransaction } from 'flow/flowfest/flowFestSellFacade';
import { flowFetchNFTIDsMainnetScript } from 'flow/flowfest/fetchNFTIDs.mainnet.script';
import { flowFetchNFTIDsTestnetScript } from 'flow/flowfest/fetchNFTIDs.testnet.script';
import { flowFestRemoveListingItemTransaction } from 'flow/flowfest/flowFestRemoveListing.tx';
import { cardForSaleError, cardForSalePurchased } from 'store/saleCard/saleCardActions';
import { LISTINGS_LIMIT } from 'util/constants';
import {
  flowFestProjectRequest,
  flowFestProjectResponse,
  redeemCodeRequest,
  redeemCodeResponse,
  redeemCodeFailure,
  ffReservePackRequest,
  ffReservePackResponse,
  ffReservePackFailure,
  ffShowPackRequest,
  ffShowPackResponse,
  ffShowPackFailure,
  ffOpenPackRequest,
  ffOpenPackResponse,
  ffOpenPackFailure,
  getFlowFestPackStateRequest,
  getFlowFestPackStateResponse,
  getFlowFestPackStateFailure,
  ffValidateCaptchaRequest,
  ffValidateCaptchaResponse,
  ffValidateCaptchaFailure,
  ffViewCardRequest,
  ffViewCardResponse,
  ffViewCardFailure,
  ffViewPackRequest,
  ffViewPackResponse,
  ffViewPackFailure,
  flowFestCheckWalletRequest,
  flowFestCheckWalletResponse,
  flowFestCheckWalletFailure,
  ffClearReservePackRequest,
  ffClearReservePackResponse,
  loadFFProjectsRequest,
  loadFFProjectsResponse,
  loadFFProjectsFailure,
  loadFFCardsRequest,
  loadFFCardsResponse,
  loadFFCardsFailure,
  ExtendedFFCard,
  buyFFCardRequest,
  buyFFCardResponse,
  buyFFCardFailure,
  resetFFCards,
  saleFFCardRequest,
  loadMyFFCardsRequest,
  loadMyFFCardsResponse,
  loadMyFFCardsFailure,
  removeFromSaleFFCardRequest,
  saleFFCardResponse,
  removeFromSaleFFCardResponse,
  saleFFCardFailure,
  removeFromSaleFFCardFailure,
} from './flowFestActions';

import { flowIsFlowFestAccountInitializedTestnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.testnet.script';
import { flowIsFlowFestAccountInitializedMainnetScript } from '../../flow/flowfest/isFlowFestAccountInitialized.mainnet.script';

function* watchProjectRequest() {
  yield takeEvery(flowFestProjectRequest, function* takeEveryCollectionRequest(action) {
    try {
      const { payload: { id } } = action;
      const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('flowFestProjects').doc(id).get());
      const project = collectionRef.data() as FlowFestProject;
      yield put(flowFestProjectResponse({ project }));
    } catch (error: any) {
      trackException(error.message);
    }
  });
}

function* watchffOpenPack() {
  yield takeEvery(ffOpenPackRequest,
    function* takeffOpenPackRequest() {
      try {
        const openPack = functions.httpsCallable('openFlowFestPack');
        const { data } = yield call(openPack);

        if (data.error) {
          yield put(ffOpenPackFailure({ error: data.error }));
        } else {
          const cardRefs: DocumentSnapshot = yield all((data.card_ids || []).map(
            (cardId) => call(() => firestore.collection('flowFestCards').doc(cardId).get()),
          ));
          const cards = cardRefs.map((cardRef) => cardRef.data() as Card);

          yield put(ffOpenPackResponse({ pack: data, cards }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffOpenPackFailure({ error }));
      }
    });
}

function* watchffShowPack() {
  yield takeEvery(ffShowPackRequest,
    function* takeffShowPackRequest() {
      try {
        const openPack = functions.httpsCallable('showFlowFestPack');
        const { data } = yield call(openPack);

        if (data.error) {
          yield put(ffShowPackFailure(data));
        } else {
          const cardIds: string[] = data.map((pack: FlowFestPack) => pack.card_ids).flat();

          const cardRefs: DocumentSnapshot[] = yield all((cardIds || []).map(
            (cardId) => call(() => firestore.collection('flowFestCards').doc(cardId).get()),
          ));
          const cards = cardRefs.map((cardRef) => cardRef.data() as FlowFestCard);

          yield put(ffShowPackResponse({ packs: data, cards }));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffShowPackFailure({ error: 'Error' }));
      }
    });
}

function* watchffViewPack() {
  yield takeEvery(ffViewPackRequest,
    function* takeffViewPackRequest({ payload: { pack_id } }) {
      try {
        const viewPack = functions.httpsCallable('viewFlowFestPack');
        const { data } = yield call(() => viewPack({ pack_id }));
        yield put(ffViewPackResponse({ data }));
      } catch (error: any) {
        trackException(error.message);
        yield put(ffViewPackFailure());
      }
    });
}

function* watchffViewCard() {
  yield takeEvery(ffViewCardRequest,
    function* takeffViewCardRequest({ payload: { pack_id, card_id } }) {
      try {
        const viewCard = functions.httpsCallable('viewFlowFestCard');
        const { data } = yield call(() => viewCard({ pack_id, card_id }));
        yield put(ffViewCardResponse({ pack: data }));
      } catch (error: any) {
        trackException(error.message);
        yield put(ffViewCardFailure());
      }
    });
}

function* watchffReservePack() {
  yield takeEvery(ffReservePackRequest,
    function* takeffReservePackRequest() {
      try {
        const reservePack = functions.httpsCallable('reserveFlowFestPack');
        const { data } = yield call(() => reservePack());
        if (data.error) {
          yield put(ffReservePackFailure(data));
        } else {
          yield put(ffReservePackResponse());
          trackEvent('flow_fest_claim_started');
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffReservePackFailure({ error: 'Error' }));
      }
    });
}

function* watchffValidateCaptcha() {
  yield takeEvery(ffValidateCaptchaRequest,
    function* takeffValidateCaptchaRequest({ payload: { captcha_response } }) {
      try {
        const validateCaptcha = functions.httpsCallable('validateFlowFestCaptcha');
        const { data } = yield call(() => validateCaptcha({ captcha_response }));
        if (data.error) {
          yield put(ffValidateCaptchaFailure(data));
        } else {
          yield put(ffValidateCaptchaResponse(data));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(ffValidateCaptchaFailure({ error: 'Error' }));
      }
    });
}

function* watchGetFlowFestPackState() {
  yield takeEvery(getFlowFestPackStateRequest,
    function* takeGetFlowFestPackStateRequest() {
      try {
        const reservePack = functions.httpsCallable('getFlowFestPackState');
        const { data } = yield call(() => reservePack());
        if (data.error) {
          yield put(getFlowFestPackStateFailure(data));
        } else {
          yield put(getFlowFestPackStateResponse(data));
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(getFlowFestPackStateFailure({ error: 'Error' }));
      }
    });
}

function* watchRedeemCodeRequest() {
  yield takeEvery(redeemCodeRequest,
    function* takeRedeemCode({ payload: { code } }) {
      try {
        const redeemCode = functions.httpsCallable('redeemFlowFestCode');
        const { data } = yield call(() => redeemCode({ code }));
        if (data.error) {
          yield put(redeemCodeFailure(data));
        } else {
          yield put(redeemCodeResponse());
        }
      } catch (error: any) {
        trackException(error.message);
        yield put(redeemCodeFailure({ error: 'Error' }));
      }
    });
}

function* watchFlowFestCheckWallet() {
  yield takeEvery(flowFestCheckWalletRequest,
    function* takeEveryFlowFestCheckWalletRequest({ payload: { wallet } }) {
      try {
        if (!wallet) {
          yield put(flowFestCheckWalletResponse(false));
          return;
        }

        const script = (process.env.REACT_APP_CHAIN_ENV === 'testnet')
          ? flowIsFlowFestAccountInitializedTestnetScript
          : flowIsFlowFestAccountInitializedMainnetScript;

        const init: FlowInit = yield call(script, wallet);

        if (Object.values(init).findIndex((v) => !v) > -1) {
          yield put(flowFestCheckWalletResponse(false));
          return;
        }
        yield put(flowFestCheckWalletResponse(true));
      } catch (error: any) {
        trackException(error.message);
        yield put(flowFestCheckWalletFailure(false));
      }
    });
}

function* watchClearPackReserve() {
  yield takeEvery(ffClearReservePackRequest,
    function* takeRedeemCode() {
      try {
        const clearReserve = functions.httpsCallable('clearUserReservedFlowFestPack');
        yield call(() => clearReserve());
        yield put(getFlowFestPackStateRequest());
        yield put(ffClearReservePackResponse());
      } catch (error: any) {
        trackException(error.message);
      }
    });
}

function* watchProjectsRequest() {
  yield takeEvery(loadFFProjectsRequest, function* takeProjectsRequest() {
    try {
      const collectionRef:DocumentSnapshot = yield call(() => firestore.collection('cache').doc('flowFestProjects').get());
      const projects = collectionRef.data() || {};

      yield put(loadFFProjectsResponse({
        projects: projects._cached_flow_fest_projects.filter(
          (project: FlowFestProject) => project.marketplace_enabled,
        ).sort((a, b) => a.name.localeCompare(b.name)),
      }));
    } catch (error) {
      yield put(loadFFProjectsFailure({ error }));
      console.error(error);
    }
  });
}

let cursor: any = null;

function* watchCardsRequest() {
  yield takeEvery(loadFFCardsRequest, function* takeFFCardsRequest({
    payload: {
      filter = defaultProjectOption.value,
      sort = sortTypes[0].value,
      isResetCursor,
      rarity,
    },
  }) {
    try {
      let query: Query;
      let sortedQuery: Query;
      const isDefaultProject: boolean = filter === defaultProjectOption.value;

      if (isDefaultProject) {
        sortedQuery = firestore.collection('flowFestListings')
          .where('state', '==', 'created')
          .orderBy(sortMapping[sort], sort.includes('desc') ? 'desc' : 'asc');
      } else {
        sortedQuery = firestore.collection('flowFestListings')
          .where('state', '==', 'created')
          .where('project_id', '==', filter)
          .orderBy(sortMapping[sort], sort.includes('desc') ? 'desc' : 'asc');
      }

      if (rarity) {
        const rarityFilter = rarity
          .filter(({ active }) => active)
          .map(({ value }) => value);

        if (rarityFilter.length !== 0) {
          sortedQuery = sortedQuery.where('rarity', 'in', rarityFilter);
        }
      }

      if (isResetCursor) {
        cursor = null;
        yield put(resetFFCards());

        query = sortedQuery.limit(LISTINGS_LIMIT);
      } else {
        query = sortedQuery.startAfter(cursor).limit(LISTINGS_LIMIT);
      }

      const { docs: listingDocs }: QuerySnapshot = yield call(() => query.get());

      cursor = listingDocs[listingDocs.length - 1];

      const listings = listingDocs.map((doc) => doc.data());

      if (listings.length) {
        const cardsRef: QuerySnapshot = yield call(() => firestore.collection('flowFestCards')
          .where('id', 'in', listings.map((listing) => listing.card_id))
          .limit(10)
          .get());

        const unsortedCards = cardsRef.docs.map((doc) => doc.data() as FlowFestCard);
        const cards = listings.map((listing) => {
          // Combine card fields with listings
          const card = unsortedCards.find((_card) => _card.id === listing.card_id) || {};

          return {
            ...card,
            price: listing.price,
            storefrontAddress: listing.storefrontAddress,
            ftType: listing.ftType,
            projectName: listing.project_name,
            listingResourceID: parseInt(listing.id, 10),
          } as ExtendedFFCard;
        });

        yield put(loadFFCardsResponse({ cards, isLastCards: listings.length < LISTINGS_LIMIT }));
      } else {
        yield put(loadFFCardsResponse({
          cards: [],
          isLastCards: listings.length < LISTINGS_LIMIT,
        }));
      }
    } catch (error) {
      yield put(loadFFCardsFailure({ error }));
      console.error(error);
    }
  });
}

function* watchBuyCardRequest() {
  yield takeEvery(buyFFCardRequest, function* takeBuyFFCardRequest(action) {
    const {
      payload: {
        project,
        listingResourceID,
        storefrontAddress,
        buyPrice,
      },
    } = action;

    try {
      const result: object = yield call(
        flowFestBuyTransaction,
        project,
        listingResourceID,
        storefrontAddress,
        buyPrice,
      );

      if (!result || result.errorMessage) {
        yield put(cardForSaleError());
        yield put(buyFFCardFailure({ error: '', listingResourceID }));
      } else {
        yield delay(15000);
        yield put(cardForSalePurchased());
        yield put(buyFFCardResponse({ listingResourceID }));
      }
    } catch (error) {
      yield put(buyFFCardFailure({ error, listingResourceID }));
    }
  });
}

function* watchSaleFFCardRequest() {
  yield takeEvery(saleFFCardRequest, function* takeEverySaleRequest({
    payload: {
      projectId,
      itemId,
      price,
    },
  }) {
    try {
      const docSnapshot: DocumentSnapshot = yield call(() => firestore.collection('flowFestProjects').doc(projectId).get());
      const {
        marketplace_flow_account: marketplaceFlowAccount,
        marketplace_sale_cut_percents: marketplaceSaleCutPercents,
      } = docSnapshot.data() as FlowFestProject;

      yield call(
        () => flowFestSellTransaction(
          projectId,
          itemId,
          price,
          marketplaceFlowAccount,
          marketplaceSaleCutPercents,
        ),
      );

      yield delay(10000);
      yield put(saleFFCardResponse());
      yield put(push(RouteTypes.FlowFestMarketplace));
    } catch (error) {
      yield put(saleFFCardFailure());
      console.error(error);
    }
  });
}

function* watchRemoveFromSaleFFCardRequest() {
  yield takeEvery(removeFromSaleFFCardRequest, function* takeEveryRequest({ payload }) {
    try {
      yield call(flowFestRemoveListingItemTransaction, payload.id);

      yield delay(10000);
      yield put(removeFromSaleFFCardResponse());
      yield put(loadMyFFCardsRequest(payload.userWallet));
    } catch (error) {
      yield put(removeFromSaleFFCardFailure());
      console.error(error);
    }
  });
}

function* watchLoadMyFFCardsRequest() {
  yield takeEvery(loadMyFFCardsRequest, function* takeEverySaleRequest({ payload }) {
    try {
      const isMainnet = process.env.REACT_APP_CHAIN_ENV === 'mainnet';
      // eslint-disable-next-line max-len
      const flowFetchNFTIDsScript = isMainnet ? flowFetchNFTIDsMainnetScript : flowFetchNFTIDsTestnetScript;
      const result: { [key: string]: number[] } = yield call(flowFetchNFTIDsScript, payload);
      const queries: Query[] = [];
      const cards: ExtendedFFCard[] = [];

      // create queries for my cards
      Object.keys(result).forEach(async (project: string) => {
        const idsNumber = result[project]?.length || 0;
        const ids = result[project];

        if (idsNumber === 0) return;

        for (let i = 0; i < Math.ceil(idsNumber / 10); i += 1) {
          const query: Query = firestore.collection('flowFestCards')
            .where('parent_project_id', '==', project)
            .where('nft_item_id', 'in', ids.slice(i * 10, (i + 1) * 10))
            .limit(10);

          queries.push(query);
        }
      });

      const queriesSnapshot: QuerySnapshot[] = yield all(
        queries.map((query) => call(() => query.get())),
      );

      queriesSnapshot.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          cards.push(doc.data() as ExtendedFFCard);
        });
      });

      // create queries to get listing for my card
      const newQueries = cards.map((card: ExtendedFFCard) => firestore.collection('flowFestListings')
        .where('storefrontAddress', '==', payload)
        .where('project_id', '==', card.project_id)
        .where('nft_item_id', '==', card.nft_item_id)
        .where('state', '==', 'created')
        .limit(1));

      const newQueriesSnapshot: QuerySnapshot[] = yield all(
        newQueries.map((query) => call(() => query.get())),
      );

      newQueriesSnapshot.forEach((querySnapshot, index) => {
        if (querySnapshot.docs.length > 0) {
          const listing = querySnapshot.docs[0].data();

          cards[index].isForSale = true;
          cards[index].listingResourceID = Number(listing.id);
          cards[index].price = Number(listing.price);
          cards[index].ftType = listing.ftType;
          cards[index].storefrontAddress = payload;
        } else {
          cards[index].isForSale = false;
          cards[index].storefrontAddress = payload;
        }
      });

      yield put(loadMyFFCardsResponse({ cards }));
    } catch (error) {
      console.error(error);

      yield put(loadMyFFCardsFailure({ error }));
    }
  });
}

export default function* flowFestSaga() {
  yield all([
    fork(watchProjectRequest),
    fork(watchRedeemCodeRequest),
    fork(watchffReservePack),
    fork(watchffOpenPack),
    fork(watchffShowPack),
    fork(watchffValidateCaptcha),
    fork(watchGetFlowFestPackState),
    fork(watchffViewCard),
    fork(watchffViewPack),
    fork(watchFlowFestCheckWallet),
    fork(watchClearPackReserve),
    fork(watchProjectsRequest),
    fork(watchCardsRequest),
    fork(watchBuyCardRequest),
    fork(watchSaleFFCardRequest),
    fork(watchLoadMyFFCardsRequest),
    fork(watchRemoveFromSaleFFCardRequest),
  ]);
}
