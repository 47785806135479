import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router';
import {
  RankingHeaderSelectContainer,
  RankingPageContainer, RankingPageHeader, RankingPageWrapper,
} from './styled';
import Typography from '../../components/Typography';
import { setRankingFilters } from '../../store/ranking/rankingActions';
import { ReactSelectContainer } from '../../components/SelectContainer/ReactSelectContainer';
import { DiscordFloatingLogo } from '../FlowFestView/components/DiscordFloatingLogo';
import { TabSelectOption } from '../../components/TabsSelector/types';
import { RouteTypes } from '../../RouteTypes';
import { useWindowSize } from '../../hooks/useWindowSize';
import { desktopBreakpoint } from '../../breakpoints';
import TabsSelector from '../../components/TabsSelector';
import RankingPage from './pages/RankingPage';
import { MarketplaceHeader, MarketplaceLink, MarketplaceLinkSeparator } from '../Marketplace/styled';
import RankingCollectors from './pages/RankingCollectors';
import RewardPoolPopUp from '../../components/RewardPoolPopUp';
import { selectRankingRewards } from '../../store/ranking/rankingSelectors';

export const rankingPages: TabSelectOption[] = [
  {
    id: 'ranking-collectors-page',
    href: RouteTypes.Ranking,
    label: 'Top collectors',
  },
  {
    id: 'ranking-page',
    href: RouteTypes.RankingCollections,
    label: 'Top collections',
  },
];

const rankingValues = [
  {
    label: '24 hours',
    value: 1,
  },
  {
    label: '7 days',
    value: 7,
  },
  {
    label: '30 days',
    value: 30,
  },
  {
    label: 'all time',
    value: -1,
  },
];

const Ranking = () => {
  const dispatch = useDispatch();
  const isMobile = useWindowSize() < desktopBreakpoint;
  const { pathname } = useLocation();
  const isRankingCollectionsPage = pathname === RouteTypes.RankingCollections;

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const { rewardsData } = useSelector(selectRankingRewards);

  return (
    <>
      <RankingPageWrapper isPopUpOpen={isPopUpOpen}>
        {isMobile && <TabsSelector links={rankingPages} />}
        <RankingPageContainer>
          <RankingPageHeader data-test-id="ranking_header">
            { !isMobile
            && (
              <MarketplaceHeader withoutMargin>
                <Typography
                  font="secondary"
                  size="popUpTitle"
                  textColor="textGray"
                  lSpacing={1.5}
                >
                  <MarketplaceLink
                    data-test-id="ranking_top_collectors_link"
                    to={RouteTypes.Ranking}
                    isActive={RouteTypes.Ranking === pathname}
                  >
                    Top collectors
                  </MarketplaceLink>
                  <MarketplaceLinkSeparator>
                    |
                  </MarketplaceLinkSeparator>
                  <MarketplaceLink
                    data-test-id="ranking_top_collections_link"
                    to={RouteTypes.RankingCollections}
                    isActive={RouteTypes.RankingCollections === pathname}
                  >
                    Top collections
                  </MarketplaceLink>
                </Typography>
              </MarketplaceHeader>
            )}
            {
              isRankingCollectionsPage && (
                <RankingHeaderSelectContainer data-test-id="ranking_header_select_container">
                  <ReactSelectContainer
                    fluid
                    transparent
                    withoutTopMargin
                  >
                    <Select
                      defaultValue={rankingValues[3]}
                      options={rankingValues}
                      isSearchable={false}
                      classNamePrefix="react-select"
                      onChange={(res) => res && dispatch(setRankingFilters({ days: res.value }))}
                    />
                  </ReactSelectContainer>
                </RankingHeaderSelectContainer>
              )
            }
          </RankingPageHeader>
          <Switch>
            <Route exact path={RouteTypes.Ranking}>
              <RankingCollectors setIsPopUpOpen={() => setIsPopUpOpen(true)} />
            </Route>
            <Route exact path={RouteTypes.RankingCollections}>
              <RankingPage />
            </Route>
            <Redirect to={RouteTypes.Ranking} />
          </Switch>
        </RankingPageContainer>
        <DiscordFloatingLogo hideOnMobile />
      </RankingPageWrapper>
      {
        isPopUpOpen && rewardsData && (
          <RewardPoolPopUp
            onClose={() => setIsPopUpOpen(false)}
            qualifiedForReward={rewardsData.collectorsCount}
            rewardPool={rewardsData.rewardPool}
            rewards={rewardsData.rewards}
          />
        )
      }
    </>
  );
};

export default Ranking;
