// @ts-ignore
import * as fcl from '@onflow/fcl';
// @ts-ignore
import * as t from '@onflow/types';

const CODE = fcl.cdc`
import NonFungibleToken from 0x631e88ae7f1d7c20
import StarlyCard from 0x697d72a988a77070

pub fun main(address: Address): {String: [UInt64]} {
    let account = getAccount(address)
    let ret: {String: [UInt64]} = {}

    if (account.getCapability<&StarlyCard.Collection{NonFungibleToken.CollectionPublic, StarlyCard.StarlyCardCollectionPublic}>(StarlyCard.CollectionPublicPath).check()) {
        let starlyCardCollectionRef = account.getCapability(StarlyCard.CollectionPublicPath)!.borrow<&{NonFungibleToken.CollectionPublic}>()
            ?? panic("Could not borrow StarlyCard receiver reference")
        ret["starly"] = starlyCardCollectionRef.getIDs();
    }

    return ret;
}`;

export function flowFetchNFTIDsTestnetScript(address: string) {
  if (address == null) return Promise.resolve(false);

  return fcl.send([
    fcl.script(CODE),
    fcl.args([
      fcl.arg(address, t.Address),
    ]),
  ]).then(fcl.decode);
}
