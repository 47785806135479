import React, { useState, useEffect } from 'react';
import { selectAuthUserId } from 'store/auth/authSelectors';
import { packBattleClose } from 'store/user/userActions';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Button';
import PopUp from 'components/PopUp';
import {
  ButtonsContainer,
  SubtitleText,
  TittleText,
  ButtonGradient,
  LoadingSpinner,
} from './styled';

const WalletWrong = () => {
  const [hidden, setHidden] = useState(false);
  const [locked, setLocked] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector(selectAuthUserId);

  /**
   * Move user to vesting page
   */
  const openVesting = () => {
    if (!locked) {
      setLocked(true);
      dispatch(packBattleClose({
        id: userId,
        redirect: true,
      }));
    }
  };

  /**
   * Just close modal
   */
  const closePackBattleModal = () => {
    if (!locked) {
      setHidden(true);
      dispatch(packBattleClose({
        id: userId,
        redirect: false,
      }));
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'auto'; };
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <>
      <PopUp>
        <SubtitleText size="popUpTitle" textColor="black" font="secondary">
          Congratulations! You are the winner of pack battle
        </SubtitleText>
        <TittleText size="popUpTitle" textColor="black" font="secondary">
          Your token prize will be gradually unlocked for you
          in the vesting section in a course of 30 days.
        </TittleText>
        <ButtonsContainer>
          <ButtonGradient onClick={() => openVesting()}>
            {locked && (
              <LoadingSpinner />
            )}
            {!locked && 'Go to vesting'}
          </ButtonGradient>
          <Button background="black" onClick={() => closePackBattleModal()}>Close</Button>
        </ButtonsContainer>
      </PopUp>
    </>
  );
};

export default WalletWrong;
