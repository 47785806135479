import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectApp = ({ app }: RootState) => app;

export const selectIsHeaderVisible = createSelector(
  selectApp, ({ isHeaderVisible }) => isHeaderVisible,
);

export const selectIsFooterVisible = createSelector(
  selectApp, ({ isFooterVisible }) => isFooterVisible,
);

export const selectActiveCard = createSelector(
  selectApp, ({ activeCard }) => activeCard,
);

export const selectPrevPathname = createSelector(
  selectApp, ({ prevPathname }) => prevPathname,
);

export const selectMoonpayUrl = createSelector(
  selectApp, ({ moonpayUrl }) => moonpayUrl,
);

export const selectCoinMarketCapInfo = createSelector(
  selectApp, ({ coinMarketCapInfo }) => coinMarketCapInfo,
);
