import { createReducer } from '@reduxjs/toolkit';
import { VestingClaimHistoryRecord } from '@starly/starly-types';
import {
  vestingLoginResponse,
  vestingReset,
  vestingToggleModal,
  vestingBalanceResponse,
  vestingClaimRequest,
  vestingToggleWalletLoading,
  vestingHistoryResponse,
  vestingHistoryRequest,
  SupportedBlockchain,
  VestingWallet,
  vestingLoginRequest,
  vestingToggleLoading,
  vestingRewardBalanceResponse,
} from './vestingActions';

export interface VestingSlice {
  wallet?: {
    blockchain: SupportedBlockchain,
    address: string,
    providerId?: string,
  },
  balance?: { [key: string]: VestingWallet }
  rewardBalance?: { [key: string]: VestingWallet }
  currentTime: number,
  history: {
    transactions: VestingClaimHistoryRecord[],
    isLoading: boolean,
  }
  modal: {
    isOpen: boolean,
    error: {
      message: string,
      code?: number,
    }
  }
  isLoading: boolean
}

const initialState: VestingSlice = {
  wallet: undefined,
  balance: undefined,
  rewardBalance: undefined,
  currentTime: 0,
  history: {
    transactions: [],
    isLoading: false,
  },
  modal: {
    isOpen: false,
    error: {
      message: '',
      code: 0,
    },
  },
  isLoading: false,
};

const vestingReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(vestingToggleLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(vestingLoginRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(vestingLoginResponse, (state, {
      payload: {
        wallet,
      },
    }) => {
      state.wallet = wallet;
      state.isLoading = false;
    })
    .addCase(vestingBalanceResponse, (state, {
      payload: {
        balance,
        currentTime,
      },
    }) => {
      state.balance = balance;
      state.currentTime = currentTime;
    })
    .addCase(vestingRewardBalanceResponse, (state, {
      payload: {
        balance,
        currentTime,
      },
    }) => {
      state.rewardBalance = balance;
      state.currentTime = currentTime;
    })
    .addCase(vestingClaimRequest, (state, {
      payload: {
        walletId,
        isReward,
      },
    }) => {
      if (isReward && state.rewardBalance) {
        state.rewardBalance[walletId].isLoading = true;
      } else if (state.balance) {
        state.balance[walletId].isLoading = true;
      }
    })
    .addCase(vestingHistoryRequest, (state) => {
      state.history.isLoading = true;
    })
    .addCase(vestingHistoryResponse, (state, {
      payload: {
        transactions,
      },
    }) => {
      state.history.transactions = transactions;
      state.history.isLoading = false;
    })
    .addCase(vestingToggleWalletLoading, (state, {
      payload: {
        walletId,
        isReward,
      },
    }) => {
      if (isReward && state.rewardBalance) {
        state.rewardBalance[walletId].isLoading = false;
      } else if (state.balance) {
        state.balance[walletId].isLoading = false;
      }
    })
    .addCase(vestingReset, (state) => {
      state.wallet = undefined;
      state.balance = undefined;
    })
    .addCase(vestingToggleModal, (state, { payload: { isOpen, errorMessage, errorCode } }) => {
      state.modal.isOpen = isOpen;
      state.modal.error.message = errorMessage && errorMessage.length > 0 ? errorMessage : '';
      state.modal.error.code = errorCode || 0;
    }),
);

export default vestingReducer;
