import React, {
  FC,
  Suspense,
  useEffect, useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import theme from 'components/theme';
import { mediaDesktop } from 'breakpoints';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Routes from 'routes';
import { auth, trackException } from 'global/firebase';
import { selectAuthAuthorized } from 'store/auth/authSelectors';
import { authLogoutRequest } from 'store/auth/authActions';
import { AppContext } from 'context/context';
import store, { history } from './store/store';

import Header from './components/Header';
import ThemeProvider from './components/ThemeProvider';
import SideMenu from './components/SideMenu';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import GetInView from './views/GetInPopUp';
import { useIosScale } from './hooks/useIosScale';
import { isIos } from './helpers/isIos';
import './i18n/config';
import ClaimRewardLabel from './components/ClaimRewardLabel';
import RandomTopLabel from './components/RandomTopLabel';

const persistor = persistStore(store);

const Root = styled.div<{ $winHeight: number }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: ${({ $winHeight }) => `${$winHeight}px`};
  background: ${theme.colors.black};

  ${mediaDesktop} {
    background: ${theme.colors.black};
  }
`;

const Wrapper = styled.main`
  flex-direction: column;
  flex: 1;
  display: flex;
`;

const NotchWrapper = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  padding-left: env(safe-area-inset-left);
`;

const LogoutWatcher: FC = () => {
  const dispatch = useDispatch();
  const isUserAuthorized = useSelector(selectAuthAuthorized);
  useEffect(() => {
    auth.onAuthStateChanged((currUser: any) => {
      if (!currUser && isUserAuthorized) {
        dispatch(authLogoutRequest({}));
      }
    });
  }, []);

  return null;
};
const url = new URL(window.location.href);
const headless = url.searchParams.get('headless');

const ProviderComponent: FC = React.memo(() => {
  const topRef = useRef<HTMLDivElement>(null);
  return (
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <LogoutWatcher />
        <Wrapper>
          <AppContext.Provider value={{ topRef }}>
            {!headless && <Header />}
            {!headless && <SideMenu />}
            {!headless && <GetInView />}
            {!headless && <ScrollToTop />}
            <div ref={topRef} />
            <ClaimRewardLabel />
            <RandomTopLabel />
            <NotchWrapper><Routes /></NotchWrapper>
          </AppContext.Provider>
        </Wrapper>
        {!headless && <Footer />}
      </ConnectedRouter>
    </PersistGate>
  );
});

const App: FC = () => {
  const [winHeight, setWinHeight] = useState<number>(window.innerHeight);
  const scale = useIosScale();
  const updateSize = () => {
    setWinHeight(
      window.innerHeight * Math.max(window.visualViewport?.scale || 1, 1),
    );
  };

  useEffect(() => {
    if (isIos()) {
      const meta = document.querySelector('meta[name="viewport"]');
      if (meta) {
        meta.setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1, viewport-fit=cover');
      }
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  useEffect(() => updateSize(), [
    scale, window.screen.width,
    window.screen.height, window.innerHeight,
    window.innerWidth,
  ]);

  /**
   * Track all uncatched errors via Firebase Events
   */
  useEffect(() => {
    window.onerror = (error) => {
      trackException(error.toString(), true);
    };
  }, []);

  return (
    <ThemeProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Root $winHeight={winHeight}>
          <Provider store={store}>
            <ProviderComponent />
          </Provider>
        </Root>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
