import { createAction } from '@reduxjs/toolkit';
import {
  FungibleTokenType, Media, PackDistribution, PackPricing, PackRarity, User,
} from '@starly/starly-types';

export type PackPurchaseStatus = 'initial' | 'purchasing';

type PackPurchaseStatusPayload = {
  status: PackPurchaseStatus,
};

type InitPackRequest = {
  collectionId: string
  rarity: PackRarity
};

type InitPackResponse = {
  authorName: string
  authorUsername: string
  avatar: string | Media
  collectionId: string
  collectionTitle: string
  packDistribution: PackDistribution,
  packPricing: PackPricing,
  price: number
  token: FungibleTokenType
};

type BuyPackRequestPayload = {
  count: number
  price: number
  token: FungibleTokenType
  paymentCurrency: FungibleTokenType
  collectionId: string
  rarity: PackRarity
  chain?: string
};

type BuyPackResponsePayload = {
  status: string
};

type InitUserPacksRequestPayload = {
  userId: string
};

type InitUserPacksResponsePayload = {
  data: any[]
};

type OpenPackRequestPayload = {
  collectionId: string
  packId: string
};

type InitOpenPackResposePayload = {
  error?: boolean
  cardsToOpen?: any[]
};

type RevealCardPayload = {
  id: number
};

type UserPacksCountRequest = {
  count: number
};

type PackBattleLeader = {
  user: User,
  score: number,
  packBattleEnded: boolean
};

type PackBattleLeaderResponse = {
  data: PackBattleLeader[];
  collectionId: string
};

export const userPacksCountRequest = createAction('pack/userPackRequest');
export const userPacksCountResponse = createAction<UserPacksCountRequest>('pack/userPacksResponse');

// Buy Actions

export const initPackRequest = createAction<InitPackRequest>('pack/initPackRequest');
export const initPackResponse = createAction<InitPackResponse>('pack/initPackResponse');

export const buyPackRequest = createAction<BuyPackRequestPayload>('pack/BuyRequest');
export const buyPackResponse = createAction<BuyPackResponsePayload>('pack/BuyResponse');

export const setBuyToDefault = createAction('pack/toDefault');
export const setPackPurchaseStatus = createAction<PackPurchaseStatusPayload>('pack/set-purchase-status');

// Open and get actions

export const initUserPacksRequest = createAction<InitUserPacksRequestPayload>('pack/initUserPacksRequest');
export const initUserPacksResponse = createAction<InitUserPacksResponsePayload>('pack/initUserPacksResponse');

export const openPackRequest = createAction<OpenPackRequestPayload>('pack/openPackRequest');
export const openPackResponse = createAction('pack/openPackResponse');
export const openPackFailure = createAction('pack/openPackFailure');

export const initOpenPackPageRequest = createAction<OpenPackRequestPayload>('pack/initOpenPackPageRequest');
export const initOpenPackPageResponse = createAction<InitOpenPackResposePayload>('pack/initOpenPackPageResponse');

export const revealCard = createAction<RevealCardPayload>('pack/revealCard');
export const revealAll = createAction('pack/revealAll');

export const startLoad = createAction('pack/startLoad');
export const switchOpenPackLoading = createAction('pack/switchLoading');

// Pack page
export const setPackPageLoading = createAction<{ isLoading: boolean }>('pack/set-pack-page-loading');

export const packBattleLeaderRequest = createAction<{ collectionId: string }>('packs/packBattleLeaderRequest');
export const packBattleLeaderResponse = createAction<PackBattleLeaderResponse>('packs/packBattleLeaderResponse');
