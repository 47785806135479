import { createReducer } from '@reduxjs/toolkit';
import { Credential, AdditionalUserInfo } from '../../types';
import {
  authLogoutResponse,
  authLoginEmailResponse,
  authLoginGoogleResponse,
  authLoginTwitterResponse,
  authLoginFacebookResponse,
  authLoginWechatResponse,
  authSignupEmailResponse,
  authError,
  authSetIsProfileCreating,
  authSetIsAuthorized,
  authLoginGoogleRequest,
  authLoginTwitterRequest,
  authLoginFacebookRequest,
  authLoginWechatRequest,
  authLoginCustomTokenResponse,
} from './authActions';

export interface AuthSlice {
  userId: string,
  authorized: boolean,
  isProfileCreating: boolean,
  credential?: Credential,
  additionalUserInfo?: AdditionalUserInfo,
  error?: string,
  onAuthRedirect?: string,
  isAdmin: boolean,
}

const initialState: AuthSlice = {
  userId: '',
  authorized: false,
  isProfileCreating: false,
  credential: undefined,
  additionalUserInfo: undefined,
  error: undefined,
  onAuthRedirect: undefined,
  isAdmin: false,
};

const authReducer = createReducer(
  initialState,
  (builder) => builder
    .addCase(authLogoutResponse, (state) => {
      state.userId = '';
      state.error = undefined;
      state.authorized = false;
      state.credential = undefined;
      state.additionalUserInfo = undefined;
      state.isAdmin = false;
    })
    .addCase(authLoginEmailResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.authorized = true;
      state.isProfileCreating = false;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authLoginCustomTokenResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.authorized = true;
      state.isProfileCreating = false;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authLoginGoogleRequest, (state) => {
      state.error = undefined;
    })
    .addCase(authLoginGoogleResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authLoginTwitterRequest, (state) => {
      state.error = undefined;
    })
    .addCase(authLoginTwitterResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authLoginFacebookRequest, (state) => {
      state.error = undefined;
    })
    .addCase(authLoginFacebookResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authLoginWechatRequest, (state) => {
      state.error = undefined;
    })
    .addCase(authLoginWechatResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isProfileCreating = true;
      state.isAdmin = isAdmin;
    })
    .addCase(authSignupEmailResponse, (state, {
      payload: {
        credential, additionalUserInfo, uid, isAdmin,
      },
    }) => {
      state.userId = uid;
      state.error = undefined;
      state.authorized = true;
      state.isProfileCreating = false;
      state.credential = credential;
      state.additionalUserInfo = additionalUserInfo;
      state.isAdmin = isAdmin;
    })
    .addCase(authSetIsAuthorized, (state, { payload }) => {
      state.authorized = payload;
    })
    .addCase(authSetIsProfileCreating, (state, { payload }) => {
      state.isProfileCreating = payload;
    })
    .addCase(authError, (state, {
      payload: {
        error,
      },
    }) => {
      // TODO Create global error handler
      state.error = error.message;
    }),
);

export default authReducer;
