// @ts-ignore
import * as fcl from '@onflow/fcl';
import { functions } from '../../global/firebase';

const additionalAuthorization = async (account = {}) => {
  // eslint-disable-next-line max-len
  const additionalAuthorizerAddress = fcl.sansPrefix(process.env.REACT_APP_ADDITIONAL_AUTHORIZER_ADDRESS);
  const keyId = 0;

  const { account: user } = await fcl.send([fcl.getAccount(additionalAuthorizerAddress)]);

  const key = user.keys[keyId];
  let sequenceNum;
  if (account.role.proposer) sequenceNum = key.sequenceNumber;

  const signingFunction = async (data) => {
    const signAsAdditionalAuthorizer = functions.httpsCallable('signAsAdditionalAuthorizer');

    const response = await signAsAdditionalAuthorizer(JSON.stringify(data));

    return ({
      addr: additionalAuthorizerAddress,
      keyId: key.index,
      signature: response.data,
    });
  };

  return {
    ...account,
    addr: additionalAuthorizerAddress,
    keyId: key.index,
    sequenceNum,
    signature: account.signature || null,
    signingFunction,
    resolve: null,
    roles: account.roles,
  };
};

export default additionalAuthorization;
